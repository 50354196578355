









import { defineComponent, PropType } from '@vue/composition-api';
import { Location } from 'vue-router';

import { useMessages } from '@/base/app';
import BaseButton from '@/base/app/components/atoms/BaseButton.vue';

export default defineComponent({
  name: 'TrainingContentVersionWarning',
  components: { BaseButton },
  inheritAttrs: false,
  props: { to: { type: Object as PropType<Location>, required: true } },
  setup() {
    const msgs = useMessages({ prefix: 'training.molecules.contentVersionWarning' });
    return { description: msgs.of('notLatestVersion'), labelOpen: msgs.of('open') };
  },
});
