import { computed, ref } from '@vue/composition-api';
import moment from 'moment';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { Optional } from '@/base/types';
import { GetGroupExamsContent, isFailed, useGetGroupExams } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { isDefined } from '@/utils/TsUtils';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { useAddGroupExam } from '../../../usecases';
import {
  GroupExamOpenForm,
  GroupExamOpenFormValue,
} from '../molecules/GroupExamOpenFormComposable';

type Target = {
  groupId: string;
  courseId: string;
  contentId: string;
  problemCount: number;
};

type Row = {
  userId: string;
  userName: string;
  avatar: UserAvatar;
  passed: number;
  max?: number;
} & (Record<string, string> | {});

const HEADERS: DataTableHeader[] = [
  { value: 'avatar', text: '', width: 80, sortable: false },
  { value: 'userName', text: '', class: 'base-table-name' },
  { value: 'passed', text: '', width: 150 },
  { value: 'max', text: '', width: 150 },
];

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(msg: string, ok?: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(msg, ok);
  }
  return { dialogFullScreen, dialog, opened, error, info };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

export function useGroupExamOpenDialog(emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'training.organisms.groupExamOpenDialog' });

  const { dialog, dialogFullScreen, opened, error, info } = useDialogFullScreen();
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const { group } = useGlobalStore();
  const trainees = computed<UserAvatar[]>(
    () => group.value?.users.filter((u) => u.role === 'trainee' && !u.removed) ?? []
  );

  const target = ref<Target>();
  const loading = ref(false);
  const content = ref<GetGroupExamsContent>();
  const examHeaders = ref<DataTableHeader[]>([]);
  const examRows = ref<Row[]>([]);
  const selected = ref<Row[]>([]);
  const userIds = computed(() => selected.value.map((item) => item.userId));

  const form = ref<GroupExamOpenForm>();
  const value = ref<GroupExamOpenFormValue>({
    level: 'invisible_to_user',
    allTrainees: true,
    holdingType: 'immediate',
  });
  const editing = ref(false);
  const updating = ref(false);

  const route = useRoute();
  const router = useRouter();
  function close() {
    dialog.value = { display: false };
    target.value = undefined;
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  function calculate(v: Optional<number>, count: number) {
    if (!isDefined(v) || !isDefined(count)) return undefined;
    if (!v || !count) return 0;
    return Math.round((v / count) * 1000) / 10;
  }

  function formatScore(item: { score?: number; isPassed?: boolean; passingStandard?: number }) {
    if (!isDefined(item.isPassed) || !isDefined(item.passingStandard))
      return msgs.of('examScore', { score: item.score ?? 0 }).value;
    const key = item.isPassed ? 'passedScore' : 'notPassedScore';
    return msgs.of(key, { score: item.score ?? 0, passingStandard: item.passingStandard }).value;
  }

  const getGroupExams = useGetGroupExams();
  async function fetch() {
    assertIsDefined(target.value, 'target');
    selected.value = [];
    loading.value = true;
    const res = await getGroupExams.execute(target.value);
    if (isFailed(res)) {
      loading.value = false;
      info(msgs.of('noData').value, close);
      return;
    }
    content.value = res.content;
    examHeaders.value = res.groupExams
      .map((g) => ({ id: g.id, times: g.times }))
      .sort((a, b) => (a.times > b.times ? -1 : 1))
      .map((g) => ({ value: g.id, text: msgs.of('examTimes', g).value, width: 150 }));
    const userExams = new Map(
      res.groupExams.flatMap((ge) =>
        ge.userExams.map((ue) => [
          `${ue.userId}_${ge.id}`,
          {
            userId: ue.userId,
            groupExamId: ge.id,
            status: ue.status,
          },
        ])
      )
    );
    const userResults = new Map(
      res.examResults.map((r) => [
        r.version === 1 ? `${r.userId}_v0` : `${r.userId}_${r.groupExamId}`,
        {
          userId: r.userId,
          groupExamId: r.version === 1 ? 'v0' : r.groupExamId,
          score: calculate(r.score, r.problemCount),
          isPassed: r.version === 1 || r.version === 2 ? undefined : r.isPassed,
          passingStandard:
            r.version === 1 || r.version === 2
              ? undefined
              : calculate(r.passingStandard, r.problemCount),
        },
      ])
    );
    examRows.value = trainees.value
      .map((u) => {
        const exams = Object.fromEntries(
          examHeaders.value.map((item) => {
            const key = `${u.id}_${item.value}`;
            const r = userResults.get(key);
            if (r) return [r.groupExamId, formatScore(r)];
            const e = userExams.get(key);
            if (e) return [e.groupExamId, msgs.of(e.status).value];
            return [item.value, '-'];
          })
        );
        const resultKeys = [...userResults.keys()].filter((key) => key.startsWith(`${u.id}_`));
        const max = Math.max(...resultKeys.map((key) => userResults.get(key)?.score ?? Number.NaN));
        let passed = -1;
        if (resultKeys.length > 0) {
          const list = resultKeys.map((key) => userResults.get(key)?.isPassed);
          if (list.some((p) => p === true)) passed = 1;
          else if (list.some((p) => p === false)) passed = 0;
        }
        return {
          userId: u.id,
          userName: u.name ?? '',
          avatar: u,
          passed,
          max: Number.isFinite(max) ? max : undefined,
          ...exams,
        };
      })
      .sort((a, b) => {
        if (a.passed === b.passed && a.max === b.max) return a.userName < b.userName ? -1 : 1;
        if (a.passed === b.passed) return (a.max ?? -1) < (b.max ?? -1) ? -1 : 1;
        return a.passed < b.passed ? -1 : 1;
      });
    if (!examRows.value.every((row) => row.passed === 1)) {
      selected.value = examRows.value.filter((row) => row.passed !== 1);
    } else {
      selected.value = [...examRows.value];
    }
    loading.value = false;
  }

  function open(payload: Target & { requiredTime: number; passingStandard?: number }) {
    const start = moment().add(1, 'day').startOf('hour');
    const finish = start.clone().add(4, 'hours');
    target.value = payload;
    value.value = {
      level: 'invisible_to_user',
      passingStandard: payload.passingStandard,
      timeLimit: payload.requiredTime,
      allTrainees: true,
      holdingType: 'immediate',
      scheduledStart: start,
      scheduledFinish: finish,
    };
    editing.value = false;
    dialog.value = { display: true };
    fetch();
  }

  function changeAllTrainees(v: boolean) {
    editing.value = !v;
  }

  const addGroupExam = useAddGroupExam();
  async function add() {
    assertIsDefined(target.value, 'target');
    assertIsDefined(value.value, 'value');
    updating.value = true;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { problemCount, ...t } = target.value;
    const {
      holdingType,
      level,
      allTrainees,
      timeLimit,
      passingStandard,
      scheduledStart,
      scheduledFinish,
    } = value.value;
    const s =
      holdingType === 'immediate' ? { scheduledFinish } : { scheduledStart, scheduledFinish };
    const res = await addGroupExam.execute({
      ...t,
      ...s,
      visibilityLevel: level,
      timeLimit,
      passingStandard,
      userIdsToBeTested: allTrainees ? trainees.value.map((u) => u.id) : userIds.value,
    });
    if (isFailed(res)) {
      updating.value = false;
      error(res.errors);
      return;
    }
    waitTransition(() => {
      updating.value = false;
      close();
      emit('done');
    });
  }

  function submit() {
    assertIsDefined(value.value, 'value');
    const { holdingType } = value.value;
    const key = holdingType === 'immediate' ? 'confirmImmediate' : 'confirmSchedule';
    confirm(msgs.of(key).value, add);
  }

  function validate() {
    assertIsDefined(form.value, 'form');
    form.value.submit();
  }

  const subtitle = computed(() => content.value?.name);
  const headers = computed(() => [
    ...HEADERS.map((item) => ({ ...item, text: msgs.of(item.value).value })),
    ...examHeaders.value,
  ]);
  const problemCount = computed(() => target.value?.problemCount ?? 0);

  return {
    dialog,
    dialogFullScreen,
    confirmDialog,
    trainees,
    form,
    value,
    selected,
    userIds,
    loading,
    editing,
    updating,
    subtitle,
    headers,
    problemCount,
    items: examRows,
    title: msgs.of('openExam'),
    description: msgs.of('description'),
    labelStart: msgs.of('open'),
    close,
    opened,
    open,
    changeAllTrainees,
    submit,
    validate,
  };
}

export type GroupExamOpenDialog = ReturnType<typeof useGroupExamOpenDialog>;
