import { onMounted, ref, watch } from '@vue/composition-api';

import { Schedule, ScheduleTag } from '@/base/domains';

export type ScheduleTagSelectorChangePayload = {
  id: string;
  tagIds: string[];
  done: (forceInit: boolean) => void;
};

export type PropsScheduleTagSelector = {
  schedule: Schedule;
  tags: ScheduleTag[];
};

export function useScheduleTagSelector(
  props: PropsScheduleTagSelector,
  emit: (name: string, arg: ScheduleTagSelectorChangePayload) => void
) {
  const ids = ref<string[]>([]);
  const updating = ref(false);

  function init() {
    ids.value = props.schedule.tags.map((t) => t.id);
  }
  onMounted(init);
  watch(() => props.schedule, init);

  function submit() {
    const a = props.schedule.tags
      .map((t) => t.id)
      .sort()
      .join();
    const b = [...ids.value].sort().join();
    if (a === b) return;
    updating.value = true;
    emit('change', {
      id: props.schedule.id,
      tagIds: [...ids.value],
      done: (forceInit) => {
        updating.value = false;
        if (forceInit) init();
      },
    });
  }

  return { ids, updating, submit };
}
