


































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { Question } from '@/base/domains';

import QuestionCommentAdd from '../molecules/QuestionCommentAdd.vue';
import QuestionCommentChange from '../molecules/QuestionCommentChange.vue';
import QuestionHeader from '../molecules/QuestionHeader.vue';
import QuestionResolvedFooter from '../molecules/QuestionResolvedFooter.vue';
import { PropsContentQuestion, useContentQuestion } from './ContentQuestionComposable';

type Props = PropsContentQuestion;

export default defineComponent({
  name: 'TrainingContentQuestion',
  components: { QuestionHeader, QuestionCommentChange, QuestionCommentAdd, QuestionResolvedFooter },
  props: {
    question: { type: Object as PropType<Question>, required: true },
    disabledReferTo: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ['click-anchor', 'click-user', 'move', 'saved'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentQuestion(props, emit);
  },
});
