var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","md":""}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.title))])]),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"input-value":_vm.value.scope.group,"label":_vm.labelGroup,"hide-details":""},on:{"change":function($event){return _vm.changeScope({ group: $event })}}})],1),((_vm.groupRole === 'trainer' || _vm.groupRole === 'mentor') && !_vm.disabled)?[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"auto"}},[_c('v-radio-group',{attrs:{"value":_vm.value.scope.mine,"disabled":!_vm.value.scope.group,"hide-details":"","mandatory":"","row":""},on:{"change":function($event){return _vm.changeScope({ mine: $event })}}},[_c('v-radio',{attrs:{"value":true,"label":_vm.labelCreate}}),_c('v-radio',{attrs:{"value":false,"label":_vm.labelAll}})],1)],1)]:_vm._e(),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"auto"}},[_c('v-checkbox',{attrs:{"input-value":_vm.value.scope.private,"label":_vm.labelPrivate,"hide-details":""},on:{"change":function($event){return _vm.changeScope({ private: $event })}}})],1),_c('v-col',{staticClass:"py-0 ml-auto",attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false,"min-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-1",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('span',[_vm._v(_vm._s(_vm.labelMore))]),_c('v-icon',{class:{ 'icon--rotate-half': _vm.menu },attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-sheet',{staticClass:"pb-10"},[_c('v-subheader',{staticClass:"px-10"},[_vm._v(_vm._s(_vm.labelFilterSetting))]),_c('div',{staticClass:"px-10"},[(_vm.textTypes)?_c('v-select',{staticClass:"mb-5",attrs:{"value":_vm.value.option.textType,"label":_vm.labelText,"items":_vm.textTypes,"item-value":"value","item-text":"label","dense":"","outlined":"","clearable":"","hide-details":""},on:{"change":function($event){return _vm.changeOption({ textType: $event })}}}):_vm._e(),_c('v-select',{staticClass:"mb-5",attrs:{"value":_vm.value.option.marker,"label":_vm.labelMarker,"items":_vm.markers,"item-value":"value","item-text":"label","dense":"","outlined":"","clearable":"","hide-details":""},on:{"change":function($event){return _vm.changeOption({ marker: $event })}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.value !== 'none')?_c('v-icon',{staticClass:"mr-3",attrs:{"color":item.value,"small":""}},[_vm._v(" mdi-marker ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.label))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [(item.value !== 'none')?_c('v-icon',{staticClass:"mr-3",attrs:{"color":item.value,"small":""}},[_vm._v(" mdi-marker ")]):_vm._e(),_c('span',[_vm._v(_vm._s(item.label))])]}}])}),_c('v-checkbox',{staticClass:"mb-5",attrs:{"value":_vm.value.option.doNotShowOtherVersions,"label":_vm.doNotShowOtherVersions,"dense":"","hide-details":""},on:{"change":function($event){return _vm.changeOption({ doNotShowOtherVersions: $event })}}})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }