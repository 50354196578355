


























































import { defineComponent } from '@vue/composition-api';

import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import UserExamActiveWarning from '../molecules/UserExamActiveWarning.vue';
import UserExamFinished from '../molecules/UserExamFinished.vue';
import UserExamNotStarted from '../molecules/UserExamNotStarted.vue';
import UserExamProblems from '../molecules/UserExamProblems.vue';
import UserExamResults from '../molecules/UserExamResults.vue';
import { PropsUserExamContainer, useUserExamContainer } from './UserExamContainerComposable';

type Props = PropsUserExamContainer;

export default defineComponent({
  name: 'TrainingUserExamContainer',
  components: {
    UserExamNotStarted,
    UserExamProblems,
    UserExamResults,
    UserExamFinished,
    UserExamActiveWarning,
    Loading,
    BaseDialogOk,
    DialogAnchorConfirm,
  },
  props: {
    examId: { type: String, required: true },
    groupId: { type: String, required: true },
    showProminent: { type: Boolean, default: false },
    shrinkLimit: { type: Number, default: 16 },
    isMobile: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useUserExamContainer(props);
  },
});
