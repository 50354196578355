

















































import { computed, defineComponent, provide, ref } from '@vue/composition-api';

import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import MyApp from '@/base/app/components/MyApp.vue';
import { useCourseColor } from '@/base/app/utils/ColorUtils';

import UserExamAppHeaderEnd from '../components/organisms/UserExamAppHeaderEnd.vue';
import UserExamAppHeaderExtension from '../components/organisms/UserExamAppHeaderExtension.vue';
import UserExamAppHeaderProminent from '../components/organisms/UserExamAppHeaderProminent.vue';
import UserExamContainer from '../components/organisms/UserExamContainer.vue';
import { UserExamStoreKey, useUserExamStore } from '../stores';

export default defineComponent({
  name: 'TrainingGroupUserExamPage',
  components: {
    MyApp,
    UserExamAppHeaderEnd,
    UserExamAppHeaderProminent,
    UserExamAppHeaderExtension,
    UserExamContainer,
    BaseDialogOk,
  },
  props: {
    id: { type: String, required: true },
    examId: { type: String, required: true },
  },
  setup() {
    const store = useUserExamStore();
    provide(UserExamStoreKey, store);

    const inProgress = computed(() => store.page.value === 'in_progress');
    const courseImage = computed(() => store.exam.value?.courseImage);
    const courseColorClass = computed(() => {
      if (!store.exam.value) return undefined;
      const { courseColorClass: ret } = useCourseColor(store.exam.value);
      return ret.value;
    });
    const contentName = computed(() => {
      if (!store.exam.value) return undefined;
      return store.exam.value.contentName;
    });

    const errorDialog = ref<InstanceType<typeof BaseDialogOk>>();
    function error(e: ErrorMessage[]) {
      if (!errorDialog.value) return;
      errorDialog.value.error(e);
    }

    return {
      loading: store.loading,
      notFound: store.notFound,
      inProgress,
      courseImage,
      courseColorClass,
      contentName,
      errorDialog,
      error,
    };
  },
});
