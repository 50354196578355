import { computed } from '@vue/composition-api';
import { Location } from 'vue-router';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { getMarkerBackground } from '@/base/app/utils/ColorUtils';
import { toMarker } from '@/base/app/utils/MarkerUtils';
import { ContentType, GroupRole, Memo, MemoContentReference } from '@/base/domains';
import { Optional } from '@/base/types';
import { GetContentContent } from '@/training/usecases';

import { MemoMenusChangeScopePayload, MemoMenusRemovePayload } from '../atoms/MemoMenusComposable';
import {
  ContentMemoCardClickAnchorPayload,
  ContentMemoCardSubmitPayload,
  PropsContentMemoCard,
} from './ContentMemoCardComposable';
import { ContentMemoSearchFieldsValue } from './ContentMemoSearchFieldsComposable';

type MemoLinkMarker = {
  caption: string;
  color: string;
  background: string;
};

type MemoLink =
  | {
      type: 'text' | 'exam' | 'workbook';
      problemIndex?: number;
      label: string;
      marker?: MemoLinkMarker;
    }
  | {
      type: 'version';
      version: string;
      label: string;
      marker?: MemoLinkMarker;
      to: Location;
    };

export type ContentMemosClickAnchorPayload = ContentMemoCardClickAnchorPayload;

export type ContentMemosChangeBodyPayload = ContentMemoCardSubmitPayload;

export type ContentMemosChangeScopePayload = MemoMenusChangeScopePayload;

export type ContentMemosRemovePayload = MemoMenusRemovePayload;

export type ContentMemosOpenPayload =
  | { type: 'text'; hash: string }
  | { type: 'exam' | 'workbook'; problemIndex: number; hash: string };

export type ContentMemosSearchValue = ContentMemoSearchFieldsValue;

export type ContentMemosMemo = PropsContentMemoCard & {
  updatedBy: UserAvatar;
};

export type PropsContentMemos = {
  value: Optional<ContentMemoSearchFieldsValue>;
  groupId: string;
  content: GetContentContent;
  memos: ContentMemosMemo[];
  groupRole?: GroupRole;
  myId: string;
  disabled: boolean;
};

export function useContentMemos(
  props: PropsContentMemos,
  emit: (
    name: string,
    args:
      | ContentMemosClickAnchorPayload
      | ContentMemosChangeBodyPayload
      | ContentMemosChangeScopePayload
      | ContentMemosRemovePayload
      | ContentMemosOpenPayload
      | ContentMemosSearchValue
  ) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.contentMemos' });

  function isDisabled(memo: Memo) {
    if (props.disabled) return true;
    if (memo.scope.type === 'private') return false;
    return !props.groupRole || !['trainer', 'mentor'].includes(props.groupRole);
  }

  function toType(
    type: ContentType,
    referTo: MemoContentReference
  ): { type: 'text' | 'exam' | 'workbook'; label: string; problemIndex?: number } {
    if ('problemIndex' in referTo) {
      let t: 'exam' | 'workbook' = 'exam';
      const { problemIndex } = referTo;
      if (type === 'text') t = 'workbook';
      const label = msgs.of(t, { no: problemIndex + 1 }).value;
      return { type: t, problemIndex, label };
    }
    return { type: 'text', label: msgs.of('text').value };
  }

  function toMarkerLink(referTo: MemoContentReference): Optional<MemoLinkMarker> {
    const marker = toMarker(referTo);
    if (!marker) return undefined;
    return {
      caption: marker.selection.caption,
      color: marker.selection.color,
      background: getMarkerBackground(marker.selection.color),
    };
  }

  const items = computed(() => {
    const { type, version, courseId } = props.content;
    const order = (m: Memo) => {
      const index = m.referTo && 'problemIndex' in m.referTo ? m.referTo.problemIndex + 1 : 0;
      const marker = toMarker(m.referTo);
      let pos = 0;
      if (marker?.selection) {
        const { position } = marker.selection;
        if (typeof position === 'number') pos = position + 10;
        else if (position === 'header') pos = 2;
        else if (position === 'body') pos = 1;
        else if (position === 'commentary') pos = 999;
      }
      return {
        version: version === m.referTo?.contentVersion ? 0 : m.referTo?.contentVersion ?? -1,
        index,
        xpath: marker ? [`0000${pos}`.slice(-4), marker.selection.start.xpath].join('_') : '0000',
        offset: marker ? marker.selection.start.offset : 0,
        scope: m.scope.type === 'group' ? 0 : 1,
        body: m.body,
      };
    };
    const filterMemo = (m: Memo) => {
      if (!props.value) return false;
      const { scope, option } = props.value;
      const pIndex = m.referTo && 'problemIndex' in m.referTo ? m.referTo.problemIndex : undefined;
      const marker = toMarker(m.referTo);
      return (
        ((scope.private && m.scope.type === 'private') ||
          (scope.group && !scope.mine && m.scope.type === 'group') ||
          (scope.group && scope.mine && m.scope.type === 'group' && m.createdBy === props.myId)) &&
        (!('textType' in option) ||
          !option.textType ||
          (option.textType === 'textBody' && pIndex === undefined) ||
          (option.textType === 'textWorkbook' && pIndex !== undefined)) &&
        (!option.marker ||
          (option.marker && option.marker === 'none' && !marker) ||
          (option.marker && option.marker === marker?.selection.color)) &&
        (!option.doNotShowOtherVersions ||
          (option.doNotShowOtherVersions && m.referTo && m.referTo.contentVersion === version))
      );
    };
    return props.memos
      .filter((item) => filterMemo(item.memo))
      .map((item) => {
        const disabled = isDisabled(item.memo);
        if (!item.memo.referTo) return { ...item, disabled };
        let link: MemoLink;
        const { referTo } = item.memo;
        if (referTo.contentVersion !== version) {
          link = {
            ...toType(type, referTo),
            type: 'version',
            version: msgs.of('version', { version: referTo.contentVersion }).value,
            marker: toMarkerLink(referTo),
            to: {
              name: 'groupContentVersion',
              params: {
                id: props.groupId,
                courseId,
                contentId: referTo.contentId,
                contentVersion: referTo.contentVersion.toString(),
              },
              query: { memo: item.memo.id },
            },
          };
        } else {
          link = { ...toType(type, referTo), marker: toMarkerLink(referTo) };
        }
        const updatedByName =
          item.updatedBy.name ?? msgs.of('unknown', { id: item.updatedBy.id }).value;
        return { ...item, updatedByName, link, disabled };
      })
      .sort((itemA, itemB) => {
        const a = order(itemA.memo);
        const b = order(itemB.memo);
        if (
          a.version === b.version &&
          a.index === b.index &&
          a.xpath === b.xpath &&
          a.offset === b.offset &&
          a.scope === b.scope
        )
          return a.body < b.body ? -1 : 1;
        if (
          a.version === b.version &&
          a.index === b.index &&
          a.xpath === b.xpath &&
          a.offset === b.offset
        )
          return a.scope - b.scope;
        if (a.version === b.version && a.index === b.index && a.xpath === b.xpath)
          return a.offset - b.offset;
        if (a.version === b.version && a.index === b.index) return a.xpath < b.xpath ? -1 : 1;
        if (a.version === b.version) return a.index - b.index;
        return a.version - b.version;
      });
  });

  function clickAnchor(payload: ContentMemoCardClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function changeBody(payload: ContentMemoCardSubmitPayload) {
    emit('change-body', payload);
  }

  function changeScope(payload: MemoMenusChangeScopePayload) {
    emit('change-scope', payload);
  }

  function remove(payload: MemoMenusRemovePayload) {
    emit('remove', payload);
  }

  function open(item: { memo: Memo; link: MemoLink }) {
    if (item.link.type === 'version') return;
    if ('problemIndex' in item.link && item.link.problemIndex !== undefined) {
      emit('open', {
        type: item.link.type,
        problemIndex: item.link.problemIndex,
        hash: item.memo.id,
      });
      return;
    }
    emit('open', { type: 'text', hash: item.memo.id });
  }

  function change(v: ContentMemoSearchFieldsValue) {
    emit('change', v);
  }

  return {
    items,
    labelMemo: msgs.of('memo'),
    labelUpdated: msgs.of('updated'),
    labelNoData: msgs.of('noData'),
    clickAnchor,
    changeBody,
    changeScope,
    remove,
    open,
    change,
  };
}
