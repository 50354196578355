
















































































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseText from '@/base/app/components/atoms/BaseText.vue';
import BaseTextDate from '@/base/app/components/atoms/BaseTextDate.vue';
import BaseTextTime from '@/base/app/components/atoms/BaseTextTime.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import ScheduleTagSelect from '@/base/app/components/molecules/ScheduleTagSelect.vue';
import TextEditor from '@/base/app/components/molecules/TextEditor.vue';
import MentionMarkdown from '@/base/app/components/organisms/MentionMarkdown.vue';
import { ScheduleTag } from '@/base/domains';

import { PropsScheduleDialog, useScheduleDialog } from './ScheduleDialogComposable';

type Props = PropsScheduleDialog;

export default defineComponent({
  name: 'TrainingScheduleDialog',
  components: {
    BaseDialogFullScreen,
    BaseButton,
    BaseButtonIcon,
    BaseText,
    BaseTextDate,
    BaseTextTime,
    ScheduleTagSelect,
    BaseDialogConfirm,
    MentionMarkdown,
    TextEditor,
  },
  props: {
    tags: { type: Array as PropType<ScheduleTag[]>, default: () => [] },
    hideOpenTags: { type: Boolean, default: false },
  },
  emits: ['done', 'click-anchor', 'click-user', 'open-tags'],
  setup(props: Props, { emit }: SetupContext) {
    return useScheduleDialog(props, emit);
  },
});
