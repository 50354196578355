import { computed, nextTick, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { delayScroll, escapeId, getAreaPropsBy } from '@/base/app/utils/DomUtils';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { requiredInject, useRoute, useRouter } from '@/utils/VueUtils';

import { ContentStoreKey } from '../../stores';
import {
  CourseContentListHeadingId,
  CourseContentListMovePayload,
} from '../molecules/CourseContentListComposable';

export type PropsContentAppHeaderStart = {
  containerClassName: string;
};

export function useContentAppHeaderStart(
  props: PropsContentAppHeaderStart,
  emit: (name: string) => void
) {
  const store = requiredInject(ContentStoreKey);

  const displayIndex = ref(false);
  watch(displayIndex, (newVal) => {
    if (!newVal) return;
    nextTick(() => emit('opened'));
  });

  const { breakpoint } = useVuetify();
  const xs = computed(() => breakpoint.value?.xs);
  watch(xs, (newVal) => {
    if (!newVal) return;
    displayIndex.value = false;
  });

  function scrollByOnPage(id: CourseContentListHeadingId, page = 'text') {
    if (!id) return;
    const changed = store.page.value !== page;
    store.changePageValue(page);
    const area = getAreaPropsBy(props.containerClassName);
    const y = (area?.areaPaddingTop ?? 0) + 8 + 8;
    delayScroll(escapeId(id), { delay: changed ? 300 : 50, offsetY: y * -1 });
  }

  const route = useRoute();
  const router = useRouter();
  function move(payload: CourseContentListMovePayload) {
    router.push({
      name: 'groupContent',
      params: { id: payload.groupId, courseId: payload.courseId, contentId: payload.contentId },
      query: route.query,
    });
  }

  const msgs = useMessages({ prefix: 'training.organisms.contentAppHeaderStart' });
  return {
    displayIndex,
    content: store.content,
    course: store.course,
    labelIndex: msgs.of('index'),
    scrollByOnPage,
    move,
  };
}
