import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject } from '@/utils/VueUtils';

import { GroupTrainingCourseContent } from '../../../domains';
import { TrainingCourseStoreKey } from '../../stores';
import { CourseContentTableItem } from '../molecules/CourseContentTableComposable';

const HEADER_KEYS = ['no', 'name', 'typeName', 'requiredTime', 'open', 'groupExam', 'version'];

type Content = GroupTrainingCourseContent;

export type PropsCourseContents = {
  id: string;
  groupId: string;
  disabled: boolean;
};

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

export function useCourseContents(props: PropsCourseContents) {
  const msgs = useMessages({ prefix: 'training.organisms.courseContents' });
  const { errorDialog, error } = useErrorDialog();
  const { confirmDialog, open: confirm } = useConfirmDialog();

  const {
    course,
    loading: fetchLoading,
    fetch: fetchCourse,
    openGroupContent,
    closeGroupContent,
    updateGroupContentVersion,
  } = requiredInject(TrainingCourseStoreKey);

  const updating = ref(false);

  function fetch() {
    return fetchCourse({ groupId: props.groupId, courseId: props.id });
  }
  onMounted(fetch);
  watch(() => props.id, fetch);

  async function openContent(contentId: string) {
    updating.value = true;
    const ret = await openGroupContent({
      groupId: props.groupId,
      courseId: props.id,
      contentId,
    });
    updating.value = false;
    if (ret !== true) error(ret);
  }

  async function closeContent(contentId: string) {
    updating.value = true;
    const ret = await closeGroupContent({
      groupId: props.groupId,
      courseId: props.id,
      contentId,
    });
    updating.value = false;
    if (ret !== true) error(ret);
  }

  function toggleOpenClose(item: Content) {
    if (item.open) {
      closeContent(item.id);
      return;
    }
    if (item.type === 'exam' && !item.open) {
      confirm(msgs.of('confirmOpenExam').value, () => openContent(item.id));
      return;
    }
    openContent(item.id);
  }

  async function updateContentVersion(contentId: string, contentVersion: number) {
    updating.value = true;
    const ret = await updateGroupContentVersion({ contentId, contentVersion });
    updating.value = false;
    if (ret !== true) error(ret);
  }

  function confirmUpdateContentVersion(item: CourseContentTableItem) {
    assertIsDefined(item.versions, 'versions');
    const toMsg = (v: { version: number; versionDescription?: string }) => {
      const li = `- ver.${v.version}\n`;
      if (!v.versionDescription) return li;
      return `${li}${v.versionDescription}\n`;
    };
    const vs = item.versions
      .map((v) => ({ version: v.version, msg: toMsg(v) }))
      .sort((a, b) => a.version - b.version);
    const [last] = vs.slice(-1);
    assertIsDefined(last, 'last');

    confirm(
      msgs.of('confirmUpdateContentVersion', {
        contentName: item.name,
        newVersion: last.version,
        versions: vs.map((v) => v.msg).join(''),
        exam: item.type === 'exam' ? msgs.of('updateVersionExam').value : '',
      }).value,
      () => updateContentVersion(item.id, last.version)
    );
  }

  const loading = computed(() => fetchLoading.value || updating.value);
  const items = computed<CourseContentTableItem[]>(
    () =>
      course.value?.contents.map((item, i) => {
        const versions = item.versions.filter((v) => v.version > item.version);
        return {
          ...item,
          no: i + 1,
          versions,
        };
      }) ?? []
  );
  const disabledControl = computed(() => props.disabled || loading.value);

  return {
    errorDialog,
    confirmDialog,
    loading,
    items,
    disabledControl,
    headerKeys: HEADER_KEYS,
    labelOpen: msgs.of('open'),
    labelClose: msgs.of('close'),
    labelHasNewVersion: msgs.of('hasNewVersion'),
    toggleOpenClose,
    confirmUpdateContentVersion,
    refresh: fetch,
  };
}
