









































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

import BaseButton from '../atoms/BaseButton.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import HintIcon from './HintIcon.vue';
import ProblemListItem from './ProblemListItem.vue';
import {
  ProblemResultsViewValue,
  PropsProblemResultsView,
  useProblemResultsView,
} from './ProblemResultsViewComposable';

type Props = PropsProblemResultsView;

export default defineComponent({
  name: 'BaseProblemResultsView',
  components: { BaseButton, BaseMarkdown, HintIcon, ProblemListItem },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Array as PropType<ProblemResultsViewValue>, default: () => [] },
    loading: { type: Boolean, default: false },
    problems: { type: Array as PropType<ProblemUtilsProblem[]>, default: () => [] },
    description: { type: String, default: undefined },
    iconComplete: { type: String, default: 'mdi-check-circle-outline' },
    labelComplete: { type: String, default: undefined },
    iconPassed: { type: String, default: 'mdi-checkbox-marked-circle-outline' },
    iconNotPassed: { type: String, default: 'mdi-circle-small' },
    enableSelect: { type: Boolean, default: false },
    enableResetSelection: { type: Boolean, default: false },
    enableCorrect: { type: Boolean, default: false },
    displayReviewLaterHint: { type: Boolean, default: false },
  },
  emits: ['change', 'complete', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useProblemResultsView(props, emit);
  },
});
