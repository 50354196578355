








































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseText from '@/base/app/components/atoms/BaseText.vue';
import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';

import {
  PropsQuestionSearchFields,
  QuestionSearchFields,
  useQuestionSearchFields,
} from './QuestionSearchFieldsComposable';

type Props = PropsQuestionSearchFields;

export default defineComponent({
  name: 'TrainingQuestionSearchFields',
  components: { BaseText, BaseButton, RefreshButton },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<QuestionSearchFields>, required: true },
    loading: { type: Boolean, default: false },
  },
  emits: ['change', 'refresh'],
  setup(props: Props, { emit }: SetupContext) {
    return useQuestionSearchFields(props, emit);
  },
});
