































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonCheckbox from '@/base/app/components/atoms/BaseButtonCheckbox.vue';
import ProblemNavigator from '@/base/app/components/atoms/ProblemNavigator.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ProblemView from '@/base/app/components/molecules/ProblemView.vue';
import { ProblemUtilsNavigator, ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

import { UserExamStoreExam } from '../../stores';
import { PropsUserExamProblems, useUserExamProblems } from './UserExamProblemsComposable';

type Props = PropsUserExamProblems;

export default defineComponent({
  name: 'TrainingUserExamProblems',
  components: { BaseButton, BaseButtonCheckbox, ProblemNavigator, ProblemView, ContentFooter },
  props: {
    index: { type: [Number, String], default: 0 },
    exam: { type: Object as PropType<UserExamStoreExam>, required: true },
    groupId: { type: String, required: true },
    problems: { type: Array as PropType<ProblemUtilsProblem[]>, default: () => [] },
    problemNavigator: { type: Object as PropType<ProblemUtilsNavigator>, default: undefined },
    showProminent: { type: Boolean, default: false },
    shrinkLimit: { type: Number, default: 16 },
    isMobile: { type: Boolean, default: false },
  },
  emits: ['change', 'choice-value', 'toggle-flag', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserExamProblems(props, emit);
  },
});
