var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('my-app',{attrs:{"group-id":_vm.id,"auth-rules":"trainee","loading":_vm.loading,"not-found":_vm.notFound,"hide-side":_vm.inProgress,"hide-return":_vm.inProgress,"hide-notifications":_vm.inProgress,"show-prominent-extension":"","show-switch-mobile":"","app-bar-image":_vm.courseImage,"app-bar-class":_vm.courseColorClass},scopedSlots:_vm._u([{key:"header-start",fn:function(){return [_c('span')]},proxy:true},{key:"header-label",fn:function(ref){
var mode = ref.mode;
return [(mode === 'desktop')?_c('div',{staticClass:"flex-grow-1 flex-shrink-1 text-truncate px-5"},[_c('span',{staticClass:"text-md-h6"},[_vm._v(_vm._s(_vm.contentName))])]):_vm._e()]}},{key:"header-end",fn:function(ref){
var mode = ref.mode;
return [(_vm.inProgress)?_c('user-exam-app-header-end',{attrs:{"show-timer":mode === 'desktop'},on:{"error":_vm.error}}):_vm._e()]}},{key:"header-prominent",fn:function(){return [(!_vm.notFound)?_c('user-exam-app-header-prominent',{attrs:{"group-id":_vm.id}}):_vm._e()]},proxy:true},{key:"header-extension",fn:function(){return [(_vm.inProgress)?_c('user-exam-app-header-extension',{attrs:{"height":"48px"},on:{"error":_vm.error}}):_c('div',{staticStyle:{"min-height":"48px"}})]},proxy:true},{key:"header-img",fn:function(ref){
var props = ref.props;
return [(_vm.courseImage)?_c('v-img',_vm._b({},'v-img',props,false)):_vm._e()]}},{key:"default",fn:function(ref){
var mode = ref.mode;
var showProminent = ref.showProminent;
var shrinkLimit = ref.shrinkLimit;
return [_c('v-container',{attrs:{"fluid":""}},[_c('user-exam-container',{staticClass:"mx-auto",staticStyle:{"width":"min(800px, calc(100vw - 40px))"},attrs:{"exam-id":_vm.examId,"group-id":_vm.id,"show-prominent":showProminent,"shrink-limit":shrinkLimit,"is-mobile":mode === 'mobile'}})],1)]}}])},[_c('base-dialog-ok',{ref:"errorDialog",attrs:{"max-width":"350"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }