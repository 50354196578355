


















































import { defineComponent } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';

import CourseContentTable from '../molecules/CourseContentTable.vue';
import { PropsCourseContents, useCourseContents } from './CourseContentsComposable';

type Props = PropsCourseContents;

export default defineComponent({
  name: 'TrainingCourseContents',
  components: {
    CourseContentTable,
    RefreshButton,
    BaseButtonIcon,
    BaseDialogConfirm,
    BaseDialogOk,
  },
  props: {
    id: { type: String, required: true },
    groupId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useCourseContents(props);
  },
});
