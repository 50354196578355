
















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  GroupExamTableItem,
  PropsGroupExamTable,
  useGroupExamTable,
} from './GroupExamTableComposable';

type Props = PropsGroupExamTable;

export default defineComponent({
  name: 'TrainingGroupExamTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    groupExams: { type: Array as PropType<GroupExamTableItem[]>, default: () => [] },
    headerKeys: {
      type: Array as PropType<string[]>,
      default: () => [
        'times',
        'statusLabel',
        'start',
        'end',
        'levelName',
        'passingStandard',
        'timeLimit',
        'countOfNotBegun',
        'countOfInProgress',
        'countOfCompleted',
        'preview',
      ],
    },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupExamTable(props, emit);
  },
});
