import { computed, nextTick, onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { ExamResultVisibilityLevel } from '@/base/domains';
import { LocalDateTime, Optional } from '@/base/types';
import { isDefined } from '@/utils/TsUtils';

const AVATARS_LIMIT = 15;

const GROUP_EXAM_OPEN_HOLDING_TYPE = ['immediate', 'schedule'] as const;
type GroupExamOpenHoldingType = typeof GROUP_EXAM_OPEN_HOLDING_TYPE[number];

export type GroupExamOpenFormValue = {
  passingStandard?: number;
  level: ExamResultVisibilityLevel;
  timeLimit?: number;
  allTrainees: boolean;
  holdingType: GroupExamOpenHoldingType;
  scheduledStart?: LocalDateTime;
  scheduledFinish?: LocalDateTime;
};

type GroupExamOpenFormInput = Omit<
  GroupExamOpenFormValue,
  'passingStandard' | 'timeLimit' | 'userIds'
> & {
  passingStandard: string;
  timeLimit: string;
};

export type PropsGroupExamOpenForm = {
  value: GroupExamOpenFormValue;
  problemCount: number;
  userIds: string[];
  trainees: UserAvatar[];
};

export function useGroupExamOpenForm(
  props: PropsGroupExamOpenForm,
  emit: (name: string, args: GroupExamOpenFormValue | boolean) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.groupExamOpenForm' });

  const observer = ref<InstanceType<typeof ValidationObserver>>();

  const input = ref<GroupExamOpenFormInput>({
    passingStandard: '',
    level: 'invisible_to_user',
    timeLimit: '',
    allTrainees: true,
    holdingType: 'immediate',
    scheduledStart: undefined,
    scheduledFinish: undefined,
  });

  const avatars = computed(() =>
    props.trainees.filter((t) => input.value.allTrainees || props.userIds.includes(t.id))
  );
  const errors = computed<Optional<string[]>>(() =>
    avatars.value.length === 0 ? [msgs.of('errorUnSelectTrainees').value] : undefined
  );

  function init() {
    input.value = {
      ...props.value,
      passingStandard: props.value.passingStandard?.toString() ?? '',
      timeLimit: props.value.timeLimit?.toString() ?? '',
    };
  }
  onMounted(init);
  watch(() => props.value, init);

  function changeAllTrainees(v: boolean) {
    emit('change-all-trainees', v);
  }

  function changeHoldingType() {
    nextTick(() => observer.value?.validate());
  }

  function toInt(v?: string) {
    if (!isDefined(v)) return undefined;
    const i = parseInt(v, 10);
    if (Number.isFinite(i)) return i;
    return undefined;
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid || !!errors.value) return;
    emit('submit', {
      ...input.value,
      passingStandard: toInt(input.value.passingStandard),
      timeLimit: toInt(input.value.timeLimit),
    });
  }

  function reset() {
    if (observer.value) observer.value.reset();
  }

  const labelTrainees = computed(
    () => msgs.of('trainees', { size: avatars.value.length, total: props.trainees.length }).value
  );
  const labelPassingStandardSuffix = computed(
    () => msgs.of('passingStandardSuffix', { total: props.problemCount }).value
  );

  return {
    observer,
    input,
    errors,
    avatars,
    avatarsLimit: AVATARS_LIMIT,
    labelTrainees,
    labelPassingStandardSuffix,
    labelPassingStandard: msgs.of('passingStandard'),
    labelTimeLimit: msgs.of('timeLimit'),
    labelTimeLimitSuffix: msgs.of('timeLimitSuffix'),
    labelLevel: msgs.of('visibilityLevel'),
    levels: msgs.listOf('visibilityLevels'),
    labelDescription: msgs.of('visibilityLevelDescription'),
    labelAllTrainees: msgs.of('allTrainees'),
    labelHoldingPeriod: msgs.of('holdingPeriod'),
    holdingTypes: msgs.listOf('holdingTypes'),
    labelImmediate: msgs.of('immediate'),
    labelScheduledStart: msgs.of('scheduledStart'),
    labelScheduledEnd: msgs.of('scheduledEnd'),
    changeAllTrainees,
    changeHoldingType,
    submit,
    reset,
  };
}

export type GroupExamOpenForm = ReturnType<typeof useGroupExamOpenForm>;
