import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { TextEditor } from '@/base/app/components/molecules/TextEditorComposable';
import { GroupRole } from '@/base/domains';

export type ContentMemoFormValue = {
  roles: GroupRole[];
  body: string;
};

export type PropsContentMemoForm = {
  value: ContentMemoFormValue;
  scopeType: 'private' | 'group';
  bodyHeightOffset: number;
  height: string;
};

export function useContentMemoForm(props: PropsContentMemoForm, emit) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const bodyEditor = ref<TextEditor>();
  const input = ref<ContentMemoFormValue>({
    body: '',
    roles: [],
  });

  function change(payload: object) {
    Object.assign(input.value, payload);
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  function reset() {
    if (bodyEditor.value) bodyEditor.value.reset();
    if (observer.value) observer.value.reset();
  }

  const bodyHeight = computed(() => {
    const h = props.scopeType === 'group' ? 110 : 56;
    return `max(calc(${props.height} - ${props.bodyHeightOffset + h}px), 6rem)`;
  });

  const msgs = useMessages({ prefix: 'training.molecules.contentMemoForm' });
  return {
    observer,
    bodyEditor,
    input,
    bodyHeight,
    roles: msgs.listOf('roles'),
    labelScope: msgs.of('scope'),
    labelBody: msgs.of('body'),
    change,
    submit,
    reset,
  };
}

export type ContentMemoForm = ReturnType<typeof useContentMemoForm>;
