import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { TextEditor, TextEditorValue } from '@/base/app/components/molecules/TextEditorComposable';
import { UserSelectItem } from '@/base/app/components/molecules/UserSelectComposable';
import { GlobalStoreGroup } from '@/base/app/store/types';

type ContentQuestionAssigneeItem = UserSelectItem;

export type ContentQuestionFormValue = {
  title: string;
  comment: TextEditorValue;
  assignees: string[];
};

export type PropsContentQuestionForm = {
  value: ContentQuestionFormValue;
  group?: GlobalStoreGroup;
  bodyHeightOffset: number;
  height: string;
};

export function useContentQuestionForm(
  props: PropsContentQuestionForm,
  emit: (name: string, arg: ContentQuestionFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const commentEditor = ref<TextEditor>();
  const input = ref<ContentQuestionFormValue>({
    title: '',
    comment: '',
    assignees: [],
  });

  function change(payload: object) {
    Object.assign(input.value, payload);
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  function reset() {
    if (commentEditor.value) commentEditor.value.reset();
    if (observer.value) observer.value.reset();
  }

  const groupUsers = computed<ContentQuestionAssigneeItem[]>(() => props.group?.users ?? []);

  const bodyHeight = computed(() => {
    const h = 160;
    return `max(calc(${props.height} - ${props.bodyHeightOffset + h}px), 6rem)`;
  });

  const msgs = useMessages({ prefix: 'training.molecules.contentQuestionForm' });
  return {
    observer,
    commentEditor,
    input,
    groupUsers,
    bodyHeight,
    labelTitle: msgs.of('title'),
    labelComment: msgs.of('comment'),
    placeholderAssignees: msgs.of('assignee'),
    change,
    submit,
    reset,
  };
}

export type ContentQuestionForm = ReturnType<typeof useContentQuestionForm>;
