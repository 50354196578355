import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import {
  BaseTable,
  BaseTableActionPayload,
} from '@/base/app/components/molecules/BaseTableComposable';
import { ExamResultVisibilityLevel, GroupExamStatus } from '@/base/domains';
import { LocalDateTime, Minute } from '@/base/types';

const HEADERS: DataTableHeader[] = [
  { value: 'times', text: '', width: 120, align: 'center' },
  { value: 'statusLabel', text: '', width: 100 },
  { value: 'start', text: '', class: 'group-exam-table-holding-period' },
  { value: 'end', text: '', class: 'group-exam-table-holding-period' },
  { value: 'levelName', text: '', class: 'base-table-name' },
  { value: 'passingStandard', text: '', align: 'center', width: 130 },
  { value: 'timeLimit', text: '', align: 'center', width: 130 },
  {
    value: 'countOfNotBegun',
    text: '',
    align: 'center',
    class: 'group-exam-table-count',
  },
  {
    value: 'countOfInProgress',
    text: '',
    align: 'center',
    class: 'group-exam-table-count',
  },
  {
    value: 'countOfCompleted',
    text: '',
    align: 'center',
    class: 'group-exam-table-count',
  },
  { value: 'preview', text: '', align: 'center', width: 80, sortable: false },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type GroupExamTableItem = {
  id: string;
  times: number;
  level: ExamResultVisibilityLevel;
  timeLimit?: Minute;
  start?: LocalDateTime;
  end?: LocalDateTime;
  status: GroupExamStatus;
  passingStandard?: number;
  countOfNotBegun: number;
  countOfInProgress: number;
  countOfCompleted: number;
};

export type GroupExamTableActionPayload = {
  event: string;
  selected: GroupExamTableItem[];
};

export type PropsGroupExamTable = {
  groupExams: GroupExamTableItem[];
  headerKeys: string[];
};

export function useGroupExamTable(
  props: PropsGroupExamTable,
  emit: (name: string, args: GroupExamTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.groupExamTable' });
  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h?.value ? msgs.of(h.value).value : '' }))
  );
  const items = computed(() =>
    props.groupExams.map((e) => ({
      ...e,
      start: e.start?.format('YYYY/MM/DD HH:mm'),
      end: e.end?.format('YYYY/MM/DD HH:mm'),
      levelName: msgs.of(e.level).value,
      statusLabel: msgs.of(e.status).value,
    }))
  );

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.groupExams.find((e) => e.id === id))
      .filter((e) => e) as GroupExamTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}
