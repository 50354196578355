





























































































import { computed, defineComponent, provide } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';
import { useCourseColor } from '@/base/app/utils/ColorUtils';

import ContentAppHeaderEnd from '../components/organisms/ContentAppHeaderEnd.vue';
import ContentAppHeaderExtensionTabs from '../components/organisms/ContentAppHeaderExtensionTabs.vue';
import ContentAppHeaderLabel from '../components/organisms/ContentAppHeaderLabel.vue';
import ContentAppHeaderProminent from '../components/organisms/ContentAppHeaderProminent.vue';
import ContentAppHeaderStart from '../components/organisms/ContentAppHeaderStart.vue';
import ContentAppToolBar from '../components/organisms/ContentAppToolBar.vue';
import ContentContainer from '../components/organisms/ContentContainer.vue';
import ContentContainerMobile from '../components/organisms/ContentContainerMobile.vue';
import { ContentStoreKey, useContentStore } from '../stores';

export default defineComponent({
  name: 'TrainingGroupContentPage',
  components: {
    MyApp,
    ContentAppHeaderStart,
    ContentAppHeaderLabel,
    ContentAppHeaderEnd,
    ContentAppHeaderProminent,
    ContentAppHeaderExtensionTabs,
    ContentAppToolBar,
    ContentContainer,
    ContentContainerMobile,
  },
  props: {
    id: { type: String, required: true },
    courseId: { type: String, required: true },
    contentId: { type: String, required: true },
    contentVersion: { type: String, default: undefined },
    question: { type: String, default: undefined },
    memo: { type: String, default: undefined },
    problem: { type: String, default: undefined },
    hash: { type: String, default: undefined },
  },
  setup() {
    const store = useContentStore();
    provide(ContentStoreKey, store);

    const courseColorClass = computed(() => {
      if (store.loading.value || !store.course.value) return undefined;
      const {
        color: courseColor,
        image: courseImage,
        fontColorOnImage: courseFontColorOnImage,
      } = store.course.value;
      const { courseColorClass: ret } = useCourseColor({
        courseColor,
        courseImage,
        courseFontColorOnImage,
      });
      return ret.value;
    });
    const courseImage = computed(() => store.course.value?.image);

    const { groupRole } = useGlobalStore();
    const disabled = computed(() => !groupRole.value);

    return {
      loading: store.loading,
      notFound: store.notFound,
      questionAvailable: store.questionAvailable,
      headingId: store.headingId,
      changeHeadingId: store.changeHeadingId,
      fetchHeadingId: store.fetchHeadingId,
      courseImage,
      courseColorClass,
      groupRole,
      disabled,
    };
  },
});
