import { useMessages } from '@/base/app';

export function useUserExamActiveWarning(emit: (name: string) => void) {
  function start() {
    emit('start');
  }

  const msgs = useMessages({ prefix: 'training.molecules.userExamActiveWarning' });
  return {
    start,
    warning: msgs.of('description'),
    labelStart: msgs.of('start'),
  };
}
