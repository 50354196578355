import { onMounted, Ref, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { Timer } from '@/base/app/components/molecules/TimerComposable';
import { LocalDateTime, Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject } from '@/utils/VueUtils';

import { UserExamStoreExam, UserExamStoreKey, UserExamStoreNow } from '../../stores';

function useTimer(
  exam: Readonly<Ref<Optional<UserExamStoreExam>>>,
  now: Readonly<Ref<Optional<UserExamStoreNow>>>
) {
  const timer = ref<Timer>();
  const started = ref(false);
  function stop() {
    assertIsDefined(timer.value);
    timer.value.stop();
    started.value = false;
  }
  function start(startedAt: LocalDateTime) {
    assertIsDefined(timer.value);
    if (!exam.value) return;
    const { timeLimit } = exam.value;
    const base = now.value;
    timer.value.start({ startedAt, timeLimit, base });
    started.value = true;
  }
  return { timer, started, stop, start };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  function cancel() {
    if (!confirmDialog.value) return;
    confirmDialog.value.close('cancel');
  }
  return { confirmDialog, open, cancel };
}

export type PropsUserExamAppHeaderEnd = {
  showTimer: boolean;
};

export function useUserExamAppHeaderEnd(
  props: PropsUserExamAppHeaderEnd,
  emit: (name: string, arg: ErrorMessage[]) => void
) {
  const msgs = useMessages({ prefix: 'training.organisms.userExamAppHeaderEnd' });
  const { status, exam, now, loading, scoring, score, allocate } = requiredInject(UserExamStoreKey);

  const { timer, started, stop, start } = useTimer(exam, now);
  const { confirmDialog, open, cancel } = useConfirmDialog();

  function checkTimer() {
    if (!props.showTimer || status.value !== 'in_progress') {
      if (started.value) stop();
      return;
    }
    if (!exam.value?.startedAt || started.value) return;
    start(exam.value.startedAt);
  }
  onMounted(checkTimer);
  watch(status, checkTimer);
  watch(() => props.showTimer, checkTimer);

  async function finish() {
    const ret = await score();
    if (ret !== true) {
      emit('error', ret);
      return;
    }
    allocate();
  }

  function confirm() {
    open(msgs.of('confirmScore').value, finish);
  }

  async function timeOver() {
    cancel();
    finish();
  }

  return {
    confirmDialog,
    loading,
    scoring,
    timer,
    started,
    labelScore: msgs.of('score'),
    confirm,
    timeOver,
  };
}
