


















import { defineComponent } from '@vue/composition-api';

import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';

import ContentQuestion from './ContentQuestion.vue';
import { PropsQuestion, useQuestion } from './QuestionComposable';

type Props = PropsQuestion;

export default defineComponent({
  name: 'TrainingQuestion',
  components: { ContentQuestion, DialogAnchorConfirm },
  props: {
    id: { type: String, required: true },
    groupId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    topHeight: { type: Number, default: 64 },
  },
  setup(props: Props) {
    return useQuestion(props);
  },
});
