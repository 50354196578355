





















import { computed, defineComponent, provide } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';

import CourseContents from '../components/organisms/CourseContents.vue';
import { TrainingCourseStoreKey, useTrainingCourseStore } from '../stores';

type Props = {
  id: string;
  courseId: string;
  edit: boolean;
};

export default defineComponent({
  name: 'TrainingTrainerGroupCoursePage',
  components: { MyApp, CourseContents },
  props: {
    id: { type: String, required: true },
    courseId: { type: String, required: true },
    edit: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const courseStore = useTrainingCourseStore();
    provide(TrainingCourseStoreKey, courseStore);

    const courseName = computed(() => courseStore.course.value?.displayName);

    const { groupRole } = useGlobalStore();
    const disabled = computed(() => {
      if (groupRole.value === 'trainer' && props.edit) return false;
      return true;
    });

    return {
      loading: courseStore.loading,
      notFound: courseStore.notFound,
      courseName,
      disabled,
    };
  },
});
