



















































import { defineComponent } from '@vue/composition-api';
import { VDialog, VMenu } from 'vuetify/lib/components';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import QuestionBar from '@/base/app/components/molecules/QuestionBar.vue';

import ContentQuestionSearchFields from '../molecules/ContentQuestionSearchFields.vue';
import {
  PropsContentQuestionSearchDialog,
  useContentQuestionSearchDialog,
} from './ContentQuestionSearchDialogComposable';

type Props = PropsContentQuestionSearchDialog;

export default defineComponent({
  name: 'TrainingContentQuestionSearchDialog',
  components: {
    VDialog,
    VMenu,
    BaseButtonIcon,
    ContentQuestionSearchFields,
    QuestionBar,
    BaseMarkdown,
  },
  props: {
    fullscreen: { type: Boolean, default: false },
    dialogClassName: { type: String, default: 'training-content-container-dialog' },
    width: { type: Number, default: 400 },
    height: { type: [Number, String], default: 500 },
  },
  setup(props: Props) {
    return useContentQuestionSearchDialog(props);
  },
});
