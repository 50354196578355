import { computed, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { requiredInject } from '@/utils/VueUtils';

import { ContentStoreKey } from '../../stores';
import { ContentCreateMenuCreateMemoPayload } from '../atoms/ContentCreateMenusComposable';

export type PropsContentAppHeaderEnd = {
  groupId: string;
  questionAvailable: boolean;
};

export function useContentAppHeaderEnd(props: PropsContentAppHeaderEnd) {
  const store = requiredInject(ContentStoreKey);
  const displayDialog = computed(() => !!store.dialog.value);
  const displaySearch = computed(() => !!store.questionListAttrs.value);
  function toggle() {
    if (displaySearch.value) {
      store.changeQuestionListAttrs();
      store.clearQuestionCondition();
    } else {
      store.changeQuestionListAttrs({ groupId: props.groupId, pending: true, loading: false });
    }
  }

  const excludeMenus = computed(() => {
    const menus = ['cancel'];
    if (!props.questionAvailable) menus.push('question');
    return menus;
  });

  const { breakpoint } = useVuetify();
  const xs = computed(() => breakpoint.value?.xs);
  watch(xs, (newVal) => {
    if (!newVal) return;
    if (displayDialog.value) {
      store.clearDialog();
      store.finishMarker();
    } else if (displaySearch.value) {
      toggle();
    }
  });

  function createQuestion() {
    store.openDialog({ name: 'contentQuestion' });
    store.prepareMarker('question');
  }

  function createMemo(payload: ContentCreateMenuCreateMemoPayload) {
    store.openDialog({ name: 'contentMemo', groupId: props.groupId, scopeType: payload.type });
    store.prepareMarker('memo');
  }

  const msgs = useMessages({ prefix: 'training.organisms.contentAppHeaderEnd' });
  return {
    displayDialog,
    displaySearch,
    excludeMenus,
    enableMarkingPage: store.enableMarkingPage,
    labelSearch: msgs.of('search'),
    toggle,
    createQuestion,
    createMemo,
  };
}
