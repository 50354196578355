








import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';

import {
  PropsMarkerControlCaption,
  useMarkerControlCaption,
} from './MarkerControlCaptionComposable';

type Props = PropsMarkerControlCaption;

export default defineComponent({
  name: 'TrainingMarkerControlCaption',
  components: { BaseLink },
  props: {
    color: { type: String, default: 'secondary' },
    iconSize: { type: Number, default: 20 },
    caption: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  emits: ['click'],
  setup(props: Props, { emit }: SetupContext) {
    return useMarkerControlCaption(props, emit);
  },
});
