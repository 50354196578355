import { computed, nextTick, onMounted, ref } from '@vue/composition-api';

import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { delayScroll } from '@/base/app/utils/DomUtils';
import { ProblemUtilsNavigatorValue } from '@/base/app/utils/ProblemUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRouter } from '@/utils/VueUtils';

import { ReviewStoreKey } from '../../stores';
import {
  CourseReviewProblemsCheckPayload,
  CourseReviewProblemsChoicePayload,
  CourseReviewProblemsClearPayload,
  CourseReviewProblemsClickAnchorPayload,
  CourseReviewProblemsCompletePayload,
} from '../molecules/CourseReviewProblemsComposable';
import { CourseReviewSettingUpdatePayload } from '../molecules/CourseReviewSettingComposable';
import { CourseReviewWelcomeStartPayload } from '../molecules/CourseReviewWelcomeComposable';

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: CourseReviewProblemsClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

export type PropsCourseReviewContainer = {
  groupId: string;
  courseId: string;
};

export function useCourseReviewContainer(props: PropsCourseReviewContainer) {
  const store = requiredInject(ReviewStoreKey);
  const router = useRouter();
  const { errorDialog, error } = useErrorDialog();

  function init() {
    store.move('welcome');
    store.fetch(props.groupId, props.courseId);
    delayScroll('top');
  }
  onMounted(init);

  function start(payload: CourseReviewWelcomeStartPayload) {
    if (payload.isContinue && store.existsData()) {
      store.start(true);
      const p = store.problemNavigator.value?.items.find((item) => !item.passed);
      if (p) store.problemController.move(p.value);
    } else {
      store.start(false);
    }
    store.move('main');
    delayScroll('top');
  }

  async function complete(payload: CourseReviewProblemsCompletePayload) {
    if ((store.problems.value?.length ?? 0) !== payload.remainProblemIndexes.length) {
      const ret = await store.removeByRemainProblemIndexes(payload.remainProblemIndexes);
      if (Array.isArray(ret)) {
        error(ret);
        return;
      }
    }
    store.problemController.remove();
    waitTransition(() => {
      payload.done();
      router.push({
        name: 'groupCourse',
        params: { id: props.groupId, courseId: props.courseId },
      });
    });
  }

  function setting() {
    store.move('setting');
    delayScroll('top');
  }

  function back() {
    store.move('welcome');
    delayScroll('top');
  }

  async function update(payload: CourseReviewSettingUpdatePayload) {
    let problemCount = 0;
    if (payload.indexes.length > 0) {
      const ret = await store.remove(payload.indexes);
      if (Array.isArray(ret)) {
        error(ret);
        return;
      }
      store.problemController.remove();
      problemCount = ret;
    }
    waitTransition(() => {
      payload.done();
      if (problemCount > 0) {
        back();
      } else {
        router.push({
          name: 'groupCourse',
          params: { id: props.groupId, courseId: props.courseId },
        });
      }
    });
  }

  function changeProblemIndex(v: ProblemUtilsNavigatorValue) {
    store.problemController.move(v);
    nextTick(() => delayScroll('top'));
  }

  function clearProblemValue(payload: CourseReviewProblemsClearPayload) {
    store.problemController.clear(payload.index);
  }

  function checkProblemValue(payload: CourseReviewProblemsCheckPayload) {
    const correct = store.problemController.score(payload.index);
    if (correct !== undefined && 'scored' in payload) payload.scored(correct);
  }

  function choiceProblemValue(payload: CourseReviewProblemsChoicePayload) {
    store.problemController.choice(payload.index, payload.values);
  }

  const courseName = computed(() => store.course.value?.name ?? '-');

  const headerProps = computed(() => {
    if (!store.course.value) return undefined;
    const { name, color, image, fontColorOnImage } = store.course.value;
    const problemCount = store.problems.value?.length ?? 0;
    const existsData = store.existsData();
    return {
      courseName: name,
      courseColor: color,
      courseImage: image,
      courseFontColorOnImage: fontColorOnImage,
      problemCount,
      existsData,
    };
  });

  return {
    errorDialog,
    page: store.page,
    problemIndex: store.problemIndex,
    problems: store.problems,
    problemNavigator: store.problemNavigator,
    courseName,
    headerProps,
    contentName: store.reviewName,
    start,
    complete,
    setting,
    update,
    back,
    changeProblemIndex,
    clearProblemValue,
    checkProblemValue,
    choiceProblemValue,
    ...useAnchorDialog(),
  };
}
