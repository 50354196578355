



















































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ScheduleTagVue from '@/base/app/components/atoms/ScheduleTag.vue';
import { Schedule } from '@/base/domains';

import { PropsScheduleCard, useScheduleCard } from './ScheduleCardComposable';

type Props = PropsScheduleCard;

export default defineComponent({
  name: 'TrainingScheduleCard',
  components: { BaseButton, ScheduleTag: ScheduleTagVue },
  inheritAttrs: false,
  props: {
    schedule: { type: Object as PropType<Schedule>, required: true },
  },
  setup(props: Props, { emit }: SetupContext) {
    return useScheduleCard(props, emit);
  },
});
