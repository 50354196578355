




































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ContentHeader from '@/base/app/components/molecules/ContentHeader.vue';

import { UserExamStoreExam } from '../../stores';
import { useUserExamActiveWarning } from './UserExamActiveWarningComposable';

export default defineComponent({
  name: 'TrainingUserExamActiveWarning',
  components: { BaseMarkdown, BaseButton, ContentHeader, ContentFooter },
  inheritAttrs: false,
  props: {
    exam: { type: Object as PropType<UserExamStoreExam>, required: true },
    groupId: { type: String, required: true },
    isMobile: { type: Boolean, default: false },
  },
  emits: ['start'],
  setup(_, { emit }: SetupContext) {
    return useUserExamActiveWarning(emit);
  },
});
