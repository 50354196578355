import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';

export type CourseReviewWelcomeStartPayload = {
  isContinue: boolean;
};

export type PropsCourseReviewWelcome = {
  contentName: string;
  problemCount: number;
};

export function useCourseReviewWelcome(
  props: PropsCourseReviewWelcome,
  emit: (name: string, arg?: CourseReviewWelcomeStartPayload) => void
) {
  function start(isContinue: boolean) {
    emit('start', { isContinue });
  }

  function setting() {
    emit('setting');
  }

  const msgs = useMessages({ prefix: 'training.molecules.courseReviewWelcome' });
  const labelCount = computed(() => msgs.of('problems', { size: props.problemCount }).value);

  return {
    labelCount,
    description: msgs.of('reviewDescription'),
    descriptionSelect: msgs.of('selectDescription'),
    labelStart: msgs.of('start'),
    labelStartFromBeginning: msgs.of('startFromBeginning'),
    descriptionStartFromBeginning: msgs.of('startFromBeginningDescription'),
    labelContinue: msgs.of('startWithContinuation'),
    descriptionContinue: msgs.of('startWithContinuationDescription'),
    labelSetting: msgs.of('setting'),
    labelNoData: msgs.of('noData'),
    start,
    setting,
  };
}
