import { onMounted, ref, watch } from '@vue/composition-api';
import moment from 'moment';
import { ValidationObserver } from 'vee-validate';

import { LocalDateTime } from '@/base/types';

export type DateTimeRangeFormValue = {
  from: LocalDateTime;
  to: LocalDateTime;
};

export type PropsDateTimeRangeForm = {
  value: DateTimeRangeFormValue;
};

export function useDateTimeRangeForm(
  props: PropsDateTimeRangeForm,
  emit: (name: string, arg: DateTimeRangeFormValue) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<DateTimeRangeFormValue>({ from: moment(), to: moment() });

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', input.value);
  }

  function init() {
    input.value = { ...props.value };
  }
  onMounted(init);
  watch(() => props.value, init);

  return { observer, input, submit };
}
