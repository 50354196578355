





































































































import { defineComponent, SetupContext } from '@vue/composition-api';

import {
  PropsContentMemoSearchFields,
  useContentMemoSearchFields,
} from './ContentMemoSearchFieldsComposable';

type Props = PropsContentMemoSearchFields;

export default defineComponent({
  name: 'TrainingContentMemoSearchFields',
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object, required: true },
    title: { type: String, required: true },
    groupRole: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentMemoSearchFields(props, emit);
  },
});
