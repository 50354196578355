import { computed } from '@vue/composition-api';
import moment from 'moment';

import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { toStyleSize } from '@/base/app/utils/DomUtils';
import { Question } from '@/base/domains';

export type ContentQuestionCardOpenPayload = {
  id: string;
};

export type PropsContentQuestionCard = {
  question: Question;
  avatar: UserAvatar;
  maxWidth?: number | string;
};

export function useContentQuestionCard(
  props: PropsContentQuestionCard,
  emit: (name: string, args: ContentQuestionCardOpenPayload) => void
) {
  const sheetStyle = computed(() => {
    if (!props.maxWidth) return undefined;
    const w = toStyleSize(props.maxWidth);
    return { width: w, 'max-width': w };
  });

  const icon = computed(() => {
    if (props.question.resolved) return { color: 'secondary', icon: 'mdi-comment-check-outline' };
    return { color: 'primary', icon: 'mdi-comment-question-outline' };
  });

  const commentCount = computed(() => props.question.comments.length - 1);
  const lastUpdatedAt = computed(() =>
    moment.max(props.question.comments.map((item) => item.editedAt ?? item.createdAt))
  );

  function click() {
    emit('open', { id: props.question.id });
  }

  return { sheetStyle, icon, commentCount, lastUpdatedAt, click };
}
