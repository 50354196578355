













import { defineComponent, PropType } from '@vue/composition-api';

import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { LocalDateTime } from '@/base/types';

import {
  PropsQuestionResolvedFooter,
  useQuestionResolvedFooter,
} from './QuestionResolvedFooterComposable';

type Props = PropsQuestionResolvedFooter;

export default defineComponent({
  name: 'TrainingQuestionResolvedFooter',
  components: { User },
  props: {
    resolvedBy: { type: Object as PropType<UserAvatar>, default: undefined },
    resolvedAt: { type: Object as PropType<LocalDateTime>, default: undefined },
  },
  setup(props: Props) {
    return useQuestionResolvedFooter(props);
  },
});
