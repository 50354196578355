




















import { defineComponent } from '@vue/composition-api';

import ScrollToTopButton from '@/base/app/components/atoms/ScrollToTopButton.vue';
import MyApp from '@/base/app/components/MyApp.vue';

import Questions from '../components/organisms/Questions.vue';

export default defineComponent({
  name: 'TrainingGroupQuestionsPage',
  components: { MyApp, Questions, ScrollToTopButton },
  props: {
    id: { type: String, required: true },
    s: { type: String, default: undefined },
    f: { type: String, default: undefined },
    t: { type: String, default: undefined },
  },
});
