







































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import TextEditor from '@/base/app/components/molecules/TextEditor.vue';

import {
  ContentMemoFormValue,
  PropsContentMemoForm,
  useContentMemoForm,
} from './ContentMemoFormComposable';

type Props = PropsContentMemoForm;

export default defineComponent({
  name: 'TrainingContentMemoForm',
  components: { TextEditor },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<ContentMemoFormValue>, required: true },
    scopeType: { type: String as PropType<'private' | 'group'>, required: true },
    bodyHeightOffset: { type: Number, default: 42 },
    height: { type: String, default: '400px' },
    width: { type: String, default: '400px' },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentMemoForm(props, emit);
  },
});
