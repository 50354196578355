





















import { computed, defineComponent } from '@vue/composition-api';

import ScrollToTopButton from '@/base/app/components/atoms/ScrollToTopButton.vue';
import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';

import Schedules from '../components/organisms/Schedules.vue';

export default defineComponent({
  name: 'TrainingGroupSchedulesPage',
  components: { MyApp, Schedules, ScrollToTopButton },
  props: { id: { type: String, required: true } },
  setup() {
    const { groupRole } = useGlobalStore();
    const disabled = computed(() => !groupRole.value || groupRole.value === 'trainee');
    const disabledTags = computed(() => groupRole.value !== 'trainer');
    return { disabled, disabledTags };
  },
});
