
















import { computed, defineComponent } from '@vue/composition-api';
import { useWindowScroll } from '@vueuse/core';

type Props = { offsetY: number };

export default defineComponent({
  name: 'BaseScrollToTopButton',
  components: {},
  props: {
    offsetY: { type: Number, default: 100 },
    areaHeight: { type: Number, default: 50 },
  },
  setup(props: Props) {
    const { y: scrollY } = useWindowScroll();
    const display = computed(() => scrollY.value > props.offsetY);

    function scrollToTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    return { display, scrollToTop };
  },
});
