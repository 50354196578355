import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { getElementRect } from '@/base/app/utils/DomUtils';
import { requiredInject } from '@/utils/VueUtils';

import { ContentStoreKey } from '../../stores';
import { QuestionCondition } from '../../utils/FilterUtils';

export function useContentAppHeaderLabel() {
  const msgs = useMessages({ prefix: 'training.organisms.contentAppHeaderLabel' });

  const store = requiredInject(ContentStoreKey);
  const display = computed(() => !!store.questionListAttrs.value);
  const loading = computed(() => store.questionListAttrs.value?.loading);

  const courseName = computed(() => store.course.value?.name);
  const contentName = computed(() => {
    if (!store.content.value) return undefined;
    const { name, open } = store.content.value;
    if (open) return name;
    return msgs.of('closedContent', { name }).value;
  });

  function closeList() {
    if (!store.questionListAttrs.value) return;
    const attrs = store.questionListAttrs.value;
    if (attrs.pending === true) return;
    store.changeQuestionListAttrs({ ...attrs, pending: true });
  }

  function openList() {
    if (!store.questionListAttrs.value) return;
    const attrs = store.questionListAttrs.value;
    if (attrs.pending === false) return;
    const rect = getElementRect('.content-app-header-label-fields');
    store.changeQuestionListAttrs({ ...attrs, pending: false, x: rect?.x, w: rect?.width });
  }

  function changeCondition(v: QuestionCondition) {
    store.changeQuestionCondition(v);
    openList();
  }

  return {
    display,
    loading,
    condition: store.questionCondition,
    courseName,
    contentName,
    openList,
    closeList,
    changeCondition,
  };
}
