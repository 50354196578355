











































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { GroupRole } from '@/base/domains';

import { PropsContentCreateMenus, useContentCreateMenus } from './ContentCreateMenusComposable';

type Props = PropsContentCreateMenus;

export default defineComponent({
  name: 'TrainingContentCreateMenus',
  inheritAttrs: false,
  props: {
    groupRole: { type: String as PropType<GroupRole>, default: undefined },
    excludes: { type: Array as PropType<string[]>, default: () => [] },
    disabled: { type: Boolean, default: false },
  },
  emits: ['create-memo', 'create-question', 'cancel'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentCreateMenus(props, emit);
  },
});
