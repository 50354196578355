import { computed, ref } from '@vue/composition-api';

import { isSucceeded, QuestionWithNames } from '@/base/usecases';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

import { useGetQuestion } from '../../usecases';

export function useQuestionStore() {
  const loading = ref(false);
  const question = ref<QuestionWithNames>();

  const getQuestion = useGetQuestion();
  async function fetch(payload: { id: string }) {
    loading.value = true;
    const res = await getQuestion.execute({ id: payload.id });
    if (isSucceeded(res)) question.value = res.question;
    else question.value = undefined;
    loading.value = false;
  }

  const notFound = computed(() => !question.value);
  const title = computed(() => question.value?.title);
  return { loading, notFound, question: readonly(question), title, fetch };
}

export type QuestionStore = ReturnType<typeof useQuestionStore>;

export const QuestionStoreKey = injectionKeyOf<QuestionStore>({
  boundedContext: 'training',
  type: 'store',
  name: 'QuestionStore',
});
