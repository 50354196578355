



















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  GroupCourseTableItem,
  PropsGroupCourseTable,
  useGroupCourseTable,
} from './GroupCourseTableComposable';

type Props = PropsGroupCourseTable;

export default defineComponent({
  name: 'TrainingGroupCourseTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    courses: { type: Array as PropType<GroupCourseTableItem[]>, default: () => [] },
    headerKeys: { type: Array as PropType<string[]>, default: () => ['name'] },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupCourseTable(props, emit);
  },
});
