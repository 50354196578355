

























import { defineComponent } from '@vue/composition-api';

import { useContentAppHeaderExtensionTabs } from './ContentAppHeaderExtensionTabsComposable';

export default defineComponent({
  name: 'TrainingContentAppHeaderExtensionTabs',
  setup() {
    return useContentAppHeaderExtensionTabs();
  },
});
