import { computed, ref, watch } from '@vue/composition-api';
import { useWindowSize } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { UpdateStatusIcon } from '@/base/app/components/molecules/UpdateStatusIconComposable';
import { useGlobalStore } from '@/base/app/store';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { delayScroll, escapeId, getAreaPropsBy, toStyleSize } from '@/base/app/utils/DomUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { QuestionContentReference } from '@/base/domains';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRoute, useRouter } from '@/utils/VueUtils';

import { useAskQuestion } from '../../../usecases';
import { ContentStoreKey } from '../../stores';
import { ContentMarkerControlColor } from '../molecules/ContentMarkerControlComposable';
import {
  ContentQuestionForm,
  ContentQuestionFormValue,
} from '../molecules/ContentQuestionFormComposable';

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

export type PropsContentQuestionDialog = {
  fullscreen: boolean;
  dialogClassName: string;
  containerClassName: string;
  width: number;
  height: number | string;
};

export function useContentQuestionDialog(props: PropsContentQuestionDialog) {
  const store = requiredInject(ContentStoreKey);
  const { group, findUser } = useGlobalStore();
  const { errorDialog, error } = useErrorDialog();
  const router = useRouter();
  const route = useRoute();

  const dialog = ref(false);
  const mini = ref(false);
  const form = ref<ContentQuestionForm>();
  const input = ref<ContentQuestionFormValue>({
    title: '',
    comment: '',
    assignees: [],
  });
  const errors = ref<ErrorMessage[]>();

  const marker = computed(() => {
    if (!dialog.value) return undefined;
    if (store.markedItem.value) return store.markedItem.value;
    if (store.enableMarking.value && !props.fullscreen) return { id: 'no-mark', type: 'question' };
    return undefined;
  });
  const markerColor = computed(() => {
    if (!marker.value) return undefined;
    if (!('marker' in marker.value)) return 'secondary';
    return marker.value.marker.selection.color ?? 'secondary';
  });
  const showMarkerHint = computed(() => store.enableMarkingPage.value && !props.fullscreen);

  function close() {
    dialog.value = false;
    mini.value = false;
    errors.value = undefined;
    if (store.dialog.value) {
      store.clearDialog();
      store.finishMarker();
    }
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }
  watch(dialog, (newVal) => {
    if (newVal) return;
    close();
  });

  function open() {
    if (!dialog.value && form.value) form.value.reset();
    dialog.value = true;
    const assignees = group.value?.users.filter((u) => u.role === 'trainer').map((u) => u.id) ?? [];
    input.value = { title: '', comment: '', assignees };
  }
  watch(store.dialog, (newVal) => {
    if (dialog.value) {
      if (!newVal || newVal.name !== 'contentQuestion') close();
      return;
    }
    if (!newVal || newVal.name !== 'contentQuestion') return;
    open();
  });

  function opened() {
    return dialog.value;
  }

  function toggleMinimize() {
    if (props.fullscreen) return;
    mini.value = !mini.value;
  }

  function changeMarkerColor(color: ContentMarkerControlColor) {
    store.changeMarkerColor(color);
  }

  function removeMarkedItem() {
    store.mark();
  }

  function moveToMarker() {
    if (!marker.value || !('marker' in marker.value)) return;
    const content = store.content.value;
    assertIsDefined(content, 'content');
    const { type } = content;
    let page: 'text' | 'exam' | 'workbook' = type;
    if ('problemIndex' in marker.value.marker) {
      store.changeProblemIndex(marker.value.marker.problemIndex);
      if (type === 'text') page = 'workbook';
    }
    const changed = store.page.value !== page;
    store.changePageValue(page);
    const area = getAreaPropsBy(props.containerClassName);
    const y = (area?.areaPaddingTop ?? 0) + 8 + 8;
    delayScroll(escapeId(marker.value.id), { delay: changed ? 300 : 50, offsetY: y * -1 });
  }

  const status = ref<UpdateStatusIcon>();
  const updating = computed(() => status.value === 'updating');

  const ask = useAskQuestion();
  async function submit() {
    const content = store.content.value;
    assertIsDefined(content, 'content');

    const referTo: QuestionContentReference = {
      contentId: content.id,
      contentVersion: content.version,
    };
    if (store.markedItem.value) {
      Object.assign(referTo, { selection: store.markedItem.value.marker.selection });
      if ('problemIndex' in store.markedItem.value.marker)
        Object.assign(referTo, { problemIndex: store.markedItem.value.marker.problemIndex });
    } else if (
      content.type === 'text' &&
      store.page.value === 'workbook' &&
      store.problemIndex.value !== 'results'
    ) {
      Object.assign(referTo, { problemIndex: store.problemIndex.value });
    } else if (content.type === 'exam') {
      Object.assign(referTo, { problemIndex: store.problemIndex.value });
    }

    const { title, comment, assignees } = input.value;
    status.value = 'updating';
    const res = await ask.execute({
      title,
      comment: { body: comment ?? '' },
      assignees,
      referTo,
    });
    if (isFailed(res)) {
      status.value = 'changed';
      if (props.fullscreen) {
        error(res.errors);
      } else {
        errors.value = res.errors;
      }
      return;
    }
    store.finishMarker();
    waitTransition(() => {
      status.value = 'updated';
      const { id } = res.question;
      store.fetchQuestionPage(id);
      store.changePageValue(id);
      close();
    });
  }

  const dialogAttrs = computed(() => {
    if (props.fullscreen) {
      return { fullscreen: true, persistent: updating.value };
    }
    const right = 24;
    let x = window.innerWidth - props.width;
    const contentClass = [props.dialogClassName];
    if (mini.value) {
      x = window.innerWidth - 88;
      contentClass.push(`${props.dialogClassName}--min`);
    }
    return {
      'position-x': x - right,
      'content-class': contentClass.join(' '),
      'close-on-click': false,
      'close-on-content-click': false,
      transition: 'scroll-y-transition',
      'max-height': toStyleSize(props.height),
    };
  });

  const windowSize = useWindowSize();
  const sheetAttrs = computed(() => {
    if (props.fullscreen) return { height: `${windowSize.height.value}px` };
    return { rounded: true };
  });

  const markerControlHeight = computed(() => {
    if (marker.value) return 42;
    return 0;
  });

  const formAttrs = computed(() => {
    if (props.fullscreen) {
      const header = 120;
      return {
        height: `calc(${windowSize.height.value}px - ${toStyleSize(header)})`,
        width: 'min(600px, calc(100vw - 24px))',
        bodyHeightOffset: markerControlHeight.value,
        findUser,
      };
    }
    const header = 42;
    const padding = 20;
    return {
      height: `calc(${toStyleSize(props.height)} - ${toStyleSize(header)})`,
      width: toStyleSize(props.width - padding),
      bodyHeightOffset: markerControlHeight.value,
      findUser,
    };
  });

  const msgs = useMessages({ prefix: 'training.organisms.contentQuestionDialog' });
  const labelCreate = computed(() => {
    const key = props.fullscreen ? 'create' : 'ask';
    return msgs.of(key).value;
  });

  return {
    errorDialog,
    dialog,
    mini,
    form,
    input,
    marker,
    markerColor,
    showMarkerHint,
    errors,
    status,
    updating,
    group,
    dialogAttrs,
    sheetAttrs,
    formAttrs,
    markerControlHeight,
    labelCreate,
    labelClose: msgs.of('close'),
    labelMinimize: msgs.of('minimize'),
    labelMaximize: msgs.of('maximize'),
    title: msgs.of('createQuestion'),
    close,
    open,
    opened,
    toggleMinimize,
    changeMarkerColor,
    removeMarkedItem,
    moveToMarker,
    submit,
  };
}

export type ContentQuestionDialog = ReturnType<typeof useContentQuestionDialog>;
