

































import { defineComponent, SetupContext } from '@vue/composition-api';

import ContentCreateMenus from '../atoms/ContentCreateMenus.vue';
import ContentAppToolBarButton from './ContentAppToolBarButton.vue';
import { useContentAppToolBarCreateMenus } from './ContentAppToolBarCreateMenusComposable';

export default defineComponent({
  name: 'TrainingContentAppToolBarCreateMenus',
  components: { ContentCreateMenus, ContentAppToolBarButton },
  inheritAttrs: false,
  props: {
    activatorWidth: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  emits: ['create-memo', 'create-question', 'cancel'],
  setup(_, { emit }: SetupContext) {
    return useContentAppToolBarCreateMenus(emit);
  },
});
