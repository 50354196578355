var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('my-app',{attrs:{"group-id":_vm.id,"auth-rules":"supervisor|admin|trainer|mentor|trainee","label":_vm.title,"loading":_vm.loading,"not-found":_vm.notFound,"show-prominent-extension":"","show-switch-mobile":"","app-bar-image":_vm.courseImage,"app-bar-class":_vm.courseColorClass},scopedSlots:_vm._u([{key:"header-label",fn:function(ref){
var mode = ref.mode;
return [(mode === 'mobile')?_c('div',{staticClass:"flex-grow-1 flex-shrink-1"}):_vm._e()]}},{key:"header-prominent",fn:function(){return [(!_vm.loading && !_vm.notFound)?_c('course-review-app-header-prominent',{attrs:{"group-id":_vm.id,"course-id":_vm.courseId}}):_vm._e()]},proxy:true},{key:"header-extension",fn:function(){return [(!_vm.loading && !_vm.notFound)?_c('course-review-app-header-extension',{staticClass:"mx-auto",staticStyle:{"width":"min(800px, calc(100vw - 40px))"}}):_vm._e()]},proxy:true},{key:"header-img",fn:function(ref){
var props = ref.props;
return [(_vm.courseImage)?_c('v-img',_vm._b({},'v-img',props,false)):_vm._e()]}},{key:"default",fn:function(ref){
var admin = ref.admin;
var mode = ref.mode;
var showProminent = ref.showProminent;
var shrinkLimit = ref.shrinkLimit;
return [(admin)?_c('not-found'):_c('v-container',{attrs:{"fluid":""}},[_c('course-review-container',{staticClass:"mx-auto",staticStyle:{"width":"min(800px, calc(100vw - 40px))"},attrs:{"group-id":_vm.id,"course-id":_vm.courseId,"show-prominent":showProminent,"shrink-limit":shrinkLimit,"is-mobile":mode === 'mobile'}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }