

















import { defineComponent } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';

import { useUserExamAppHeaderProminent } from './UserExamAppHeaderProminentComposable';

export default defineComponent({
  name: 'TrainingUserExamAppHeaderProminent',
  components: { BaseLink },
  props: {
    groupId: { type: String, required: true },
  },
  setup() {
    return useUserExamAppHeaderProminent();
  },
});
