var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-dialog-full-screen',{ref:"dialogFullScreen",attrs:{"title":_vm.title,"subtitle":_vm.subtitle},on:{"closed":_vm.close},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('div',{staticClass:"d-flex flex-column",staticStyle:{"height":"calc(100vh - 100px)"}},[_c('v-row',{staticClass:"flex-grow-1 flex-shrink-1 overflow-y-auto mx-auto",style:({
        width: _vm.editing ? 'calc(100vw - 40px)' : 'min(600px, calc(100vw - 64px))',
      }),attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-3",attrs:{"cols":"12","md":_vm.editing ? 3 : 12}},[_c('v-sheet',{attrs:{"min-height":"100%"}},[_c('div',{staticClass:"d-flex pa-10"},[_c('hint-icon',{attrs:{"title":_vm.title,"body":_vm.description,"button-class":"ml-auto"}})],1),_c('group-exam-open-form',{ref:"form",staticClass:"pt-5",attrs:{"trainees":_vm.trainees,"user-ids":_vm.userIds,"problem-count":_vm.problemCount},on:{"change-all-trainees":_vm.changeAllTrainees,"submit":_vm.submit},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})],1)],1),(_vm.editing)?_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","md":"9"}},[_c('base-table',{attrs:{"loading":_vm.loading,"show-search":"","height":"calc(100vh - 268px)","store-option-id":"trainingGroupExamOpenDialogUserTable"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var attrs = ref.attrs;
      var on = ref.on;
return [_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"userId","show-select":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.passed",fn:function(ref){
      var item = ref.item;
return [(item.passed === 1)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")]):(item.passed === 0)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")]):_c('span',[_vm._v("-")])]}},{key:"item.max",fn:function(ref){
      var item = ref.item;
return [(item.max === undefined)?_c('span',[_vm._v("-")]):_c('span',[_vm._v(_vm._s(item.max)+"%")])]}},{key:"item.avatar",fn:function(ref){
      var item = ref.item;
return [_c('user',{attrs:{"user":item.avatar,"small":"","disabled":""}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},'v-data-table',attrs,false),on))]}}],null,false,4131807390)})],1):_vm._e()],1),_c('div',{staticClass:"pt-5 px-5 mx-auto",style:({ width: _vm.editing ? undefined : 'min(600px, calc(100vw - 64px))' })},[_c('base-button',{attrs:{"loading":_vm.updating,"disabled":_vm.loading,"data-cy":"training.groupExamOpenDialog.start"},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.labelStart)+" ")])],1)],1),_c('base-dialog-confirm',{ref:"confirmDialog",attrs:{"max-width":"400"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }