import { computed, nextTick, onMounted, ref, watch } from '@vue/composition-api';
import moment from 'moment';

import { LocalDateTime, Optional } from '@/base/types';

import { useMessages } from '../../Messages';

const FORMAT_DATETIME = 'YYYY/MM/DD HH:mm';

export type BaseTextDateTimeChangePayload = Optional<LocalDateTime>;

export type PropsBaseTextDateTime = {
  value?: LocalDateTime;
  start?: LocalDateTime;
  startLabel?: string;
  required: boolean;
  laterThanNow: boolean;
};

export function useBaseTextDateTime(
  props: PropsBaseTextDateTime,
  emit: (name: string, arg: BaseTextDateTimeChangePayload) => void
) {
  const msgs = useMessages({ prefix: 'base.atoms.baseTextDateTime' });
  const input = ref<string>();
  function init() {
    if (props.value?.isValid()) input.value = props.value?.format(FORMAT_DATETIME);
  }
  onMounted(init);
  watch(() => props.value, init);

  function change(s?: string) {
    input.value = undefined;
    if (!s) {
      emit('change', undefined);
      return;
    }
    const m = moment(s, FORMAT_DATETIME);
    nextTick(() => {
      if (m.isValid()) input.value = m.format(FORMAT_DATETIME);
      else input.value = s;
      emit('change', m);
    });
  }

  const min = computed(() => {
    if (!props.start || !props.start.isValid()) return undefined;
    return props.start.format(FORMAT_DATETIME);
  });
  const rules = computed(() => {
    const items = ['datetime'];
    if (props.required) items.splice(0, 0, 'required');
    if (props.laterThanNow) items.push('later_than_now');
    if (min.value) {
      const params = [min.value, props.startLabel || msgs.of('defaultLabelStart').value];
      items.push(`min_datetime:${params.join(',')}`);
    }
    return items.join('|');
  });

  return { input, rules, change };
}
