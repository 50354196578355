import { computed, ref } from '@vue/composition-api';

import { GroupExam } from '@/base/domains';
import {
  GetGroupExamsContent,
  GetGroupExamsCourse,
  GetGroupExamsRequest,
  isFailed,
  isSucceeded,
  useFinishGroupExam,
  useGetGroupExams,
} from '@/base/usecases';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

export function useGroupExamsStore() {
  const course = ref<GetGroupExamsCourse>();
  const content = ref<GetGroupExamsContent>();
  const groupExams = ref<GroupExam[]>();
  const loading = ref(false);
  const lastGroupExam = computed(
    () => groupExams.value?.filter((item) => item.status !== 'announced').slice(-1)[0]
  );
  const scheduledGroupExam = computed(() =>
    groupExams.value?.find((item) => item.status === 'announced')
  );

  const getGroupExams = useGetGroupExams();
  async function fetch(req: GetGroupExamsRequest) {
    loading.value = true;
    const res = await getGroupExams.execute(req);
    if (isSucceeded(res)) {
      course.value = res.course;
      content.value = res.content;
      groupExams.value = res.groupExams.sort((a, b) => a.times - b.times);
    } else {
      course.value = undefined;
      content.value = undefined;
      groupExams.value = undefined;
    }
    loading.value = false;
  }

  const closeGroupExam = useFinishGroupExam();
  async function finish() {
    if (!lastGroupExam.value || lastGroupExam.value.status !== 'in_progress') return true;
    const res = await closeGroupExam.execute({ id: lastGroupExam.value.id });
    if (isFailed(res)) return res.errors;
    return true;
  }

  const notFound = computed(() => !course.value || !content.value);
  return {
    course: readonly(course),
    content: readonly(content),
    groupExams: readonly(groupExams),
    lastGroupExam,
    scheduledGroupExam,
    loading,
    notFound,
    fetch,
    finish,
  };
}

export type GroupExamsStore = ReturnType<typeof useGroupExamsStore>;

export const GroupExamsStoreKey = injectionKeyOf<GroupExamsStore>({
  boundedContext: 'training',
  type: 'store',
  name: 'GroupExamsStore',
});
