









import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import ScheduleTagSelect from '@/base/app/components/molecules/ScheduleTagSelect.vue';
import { Schedule, ScheduleTag } from '@/base/domains';

import { PropsScheduleTagSelector, useScheduleTagSelector } from './ScheduleTagSelectorComposable';

type Props = PropsScheduleTagSelector;

export default defineComponent({
  name: 'TrainingScheduleTagSelector',
  components: { ScheduleTagSelect },
  props: {
    schedule: { type: Object as PropType<Schedule>, required: true },
    tags: { type: Array as PropType<ScheduleTag[]>, default: () => [] },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useScheduleTagSelector(props, emit);
  },
});
