





























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';

import CourseContentList from '../molecules/CourseContentList.vue';
import {
  PropsContentAppHeaderStart,
  useContentAppHeaderStart,
} from './ContentAppHeaderStartComposable';

type Props = PropsContentAppHeaderStart;

export default defineComponent({
  name: 'TrainingContentAppHeaderStart',
  components: { BaseButtonIcon, CourseContentList },
  props: {
    groupId: { type: String, required: true },
    headingId: { type: String, default: undefined },
    containerClassName: { type: String, default: '.training-content-container' },
  },
  emits: ['opened'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentAppHeaderStart(props, emit);
  },
});
