import { computed, nextTick, onUnmounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';
import mi from 'markdown-it';
import miAnchor from 'markdown-it-anchor';
import miEmoji from 'markdown-it-emoji';
import miRuby from 'markdown-it-ruby';

import { useMessages } from '@/base/app';
import { BASE_MARKDOWN_OPTION } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { getAreaProps, toStyleSize } from '@/base/app/utils/DomUtils';
import { Memo } from '@/base/domains';

const EXCLUDE_TOKEN_TYPES = ['image', 'video', 'audio'];

export type PropsMemoTitleLink = {
  memo: Memo;
  nudgeLeft: number;
  nudgeTop: number;
  disabled: boolean;
};

export function useMemoTitleLink(props: PropsMemoTitleLink, emit) {
  const msgs = useMessages({ prefix: 'training.atoms.memoTitleLink' });

  const md = mi({ ...BASE_MARKDOWN_OPTION })
    .use(miAnchor)
    .use(miEmoji)
    .use(miRuby);

  const label = computed(() => {
    let innerHTML = msgs.of('defaultLabel').value;
    if (props.memo.body) {
      const [firstLine] = props.memo.body.split('\n');
      const [inline] = md
        .parse(firstLine, {})
        .filter((item) => item.type === 'inline' && item.children?.some((child) => child.content));
      const tokens = inline?.children?.filter((item) => !EXCLUDE_TOKEN_TYPES.includes(item.type));
      if (tokens && tokens.length > 0) {
        innerHTML = md.renderer.render(tokens, {}, {});
      }
    }
    return innerHTML;
  });

  const id = computed(() => props.memo.id);
  const to = computed(() => ({ query: { memo: id.value } }));

  const chips = computed(() => {
    const { type } = props.memo.scope;
    const ret: { label: string; props?: Record<string, string | boolean> }[] = [
      { label: msgs.of(type).value },
    ];
    if (type === 'group') {
      const { roles } = props.memo.scope;
      if (roles.length > 0) {
        ret.push({
          label: msgs.of('roles', { roles: roles.map((r) => msgs.of(r).value).join() }).value,
          props: { color: 'primary' },
        });
      } else {
        ret.push({ label: msgs.of('noRoles').value, props: { color: 'primary', outlined: true } });
      }
    }
    return ret;
  });

  const slotDisplay = ref(false);
  const slotAttrs = ref<Record<string, unknown>>();

  function tryClose(e?: Event) {
    if (e) {
      const slotEl = (e.target as Element).closest('.training-memo-title-slot');
      if (slotEl) return;
    }
    slotDisplay.value = false;
    slotAttrs.value = undefined;
    document.removeEventListener('click', tryClose);
  }

  function closeSlot() {
    tryClose();
  }

  function calculateSlot() {
    const div = document.getElementById(id.value);
    const divRect = div?.getBoundingClientRect();
    if (!div || !divRect) {
      closeSlot();
      return;
    }

    const slotEl = document.getElementById(`${id.value}-slot`);
    if (!slotEl) {
      closeSlot();
      return;
    }

    const slotRect = slotEl.getBoundingClientRect();
    const { scrollTop, areaTop, areaLeft, areaWidth } = getAreaProps(
      slotEl.closest('.overflow-y-auto')
    );

    const top = scrollTop + divRect.top + divRect.height + props.nudgeTop - areaTop;

    let left = divRect.left + props.nudgeLeft - areaLeft;
    const scrollBarWidth = 16;
    const diffX = areaWidth - scrollBarWidth - (divRect.left - areaLeft + slotRect.width);
    if (diffX < 0) left += diffX + props.nudgeLeft;
    if (left <= 0) left = Math.abs(props.nudgeLeft);

    slotAttrs.value = { style: { top: toStyleSize(top), left: toStyleSize(left) } };
    document.addEventListener('click', tryClose);
  }
  watch(slotDisplay, (newVal) => {
    if (newVal) {
      nextTick(calculateSlot);
      emit('show', { id: id.value });
    } else {
      emit('hide', { id: id.value });
    }
  });

  function click() {
    if (slotDisplay.value || props.disabled) {
      closeSlot();
      return;
    }
    useTimeoutFn(() => {
      slotDisplay.value = true;
    }, 10);
  }

  onUnmounted(() => {
    document.removeEventListener('click', tryClose);
  });

  return { label, id, to, chips, slotDisplay, slotAttrs, click, close: closeSlot };
}
