import { computed, ref } from '@vue/composition-api';

import { ScheduleTag } from '@/base/domains';

import { or } from '../../utils/FilterUtils';
import {
  BaseSelectSearchItem,
  BaseSelectSearchSearchText,
  BaseSelectSearchValue,
} from '../atoms/BaseSelectSearchComposable';

export type ScheduleTagSelectValue = BaseSelectSearchValue;

export type ScheduleTagSelectItem = BaseSelectSearchItem<ScheduleTag>;

export type PropsScheduleTagSelect = {
  value: ScheduleTagSelectValue;
  tags: ScheduleTagSelectItem[];
};

export function useScheduleTagSelect(
  props: PropsScheduleTagSelect,
  emit: (name: string, arg: void | ScheduleTagSelectValue) => void
) {
  const text = ref<BaseSelectSearchSearchText>();
  const filtered = computed<ScheduleTagSelectItem[]>(() =>
    props.tags
      .filter((item) => or(item.text, text.value))
      .sort((a, b) => {
        if (a.text === b.text) return a.id < b.id ? -1 : 1;
        return a.text < b.text ? -1 : 1;
      })
  );

  function findTag(id: string) {
    return props.tags.find((item) => item.id === id);
  }

  function search(v: BaseSelectSearchSearchText) {
    text.value = v;
  }

  function change(v: BaseSelectSearchValue) {
    emit('change', v);
  }

  function submit() {
    emit('submit');
  }

  return {
    filtered,
    findTag,
    search,
    change,
    submit,
  };
}
