import { computed, ref } from '@vue/composition-api';

import { ContentId, ContentVersion } from '@/base/domains';
import { isFailed, isSucceeded } from '@/base/usecases';
import { GroupTrainingCourseReference } from '@/training/domains';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

import {
  CloseGroupContentRequest,
  GetGroupTrainingCourseGroupTrainingCourse,
  GetGroupTrainingCourseRequest,
  OpenGroupContentRequest,
  useChangeGroupTrainingCourseContentVersion,
  useCloseGroupContent,
  useGetGroupTrainingCourse,
  useOpenGroupContent,
} from '../../usecases';

export function useTrainingCourseStore() {
  const course = ref<GetGroupTrainingCourseGroupTrainingCourse>();
  const loading = ref(false);

  const getTrainingCourse = useGetGroupTrainingCourse();
  async function fetch(req: GetGroupTrainingCourseRequest) {
    loading.value = true;
    const res = await getTrainingCourse.execute(req);
    if (isSucceeded(res)) course.value = res.groupTrainingCourse;
    else course.value = undefined;
    loading.value = false;
  }

  function getVersions(id: string) {
    return course.value?.contents.find((item) => item.id === id)?.versions;
  }

  function setTrainingCourse(trainingCourse: GroupTrainingCourseReference) {
    assertIsDefined(course.value, 'course');
    const contents = trainingCourse.contents.map((item) => ({
      ...item,
      versions: getVersions(item.id) ?? [],
    }));
    course.value = {
      ...trainingCourse,
      hasNewContentVersion: course.value.hasNewContentVersion,
      contents,
    };
  }

  const openGroupContent = useOpenGroupContent();
  async function openContent(req: OpenGroupContentRequest) {
    const res = await openGroupContent.execute(req);
    if (isFailed(res)) return res.errors;
    setTrainingCourse(res.course);
    return true;
  }

  const closeGroupContent = useCloseGroupContent();
  async function closeContent(req: CloseGroupContentRequest) {
    const res = await closeGroupContent.execute(req);
    if (isFailed(res)) return res.errors;
    setTrainingCourse(res.course);
    return true;
  }

  const changeGroupContentVersion = useChangeGroupTrainingCourseContentVersion();
  async function updateGroupContentVersion(req: {
    contentId: ContentId;
    contentVersion: ContentVersion;
  }) {
    const id = course.value?.id;
    assertIsDefined(id, 'trainingGroupCourseId');
    const res = await changeGroupContentVersion.execute({ ...req, id });
    if (isFailed(res)) return res.errors;
    setTrainingCourse(res.groupTrainingCourse);
    return true;
  }

  const notFound = computed(() => !course.value);

  return {
    course: readonly(course),
    loading,
    notFound,
    fetch,
    openGroupContent: openContent,
    closeGroupContent: closeContent,
    updateGroupContentVersion,
  };
}

export type TrainingCourseStore = ReturnType<typeof useTrainingCourseStore>;

export const TrainingCourseStoreKey = injectionKeyOf<TrainingCourseStore>({
  boundedContext: 'training',
  type: 'store',
  name: 'TrainingCourseStore',
});
