import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { BaseTableActionMenu } from '@/base/app/components/molecules/BaseTableComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useGlobalStore } from '@/base/app/store';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { isFailed, isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import {
  GetCoursesByTrainerCourse,
  GetGroupTrainingGroupTrainingCourse,
  useGetCoursesByTrainer,
  useGetGroupTraining,
  useRemoveGroupCourse,
} from '../../../usecases';
import {
  GroupCourseTable,
  GroupCourseTableActionPayload,
  GroupCourseTableItem,
} from '../molecules/GroupCourseTableComposable';
import { CourseAddDialog } from './CourseAddDialogComposable';
import { CourseListDialog } from './CourseListDialogComposable';

const MENUS_ADD: BaseTableActionMenu[] = [{ value: 'add', label: '', single: true }];

const MENUS_UPD: BaseTableActionMenu[] = [
  { value: 'setting', label: '', single: true },
  { value: 'remove', label: '', required: true },
  { value: 'sort', label: '' },
];

export type PropsCourses = {
  id: string;
  disabled: boolean;
};

function useTable() {
  const table = ref<GroupCourseTable>();
  function clear() {
    if (table.value) table.value.clear();
  }
  return { table, clear };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

function useCourseAddDialog(props: PropsCourses) {
  const courseAddDialog = ref<CourseAddDialog>();
  function open(courseId: string) {
    assertIsDefined(courseAddDialog.value);
    courseAddDialog.value.open({ courseId, groupId: props.id });
  }
  return { courseAddDialog, open };
}

function useCourseListDialog(props: PropsCourses) {
  const courseListDialog = ref<CourseListDialog>();
  const { getQuery, moveTo: moveToCourseList } = useDialogQuery(DialogName.TRAINING_COURSE_LIST);
  function watchCourseListDialogQuery() {
    if (!courseListDialog.value) return;
    const q = getQuery();
    if (q) {
      courseListDialog.value.open({ groupId: props.id });
    } else if (!q && courseListDialog.value.opened()) {
      courseListDialog.value.close();
    }
  }
  return { courseListDialog, moveToCourseList, watchCourseListDialogQuery };
}

export function useCourses(props: PropsCourses) {
  const msgs = useMessages({ prefix: 'training.organisms.courses' });
  const router = useRouter();
  const route = useRoute();
  const { table, clear: clearTableSelection } = useTable();
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const { errorDialog, error } = useErrorDialog();
  const { courseAddDialog, open: openCourseAdd } = useCourseAddDialog(props);
  const { courseListDialog, moveToCourseList, watchCourseListDialogQuery } =
    useCourseListDialog(props);
  const { findUser } = useGlobalStore();

  const groupCourses = ref<GetGroupTrainingGroupTrainingCourse[]>([]);
  const courses = ref<GetCoursesByTrainerCourse[]>([]);
  const loading = ref(false);
  const notBelongTo = ref(false);

  const headerKeys = computed(() =>
    notBelongTo.value
      ? ['preview', 'confirmedBy', 'confirmedAt', 'contentLastUpdatedBy', 'contentLastUpdatedAt']
      : ['preview', 'hasNewContentVersion']
  );

  const getUserName = (id?: string) =>
    id ? findUser(id)?.name || msgs.of('unknown', { id }).value : '';
  const items = computed<GroupCourseTableItem[]>(() => {
    let data: GroupCourseTableItem[];
    if (notBelongTo.value) {
      data = courses.value
        .filter((c) => !groupCourses.value.some((tc) => tc.courseId === c.id))
        .map((c) => ({
          id: c.id,
          name: c.name,
          confirmedBy: getUserName(c.confirmedBy),
          confirmedAt: c.confirmedAt.format('YYYY/MM/DD HH:mm'),
          contentLastUpdatedBy: getUserName(c.contentLastUpdatedBy),
          contentLastUpdatedAt: c.contentLastUpdatedAt?.format('YYYY/MM/DD HH:mm') ?? '',
        }));
    } else {
      data = groupCourses.value.map((tc) => ({
        id: tc.courseId,
        name: tc.displayName,
        originalName: tc.courseName,
        hasNewContentVersion: tc.hasNewContentVersion,
      }));
    }
    return data;
  });
  const getGroupTraining = useGetGroupTraining();
  async function fetchTraining() {
    groupCourses.value = [];
    const groupId = props.id;
    const res = await getGroupTraining.execute({ groupId });
    if (isSucceeded(res)) groupCourses.value = res.groupTrainingCourses;
  }

  const getCourses = useGetCoursesByTrainer();
  async function fetchCourses() {
    courses.value = [];
    if (props.disabled) return;
    const groupId = props.id;
    const res = await getCourses.execute({ groupId });
    if (isSucceeded(res)) courses.value = res.courses;
  }

  async function fetch() {
    notBelongTo.value = false;
    loading.value = true;
    await fetchTraining();
    await fetchCourses();
    loading.value = false;
  }

  function init() {
    fetch();
    watchCourseListDialogQuery();
  }
  onMounted(init);
  watch(() => props.id, init);
  watch(() => route.query, watchCourseListDialogQuery);

  function done() {
    clearTableSelection();
    fetch();
  }
  watch(() => props.id, done);

  const removeGroupCourse = useRemoveGroupCourse();
  async function removeCourses(ids: string[]) {
    const groupId = props.id;
    const ret = await Promise.all(
      ids.map((courseId) => removeGroupCourse.execute({ groupId, courseId }))
    );
    const errors = ret.map((r) => (isFailed(r) ? r.errors : [])).reduce((p, c) => p.concat(c), []);
    if (errors.length === 0) return true;
    return errors;
  }

  function action({ event, selected }: GroupCourseTableActionPayload) {
    switch (event) {
      case 'add': {
        const [first] = selected;
        assertIsDefined(first, 'first');
        openCourseAdd(first.id);
        break;
      }
      case 'setting':
      case 'show': {
        if (notBelongTo.value) return;
        const [first] = selected;
        assertIsDefined(first, 'first');
        const query = {
          edit: event === 'setting' ? '' : undefined,
          ...toQuery(route),
        };
        router.push({
          name: 'trainerGroupCourse',
          params: { id: props.id, courseId: first.id },
          query,
        });
        break;
      }
      case 'remove':
        if (selected.length === 0) return;
        confirm(msgs.of('confirmRemove').value, async () => {
          loading.value = true;
          const ret = await removeCourses(selected.map((item) => item.id));
          if (ret !== true) {
            loading.value = false;
            error(ret);
            return;
          }
          waitTransition(done);
        });
        break;
      case 'sort':
        moveToCourseList();
        break;
      default:
    }
  }

  const menus = computed(() => {
    if (props.disabled) return [];
    const menuItems = notBelongTo.value ? MENUS_ADD : MENUS_UPD;
    return menuItems.map((item) => ({ ...item, label: msgs.of(item.value).value }));
  });

  return {
    table,
    confirmDialog,
    errorDialog,
    courseAddDialog,
    courseListDialog,
    loading,
    notBelongTo,
    headerKeys,
    items,
    menus,
    labelCourseNotInGroup: msgs.of('courseNotInGroup'),
    action,
    done,
    clearTableSelection,
  };
}
