

































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ProblemResultsView from '@/base/app/components/molecules/ProblemResultsView.vue';
import UserExamResultSummary from '@/base/app/components/molecules/UserExamResultSummary.vue';
import { ExamResultVisibilityLevel, MyExamResult, Problem, ProblemHeader } from '@/base/domains';

import { UserExamStoreExam } from '../../stores';
import { PropsUserExamResults, useUserExamResults } from './UserExamResultsComposable';

type Props = PropsUserExamResults;

export default defineComponent({
  name: 'TrainingUserExamResults',
  components: { ProblemResultsView, UserExamResultSummary, ContentFooter },
  props: {
    exam: { type: Object as PropType<UserExamStoreExam>, required: true },
    groupId: { type: String, required: true },
    examResult: { type: Object as PropType<MyExamResult>, required: true },
    problems: { type: Array as PropType<Problem[]>, default: () => [] },
    problemHeaders: { type: Array as PropType<ProblemHeader[]>, default: () => [] },
    visibilityLevel: { type: String as PropType<ExamResultVisibilityLevel>, required: true },
    isMobile: { type: Boolean, default: false },
  },
  emits: ['complete', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserExamResults(props, emit);
  },
});
