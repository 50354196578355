























































































































































import { defineComponent, SetupContext } from '@vue/composition-api';

import WindowControl from '@/base/app/components/molecules/WindowControl.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';
import NotFound from '@/base/app/components/organisms/NotFound.vue';

import ContentExam from '../molecules/ContentExam.vue';
import ContentMemos from '../molecules/ContentMemos.vue';
import ContentText from '../molecules/ContentText.vue';
import ContentWorkbook from '../molecules/ContentWorkbook.vue';
import { PropsContentContainer, useContentContainer } from './ContentContainerComposable';
import ContentMemoDialog from './ContentMemoDialog.vue';
import ContentQuestion from './ContentQuestion.vue';
import ContentQuestionDialog from './ContentQuestionDialog.vue';
import ContentQuestionSearchDialog from './ContentQuestionSearchDialog.vue';

type Props = PropsContentContainer;

export default defineComponent({
  name: 'TrainingContentContainer',
  components: {
    WindowControl,
    ContentMemos,
    ContentWorkbook,
    ContentText,
    ContentExam,
    ContentQuestion,
    NotFound,
    Loading,
    ContentQuestionSearchDialog,
    ContentQuestionDialog,
    ContentMemoDialog,
    DialogAnchorConfirm,
  },
  props: {
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
    contentId: { type: String, required: true },
    contentVersion: { type: String, default: undefined },
    question: { type: String, default: undefined },
    memo: { type: String, default: undefined },
    problem: { type: String, default: undefined },
    hash: { type: String, default: undefined },
    disabled: { type: Boolean, default: false },
    disabledWindowScroll: { type: Boolean, default: false },
    adminMode: { type: Boolean, default: false },
    topHeight: { type: Number, default: 64 },
    sideWidth: { type: Number, default: 300 },
    side: { type: Boolean, default: false },
  },
  emits: ['change-heading-id'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentContainer(
      props,
      {
        className: '.training-content-container',
        initPages: ['memo'],
        routeNameCompleted: 'groupCourse',
        saveScrollPosition: true,
        watchTextHeadingId: true,
      },
      emit
    );
  },
});
