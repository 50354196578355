import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import {
  BaseTable,
  BaseTableActionPayload,
} from '@/base/app/components/molecules/BaseTableComposable';
import { ScheduleTag } from '@/base/domains';

const HEADERS: DataTableHeader[] = [
  { value: 'text', text: '', class: 'base-table-name' },
  { value: 'preview', text: '', width: 'min(400px, 40vw)', sortable: false },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type ScheduleTagTableItem = ScheduleTag;

export type PropsScheduleTable = {
  tags: ScheduleTagTableItem[];
  headerKeys: string[];
};

export type ScheduleTagTableActionPayload = {
  event: string;
  selected: ScheduleTagTableItem[];
};

export function useScheduleTagTable(
  props: PropsScheduleTable,
  emit: (name: string, arg: ScheduleTagTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.scheduleTagTable' });
  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );
  const items = computed(() => props.tags);

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.tags.find((t) => t.id === id))
      .filter((t) => t) as ScheduleTagTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type ScheduleTagTable = ReturnType<typeof useScheduleTagTable>;
