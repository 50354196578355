























































import { defineComponent } from '@vue/composition-api';

import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';

import CourseReviewProblems from '../molecules/CourseReviewProblems.vue';
import CourseReviewSetting from '../molecules/CourseReviewSetting.vue';
import CourseReviewWelcome from '../molecules/CourseReviewWelcome.vue';
import {
  PropsCourseReviewContainer,
  useCourseReviewContainer,
} from './CourseReviewContainerComposable';

type Props = PropsCourseReviewContainer;

export default defineComponent({
  name: 'TrainingCourseReviewContainer',
  components: {
    CourseReviewWelcome,
    CourseReviewProblems,
    CourseReviewSetting,
    BaseDialogOk,
    DialogAnchorConfirm,
  },
  props: {
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
    showProminent: { type: Boolean, default: false },
    shrinkLimit: { type: Number, default: 16 },
    isMobile: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useCourseReviewContainer(props);
  },
});
