
































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import MarkerControlCaption from '../atoms/MarkerControlCaption.vue';
import MarkerControlHint from '../atoms/MarkerControlHint.vue';
import MarkerControlTileMenus from '../atoms/MarkerControlTileMenus.vue';
import {
  ContentMarkerProblem,
  ContentMarkerText,
  PropsContentMarkerControl,
  useContentMarkerControl,
} from './ContentMarkerControlComposable';

type Props = PropsContentMarkerControl;

export default defineComponent({
  name: 'TrainingContentMarkerControl',
  components: { MarkerControlCaption, MarkerControlHint, MarkerControlTileMenus },
  props: {
    id: { type: String, required: true },
    type: { type: String, required: true },
    marker: {
      type: Object as PropType<ContentMarkerText | ContentMarkerProblem>,
      default: undefined,
    },
    hint: { type: String, default: undefined },
    showHint: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    height: { type: Number, default: 48 },
  },
  emits: ['change-color', 'remove', 'move'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentMarkerControl(props, emit);
  },
});
