import { computed, onMounted, Ref, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { GroupExam } from '@/base/domains';
import { Optional } from '@/base/types';
import { GetGroupExamsContent, isFailed, useFinishGroupExam } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { isDefined } from '@/utils/TsUtils';
import { requiredInject, useRoute } from '@/utils/VueUtils';

import { useChangeGroupExamScheduledTime, useRemoveGroupExamSchedule } from '../../../usecases';
import { GroupExamsStoreKey } from '../../stores';
import {
  GroupExamChangeScheduleDialog,
  GroupExamChangeScheduleDialogSubmitPayload,
} from '../molecules/GroupExamChangeScheduleDialogComposable';
import { GroupExamTableItem } from '../molecules/GroupExamTableComposable';
import { GroupExamDialog } from './GroupExamDialogComposable';
import { GroupExamOpenDialog } from './GroupExamOpenDialogComposable';

export type PropsGroupExams = {
  groupId: string;
  courseId: string;
  contentId: string;
  disabled: boolean;
};

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

function useExamOpenDialog(
  props: PropsGroupExams,
  content: Readonly<Ref<Optional<GetGroupExamsContent>>>
) {
  const examOpenDialog = ref<GroupExamOpenDialog>();
  const { getQuery, moveTo: moveToExamOpen } = useDialogQuery(DialogName.TRAINING_GROUP_EXAM_OPEN);
  function watchExamOpenDialogQuery() {
    if (!examOpenDialog.value || !content.value) return;
    const q = getQuery();
    if (q) {
      examOpenDialog.value.open({
        ...props,
        requiredTime: content.value.requiredTime,
        passingStandard: content.value.passingStandard,
        problemCount: content.value.problemCount,
      });
    } else if (!q && examOpenDialog.value.opened()) {
      examOpenDialog.value.close();
    }
  }
  return { examOpenDialog, moveToExamOpen, watchExamOpenDialogQuery };
}

function useGroupExamDialog(props: PropsGroupExams) {
  const groupExamDialog = ref<GroupExamDialog>();
  const { getQuery, moveTo } = useDialogQuery(DialogName.TRAINING_GROUP_EXAM);
  function moveToGroupExam(item: GroupExamTableItem) {
    moveTo({ id: item.id });
  }
  function watchGroupExamDialogQuery() {
    if (!groupExamDialog.value) return;
    const q = getQuery();
    if (q && q.params && 'id' in q.params) {
      groupExamDialog.value.open({ groupExamId: q.params.id }, props.disabled);
    } else if (!q && groupExamDialog.value.opened()) {
      groupExamDialog.value.close();
    }
  }
  return { groupExamDialog, moveToGroupExam, watchGroupExamDialogQuery };
}

function useGroupExamChangeScheduleDialog() {
  const examChangeScheduleDialog = ref<GroupExamChangeScheduleDialog>();
  function open(exam: GroupExam) {
    assertIsDefined(examChangeScheduleDialog.value);
    examChangeScheduleDialog.value.open({
      scheduledStart: exam.scheduledStart,
      scheduledFinish: exam.scheduledFinish,
    });
  }
  return { examChangeScheduleDialog, open };
}

export function useGroupExams(props: PropsGroupExams) {
  const msgs = useMessages({ prefix: 'training.organisms.groupExams' });
  const route = useRoute();
  const { errorDialog, error } = useErrorDialog();
  const { confirmDialog, open: confirm } = useConfirmDialog();

  const {
    content,
    groupExams,
    lastGroupExam,
    scheduledGroupExam,
    loading: loadingGroupExams,
    fetch: fetchGroupExams,
  } = requiredInject(GroupExamsStoreKey);
  const { examOpenDialog, moveToExamOpen, watchExamOpenDialogQuery } = useExamOpenDialog(
    props,
    content
  );
  const { groupExamDialog, moveToGroupExam, watchGroupExamDialogQuery } = useGroupExamDialog(props);
  const { examChangeScheduleDialog, open: openExamChangeScheduleDialog } =
    useGroupExamChangeScheduleDialog();

  function calculate(item: GroupExam) {
    const v = item.passingStandard;
    if (!isDefined(v)) return undefined;
    const count = item.content.problems.length;
    if (!v || !count) return 0;
    return Math.round((v / count) * 1000) / 10;
  }

  function fetch() {
    return fetchGroupExams({
      groupId: props.groupId,
      courseId: props.courseId,
      contentId: props.contentId,
    });
  }

  function initDialog() {
    watchGroupExamDialogQuery();
    watchExamOpenDialogQuery();
  }
  async function init() {
    await fetch();
    initDialog();
  }
  onMounted(init);
  watch(() => props.contentId, init);
  watch(() => route.query, initDialog);

  const updating = ref(false);
  const finishGroupExam = useFinishGroupExam();
  function finish() {
    assertIsDefined(lastGroupExam.value?.id, 'groupExamId');
    const { id, status } = lastGroupExam.value;
    if (status !== 'in_progress') return;
    confirm(msgs.of('confirmFinish').value, async () => {
      updating.value = true;
      const res = await finishGroupExam.execute({ id });
      if (isFailed(res)) {
        updating.value = false;
        error(res.errors);
        return;
      }
      updating.value = false;
      fetch();
    });
  }

  const removeGroupExamSchedule = useRemoveGroupExamSchedule();
  function remove() {
    assertIsDefined(scheduledGroupExam.value?.id, 'groupExamId');
    const { id, status, scheduledStart } = scheduledGroupExam.value;
    if (status !== 'announced' || scheduledStart.isBefore()) {
      error([msgs.of('failedRemoveSchedule').value]);
      fetch();
      return;
    }
    confirm(msgs.of('confirmRemoveSchedule').value, async () => {
      updating.value = true;
      const res = await removeGroupExamSchedule.execute({ id });
      if (isFailed(res)) {
        updating.value = false;
        error(res.errors);
        return;
      }
      updating.value = false;
      fetch();
    });
  }

  function action(v: string) {
    switch (v) {
      case 'start':
        moveToExamOpen();
        break;
      case 'finish':
        finish();
        break;
      case 'removeSchedule':
        remove();
        break;
      case 'changeScheduledDate': {
        assertIsDefined(scheduledGroupExam.value, 'groupExam');
        openExamChangeScheduleDialog(scheduledGroupExam.value);
        break;
      }
      default:
    }
  }

  const changeGroupExamScheduledTime = useChangeGroupExamScheduledTime();
  async function changeSchedule(payload: GroupExamChangeScheduleDialogSubmitPayload) {
    assertIsDefined(scheduledGroupExam.value?.id, 'groupExamId');
    const { id } = scheduledGroupExam.value;
    updating.value = true;
    const res = await changeGroupExamScheduledTime.execute({
      id,
      scheduledStart: payload.scheduledStart,
      scheduledFinish: payload.scheduledFinish,
    });
    payload.done();
    if (isFailed(res)) {
      updating.value = false;
      error(res.errors);
      return;
    }
    updating.value = false;
    fetch();
  }

  const menus = computed(() => {
    const keys: string[] = [];
    if (lastGroupExam.value?.status === 'in_progress') {
      // 実施中の場合、終了できる
      keys.push('finish');
    }
    if (
      !content.value?.open &&
      lastGroupExam.value?.status !== 'in_progress' &&
      !scheduledGroupExam.value
    ) {
      // 実施中がないかつ予約がない場合、開始/予約できる
      keys.push('start');
    }
    if (scheduledGroupExam.value) {
      // 予約がある場合、予約の変更、削除ができる
      keys.push('changeScheduledDate', 'removeSchedule');
    }
    return keys.map((value) => ({ value, label: msgs.of(value).value }));
  });

  const items = computed<Optional<GroupExamTableItem[]>>(() => {
    const getEndDate = (ge: GroupExam) => {
      if (!ge.finishedAt || !ge.scheduledFinish) return ge.scheduledFinish ?? ge.finishedAt;
      return ge.finishedAt.isBefore(ge.scheduledFinish) ? ge.finishedAt : ge.scheduledFinish;
    };
    return groupExams.value
      ?.map((item) => {
        const countOfNotBegun = item.userExams.filter(
          (ue) => ue.status === 'not_started' || ue.status === 'skipped'
        ).length;
        const countOfInProgress = item.userExams.filter((ue) => ue.status === 'in_progress').length;
        const countOfCompleted = item.userExams.filter((ue) => ue.status === 'finished').length;
        return {
          id: item.id,
          times: item.times,
          level: item.visibilityLevel,
          timeLimit: item.timeLimit,
          start: item.scheduledStart,
          end: getEndDate(item),
          status: item.status,
          passingStandard: calculate(item),
          countOfNotBegun,
          countOfInProgress,
          countOfCompleted,
        };
      })
      .sort((a, b) => a.times - b.times);
  });
  const loading = computed(() => loadingGroupExams.value || updating.value);
  return {
    errorDialog,
    confirmDialog,
    examOpenDialog,
    groupExamDialog,
    examChangeScheduleDialog,
    loading,
    menus,
    items,
    labelDetails: msgs.of('details'),
    action,
    changeSchedule,
    moveToGroupExam,
    refresh: fetch,
  };
}
