import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { ProblemResultsViewClickAnchorPayload } from '@/base/app/components/molecules/ProblemResultsViewComposable';
import {
  ProblemViewChoiceValue,
  ProblemViewClickAnchorPayload,
} from '@/base/app/components/molecules/ProblemViewComposable';
import { getElementRect } from '@/base/app/utils/DomUtils';
import {
  ProblemUtilsNavigator,
  ProblemUtilsNavigatorValue,
  ProblemUtilsProblem,
} from '@/base/app/utils/ProblemUtils';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { Optional } from '@/base/types';

const STYLE_EMPTY = {
  class: undefined as Optional<string>,
  style: undefined as Optional<Record<string, string>>,
};
const STYLES = {
  frame: STYLE_EMPTY,
  windows: STYLE_EMPTY,
  problem: { minHeight: '75vh' as Optional<string> },
};
type Styles = typeof STYLES;

export type CourseReviewProblemsIndex = number;

export type CourseReviewProblemsCheckPayload = {
  index: CourseReviewProblemsIndex;
  scored: (correct: boolean) => void;
};

export type CourseReviewProblemsChoicePayload = {
  index: CourseReviewProblemsIndex;
  values: number[];
};

export type CourseReviewProblemsClearPayload = {
  index: CourseReviewProblemsIndex;
};

export type CourseReviewProblemsCompletePayload = {
  remainProblemIndexes: CourseReviewProblemsIndex[];
  done: () => void;
};

export type CourseReviewProblemsClickAnchorPayload =
  | ProblemViewClickAnchorPayload
  | ProblemResultsViewClickAnchorPayload;

export type PropsCourseReviewProblems = {
  index: ProblemUtilsNavigatorValue;
  contentName: string;
  problems: ProblemUtilsProblem[];
  problemNavigator?: ProblemUtilsNavigator;
  showProminent?: boolean;
  isMobile: boolean;
};

export function useCourseReviewProblems(
  props: PropsCourseReviewProblems,
  emit: (
    name: string,
    arg?:
      | ProblemUtilsNavigatorValue
      | CourseReviewProblemsCheckPayload
      | CourseReviewProblemsChoicePayload
      | CourseReviewProblemsClearPayload
      | CourseReviewProblemsCompletePayload
      | CourseReviewProblemsClickAnchorPayload
  ) => void
) {
  // 削除しないで残す問題のインデックスリスト
  const remainProblemIndexes = ref<CourseReviewProblemsIndex[]>([]);
  const loading = ref(false);

  function init() {
    remainProblemIndexes.value = props.problems
      .filter((item) => !item.passed || (item.failures ?? 0) > 0)
      .map((item) => item.index);
    loading.value = false;
  }

  function addRemainProblemIndexes(i: CourseReviewProblemsIndex) {
    if (remainProblemIndexes.value.includes(i)) return;
    remainProblemIndexes.value = [...remainProblemIndexes.value, i];
  }

  function removeRemainProblemIndexes(i: CourseReviewProblemsIndex) {
    remainProblemIndexes.value = remainProblemIndexes.value.filter((index) => index !== i);
  }

  function choiceValue(v: ProblemViewChoiceValue) {
    if (props.index === 'results') return;
    emit('choice-value', { index: props.index, values: v });
  }

  function checkValue() {
    if (props.index === 'results') return;
    const { index } = props;
    const p = props.problems.find((item) => item.index === index);
    emit('check-value', {
      index,
      scored: (correct) => {
        if (correct && p?.failures === 0) removeRemainProblemIndexes(index);
        if (!correct) addRemainProblemIndexes(index);
      },
    });
  }

  function clearValue() {
    if (props.index === 'results') return;
    emit('clear-value', { index: props.index });
  }

  function change(payload: ProblemUtilsNavigatorValue) {
    emit('change', payload);
  }

  function prev() {
    if (!props.problemNavigator || props.problemNavigator.prev.value === undefined) return;
    emit('change', props.problemNavigator.prev.value);
  }

  function next() {
    if (!props.problemNavigator || props.problemNavigator.next.value === undefined) return;
    emit('change', props.problemNavigator.next.value);
  }

  function done() {
    loading.value = false;
  }

  function complete() {
    loading.value = true;
    emit('complete', { remainProblemIndexes: remainProblemIndexes.value, done });
  }

  function back() {
    emit('back');
  }

  function clickAnchor(
    payload: ProblemViewClickAnchorPayload | ProblemResultsViewClickAnchorPayload
  ) {
    emit('click-anchor', payload);
  }

  const progressValue = computed(() => {
    if (!props.problemNavigator) return undefined;
    const total = props.problemNavigator.items.filter((item) => item.value !== 'results').length;
    const count = props.problemNavigator.items.filter((item) => item.passed).length;
    return (count / total) * 100;
  });

  const { mobile } = useVuetify();
  const vWindow = ref<Vue>();
  const styles = ref<Styles>(STYLES);

  function calculateStyle() {
    if (!vWindow.value) return;
    const rectVWindow = vWindow.value.$el.getBoundingClientRect();
    const { top: vwTop } = rectVWindow;
    if (props.isMobile || props.index === 'results' || mobile.value) {
      styles.value = {
        ...STYLES,
        frame: { class: 'pt-16', style: undefined },
        problem: { minHeight: 'calc(100vh - 48px)' },
      };
    } else {
      const rectMain = getElementRect('.course-review-problems');
      if (!rectMain) return;
      const rectBottom = getElementRect('.course-review-problems-bottom');
      styles.value = {
        ...STYLES,
        frame: { class: undefined, style: { maxHeight: `calc(100vh - ${rectMain.top + 8}px)` } },
        windows: { class: 'overflow-y-auto', style: undefined },
        problem: { minHeight: `calc(100vh - ${vwTop + (rectBottom?.height ?? 0) + 16}px)` },
      };
    }
  }
  onMounted(() => {
    init();
    useTimeoutFn(calculateStyle, 1000);
  });
  watch(
    () => [props.index, props.showProminent, mobile.value],
    () => useTimeoutFn(calculateStyle, 500)
  );

  const msgs = useMessages({ prefix: 'training.molecules.courseReviewProblems' });
  const description = computed(
    () => msgs.of('completeDescription', { review: props.contentName }).value
  );

  return {
    remainProblemIndexes,
    loading,
    progressValue,
    vWindow,
    styles,
    description,
    labelComplete: msgs.of('complete'),
    choiceValue,
    checkValue,
    clearValue,
    change,
    prev,
    next,
    complete,
    back,
    clickAnchor,
  };
}
