import { computed, ref, watch } from '@vue/composition-api';
import moment from 'moment';

import { useMessages } from '@/base/app';
import { findMentions } from '@/base/app/components/atoms/BaseMarkdownMention';
import { createUserAvatar } from '@/base/app/components/atoms/UserComposable';
import {
  PropsQuestionBar,
  QuestionBarSelectPayload,
} from '@/base/app/components/molecules/QuestionBarComposable';
import { useGlobalStore } from '@/base/app/store';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { toStyleSize } from '@/base/app/utils/DomUtils';
import { Comment } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { isFailed } from '@/base/usecases';
import { requiredInject, useRoute, useRouter } from '@/utils/VueUtils';

import { useGetQuestions } from '../../../usecases';
import { ContentStoreKey } from '../../stores';
import {
  filterQuestions,
  QuestionCondition,
  QuestionFilterConvert,
  QuestionFilterTargetType,
} from '../../utils/FilterUtils';

const CONDITION_STATUS: QuestionFilterTargetType[] = ['solved', 'unsolved'];
const CONDITION_TARGET: QuestionFilterTargetType[] = [
  'title',
  'createdBy',
  'assignee',
  'courseName',
  'contentName',
  'content',
];

type Question = PropsQuestionBar;

type ContentQuestionSearchDialogTarget = {
  groupId: string;
};

export type PropsContentQuestionSearchDialog = {
  fullscreen: boolean;
  dialogClassName: string;
  width: number;
  height: number | string;
};

export function useContentQuestionSearchDialog(props: PropsContentQuestionSearchDialog) {
  const msgs = useMessages({ prefix: 'training.organisms.contentQuestionSearchDialog' });
  const store = requiredInject(ContentStoreKey);
  const { findUser } = useGlobalStore();
  const route = useRoute();
  const router = useRouter();

  const dialog = ref(false);

  const target = ref<ContentQuestionSearchDialogTarget>();
  const questions = ref<Question[]>([]);
  const warn = ref<string>();
  const loading = ref(false);

  function changeLoading(v: boolean) {
    loading.value = v;
    if (!store.questionListAttrs.value || store.questionListAttrs.value.loading === v) return;
    store.changeQuestionListAttrs({ ...store.questionListAttrs.value, loading: v });
  }

  function maxUpdatedAt(comments: Comment[]) {
    return moment.max(
      comments.flatMap((c) => [c.createdAt, c.editedAt]).filter((m) => m) as LocalDateTime[]
    );
  }

  const getQuestions = useGetQuestions();
  async function search(condition: QuestionCondition) {
    if (!dialog.value || !target.value) return;

    warn.value = undefined;
    questions.value = [];

    const { text, target: condTarget, userId } = condition;
    if (CONDITION_STATUS.every((key) => !condTarget.includes(key))) return;
    if (!!text && CONDITION_TARGET.every((key) => !condTarget.includes(key))) return;

    let resolved: boolean | undefined;
    if (condTarget.includes('solved') && !condTarget.includes('unsolved')) {
      resolved = true;
    } else if (!condTarget.includes('solved') && condTarget.includes('unsolved')) {
      resolved = false;
    }

    const createdBy = condTarget.includes('own') ? userId : undefined;
    const convert: QuestionFilterConvert = (t, q) => {
      switch (t) {
        case 'createdBy': {
          const u = findUser(q.createdBy);
          if (u) return [u.name];
          return [];
        }
        case 'assignee':
          return q.assignees.map((id) => findUser(id)?.name).filter((u) => !!u) as string[];
        case 'content':
          return q.comments
            .map((item) => findMentions(item.body, findUser).map((m) => `@${m.user.name}`))
            .flat();
        default:
      }
      return [];
    };

    changeLoading(true);
    const res = await getQuestions.execute({ groupId: target.value.groupId, resolved, createdBy });
    changeLoading(false);
    if (isFailed(res)) return;

    questions.value = filterQuestions(res.questions, condition, convert)
      .map((q) => ({
        question: q,
        createdBy: createUserAvatar(q.createdBy, findUser),
        resolvedBy: q.resolvedBy ? createUserAvatar(q.resolvedBy, findUser) : undefined,
        assignees: q.assignees.map((a) => createUserAvatar(a, findUser)),
        updatedAt: maxUpdatedAt(q.comments),
      }))
      .sort((a, b) => b.updatedAt.diff(a.updatedAt));
    if (res.limitExceeded) {
      const [q] = questions.value.slice(-1);
      warn.value = msgs.of('limitExceeded', {
        date: q?.question.createdAt.format('ll HH:mm') ?? '-',
      }).value;
    }
  }
  watch(store.questionCondition, (newVal) => {
    if (!newVal) return;
    if (!store.questionListAttrs.value || !!newVal.text) search(newVal);
  });

  function close() {
    dialog.value = false;
    questions.value = [];
    warn.value = undefined;
    if (store.questionListAttrs.value) {
      const attrs = store.questionListAttrs.value;
      if (attrs.pending === false) {
        store.changeQuestionListAttrs({ ...attrs, pending: true });
      }
    } else {
      store.clearQuestionCondition();
    }
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }
  watch(dialog, (newVal) => {
    if (newVal) return;
    close();
  });

  function open(payload: { groupId: string }) {
    target.value = payload;
    dialog.value = true;
    const cond = store.questionCondition.value;
    if (cond) search(cond);
  }
  watch(store.questionListAttrs, (newVal) => {
    if (!newVal) {
      if (dialog.value) close();
      return;
    }
    if (!dialog.value && !newVal.pending) open(newVal);
    if (dialog.value && newVal.pending) close();
  });

  function opened() {
    return dialog.value;
  }

  function changeCondition(v: QuestionCondition) {
    store.changeQuestionCondition(v);
  }

  function openQuestion(payload: QuestionBarSelectPayload) {
    store.fetchQuestionPage(payload.questionId);
    store.changePageValue(payload.questionId);
    close();
  }

  const dialogAttrs = computed(() => {
    if (props.fullscreen) {
      return { fullscreen: true };
    }
    let posX = window.innerWidth - props.width;
    if (store.questionListAttrs.value) {
      const { x } = store.questionListAttrs.value;
      if (x !== undefined) posX = x;
    }
    return {
      absolute: true,
      'position-x': posX,
      'content-class': `${props.dialogClassName} ${props.dialogClassName}--flat`,
      'close-on-click': false,
      'close-on-content-click': false,
      transition: 'scroll-y-transition',
      'max-height': toStyleSize(props.height),
    };
  });

  const sheetAttrs = computed(() => {
    if (props.fullscreen) return { height: '100vh' };
    return { color: 'transparent' };
  });

  const mainStyle = computed(() => {
    if (props.fullscreen) {
      const header = 76;
      return {
        height: `calc(100vh - ${toStyleSize(header)})`,
        width: 'min(600px, calc(100vw - 24px))',
      };
    }
    const header = 42;
    let width = toStyleSize(props.width);
    if (store.questionListAttrs.value) {
      const { w } = store.questionListAttrs.value;
      if (w !== undefined) width = toStyleSize(w);
    }
    return {
      'max-height': `calc(${toStyleSize(props.height)} - ${toStyleSize(header)})`,
      width,
    };
  });

  return {
    dialog,
    condition: store.questionCondition,
    questions,
    warn,
    loading,
    dialogAttrs,
    sheetAttrs,
    mainStyle,
    title: msgs.of('searchQuestion'),
    labelClose: msgs.of('close'),
    labelNoData: msgs.of('noData'),
    close,
    open,
    opened,
    changeCondition,
    openQuestion,
  };
}

export type ContentQuestionSearchDialog = ReturnType<typeof useContentQuestionSearchDialog>;
