





































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import ContentIconClosed from '@/base/app/components/molecules/ContentIconClosed.vue';
import StatusIcon from '@/base/app/components/molecules/StatusIcon.vue';
import { GetContentContent, GetContentCourse } from '@/training/usecases';

import ContentHeadingList from './ContentHeadingList.vue';
import { PropsCourseContentList, useCourseContentList } from './CourseContentListComposable';

type Props = PropsCourseContentList;

export default defineComponent({
  name: 'TrainingCourseContentList',
  components: { ContentIconClosed, StatusIcon, ContentHeadingList },
  inheritAttrs: false,
  props: {
    groupId: { type: String, required: true },
    content: { type: Object as PropType<GetContentContent>, required: true },
    course: { type: Object as PropType<GetContentCourse>, required: true },
    headingId: { type: String, default: undefined },
  },
  emits: ['change-heading', 'move'],
  setup(props: Props, { emit }: SetupContext) {
    return useCourseContentList(props, emit);
  },
});
