















































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ProblemNavigator from '@/base/app/components/atoms/ProblemNavigator.vue';
import Timer from '@/base/app/components/molecules/Timer.vue';

import { useUserExamAppHeaderExtension } from './UserExamAppHeaderExtensionComposable';

export default defineComponent({
  name: 'TrainingUserExamAppHeaderExtension',
  components: { BaseButton, Timer, ProblemNavigator },
  props: {
    height: { type: String, default: undefined },
  },
  emits: ['error'],
  setup(_, { emit }: SetupContext) {
    return useUserExamAppHeaderExtension(emit);
  },
});
