






















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { LocalDate, LocalDateTime } from '@/base/types';

import BaseText from './BaseText.vue';
import { PropsBaseTextDate, useBaseTextDate } from './BaseTextDateComposable';

type Props = PropsBaseTextDate;

export default defineComponent({
  name: 'BaseBaseTextDate',
  components: { BaseText },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<LocalDate | LocalDateTime>, default: undefined },
    required: { type: Boolean, defalt: false },
  },
  emits: ['change', 'click-prepend', 'click-prepend-inner', 'click-append-outer'],
  setup(props: Props, { emit }: SetupContext) {
    return useBaseTextDate(props, emit);
  },
});
