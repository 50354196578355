import { computed, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import {
  TextEditorClickAnchorPayload,
  TextEditorClickUserPayload,
  TextEditorValue,
} from '@/base/app/components/molecules/TextEditorComposable';
import {
  MentionMarkdownClickAnchorPayload,
  MentionMarkdownClickMentionPayload,
} from '@/base/app/components/organisms/MentionMarkdownComposable';
import { assertIsDefined } from '@/utils/Asserts';

type QuestionCommentChangeForm = {
  body: TextEditorValue;
};

export type QuestionCommentChangeClickAnchorPayload =
  | TextEditorClickAnchorPayload
  | MentionMarkdownClickAnchorPayload;

export type QuestionCommentChangeClickUserPayload =
  | TextEditorClickUserPayload
  | MentionMarkdownClickMentionPayload;

export type QuestionCommentChangeSubmitPayload = QuestionCommentChangeForm & {
  id: string;
  done: () => void;
};

export type PropsQuestionCommentChange = {
  id: string;
  body: string;
  createdBy: UserAvatar;
  editedBy?: UserAvatar;
};

export function useQuestionCommentChange(
  props: PropsQuestionCommentChange,
  emit: (
    name: string,
    args:
      | QuestionCommentChangeSubmitPayload
      | QuestionCommentChangeClickAnchorPayload
      | QuestionCommentChangeClickUserPayload
  ) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<QuestionCommentChangeForm>({ body: '' });
  const loading = ref(false);

  function init() {
    loading.value = false;
    input.value = { body: props.body };
    if (observer.value) observer.value.reset();
  }
  watch(() => props.body, init);

  const inputHeight = ref<number>();
  const editorHeight = computed(() =>
    inputHeight.value
      ? {
          height: `max(${inputHeight.value}px, 5em)`,
          'max-height': `max(${inputHeight.value}px, 5em)`,
        }
      : { height: 'min(200px, 50vh)', 'max-height': 'min(500px, 50vh)' }
  );
  function calcHeight() {
    const e = document.getElementById(props.id);
    if (!e) return undefined;
    const [el] = e.getElementsByClassName('question-comment-markdown');
    if (!el) return undefined;
    const { height } = el.getBoundingClientRect();
    return height;
  }

  const editing = ref(false);
  function toggleEditing() {
    if (!editing.value) {
      init();
      inputHeight.value = calcHeight();
    }
    editing.value = !editing.value;
  }

  async function submit() {
    assertIsDefined(observer.value);
    const valid = await observer.value.validate();
    if (!valid) return;
    if (props.body === input.value.body) {
      editing.value = false;
      return;
    }
    loading.value = true;
    emit('submit', {
      ...input.value,
      id: props.id,
      done: () => {
        editing.value = false;
      },
    });
  }

  function clickAnchor(payload: TextEditorClickAnchorPayload | MentionMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function clickUser(payload: TextEditorClickUserPayload | MentionMarkdownClickMentionPayload) {
    emit('click-user', payload);
  }

  const msgs = useMessages({ prefix: 'training.molecules.questionCommentChange' });
  const createdByName = computed(
    () => props.createdBy.name || msgs.of('unknown', { id: props.createdBy.id }).value
  );
  const editedByName = computed(() =>
    props.editedBy
      ? props.editedBy.name || msgs.of('unknown', { id: props.editedBy.id }).value
      : undefined
  );
  return {
    observer,
    input,
    loading,
    editing,
    editorHeight,
    createdByName,
    editedByName,
    labelComment: msgs.of('comment'),
    labelCancel: msgs.of('cancelEdit'),
    labelEdit: msgs.of('startEdit'),
    labelUpdate: msgs.of('update'),
    toggleEditing,
    submit,
    clickAnchor,
    clickUser,
  };
}
