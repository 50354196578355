import { onMounted, ref, watch } from '@vue/composition-api';
import mi from 'markdown-it';
import Token from 'markdown-it/lib/token';
import miAnchor from 'markdown-it-anchor';
import miEmoji from 'markdown-it-emoji';
import miRuby from 'markdown-it-ruby';

import { useMessages } from '@/base/app';
import { BASE_MARKDOWN_OPTION } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { Optional } from '@/base/types';

import { GetContentContent } from '../../../usecases';

export type ContentHeadingListHeadingId = Optional<string>;

type Heading = {
  id: ContentHeadingListHeadingId;
  level: number;
  html: string;
};

export type ContentHeadingListContent = GetContentContent;

export type PropsContentHeadingList = {
  headingId: ContentHeadingListHeadingId;
  content: ContentHeadingListContent;
  limit: number;
  link: boolean;
};

export function useContentHeadingList(
  props: PropsContentHeadingList,
  emit: (name: string, args: ContentHeadingListHeadingId) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.contentHeadingList' });

  const md = mi({ ...BASE_MARKDOWN_OPTION })
    .use(miAnchor)
    .use(miEmoji)
    .use(miRuby);

  function createList(text: string) {
    return md
      .parse(text, {})
      .reduce((p, c) => {
        if (c.type === 'heading_open') {
          p.push([c]);
        } else if (c.type === 'heading_close') {
          const [last] = p.slice(-1);
          if (last) last.push(c);
        } else if (c.type === 'inline') {
          const [last] = p.slice(-1);
          if (last && !last.some((item) => item.type === 'heading_close')) {
            last.push(c);
          }
        }
        return p;
      }, [] as Token[][])
      .map((ts) => {
        const o = ts.find((item) => item.type === 'heading_open');
        if (!o) return undefined;
        return {
          id: o.attrGet('id'),
          level: parseInt(o.tag.slice(-1), 10),
          html: md.renderInline(
            ts
              .filter((item) => item.type === 'inline')
              .map((item) => item.content)
              .join(' ')
          ),
        };
      })
      .filter((h) => !!h && h.level <= props.limit) as Heading[];
  }

  const activeId = ref<ContentHeadingListHeadingId>();
  const headings = ref<Heading[]>();

  function init() {
    activeId.value = props.headingId;
    if (props.content.type !== 'text') return;
    const wb =
      props.content.workbook && props.content.workbook.problems.length > 0
        ? `# ${msgs.of('toWorkbook').value}\n`
        : '';
    headings.value = createList(`${props.content.body.body}\n${wb}`);
  }
  onMounted(init);
  watch(() => props.content, init);
  watch(
    () => props.headingId,
    () => {
      activeId.value = props.headingId;
    }
  );

  function change(id: ContentHeadingListHeadingId) {
    emit('change', id);
    activeId.value = id;
  }

  function clickLink(id: ContentHeadingListHeadingId) {
    emit('click', id);
  }

  return {
    activeId,
    headings,
    noData: msgs.of('noData'),
    change,
    clickLink,
  };
}
