import { computed, nextTick, onMounted, ref, watch } from '@vue/composition-api';
import moment from 'moment';

import { LocalDate, LocalDateTime, Optional } from '@/base/types';

const FORMAT_DATE = 'YYYY/MM/DD';
const FORMAT_PICKER = 'YYYY-MM-DD';

export type BaseTextDateChangePayload = Optional<LocalDateTime>;

export type PropsBaseTextDate = {
  value?: LocalDate | LocalDateTime;
  required: boolean;
};

export function useBaseTextDate(
  props: PropsBaseTextDate,
  emit: (name: string, arg: BaseTextDateChangePayload) => void
) {
  const input = ref<string>();
  function init() {
    if (props.value?.isValid()) input.value = props.value?.format(FORMAT_DATE);
  }
  onMounted(init);
  watch(() => props.value, init);

  function change(s?: string) {
    input.value = undefined;
    if (!s) {
      emit('change', undefined);
      return;
    }
    const m = moment(s, FORMAT_DATE);
    nextTick(() => {
      if (m.isValid()) input.value = m.format(FORMAT_DATE);
      else input.value = s;
      emit('change', m);
    });
  }

  const menu = ref(false);
  function toggleMenu() {
    menu.value = !menu.value;
  }

  const picker = computed(() => {
    if (!props.value || !props.value.isValid()) return undefined;
    return props.value.format(FORMAT_PICKER);
  });
  function formatPickerDay(s: string) {
    return moment(s, FORMAT_PICKER).format('D');
  }

  return { input, change, menu, toggleMenu, picker, formatPickerDay };
}
