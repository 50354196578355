import { computed } from '@vue/composition-api';

import { findMentions } from '@/base/app/components/atoms/BaseMarkdownMention';
import { createUserAvatar, UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { UserSelectItem } from '@/base/app/components/molecules/UserSelectComposable';
import { useGlobalStore } from '@/base/app/store';
import { Comment as BaseComment, Question } from '@/base/domains';

import {
  useAddCommentToQuestion,
  useChangeCommentInQuestion,
  useChangeQuestionAssignees,
  useChangeQuestionTitle,
  useResolveQuestion,
} from '../../../usecases';
import {
  QuestionCommentAddClickAnchorPayload,
  QuestionCommentAddClickUserPayload,
  QuestionCommentAddSubmitPayload,
} from '../molecules/QuestionCommentAddComposable';
import {
  QuestionCommentChangeClickAnchorPayload,
  QuestionCommentChangeClickUserPayload,
  QuestionCommentChangeSubmitPayload,
} from '../molecules/QuestionCommentChangeComposable';
import {
  QuestionHeaderChangeAssigneesPayload,
  QuestionHeaderChangeTitlePayload,
  QuestionHeaderMovePayload,
} from '../molecules/QuestionHeaderComposable';

type Comment = BaseComment & {
  createdByUser: UserAvatar;
  isMine: boolean;
};

export type ContentQuestionSavedPayload = { id: string };

export type ContentQuestionMovePayload = QuestionHeaderMovePayload & { from: string };

export type ContentQuestionClickAnchorPayload =
  | QuestionCommentAddClickAnchorPayload
  | QuestionCommentChangeClickAnchorPayload;

export type ContentQuestionClickUserPayload = {
  userId: string;
  groupId: string;
  event: Event;
};

export type PropsContentQuestion = {
  question: Question;
  disabled: boolean;
};

export function useContentQuestion(
  props: PropsContentQuestion,
  emit: (
    name: string,
    args:
      | ContentQuestionSavedPayload
      | ContentQuestionMovePayload
      | ContentQuestionClickAnchorPayload
      | ContentQuestionClickUserPayload
  ) => void
) {
  const { group, user, groupRole, findUser } = useGlobalStore();
  const users = computed<UserSelectItem[]>(() => group.value?.users ?? []);
  const createdByUser = computed(() => createUserAvatar(props.question.createdBy, findUser));
  const resolvedByUser = computed(() =>
    props.question.resolvedBy ? createUserAvatar(props.question.resolvedBy, findUser) : undefined
  );

  const comments = computed<Comment[]>(() =>
    props.question.comments.map((c) => ({
      ...c,
      createdByUser: createUserAvatar(c.createdBy, findUser),
      editedByUser: c.editedBy ? createUserAvatar(c.editedBy, findUser) : undefined,
      isMine: user.value?.id === c.createdBy,
    }))
  );
  const lastComment = computed(() =>
    comments.value.length > 1 ? comments.value[comments.value.length - 1] : undefined
  );
  const priorityMentions = computed(() => {
    const ids = comments.value
      .map((c) => {
        const mentions = findMentions(c.body, findUser)
          .map((item) => item.user)
          .sort((a, b) => {
            if (a.name === b.name) return a.id < b.id ? -1 : 1;
            return a.name < b.name ? -1 : 1;
          })
          .map((u) => u.id);
        mentions.splice(0, 0, c.createdBy);
        if (c.editedBy) mentions.splice(0, 0, c.editedBy);
        return mentions;
      })
      .reverse()
      .reduce((p, c) => p.concat(c), []);
    return ids.filter((id, i, arr) => user.value?.id !== id && arr.indexOf(id) === i);
  });

  const addComment = useAddCommentToQuestion();
  const resolve = useResolveQuestion();
  async function add(payload: QuestionCommentAddSubmitPayload) {
    const { id } = props.question;
    if (payload.body) await addComment.execute({ id, comment: { body: payload.body } });
    if (payload.resolve) await resolve.execute({ id });
    payload.done();
    emit('saved', { id });
  }

  const updateComment = useChangeCommentInQuestion();
  async function change(payload: QuestionCommentChangeSubmitPayload) {
    await updateComment.execute({ id: payload.id, body: payload.body ?? '' });
    payload.done();
    emit('saved', { id: props.question.id });
  }

  const changeQuestionAssignees = useChangeQuestionAssignees();
  async function changeAssignees(payload: QuestionHeaderChangeAssigneesPayload) {
    const { id } = props.question;
    await changeQuestionAssignees.execute({ id, assignees: payload.assignees });
    payload.done();
    emit('saved', { id });
  }

  const changeQuestionTitle = useChangeQuestionTitle();
  async function changeTitle(payload: QuestionHeaderChangeTitlePayload) {
    const { id } = props.question;
    await changeQuestionTitle.execute({ id, title: payload.title });
    payload.done();
    emit('saved', { id });
  }

  function move(payload: QuestionHeaderMovePayload) {
    emit('move', { ...payload, from: props.question.id });
  }

  function clickAnchor(
    payload: QuestionCommentAddClickAnchorPayload | QuestionCommentChangeClickAnchorPayload
  ) {
    emit('click-anchor', payload);
  }

  function clickUser(
    payload: QuestionCommentAddClickUserPayload | QuestionCommentChangeClickUserPayload
  ) {
    emit('click-user', { ...payload, groupId: props.question.groupId });
  }

  const my = computed(() =>
    user.value?.id ? createUserAvatar(user.value.id, findUser) : undefined
  );

  const canChangeAndSolve = computed(
    () =>
      props.question.createdBy === user.value?.id ||
      groupRole.value === 'trainer' ||
      groupRole.value === 'mentor'
  );

  return {
    createdByUser,
    resolvedByUser,
    comments,
    lastComment,
    priorityMentions,
    my,
    group,
    users,
    canChangeAndSolve,
    findUser,
    add,
    change,
    changeTitle,
    changeAssignees,
    move,
    clickAnchor,
    clickUser,
  };
}
