import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import {
  BaseTable,
  BaseTableActionPayload,
} from '@/base/app/components/molecules/BaseTableComposable';
import { useMessages } from '@/base/app/Messages';
import { UserExam } from '@/base/domains';
import { isDefined } from '@/utils/TsUtils';

const HEADERS: DataTableHeader[] = [
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'startedAt', text: '', class: 'base-table-datetime' },
  { value: 'finishedAt', text: '', class: 'base-table-datetime' },
  { value: 'statusName', text: '', width: 180 },
  { value: 'scorePer', text: '', align: 'center', width: 150 },
  { value: 'passFail', text: '', align: 'center', width: 150 },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type UserExamTableItem = UserExam & {
  name?: string;
  score?: number;
  scorePer?: number;
  passingStandard?: number;
  passingStandardPer?: number;
  isPassed?: boolean;
};

export type UserExamTableActionPayload = {
  event: string;
  selected: UserExamTableItem[];
};

export type PropsUserExamTable = {
  userExams: UserExamTableItem[];
  headerKeys: string[];
};

export function useUserExamTable(
  props: PropsUserExamTable,
  emit: (name: string, args: UserExamTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.userExamTable' });

  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );
  const items = computed(() =>
    props.userExams.map((u) => ({
      ...u,
      startedAt: u.startedAt?.format('YYYY/MM/DD HH:mm'),
      finishedAt: u.finishedAt?.format('YYYY/MM/DD HH:mm'),
      statusName: u.status ? msgs.of(u.status).value : '',
      passFail: isDefined(u.isPassed)
        ? msgs.of(u.isPassed ? 'passed' : 'notPassed').value
        : undefined,
    }))
  );

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.userExams.find((u) => u.id === id))
      .filter((u) => u) as UserExamTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type UserExamTable = ReturnType<typeof useUserExamTable>;
