import { computed } from '@vue/composition-api';

import { getMarkerBackground } from '@/base/app/utils/ColorUtils';

export type PropsMarkerControlCaption = {
  color: string;
  disabled: boolean;
};

export function useMarkerControlCaption(
  props: PropsMarkerControlCaption,
  emit: (name: string) => void
) {
  const background = computed(() => getMarkerBackground(props.color));
  function click() {
    if (props.disabled) return;
    emit('click');
  }
  return { background, click };
}
