






















































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ProblemNavigator from '@/base/app/components/atoms/ProblemNavigator.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ProblemResultsView from '@/base/app/components/molecules/ProblemResultsView.vue';
import ProblemView from '@/base/app/components/molecules/ProblemView.vue';
import { ProblemUtilsNavigator, ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

import {
  PropsCourseReviewProblems,
  useCourseReviewProblems,
} from './CourseReviewProblemsComposable';

type Props = PropsCourseReviewProblems;

export default defineComponent({
  name: 'TrainingCourseReviewProblems',
  components: { BaseButton, ProblemNavigator, ProblemView, ProblemResultsView, ContentFooter },
  props: {
    index: { type: [Number, String], default: 0 },
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
    courseName: { type: String, required: true },
    contentName: { type: String, required: true },
    problems: { type: Array as PropType<ProblemUtilsProblem[]>, default: () => [] },
    problemNavigator: { type: Object as PropType<ProblemUtilsNavigator>, default: undefined },
    showProminent: { type: Boolean, default: false },
    shrinkLimit: { type: Number, default: 16 },
    isMobile: { type: Boolean, default: false },
  },
  emits: [
    'change',
    'choice-value',
    'check-value',
    'clear-value',
    'complete',
    'back',
    'click-anchor',
  ],
  setup(props: Props, { emit }: SetupContext) {
    return useCourseReviewProblems(props, emit);
  },
});
