import { nextTick, onMounted, ref } from '@vue/composition-api';

import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { delayScroll } from '@/base/app/utils/DomUtils';
import { ProblemUtilsNavigatorValue } from '@/base/app/utils/ProblemUtils';
import { config } from '@/config';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRouter } from '@/utils/VueUtils';

import { UserExamStoreKey } from '../../stores';
import {
  UserExamProblemsChoicePayload,
  UserExamProblemsClickAnchorPayload,
  UserExamProblemsToggleFlagPayload,
} from '../molecules/UserExamProblemsComposable';
import { UserExamResultsClickAnchorPayload } from '../molecules/UserExamResultsComposable';

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(
    payload: UserExamProblemsClickAnchorPayload | UserExamResultsClickAnchorPayload
  ) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

export type PropsUserExamContainer = {
  examId: string;
  groupId: string;
};

export function useUserExamContainer(props: PropsUserExamContainer) {
  const router = useRouter();
  const store = requiredInject(UserExamStoreKey);
  const { errorDialog, error } = useErrorDialog();

  async function init() {
    const ret = await store.fetch(props.examId);
    if (!ret) return;
    store.allocate();
  }
  onMounted(init);

  async function start() {
    const ret = await store.start();
    if (ret !== true) {
      error(ret);
      return;
    }
    store.allocate();
  }

  function reset() {
    store.reset();
    store.allocate();
  }

  function complete() {
    const delay = `${config().app.transitionDelay * 1000}`;
    router.push({ name: 'redirect', query: { d: delay } });
  }

  function changeProblemIndex(v: ProblemUtilsNavigatorValue) {
    store.problemController.move(v);
    nextTick(() => delayScroll('top'));
  }

  function choiceProblemValue(payload: UserExamProblemsChoicePayload) {
    store.problemController.choice(payload.index, payload.values);
  }

  function toggleProblemFlag(payload: UserExamProblemsToggleFlagPayload) {
    store.problemController.toggleFlag(payload.index);
  }

  return {
    errorDialog,
    loading: store.loading,
    scoring: store.scoring,
    page: store.page,
    exam: store.exam,
    existsOtherActive: store.existsOtherActive,
    problemIndex: store.problemIndex,
    problems: store.problems,
    problemNavigator: store.problemNavigator,
    results: store.results,
    status: store.status,
    start,
    reset,
    complete,
    changeProblemIndex,
    choiceProblemValue,
    toggleProblemFlag,
    ...useAnchorDialog(),
  };
}
