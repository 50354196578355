


































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseText from '@/base/app/components/atoms/BaseText.vue';

import { PropsQuestionTitle, useQuestionTitle } from './QuestionTitleComposable';

type Props = PropsQuestionTitle;

export default defineComponent({
  name: 'TrainingQuestionTitle',
  components: { BaseButton, BaseText },
  props: {
    title: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useQuestionTitle(props, emit);
  },
});
