import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';

import { UserExamStoreExam } from '../../stores';

export type PropsUserExamFinished = {
  exam: UserExamStoreExam;
};

export function useUserExamFinished(props: PropsUserExamFinished) {
  const msgs = useMessages({ prefix: 'training.molecules.userExamFinished' });

  const problemsCount = computed(
    () => msgs.of('problems', { size: props.exam.problemCount }).value
  );
  const descriptionTimeLimit = computed(() => {
    if (props.exam.timeLimit)
      return msgs.of('timeLimit', { timeLimit: props.exam.timeLimit }).value;
    return msgs.of('noTimeLimit').value;
  });
  const message = computed(
    () => msgs.of('description', { date: props.exam.finishedAt?.format('lll') ?? '-' }).value
  );

  return {
    problemsCount,
    descriptionTimeLimit,
    message,
  };
}
