






















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';
import ColorSelect from '@/base/app/components/atoms/ColorSelect.vue';

import {
  PropsScheduleTagForm,
  ScheduleTagFormValue,
  useScheduleTagForm,
} from './ScheduleTagFormComposable';

type Props = PropsScheduleTagForm;

export default defineComponent({
  name: 'TrainingScheduleTagForm',
  components: { BaseText, ColorSelect },
  model: { prop: 'value', event: 'submit' },
  props: { value: { type: Object as PropType<ScheduleTagFormValue>, required: true } },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useScheduleTagForm(props, emit);
  },
});
