import { GetContentContent, GetContentCourse, GetContentCourseContent } from '../../../usecases';
import { ContentHeadingListHeadingId } from './ContentHeadingListComposable';

export type CourseContentListHeadingId = ContentHeadingListHeadingId;

export type CourseContentListMovePayload = {
  groupId: string;
  courseId: string;
  contentId: string;
};

export type PropsCourseContentList = {
  groupId: string;
  course: GetContentCourse;
  content: GetContentContent;
  headingId?: string;
};

export function useCourseContentList(
  props: PropsCourseContentList,
  emit: (
    name: string,
    args: void | CourseContentListHeadingId | CourseContentListMovePayload
  ) => void
) {
  function change(id: ContentHeadingListHeadingId) {
    emit('change-heading', id);
  }

  function move(courseContent: GetContentCourseContent) {
    if (props.content.id === courseContent.id) return;
    emit('move', {
      groupId: props.groupId,
      courseId: props.course.id,
      contentId: courseContent.id,
    });
  }

  return {
    change,
    move,
  };
}
