




























































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseText from '@/base/app/components/atoms/BaseText.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import { useCourseAddDialog } from './CourseAddDialogComposable';

export default defineComponent({
  name: 'TrainingCourseAddDialog',
  components: { BaseDialogConfirm, BaseButton, BaseText, ErrorMessages },
  inheritAttrs: false,
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useCourseAddDialog(emit);
  },
});
