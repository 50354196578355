















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { MarkerRect } from '@/base/app/utils/MarkerUtils';

import { PropsMarkerRects, useMarkerRects } from './MarkerRectsComposable';

type Props = PropsMarkerRects;

export default defineComponent({
  name: 'TrainingMarkerRects',
  props: {
    id: { type: String, required: true },
    markerRects: { type: Array as PropType<MarkerRect[]>, default: () => [] },
    markerColor: { type: String, default: 'success' },
    relativeClassName: { type: String, default: '.v-window' },
    slotProps: { type: Object, default: () => ({}) },
    nudgeLeft: { type: Number, default: 0 },
    nudgeTop: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
  },
  emits: ['show', 'hide'],
  setup(props: Props, { emit }: SetupContext) {
    return useMarkerRects(props, emit);
  },
});
