


















import { defineComponent } from '@vue/composition-api';

import ContentQuestionSearchFields from '../molecules/ContentQuestionSearchFields.vue';
import { useContentAppHeaderLabel } from './ContentAppHeaderLabelComposable';

export default defineComponent({
  name: 'TrainingContentAppHeaderLabel',
  components: { ContentQuestionSearchFields },
  props: {
    topHeight: { type: Number, default: 64 },
  },
  setup() {
    return useContentAppHeaderLabel();
  },
});
