import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';

import { QuestionCondition, QuestionFilterTargetType } from '../../utils/FilterUtils';

const DEFAULT_OPTIONS: { key: QuestionFilterTargetType; group?: string }[] = [
  { key: 'title' },
  { key: 'createdBy' },
  { key: 'assignee' },
  { key: 'content' },
  { key: 'courseName' },
  { key: 'contentName' },
  { key: 'unsolved', group: 'status' },
  { key: 'solved', group: 'status' },
  { key: 'own', group: 'other' },
  { key: 'assigned', group: 'other' },
];

export type QuestionSearchFields = QuestionCondition & { sort: string };

export type PropsQuestionSearchFields = {
  value: QuestionSearchFields;
};

export function useQuestionSearchFields(
  props: PropsQuestionSearchFields,
  emit: (event: string, condition: void | QuestionSearchFields) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.questionSearchFields' });

  const menu = ref(false);

  const options = computed(() =>
    DEFAULT_OPTIONS.filter((item) => !item.group).map((item) => ({
      key: item.key,
      value: props.value.target.includes(item.key),
      label: msgs.of(item.key).value,
    }))
  );

  const statusOptions = computed(() =>
    DEFAULT_OPTIONS.filter((item) => item.group === 'status').map((item) => ({
      key: item.key,
      value: props.value.target.includes(item.key),
      label: msgs.of(item.key).value,
    }))
  );

  const otherOptions = computed(() =>
    DEFAULT_OPTIONS.filter((item) => item.group === 'other').map((item) => ({
      key: item.key,
      value: props.value.target.includes(item.key),
      label: msgs.of(item.key).value,
    }))
  );

  function toggleMenu() {
    menu.value = !menu.value;
  }

  function changeText(value: string) {
    emit('change', { ...props.value, text: value });
  }

  function changeSort(value: string) {
    emit('change', { ...props.value, sort: value });
  }

  function changeValue(name: QuestionFilterTargetType, value: boolean) {
    const target = props.value.target.filter((key) => key !== name);
    if (value) target.push(name);
    emit('change', { ...props.value, target });
  }

  function refresh() {
    emit('refresh');
  }

  return {
    menu,
    options,
    statusOptions,
    otherOptions,
    sorts: msgs.listOf('sorts'),
    placeholderSearch: msgs.of('search'),
    placeholderSort: msgs.of('sort'),
    labelClose: msgs.of('close'),
    labelSearchSettings: msgs.of('searchSettings'),
    labelSearchTarget: msgs.of('searchTarget'),
    labelStatus: msgs.of('status'),
    toggleMenu,
    changeText,
    changeSort,
    changeValue,
    refresh,
  };
}
