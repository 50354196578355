import { nextTick, onMounted, Ref, ref, watch } from '@vue/composition-api';

import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { Timer } from '@/base/app/components/molecules/TimerComposable';
import { delayScroll } from '@/base/app/utils/DomUtils';
import { ProblemUtilsNavigatorValue } from '@/base/app/utils/ProblemUtils';
import { LocalDateTime, Optional } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject } from '@/utils/VueUtils';

import { UserExamStoreExam, UserExamStoreKey, UserExamStoreNow } from '../../stores';

function useTimer(
  exam: Readonly<Ref<Optional<UserExamStoreExam>>>,
  now: Readonly<Ref<Optional<UserExamStoreNow>>>
) {
  const timer = ref<Timer>();
  const started = ref(false);
  function stop() {
    assertIsDefined(timer.value);
    timer.value.stop();
    started.value = false;
  }
  function start(startedAt: LocalDateTime) {
    assertIsDefined(timer.value);
    if (!exam.value) return;
    const { timeLimit } = exam.value;
    const base = now.value;
    timer.value.start({ startedAt, timeLimit, base });
    started.value = true;
  }
  return { timer, started, stop, start };
}

export function useUserExamAppHeaderExtension(emit: (name: string, arg: ErrorMessage[]) => void) {
  const {
    status,
    exam,
    now,
    loading,
    scoring,
    problemIndex,
    problemController,
    problemNavigator,
    score,
    allocate,
  } = requiredInject(UserExamStoreKey);
  const { timer, started, stop, start } = useTimer(exam, now);

  function checkTimer() {
    if (status.value !== 'in_progress') {
      if (started.value) stop();
      return;
    }
    if (!exam.value?.startedAt || started.value) return;
    start(exam.value.startedAt);
  }
  onMounted(checkTimer);
  watch(status, checkTimer);

  async function finish() {
    const ret = await score();
    if (ret !== true) {
      emit('emit', ret);
      return;
    }
    allocate();
  }

  function timeOver() {
    finish();
  }

  function changeIndex(v: ProblemUtilsNavigatorValue) {
    problemController.move(v);
    nextTick(() => delayScroll('top'));
  }

  function prev() {
    if (!problemNavigator.value || problemNavigator.value.prev.value === undefined) return;
    changeIndex(problemNavigator.value.prev.value);
  }

  function next() {
    if (!problemNavigator.value || problemNavigator.value.next.value === undefined) return;
    changeIndex(problemNavigator.value.next.value);
  }

  return {
    timer,
    started,
    loading,
    scoring,
    problemIndex,
    problemNavigator,
    timeOver,
    changeIndex,
    prev,
    next,
  };
}
