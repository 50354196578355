











































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseText from '@/base/app/components/atoms/BaseText.vue';
import TextEditor from '@/base/app/components/molecules/TextEditor.vue';
import UserSelect from '@/base/app/components/molecules/UserSelect.vue';
import { GlobalStoreGroup, GlobalStoreUser } from '@/base/app/store/types';
import { Optional } from '@/base/types';

import {
  ContentQuestionFormValue,
  PropsContentQuestionForm,
  useContentQuestionForm,
} from './ContentQuestionFormComposable';

type Props = PropsContentQuestionForm;

export default defineComponent({
  name: 'TrainingContentQuestionForm',
  components: { BaseText, TextEditor, UserSelect },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<ContentQuestionFormValue>, required: true },
    group: { type: Object as PropType<GlobalStoreGroup>, default: undefined },
    findUser: {
      type: Function as PropType<(id: string) => Optional<GlobalStoreUser>>,
      default: undefined,
    },
    bodyHeightOffset: { type: Number, default: 42 },
    height: { type: String, default: '400px' },
    width: { type: String, default: '400px' },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentQuestionForm(props, emit);
  },
});
