import { nextTick, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { ScheduleTag } from '@/base/domains';

import { ScheduleTagForm, ScheduleTagFormValue } from './ScheduleTagFormComposable';

export type ScheduleTagDialogPayload = ScheduleTagFormValue & {
  id?: string;
  done: (failed?: ErrorMessage[]) => void;
};

export function useScheduleTagDialog(emit: (name: string, arg: ScheduleTagDialogPayload) => void) {
  const dialog = ref(false);
  const loading = ref(false);
  const errors = ref<ErrorMessage[]>();

  const form = ref<ScheduleTagForm>();
  const input = ref<ScheduleTagFormValue>({ text: '', color: '' });
  const tagId = ref<string>();

  function close() {
    dialog.value = false;
    errors.value = undefined;
  }

  function open(payload: { tag?: ScheduleTag }) {
    if (payload.tag) {
      tagId.value = payload.tag.id;
      input.value = { text: payload.tag.text, color: payload.tag.color };
    } else {
      tagId.value = undefined;
      input.value = { text: '', color: '' };
    }
    dialog.value = true;
    nextTick(() => {
      if (form.value) form.value.reset();
    });
  }

  function done(failed?: ErrorMessage[]) {
    loading.value = false;
    errors.value = failed;
    if (failed) return;
    close();
  }

  function submit() {
    errors.value = undefined;
    loading.value = true;
    emit('submit', { ...input.value, id: tagId.value, done });
  }
  const msgs = useMessages({ prefix: 'training.molecules.scheduleTagDialog' });
  return {
    dialog,
    loading,
    errors,
    form,
    input,
    tagId,
    labelClose: msgs.of('close'),
    labelCreate: msgs.of('create'),
    labelUpdate: msgs.of('update'),
    close,
    open,
    submit,
  };
}

export type ScheduleTagDialog = ReturnType<typeof useScheduleTagDialog>;
