import { useMessages } from '@/base/app';

import { ContentHeadingListHeadingId } from './ContentHeadingListComposable';

export type ContentTextIndexMobileOpenTextPayload = {
  type: 'text';
  hash: string;
};

export function useContentTextIndexMobile(
  emit: (name: string, args: ContentTextIndexMobileOpenTextPayload) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.contentTextIndexMobile' });
  function openText(id: ContentHeadingListHeadingId) {
    if (!id) return;
    emit('open', { hash: id, type: 'text' });
  }
  return { labelIndex: msgs.of('index'), openText };
}
