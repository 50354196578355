







































import { computed, defineComponent, provide } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import ScrollToTopButton from '@/base/app/components/atoms/ScrollToTopButton.vue';
import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';

import Question from '../components/organisms/Question.vue';
import { QuestionStoreKey, useQuestionStore } from '../stores';

type Props = {
  id: string;
  questionId: string;
};

export default defineComponent({
  name: 'TrainingGroupQuestionPage',
  components: { MyApp, Question, RefreshButton, ScrollToTopButton },
  props: {
    id: { type: String, required: true },
    questionId: { type: String, required: true },
  },
  setup(props: Props) {
    const store = useQuestionStore();
    provide(QuestionStoreKey, store);

    function refresh() {
      store.fetch({ id: props.questionId });
    }

    const { groupRole } = useGlobalStore();
    const disabled = computed(() => !groupRole.value);

    return {
      loading: store.loading,
      notFound: store.notFound,
      title: store.title,
      disabled,
      refresh,
    };
  },
});
