import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';

import { UserExamStoreExam } from '../../stores';

export type PropsUserExamNotStarted = {
  exam: UserExamStoreExam;
  existsOtherActive: boolean;
  scheduled: boolean;
};

export function useUserExamNotStarted(
  props: PropsUserExamNotStarted,
  emit: (name: string) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.userExamNotStarted' });

  const problemsCount = computed(
    () => msgs.of('problems', { size: props.exam.problemCount ?? '-' }).value
  );
  const descriptionTimeLimit = computed(() => {
    if (props.exam.timeLimit)
      return msgs.of('timeLimit', { timeLimit: props.exam.timeLimit }).value;
    return msgs.of('noTimeLimit').value;
  });
  const scheduledDate = computed(() =>
    props.exam.scheduledStart
      ? msgs.of('scheduled', {
          start: props.exam.scheduledStart.format('lll'),
          end: props.exam.scheduledFinish?.format('lll') ?? '-',
        }).value
      : undefined
  );
  const message = computed(() => {
    if (props.scheduled) return msgs.of('notStartedExam').value;
    if (props.existsOtherActive) return msgs.of('errorActiveExam').value;
    return msgs.of('attention').value;
  });

  function start() {
    emit('start');
  }

  return {
    problemsCount,
    descriptionTimeLimit,
    scheduledDate,
    message,
    labelStart: msgs.of('start'),
    start,
  };
}
