import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';

const MARKER_ITEMS = [
  { value: 'none' },
  { value: 'success', color: 'green' },
  { value: 'info', color: 'blue' },
  { value: 'warning', color: 'yellow' },
  { value: 'error', color: 'red' },
];

type ScopeFields = {
  private: boolean;
  group: boolean;
  mine: boolean;
};

type OptionBase = {
  marker?: string;
  doNotShowOtherVersions: boolean;
};

type OptionTextHasWorkbook = OptionBase & {
  type: 'textHasWorkbook';
  textType?: string;
};

type OptionText = OptionBase & {
  type: 'text';
};

type OptionExam = OptionBase & {
  type: 'exam';
};

export type ContentMemoSearchFieldsValue = {
  scope: ScopeFields;
  option: OptionTextHasWorkbook | OptionText | OptionExam;
};

export type PropsContentMemoSearchFields = {
  value: ContentMemoSearchFieldsValue;
};

export function useContentMemoSearchFields(props: PropsContentMemoSearchFields, emit) {
  const msgs = useMessages({ prefix: 'training.molecules.contentMemoSearchFields' });

  const menu = ref(false);

  function changeScope(v: Record<string, boolean>) {
    emit('change', { ...props.value, scope: { ...props.value.scope, ...v } });
  }

  function changeOption(v: Record<string, string | boolean | undefined>) {
    emit('change', { ...props.value, option: { ...props.value.option, ...v } });
  }

  const textTypes = computed(() => {
    if (props.value.option.type !== 'textHasWorkbook') return undefined;
    return ['textBody', 'textWorkbook'].map((value) => ({ value, label: msgs.of(value).value }));
  });

  const markers = computed(() =>
    MARKER_ITEMS.map((m) => ({ value: m.value, label: msgs.of(m.color ?? m.value).value }))
  );

  return {
    menu,
    textTypes,
    markers,
    labelGroup: msgs.of('group'),
    labelAll: msgs.of('all'),
    labelCreate: msgs.of('create'),
    labelPrivate: msgs.of('private'),
    labelMore: msgs.of('filterMore'),
    labelFilterSetting: msgs.of('filterSetting'),
    labelText: msgs.of('text'),
    labelMarker: msgs.of('marker'),
    doNotShowOtherVersions: msgs.of('doNotShowOtherVersions'),
    changeScope,
    changeOption,
  };
}
