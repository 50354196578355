import { computed } from '@vue/composition-api';

import { UserAvatar, UserClickPayload } from '@/base/app/components/atoms/UserComposable';
import { LocalDateTime } from '@/base/types';
import { QuestionWithNames } from '@/base/usecases';

import { useMessages } from '../../Messages';

export type QuestionBarSelectPayload = {
  questionId: string;
  groupId: string;
  event: KeyboardEvent | MouseEvent;
};

export type QuestionBarClickUserPayload = UserClickPayload & { groupId: string };

export type PropsQuestionBar = {
  question: QuestionWithNames;
  createdBy: UserAvatar;
  resolvedBy?: UserAvatar;
  assignees: UserAvatar[];
  updatedAt: LocalDateTime;
};

export function useQuestionBar(
  props: PropsQuestionBar,
  emit: (name: string, arg: QuestionBarSelectPayload | QuestionBarClickUserPayload) => void
) {
  function select(event: KeyboardEvent | MouseEvent) {
    emit('select', { questionId: props.question.id, groupId: props.question.groupId, event });
  }

  function clickUser(payload: UserClickPayload) {
    emit('click-user', { ...payload, groupId: props.question.groupId });
  }

  const msgs = useMessages({ prefix: 'base.molecules.questionBar' });
  const userName = computed(
    () => props.createdBy.name || msgs.of('unknown', { id: props.createdBy.id }).value
  );
  const resolvedUserName = computed(() => {
    if (!props.resolvedBy) return undefined;
    return props.resolvedBy.name || msgs.of('unknown', { id: props.resolvedBy.id }).value;
  });
  const assigneeName = computed(() => {
    const [first] = props.assignees;
    if (!first) return undefined;
    return first.name || msgs.of('unknown', { id: first.id }).value;
  });
  const labelOmitAssignees = computed(
    () => msgs.of('omitAssignees', { number: props.assignees.length - 1 }).value
  );
  return {
    userName,
    resolvedUserName,
    assigneeName,
    labelOmitAssignees,
    clickUser,
    select,
  };
}
