var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.links.length > 0 || _vm.markers.length > 0)?_c('div',[_vm._l((_vm.links),function(l,i){return _c('memo-title-link',_vm._b({key:i,staticClass:"mb-1",attrs:{"nudge-left":_vm.nudgeLeft,"nudge-top":_vm.nudgeTop,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var display = ref.display;
var props = ref.props;
return [(!display || !props)?void 0:_c('content-memo-card',{attrs:{"memo":props.memo,"avatar":props.avatar,"disabled":props.disabled,"elevation":"3","rounded":"","max-height":200,"max-width":"min(400px, 100vw - 60px)","min-height":"62"},on:{"click-anchor":_vm.clickAnchor,"submit":_vm.changeMemo},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var edit = ref.edit;
return [(!edit)?_c('memo-menus',{attrs:{"memo":props.memo,"disabled-remove":props.disabled,"disabled-scope":"","min-width":"12rem"},on:{"remove":_vm.removeMemo},scopedSlots:_vm._u([{key:"items-top",fn:function(){return [_c('v-list-item',{on:{"click":function($event){return _vm.openMemoTab(props.memo)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-note-multiple-outline")])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.labelMemoTab)}})],1)]},proxy:true}],null,true)}):_vm._e()]}}],null,true)})]}}],null,true)},'memo-title-link',l,false))}),_vm._l((_vm.markers),function(m,i){return _c('marker-rects',_vm._b({key:i,attrs:{"nudge-left":_vm.nudgeLeft,"nudge-top":_vm.nudgeTop,"relative-class-name":_vm.markerRelativeClassName},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var display = ref.display;
var props = ref.props;
return [(!display || !props)?void 0:(props.type === 'memo')?_c('content-memo-card',{attrs:{"memo":props.memo,"avatar":props.avatar,"disabled":props.disabled,"elevation":"3","rounded":"","max-height":200,"max-width":"min(400px, 100vw - 60px)","min-height":"62"},on:{"click-anchor":_vm.clickAnchor,"submit":_vm.changeMemo},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var edit = ref.edit;
return [(!edit)?_c('memo-menus',{attrs:{"memo":props.memo,"disabled-remove":props.disabled,"disabled-scope":"","min-width":"12rem"},on:{"remove":_vm.removeMemo},scopedSlots:_vm._u([{key:"items-top",fn:function(){return [_c('v-list-item',{on:{"click":function($event){return _vm.openMemoTab(props.memo)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-note-multiple-outline")])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.labelMemoTab)}})],1)]},proxy:true}],null,true)}):_vm._e()]}}],null,true)}):(props.type === 'question')?_c('content-question-card',{attrs:{"question":props.question,"avatar":props.avatar,"elevation":"3","rounded":"","max-width":"min(400px, 100vw - 60px)","min-height":"70"},on:{"open":_vm.openQuestion}}):_vm._e()]}}],null,true)},'marker-rects',m,false))})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }