

























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTextDateTime from '@/base/app/components/atoms/BaseTextDateTime.vue';

import {
  DateTimeRangeFormValue,
  PropsDateTimeRangeForm,
  useDateTimeRangeForm,
} from './DateTimeRangeFormComposable';

type Props = PropsDateTimeRangeForm;

export default defineComponent({
  name: 'TrainingDateTimeRangeForm',
  components: { BaseTextDateTime },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<DateTimeRangeFormValue>, required: true },
    labelFrom: { type: String, default: undefined },
    labelTo: { type: String, default: undefined },
  },
  emits: ['submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useDateTimeRangeForm(props, emit);
  },
});
