import { computed, ref } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import {
  BaseTable,
  BaseTableActionPayload,
} from '@/base/app/components/molecules/BaseTableComposable';
import { useMessages } from '@/base/app/Messages';

const HEADERS: DataTableHeader[] = [
  { value: 'preview', text: '', class: 'base-table-name' },
  { value: 'hasNewContentVersion', text: '', class: 'base-table-name' },
  { value: 'confirmedBy', text: '', class: 'base-table-name' },
  { value: 'confirmedAt', text: '', class: 'base-table-datetime' },
  { value: 'contentLastUpdatedBy', text: '', class: 'base-table-name' },
  { value: 'contentLastUpdatedAt', text: '', class: 'base-table-datetime' },
];

function useTable() {
  const table = ref<BaseTable>();
  function clear() {
    if (table.value) table.value.clearSelection();
  }
  return { table, clear };
}

export type GroupCourseTableItem =
  | {
      id: string;
      name: string;
      originalName: string;
      hasNewContentVersion: boolean;
      index?: number;
    }
  | {
      id: string;
      name: string;
      confirmedBy: string;
      confirmedAt: string;
      contentLastUpdatedBy: string;
      contentLastUpdatedAt?: string;
      index?: number;
    };

export type PropsGroupCourseTable = {
  courses: GroupCourseTableItem[];
  headerKeys: string[];
};

export type GroupCourseTableActionPayload = {
  event: string;
  selected: GroupCourseTableItem[];
};

export function useGroupCourseTable(
  props: PropsGroupCourseTable,
  emit: (name: string, args: GroupCourseTableActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.groupCourseTable' });
  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );

  const items = computed(() => {
    const available = msgs.of('latestVersionAvailable').value;
    return props.courses.map((item) => ({
      ...item,
      preview: item.name,
      hasNewContentVersion:
        'hasNewContentVersion' in item && item.hasNewContentVersion ? available : undefined,
    }));
  });

  function action(payload: BaseTableActionPayload) {
    const selected = payload.selected
      .map((id) => props.courses.find((c) => c.id === id))
      .filter((c) => c) as GroupCourseTableItem[];
    emit('action', { ...payload, selected });
  }

  return { headers, items, action, ...useTable() };
}

export type GroupCourseTable = ReturnType<typeof useGroupCourseTable>;
