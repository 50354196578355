























































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseText from '@/base/app/components/atoms/BaseText.vue';

import {
  ContentQuestionCondition,
  PropsContentQuestionSearchFields,
  useContentQuestionSearchFields,
} from './ContentQuestionSearchFieldsComposable';

type Props = PropsContentQuestionSearchFields;

export default defineComponent({
  name: 'TrainingContentQuestionSearchFields',
  components: { BaseButton, BaseText },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<ContentQuestionCondition>, required: true },
    loading: { type: Boolean, default: false },
    hideClose: { type: Boolean, default: false },
  },
  emits: ['change', 'close', 'close-list', 'open-list'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentQuestionSearchFields(props, emit);
  },
});
