









import { computed, defineComponent } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';

import Courses from '../components/organisms/Courses.vue';

export default defineComponent({
  name: 'TrainingTraingerGroupCoursesPage',
  components: { MyApp, Courses },
  props: { id: { type: String, required: true } },
  setup() {
    const { groupRole } = useGlobalStore();
    const disabled = computed(() => groupRole.value !== 'trainer');
    return { disabled };
  },
});
