



























































































import { defineComponent } from '@vue/composition-api';
import { VDialog, VMenu } from 'vuetify/lib/components';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';
import UpdateStatusIcon from '@/base/app/components/molecules/UpdateStatusIcon.vue';

import ContentMarkerControl from '../molecules/ContentMarkerControl.vue';
import ContentMemoForm from '../molecules/ContentMemoForm.vue';
import { PropsContentMemoDialog, useContentMemoDialog } from './ContentMemoDialogComposable';

type Props = PropsContentMemoDialog;

export default defineComponent({
  name: 'TrainingContentMemoDialog',
  components: {
    VDialog,
    VMenu,
    BaseButton,
    BaseButtonIcon,
    BaseDialogConfirm,
    BaseDialogOk,
    ContentMarkerControl,
    ContentMemoForm,
    ErrorMessages,
    UpdateStatusIcon,
  },
  props: {
    fullscreen: { type: Boolean, default: false },
    dialogClassName: { type: String, default: 'training-content-container-dialog' },
    containerClassName: { type: String, default: 'training-content-container' },
    width: { type: Number, default: 400 },
    height: { type: [Number, String], default: 500 },
  },
  setup(props: Props) {
    return useContentMemoDialog(props);
  },
});
