import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { delayScroll, escapeId } from '@/base/app/utils/DomUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRoute } from '@/utils/VueUtils';

import { ContentStoreKey } from '../../stores';
import { ContentCreateMenuCreateMemoPayload } from '../atoms/ContentCreateMenusComposable';
import { ContentMarkerControlColor } from '../molecules/ContentMarkerControlComposable';

export type PropsContentAppToolBar = {
  questionAvailable: boolean;
  topHeight: number;
};

export function useContentAppToolBar(props: PropsContentAppToolBar) {
  const route = useRoute();
  const { moveTo: moveToCreateQuestion } = useDialogQuery(DialogName.TRAINING_CONTENT_QUESTION);
  const { moveTo: moveToSearchQuestion } = useDialogQuery(DialogName.TRAINING_CONTENT_QUESTION_SRC);
  const { moveTo: moveToCreateMemo } = useDialogQuery(DialogName.TRAINING_CONTENT_MEMO);

  const store = requiredInject(ContentStoreKey);
  const markerControl = computed(() => {
    if (store.markedItem.value) return store.markedItem.value;
    if (store.enableMarking.value) return { id: 'no-marker', type: 'unknown' };
    return undefined;
  });

  const excludeMenus = computed(() => {
    const menus = ['cancel'];
    if (!props.questionAvailable) menus.push('question');
    return menus;
  });

  const collapse = ref(true);
  function toggleCollapse() {
    collapse.value = !collapse.value;
  }
  function init() {
    if (markerControl.value) return;
    collapse.value = true;
  }
  onMounted(init);
  watch(() => route.query, init);

  function quote() {
    store.prepareMarker();
  }

  function cancel() {
    store.finishMarker();
  }

  function createMemo(payload: ContentCreateMenuCreateMemoPayload) {
    moveToCreateMemo({ type: payload.type });
  }

  function changePageAndScroll() {
    const content = store.content.value;
    assertIsDefined(content, 'content');
    if (!store.markedItem.value?.marker) return;

    let changed = false;
    const { id, marker } = store.markedItem.value;
    if ('problemIndex' in marker) {
      const next = content.type === 'text' ? 'workbook' : 'exam';
      changed = next !== store.page.value;
      store.changeProblemIndex(marker.problemIndex);
      store.changePageValue(next);
    } else {
      store.changePageValue('text');
    }
    delayScroll(escapeId(id), { delay: changed ? 300 : 50, offsetY: -8 });
  }

  function changeMarkerColor(color: ContentMarkerControlColor) {
    store.changeMarkerColor(color);
  }

  function removeMarker() {
    store.mark();
  }

  const msgs = useMessages({ prefix: 'training.organisms.contentAppToolBar' });
  return {
    markerControl,
    collapse,
    excludeMenus,
    enableMarking: store.enableMarking,
    enableMarkingPage: store.enableMarkingPage,
    labelSearch: msgs.of('search'),
    labelQuote: msgs.of('quote'),
    labelCancel: msgs.of('cancel'),
    labelMarkerHint: msgs.of('markerHint'),
    toggleCollapse,
    quote,
    cancel,
    search: moveToSearchQuestion,
    createQuestion: moveToCreateQuestion,
    createMemo,
    changePageAndScroll,
    changeMarkerColor,
    removeMarker,
  };
}
