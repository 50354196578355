import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { LocalDateTime } from '@/base/types';

export type PropsQuestionResolvedFooter = {
  resolvedBy?: UserAvatar;
  resolvedAt?: LocalDateTime;
};

export function useQuestionResolvedFooter(props: PropsQuestionResolvedFooter) {
  const msgs = useMessages({ prefix: 'training.molecules.questionResolvedFooter' });

  const description = computed(
    () =>
      msgs.of('resolved', {
        name: props.resolvedBy
          ? props.resolvedBy.name || msgs.of('unknown', { id: props.resolvedBy.id }).value
          : msgs.of('unknown', { id: 'unknown' }).value,
        datetime: props.resolvedAt?.format('lll') ?? '---',
      }).value
  );
  return { description };
}
