

























































































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import ScheduleTagSelect from '@/base/app/components/molecules/ScheduleTagSelect.vue';
import DialogAnchorConfirm from '@/base/app/components/organisms/DialogAnchorConfirm.vue';

import ScheduleMenus from '../atoms/ScheduleMenus.vue';
import ScheduleTagSelector from '../atoms/ScheduleTagSelector.vue';
import ScheduleCard from '../molecules/ScheduleCard.vue';
import ScheduleSearchFields from '../molecules/ScheduleSearchFields.vue';
import ScheduleDialog from './ScheduleDialog.vue';
import { PropsSchedules, useSchedules } from './SchedulesComposable';
import ScheduleTagsDialog from './ScheduleTagsDialog.vue';

type Props = PropsSchedules;

export default defineComponent({
  name: 'TrainingSchedules',
  components: {
    BaseButton,
    BaseButtonIcon,
    ScheduleTagSelect,
    ScheduleSearchFields,
    ScheduleCard,
    ScheduleTagSelector,
    ScheduleMenus,
    ScheduleDialog,
    ScheduleTagsDialog,
    BaseDialogConfirm,
    BaseDialogOk,
    DialogAnchorConfirm,
  },
  props: {
    id: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    disabledTags: { type: Boolean, default: false },
    disabledHistory: { type: Boolean, default: false },
    topHeight: { type: Number, default: 64 },
  },
  setup(props: Props) {
    return useSchedules(props);
  },
});
