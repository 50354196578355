









































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import DateTimeNow from '@/base/app/components/molecules/DateTimeNow.vue';
import UserSelect from '@/base/app/components/molecules/UserSelect.vue';
import { UserSelectItem } from '@/base/app/components/molecules/UserSelectComposable';
import { Comment, UserId } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { QuestionContentReferenceWithNames } from '@/base/usecases';

import { PropsQuestionHeader, useQuestionHeader } from './QuestionHeaderComposable';
import QuestionTitle from './QuestionTitle.vue';

type CommentAndUser = Comment & { createdByUser?: UserAvatar };

type Props = PropsQuestionHeader;

export default defineComponent({
  name: 'TrainingQuestionHeader',
  components: { BaseLink, DateTimeNow, UserSelect, QuestionTitle },
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    resolved: { type: Boolean, default: false },
    createdBy: { type: Object as PropType<UserAvatar>, default: undefined },
    createdAt: { type: Object as PropType<LocalDateTime>, default: undefined },
    resolvedBy: { type: Object as PropType<UserAvatar>, default: undefined },
    resolvedAt: { type: Object as PropType<LocalDateTime>, default: undefined },
    assignees: { type: Array as PropType<UserId[]>, default: () => [] },
    referTo: { type: Object as PropType<QuestionContentReferenceWithNames>, default: undefined },
    disabledReferTo: { type: Boolean, default: false },
    lastComment: { type: Object as PropType<CommentAndUser>, default: undefined },
    groupId: { type: String, required: true },
    users: { type: Array as PropType<UserSelectItem[]>, default: () => [] },
    disabled: { type: Boolean, default: false },
    canChangeAndSolve: { type: Boolean, default: false },
  },
  emits: ['move', 'change-title', 'change-assignees'],
  setup(props: Props, { emit }: SetupContext) {
    return useQuestionHeader(props, emit);
  },
});
