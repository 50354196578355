import { computed } from '@vue/composition-api';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import { ContentVersion } from '@/base/domains';

import { GroupTrainingCourseContent } from '../../../domains';

const HEADERS: DataTableHeader[] = [
  { value: 'no', text: '', width: 140, align: 'center' },
  { value: 'name', text: '', class: 'base-table-name' },
  { value: 'typeName', text: '', width: 140 },
  { value: 'requiredTime', text: '', width: 140, align: 'center' },
  { value: 'open', text: '', width: 140, align: 'center' },
  { value: 'groupExam', text: '', width: 140 },
  { value: 'version', text: '', width: 140, align: 'center' },
];

type CourseContentVersion = {
  version: ContentVersion;
  versionDescription?: string;
};

export type CourseContentTableItem = GroupTrainingCourseContent & {
  no: number;
  versions?: CourseContentVersion[];
};

export type PropsCourseContentTable = {
  contents: CourseContentTableItem[];
  headerKeys: string[];
  loading: boolean;
};

export function useCourseContentTable(props: PropsCourseContentTable) {
  const msgs = useMessages({ prefix: 'training.molecules.courseContentTable' });
  const headers = computed(() =>
    props.headerKeys
      .map((value) => HEADERS.find((h) => h.value === value))
      .filter((h) => !!h)
      .map((h) => ({ ...h, text: h ? msgs.of(h.value).value : '' }))
  );
  const items = computed(() => {
    const labelInProgress = msgs.of('groupExamInProgress').value;
    const labelScheduled = msgs.of('groupExamScheduled').value;
    return props.contents.map((c) => {
      let groupExam = '';
      if ('examStatus' in c && c.examStatus === 'in_progress') groupExam = labelInProgress;
      else if ('examStatus' in c && c.examStatus !== 'finished') groupExam = labelScheduled;
      return {
        ...c,
        typeName: msgs.of(c.type as string).value,
        groupExam,
      };
    });
  });
  return { headers, items };
}

export type CourseContentTable = ReturnType<typeof useCourseContentTable>;
