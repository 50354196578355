import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { requiredInject } from '@/utils/VueUtils';

import { UserExamStoreKey } from '../../stores';

export function useUserExamAppHeaderProminent() {
  const msgs = useMessages({ prefix: 'training.organisms.userExamAppHeaderProminent' });
  const { exam, page } = requiredInject(UserExamStoreKey);
  const times = computed(() => msgs.of('times', { times: exam.value?.times ?? '-' }).value);
  const inProgress = computed(() => page.value === 'in_progress');
  return { exam, times, inProgress };
}
