import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { useColor } from '@/base/app/utils/ColorUtils';

export type ScheduleTagFormValue = {
  text: string;
  color: string;
};

export type PropsScheduleTagForm = {
  value: ScheduleTagFormValue;
};

export function useScheduleTagForm(
  props: PropsScheduleTagForm,
  emit: (name: string, arg: ScheduleTagFormValue) => void
) {
  const { colors } = useColor();

  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<ScheduleTagFormValue>({ text: '', color: '' });

  function change(payload: object) {
    Object.assign(input.value, payload);
  }
  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    emit('submit', { ...input.value });
  }

  function init() {
    input.value = { ...props.value };
    const [first] = colors.value;
    if (!input.value.color && first) input.value.color = first.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  function reset() {
    if (observer.value) observer.value.reset();
  }
  const msgs = useMessages({ prefix: 'training.molecules.scheduleTagForm' });
  return { observer, input, labelText: msgs.of('text'), change, submit, reset, colors };
}

export type ScheduleTagForm = ReturnType<typeof useScheduleTagForm>;
