











import { computed, defineComponent, PropType } from '@vue/composition-api';

import { ContentType } from '@/base/domains';

import { useMessages } from '../../Messages';
import BaseLabel from '../atoms/BaseLabel.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';

type Props = {
  contentType: ContentType;
  label: boolean;
};

export default defineComponent({
  name: 'BaseContentIconClosed',
  components: { BaseMarkdown, BaseLabel },
  inheritAttrs: false,
  props: {
    contentType: { type: String as PropType<ContentType>, required: true },
    label: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const msgs = useMessages({ prefix: 'base.molecules.contentIconClosed' });
    const description = computed(() => {
      if (props.contentType === 'exam') return msgs.of('descriptionExam').value;
      return msgs.of('description').value;
    });
    return { closed: msgs.of('closed'), description };
  },
});
