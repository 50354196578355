























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import { Memo } from '@/base/domains';

import { PropsMemoTitleLink, useMemoTitleLink } from './MemoTitleLinkComposable';

type Props = PropsMemoTitleLink;

export default defineComponent({
  name: 'TrainingMemoTitleLink',
  components: { BaseLink },
  props: {
    memo: { type: Object as PropType<Memo>, required: true },
    slotProps: { type: Object, default: () => ({}) },
    nudgeLeft: { type: Number, default: 0 },
    nudgeTop: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
  },
  emits: ['show', 'hide'],
  setup(props: Props, { emit }: SetupContext) {
    return useMemoTitleLink(props, emit);
  },
});
