























































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import DateTimeNow from '@/base/app/components/molecules/DateTimeNow.vue';
import { GetContentContent } from '@/training/usecases';

import MemoMenus from '../atoms/MemoMenus.vue';
import ContentMemoCard from './ContentMemoCard.vue';
import {
  ContentMemosMemo,
  ContentMemosSearchValue,
  PropsContentMemos,
  useContentMemos,
} from './ContentMemosComposable';
import ContentMemoSearchFields from './ContentMemoSearchFields.vue';
import ContentVersionWarning from './ContentVersionWarning.vue';

type Props = PropsContentMemos;

export default defineComponent({
  name: 'TrainingContentMemos',
  components: {
    BaseLink,
    ContentVersionWarning,
    ContentMemoSearchFields,
    ContentFooter,
    ContentMemoCard,
    DateTimeNow,
    MemoMenus,
  },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<ContentMemosSearchValue>, default: undefined },
    groupId: { type: String, required: true },
    courseName: { type: String, required: true },
    content: { type: Object as PropType<GetContentContent>, required: true },
    memos: { type: Array as PropType<ContentMemosMemo[]>, default: () => [] },
    toLatest: { type: Object as PropType<Location>, default: undefined },
    groupRole: { type: String, default: undefined },
    myId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    minHeight: { type: String, default: undefined },
  },
  emits: ['open', 'change', 'change-body', 'change-scope', 'remove'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentMemos(props, emit);
  },
});
