



























import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import Timer from '@/base/app/components/molecules/Timer.vue';

import {
  PropsUserExamAppHeaderEnd,
  useUserExamAppHeaderEnd,
} from './UserExamAppHeaderEndComposable';

type Props = PropsUserExamAppHeaderEnd;

export default defineComponent({
  name: 'TrainingUserExamAppHeaderEnd',
  components: { BaseButton, BaseButtonIcon, BaseDialogConfirm, Timer },
  props: { showTimer: { type: Boolean, default: false } },
  emits: ['error'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserExamAppHeaderEnd(props, emit);
  },
});
