import { computed } from '@vue/composition-api';

import { MyExamResult } from '@/base/domains';
import { Optional } from '@/base/types';
import { isDefined } from '@/utils/TsUtils';

import { useMessages } from '../../Messages';

function calculate(v: Optional<number>, count: number) {
  if (!isDefined(v)) return undefined;
  if (!v || !count) return 0;
  return Math.round((v / count) * 1000) / 10;
}

export type PropsUserExamResultSummary = {
  result: MyExamResult;
};

export function useUserExamResultSummary(
  props: PropsUserExamResultSummary,
  emit: (name: string) => void
) {
  const visibilityLevel = computed(() => props.result.visibilityLevel);
  const isPassed = computed(() => {
    if (props.result.version === 1 || props.result.version === 2) return undefined;
    return props.result.isPassed;
  });
  const score = computed(() => {
    if (props.result.visibilityLevel === 'invisible_to_user') return undefined;
    return calculate(props.result.score, props.result.problemCount);
  });
  const passingStandard = computed(() => {
    if (props.result.version === 1 || props.result.version === 2) return undefined;
    if (props.result.visibilityLevel === 'invisible_to_user') return undefined;
    return calculate(props.result.passingStandard, props.result.problemCount);
  });

  const msgs = useMessages({ prefix: 'base.molecules.userExamResultSummary' });
  const descriptionLevel = computed(() => {
    if (props.result.visibilityLevel === 'invisible_to_user')
      return msgs.of('descriptionInvisibleToUser').value;
    if (props.result.visibilityLevel === 'score') return msgs.of('descriptionScore').value;
    return undefined;
  });

  function click() {
    emit('click');
  }

  return {
    visibilityLevel,
    isPassed,
    score,
    passingStandard,
    descriptionLevel,
    labelHome: msgs.of('home'),
    labelResult: msgs.of('result'),
    labelPassed: msgs.of('passed'),
    labelNotPassed: msgs.of('notPassed'),
    click,
  };
}
