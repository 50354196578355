import { onMounted, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupRole, Memo } from '@/base/domains';

export type MemoMenusChangeScopePayload = {
  id: string;
  scope: {
    type: 'group';
    roles: GroupRole[];
  };
  done: () => void;
};

export type MemoMenusRemovePayload = {
  id: string;
};

export type PropsMemoMenus = {
  memo: Memo;
  disabledScope: boolean;
};

export function useMemoMenus(
  props: PropsMemoMenus,
  emit: (name: string, args: MemoMenusChangeScopePayload | MemoMenusRemovePayload) => void
) {
  const menu = ref(false);
  const menuScope = ref(false);
  const loading = ref(false);

  const input = ref<GroupRole[]>();
  function init() {
    input.value = undefined;
    if (props.memo.scope.type === 'group' && !props.disabledScope) {
      input.value = [...props.memo.scope.roles];
    }
  }
  onMounted(init);

  function done() {
    loading.value = false;
  }

  function close() {
    menu.value = false;
    menuScope.value = false;
    if (!input.value || props.memo.scope.type === 'private') return;
    const a = JSON.stringify([...props.memo.scope.roles].sort());
    const b = JSON.stringify([...input.value].sort());
    if (a === b) return;
    loading.value = true;
    emit('change-scope', { id: props.memo.id, scope: { type: 'group', roles: input.value }, done });
  }

  watch(menu, (newVal) => {
    if (newVal) init();
    else close();
  });

  function remove() {
    emit('remove', { id: props.memo.id });
    close();
  }

  const msgs = useMessages({ prefix: 'training.atoms.memoMenus' });
  return {
    menu,
    menuScope,
    loading,
    input,
    roles: msgs.listOf('roles'),
    label: msgs.of('menu'),
    labelChangeScope: msgs.of('changeScope'),
    labelRemove: msgs.of('remove'),
    remove,
  };
}
