import { computed, nextTick, onMounted, ref, watch } from '@vue/composition-api';
import moment from 'moment';

import { LocalDateTime, Optional } from '@/base/types';

import { useMessages } from '../../Messages';

const FORMAT_TIME = 'HH:mm';

export type BaseTextTimeChangePayload = Optional<LocalDateTime>;

export type PropsBaseTextTime = {
  value?: LocalDateTime;
  start?: LocalDateTime;
  startLabel?: string;
  required: boolean;
};

export function useBaseTextTime(
  props: PropsBaseTextTime,
  emit: (name: string, arg: BaseTextTimeChangePayload) => void
) {
  const msgs = useMessages({ prefix: 'base.atoms.baseTextTime' });
  const input = ref<string>();
  function init() {
    if (props.value?.isValid()) input.value = props.value?.format(FORMAT_TIME);
  }
  onMounted(init);
  watch(() => props.value, init);

  function change(s?: string) {
    input.value = undefined;
    if (!s) {
      emit('change', undefined);
      return;
    }
    const ymd = (props.start || props.value || moment()).format('YYYY/MM/DD');
    const m = moment(`${ymd} ${s}`, 'YYYY/MM/DD HH:mm');
    nextTick(() => {
      if (m.isValid()) input.value = m.format(FORMAT_TIME);
      else input.value = s;
      emit('change', m);
    });
  }
  watch(
    () => props.start,
    () => {
      if (props.value) change(props.value.format(FORMAT_TIME));
    }
  );

  const menu = ref(false);
  function toggleMenu() {
    menu.value = !menu.value;
  }

  const picker = computed(() => {
    if (!props.value || !props.value.isValid()) return undefined;
    return props.value.format('HH:mm');
  });
  const pickerMin = computed(() => {
    if (!props.start || !props.start.isValid()) return undefined;
    return props.start.format('HH:mm');
  });
  const rules = computed(() => {
    const items = ['time'];
    if (props.required) items.splice(0, 0, 'required');
    if (pickerMin.value) {
      const params = [pickerMin.value, props.startLabel || msgs.of('defaultLabelStart').value];
      items.push(`min_time:${params.join(',')}`);
    }
    return items.join('|');
  });

  return { input, change, picker, pickerMin, rules, menu, toggleMenu };
}
