























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseSelectSearch from '../atoms/BaseSelectSearch.vue';
import ScheduleTag from '../atoms/ScheduleTag.vue';
import {
  PropsScheduleTagSelect,
  ScheduleTagSelectItem,
  ScheduleTagSelectValue,
  useScheduleTagSelect,
} from './ScheduleTagSelectComposable';

type Props = PropsScheduleTagSelect;

export default defineComponent({
  name: 'BaseScheduleTagSelect',
  components: { BaseSelectSearch, ScheduleTag },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: [String, Array] as PropType<ScheduleTagSelectValue>, default: undefined },
    tags: { type: Array as PropType<ScheduleTagSelectItem[]>, default: () => [] },
    label: { type: String, default: undefined },
  },
  emits: ['change', 'submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useScheduleTagSelect(props, emit);
  },
});
