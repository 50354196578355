






import { defineComponent } from '@vue/composition-api';

import { PropsMarkerControlHint, useMarkerControlHint } from './MarkerControlHintComposable';

type Props = PropsMarkerControlHint;

export default defineComponent({
  name: 'TrainingMarkerControlHint',
  props: {
    hint: { type: String, default: undefined },
  },
  setup(props: Props) {
    return useMarkerControlHint(props);
  },
});
