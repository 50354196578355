































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';
import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import DateTimeNow from '@/base/app/components/molecules/DateTimeNow.vue';
import { Question } from '@/base/domains';

import { PropsContentQuestionCard, useContentQuestionCard } from './ContentQuestionCardComposable';

type Props = PropsContentQuestionCard;

export default defineComponent({
  name: 'TrainingContentQuestionCard',
  components: { BaseLink, DateTimeNow, User },
  inheritAttrs: false,
  props: {
    question: { type: Object as PropType<Question>, required: true },
    avatar: { type: Object as PropType<UserAvatar>, required: true },
    maxWidth: { type: [Number, String], default: undefined },
  },
  emits: ['open'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentQuestionCard(props, emit);
  },
});
