import { render, staticRenderFns } from "./BaseTextTime.vue?vue&type=template&id=69698038&"
import script from "./BaseTextTime.vue?vue&type=script&lang=ts&"
export * from "./BaseTextTime.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTimePicker } from 'vuetify/lib/components/VTimePicker';
installComponents(component, {VMenu,VTimePicker})
