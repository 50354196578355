






















import { defineComponent, PropType } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import {
  CourseContentTableItem,
  PropsCourseContentTable,
  useCourseContentTable,
} from './CourseContentTableComposable';

type Props = PropsCourseContentTable;

export default defineComponent({
  name: 'TrainingCourseContentTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    contents: { type: Array as PropType<CourseContentTableItem[]>, default: () => [] },
    headerKeys: {
      type: Array as PropType<string[]>,
      default: () => ['no', 'name', 'typeName', 'requiredTime'],
    },
  },
  setup(props: Props) {
    return useCourseContentTable(props);
  },
});
