








































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';

import GroupExamChangeScheduleDialog from '../molecules/GroupExamChangeScheduleDialog.vue';
import GroupExamTable from '../molecules/GroupExamTable.vue';
import GroupExamDialog from './GroupExamDialog.vue';
import GroupExamOpenDialog from './GroupExamOpenDialog.vue';
import { PropsGroupExams, useGroupExams } from './GroupExamsComposable';

type Props = PropsGroupExams;

export default defineComponent({
  name: 'TrainingGroupExams',
  components: {
    GroupExamTable,
    BaseButton,
    BaseButtonIcon,
    BaseDialogConfirm,
    BaseDialogOk,
    GroupExamDialog,
    GroupExamOpenDialog,
    GroupExamChangeScheduleDialog,
  },
  props: {
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
    contentId: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useGroupExams(props);
  },
});
