































































































import { defineComponent } from '@vue/composition-api';
import { VDialog, VMenu } from 'vuetify/lib/components';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';
import UpdateStatusIcon from '@/base/app/components/molecules/UpdateStatusIcon.vue';

import ContentMarkerControl from '../molecules/ContentMarkerControl.vue';
import ContentQuestionForm from '../molecules/ContentQuestionForm.vue';
import {
  PropsContentQuestionDialog,
  useContentQuestionDialog,
} from './ContentQuestionDialogComposable';

type Props = PropsContentQuestionDialog;

export default defineComponent({
  name: 'TrainingContentQuestionDialog',
  components: {
    VDialog,
    VMenu,
    BaseButton,
    BaseButtonIcon,
    BaseDialogOk,
    ContentMarkerControl,
    ContentQuestionForm,
    ErrorMessages,
    UpdateStatusIcon,
  },
  props: {
    fullscreen: { type: Boolean, default: false },
    dialogClassName: { type: String, default: 'training-content-container-dialog' },
    containerClassName: { type: String, default: '.training-content-container' },
    width: { type: Number, default: 400 },
    height: { type: [Number, String], default: 500 },
  },
  setup(props: Props) {
    return useContentQuestionDialog(props);
  },
});
