import { computed } from '@vue/composition-api';

import { requiredInject } from '@/utils/VueUtils';

import { ContentStoreKey } from '../../stores';

export function useContentAppHeaderExtensionTabs() {
  const store = requiredInject(ContentStoreKey);
  const pageNo = computed(() =>
    store.pages.value.findIndex((item) => item.value === store.page.value)
  );

  function changePageNo(i = -1) {
    const v = store.pages.value[i]?.value;
    store.changePageValue(v);
    store.finishMarker();
  }

  return {
    content: store.content,
    pages: store.pages,
    pageNo,
    changePageNo,
  };
}
