






















































































import { defineComponent, PropType } from '@vue/composition-api';

import { GroupRole } from '@/base/domains';

import ContentAppToolBarButton from '../molecules/ContentAppToolBarButton.vue';
import ContentAppToolBarCreateMenus from '../molecules/ContentAppToolBarCreateMenus.vue';
import ContentMarkerControl from '../molecules/ContentMarkerControl.vue';
import { PropsContentAppToolBar, useContentAppToolBar } from './ContentAppToolBarComposable';

type Props = PropsContentAppToolBar;

export default defineComponent({
  name: 'TrainingContentAppToolBar',
  components: { ContentAppToolBarButton, ContentAppToolBarCreateMenus, ContentMarkerControl },
  props: {
    groupRole: { type: String as PropType<GroupRole>, required: true },
    questionAvailable: { type: Boolean, default: false },
    topHeight: { type: Number, default: 64 },
  },
  setup(props: Props) {
    return useContentAppToolBar(props);
  },
});
