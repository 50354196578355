








































import { defineComponent } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogOk from '@/base/app/components/molecules/BaseDialogOk.vue';

import GroupCourseTable from '../molecules/GroupCourseTable.vue';
import CourseAddDialog from './CourseAddDialog.vue';
import CourseListDialog from './CourseListDialog.vue';
import { PropsCourses, useCourses } from './CoursesComposable';

type Props = PropsCourses;

export default defineComponent({
  name: 'TrainingCourses',
  components: {
    GroupCourseTable,
    RefreshButton,
    CourseAddDialog,
    CourseListDialog,
    BaseDialogConfirm,
    BaseDialogOk,
  },
  props: {
    id: { type: String, required: true },
    disabled: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useCourses(props);
  },
});
