

















































import { defineComponent } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ProblemNavigator from '@/base/app/components/atoms/ProblemNavigator.vue';

import { useCourseReviewAppHeaderExtension } from './CourseReviewAppHeaderExtensionComposable';

export default defineComponent({
  name: 'TrainingCourseReviewAppHeaderExtension',
  components: { BaseButton, ProblemNavigator },
  setup() {
    return useCourseReviewAppHeaderExtension();
  },
});
