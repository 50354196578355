





















import { defineComponent, SetupContext } from '@vue/composition-api';

import { useTimer } from './TimerComposable';

export default defineComponent({
  name: 'BaseTimer',
  props: {
    hide: { type: Boolean, default: false },
    height: { type: [String, Number], default: '100%' },
    displayClass: { type: String, default: 'd-flex' },
  },
  emits: ['time-over'],
  setup(_, { emit }: SetupContext) {
    return useTimer(emit);
  },
});
