import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { Schedule } from '@/base/domains';

const MENUS = [
  { value: 'edit', icon: 'mdi-pencil' },
  { value: 'copy', icon: 'mdi-content-copy' },
  { value: 'remove', icon: 'mdi-trash-can' },
];

export type ScheduleMenusActionPayload = { id: string };

export type PropsScheduleMenus = {
  schedule: Schedule;
};

export function useScheduleMenus(
  props: PropsScheduleMenus,
  emit: (name: string, arg: ScheduleMenusActionPayload) => void
) {
  const msgs = useMessages({ prefix: 'training.atoms.scheduleMenus' });

  const menu = ref(false);
  const menus = computed(() =>
    MENUS.map((item) => ({ ...item, label: msgs.of(item.value).value }))
  );

  function action(value: string) {
    emit(value, { id: props.schedule.id });
  }

  return { menu, menus, label: msgs.of('menu'), action };
}
