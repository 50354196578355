


































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';

import UserExamTable from '../molecules/UserExamTable.vue';
import { useGroupExamDialog } from './GroupExamDialogComposable';

export default defineComponent({
  name: 'TrainingGroupExamDialog',
  components: {
    BaseDialogFullScreen,
    RefreshButton,
    UserExamTable,
    BaseDialogConfirm,
    BaseButton,
  },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useGroupExamDialog(emit);
  },
});
