import { computed, nextTick, onMounted, onUnmounted, Ref, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { createUserAvatar } from '@/base/app/components/atoms/UserComposable';
import {
  WindowControlClearValue,
  WindowControlValue,
} from '@/base/app/components/molecules/WindowControlComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { useGlobalStore } from '@/base/app/store';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import {
  delayScroll,
  escapeId,
  getAreaPropsBy,
  getElements,
  useSaveScrollPosition,
} from '@/base/app/utils/DomUtils';
import { createTipPosition } from '@/base/app/utils/MarkerUtils';
import { ProblemUtilsNavigatorValue } from '@/base/app/utils/ProblemUtils';
import { Optional } from '@/base/types';
import { config } from '@/config';
import { assertIsDefined } from '@/utils/Asserts';
import { isDefined } from '@/utils/TsUtils';
import { requiredInject, useRoute, useRouter } from '@/utils/VueUtils';

import { GetContentContent } from '../../../usecases';
import { ContentStoreKey } from '../../stores';
import {
  ContentExamChangeMemoPayload,
  ContentExamCheckPayload,
  ContentExamChoicePayload,
  ContentExamClearPayload,
  ContentExamClickAnchorPayload,
  ContentExamMarkPayload,
  ContentExamOpenTabPayload,
  ContentExamRemoveMemoPayload,
} from '../molecules/ContentExamComposable';
import {
  ContentMemosChangeBodyPayload,
  ContentMemosChangeScopePayload,
  ContentMemosClickAnchorPayload,
  ContentMemosMemo,
  ContentMemosOpenPayload,
  ContentMemosRemovePayload,
  ContentMemosSearchValue,
} from '../molecules/ContentMemosComposable';
import {
  ContentTextChangeMemoPayload,
  ContentTextClickAnchorPayload,
  ContentTextMarkPayload,
  ContentTextOpenTabPayload,
  ContentTextRemoveMemoPayload,
} from '../molecules/ContentTextComposable';
import { ContentTextIndexMobileOpenTextPayload } from '../molecules/ContentTextIndexMobileComposable';
import { ContentTip } from '../molecules/ContentTipBlockComposable';
import {
  ContentWorkbookChangeMemoPayload,
  ContentWorkbookCheckPayload,
  ContentWorkbookChoicePayload,
  ContentWorkbookClearPayload,
  ContentWorkbookClickAnchorPayload,
  ContentWorkbookCompletePayload,
  ContentWorkbookMarkPayload,
  ContentWorkbookOpenTabPayload,
  ContentWorkbookRemoveMemoPayload,
} from '../molecules/ContentWorkbookComposable';
import { ContentMemoDialog } from './ContentMemoDialogComposable';
import {
  ContentQuestionClickAnchorPayload,
  ContentQuestionMovePayload,
  ContentQuestionSavedPayload,
} from './ContentQuestionComposable';
import { ContentQuestionDialog } from './ContentQuestionDialogComposable';
import { ContentQuestionSearchDialog } from './ContentQuestionSearchDialogComposable';

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(
    payload:
      | ContentTextClickAnchorPayload
      | ContentWorkbookClickAnchorPayload
      | ContentExamClickAnchorPayload
      | ContentQuestionClickAnchorPayload
      | ContentMemosClickAnchorPayload
  ) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

function useSearchQuestionDialog(props: PropsContentContainer) {
  const searchQuestionDialog = ref<ContentQuestionSearchDialog>();
  const { getQuery } = useDialogQuery(DialogName.TRAINING_CONTENT_QUESTION_SRC);
  function watchSearchQuestionDialogQuery() {
    if (!searchQuestionDialog.value) return;
    const q = getQuery();
    if (q) {
      searchQuestionDialog.value.open({ groupId: props.groupId });
    } else if (!q && searchQuestionDialog.value.opened()) {
      searchQuestionDialog.value.close();
    }
  }
  return { searchQuestionDialog, watchSearchQuestionDialogQuery };
}

function useCreateQuestionDialog() {
  const createQuestionDialog = ref<ContentQuestionDialog>();
  const { getQuery } = useDialogQuery(DialogName.TRAINING_CONTENT_QUESTION);
  function watchCreateQuestionDialogQuery() {
    if (!createQuestionDialog.value) return;
    const q = getQuery();
    if (q) {
      createQuestionDialog.value.open();
    } else if (!q && createQuestionDialog.value.opened()) {
      createQuestionDialog.value.close();
    }
  }
  return { createQuestionDialog, watchCreateQuestionDialogQuery };
}

function useCreateMemoDialog(props: PropsContentContainer) {
  const createMemoDialog = ref<ContentMemoDialog>();
  const { getQuery } = useDialogQuery(DialogName.TRAINING_CONTENT_MEMO);
  function watchCreateMemoDialogQuery() {
    if (!createMemoDialog.value) return;
    const q = getQuery();
    if (q && q.params && 'type' in q.params) {
      const scopeType = q.params.type === 'group' ? 'group' : 'private';
      createMemoDialog.value.open({ groupId: props.groupId, scopeType });
    } else if (!q && createMemoDialog.value.opened()) {
      createMemoDialog.value.close();
    }
  }
  return { createMemoDialog, watchCreateMemoDialogQuery };
}

function useWatchTextHeadingId(
  options: { watchTextHeadingId: boolean; className: string },
  page: Readonly<Ref<Optional<string>>>,
  onFetch: (f: () => void) => void,
  emit: (name: string, args: Optional<string>) => void
) {
  function fetchHeadingId() {
    const area = getAreaPropsBy(options.className);
    if (!area) return;
    const y = area.areaTop + area.areaPaddingTop + window.scrollY;
    const headers = getElements(`${options.className} h1,h2,h3`)
      .map((el) => {
        const { top } = el.getBoundingClientRect();
        return { id: el.id, y: top };
      })
      .sort((a, b) => a.y - b.y);
    const i = headers.findIndex((item) => item.y > y);
    if (i <= 0) return;
    emit('change-heading-id', headers[i - 1]?.id);
  }
  onFetch(fetchHeadingId);

  function setListener() {
    if (page.value === 'text') {
      if (!options.watchTextHeadingId) return;
      window.addEventListener('scroll', fetchHeadingId);
    } else {
      window.removeEventListener('scroll', fetchHeadingId);
    }
  }
  watch(page, setListener);
  onMounted(() => setListener);
  onUnmounted(() => window.removeEventListener('scroll', fetchHeadingId));
  return {};
}

function useMemosSearch(content: Readonly<Ref<Optional<GetContentContent>>>) {
  const value = ref<ContentMemosSearchValue>();
  function init() {
    value.value = undefined;
    if (!content.value) return;
    const c = content.value;
    if (c.type === 'text' && c.workbook && c.workbook.problems.length > 0) {
      value.value = {
        scope: { private: true, group: true, mine: false },
        option: {
          type: 'textHasWorkbook',
          textType: undefined,
          marker: undefined,
          doNotShowOtherVersions: false,
        },
      };
    } else if (c.type === 'text' || c.type === 'exam') {
      value.value = {
        scope: { private: true, group: true, mine: false },
        option: { type: c.type, marker: undefined, doNotShowOtherVersions: false },
      };
    }
  }
  function change(v: ContentMemosSearchValue) {
    value.value = { ...v };
  }
  return { value, init, change };
}

export type ContentContainerOption = {
  className: string;
  initPages: string[];
  routeNameCompleted: 'home' | 'groupCourse';
  saveScrollPosition: boolean;
  watchTextHeadingId: boolean;
};

export type PropsContentContainer = {
  groupId: string;
  courseId: string;
  contentId: string;
  contentVersion?: string;
  question?: string;
  memo?: string;
  problem?: string;
  hash?: string;
  adminMode: boolean;
  disabledWindowScroll?: boolean;
};

export function useContentContainer(
  props: PropsContentContainer,
  options: ContentContainerOption,
  emit: (name: string, args: Optional<string>) => void
) {
  const store = requiredInject(ContentStoreKey);
  const { user, groupRole, findUser } = useGlobalStore();
  const { searchQuestionDialog, watchSearchQuestionDialogQuery } = useSearchQuestionDialog(props);
  const { createQuestionDialog, watchCreateQuestionDialogQuery } = useCreateQuestionDialog();
  const { createMemoDialog, watchCreateMemoDialogQuery } = useCreateMemoDialog(props);
  const {
    value: memoSearchValue,
    init: initMemoSearchValue,
    change: changeMemoSearchValue,
  } = useMemosSearch(store.content);
  const { clearScrollPositions, preventScroll, allowScroll } = useSaveScrollPosition(
    props,
    options,
    store.onChangePageValue
  );
  useWatchTextHeadingId(options, store.page, store.onFetchHeadingId, emit);

  const route = useRoute();
  const router = useRouter();

  function toInt(v?: string, defValue?: number) {
    if (!isDefined(v)) return defValue;
    const i = parseInt(v, 10);
    if (Number.isInteger(i)) return i;
    return defValue;
  }

  function initDialog() {
    watchCreateMemoDialogQuery();
    watchCreateQuestionDialogQuery();
    watchSearchQuestionDialogQuery();
  }

  async function init() {
    clearScrollPositions();
    await store.fetch(
      {
        id: props.contentId,
        groupId: props.groupId,
        courseId: props.courseId,
        version: toInt(props.contentVersion),
      },
      options.initPages,
      props.adminMode
    );
    if (!store.content.value) return;

    preventScroll();
    const [first] = store.pages.value.filter((item) =>
      ['text', 'exam', 'workbook'].includes(item.value)
    );
    if (first) store.changePageValue(first.value);
    else store.changePageValue(store.content.value.type);
    store.changeProblemIndex(toInt(props.problem, 0));
    if (store.content.value.type === 'text' && props.problem) {
      store.addWorkbookPage();
      store.changePageValue('workbook');
    }
    if (props.question) {
      const qId = props.question;
      const qItem = await store.fetchQuestionPage(qId);
      if (qItem && qItem.question.referTo && 'selection' in qItem.question.referTo) {
        if ('problemIndex' in qItem.question.referTo) {
          store.changeProblemIndex(qItem.question.referTo.problemIndex);
          if (store.content.value.type === 'text') {
            store.addWorkbookPage();
            store.changePageValue('workbook');
          }
        }
      }
      const area = getAreaPropsBy(options.className);
      const y = (area?.areaPaddingTop ?? 0) + 8 + 8;
      await delayScroll(escapeId(qId), {
        delay: 800,
        offsetY: y * -1,
        scrollArea: { selector: '.v-window.overflow-y-auto', isUnescapedId: false },
      });
    } else if (props.memo) {
      const mId = props.memo;
      const m = store.memos.value.find((item) => item.memo.id === mId);
      if (m && !m.disabledVersion && m.memo.referTo) {
        if ('problemIndex' in m.memo.referTo) {
          store.changeProblemIndex(m.memo.referTo.problemIndex);
          if (store.content.value.type === 'text') {
            store.addWorkbookPage();
            store.changePageValue('workbook');
          }
        }
        const area = getAreaPropsBy(options.className);
        const y = (area?.areaPaddingTop ?? 0) + 8 + 8;
        await delayScroll(escapeId(mId), {
          delay: 800,
          offsetY: y * -1,
          scrollArea: { selector: '.v-window.overflow-y-auto', isUnescapedId: false },
        });
      }
    }
    allowScroll();
    initDialog();
    initMemoSearchValue();
  }
  onMounted(init);
  watch(() => route.path, init);
  watch(
    () => route.query,
    (newVal) => {
      if (Object.keys(newVal).length > 0) initDialog();
    }
  );

  onUnmounted(store.dispose);

  function changePage(v: WindowControlValue) {
    store.changePageValue(v);
  }

  async function changePageAndScroll(
    payload:
      | ContentTextIndexMobileOpenTextPayload
      | ContentTextOpenTabPayload
      | ContentMemosOpenPayload
      | ContentExamOpenTabPayload
      | ContentWorkbookOpenTabPayload
      | { type: 'text'; hash: string }
      | { type: 'exam' | 'workbook'; problemIndex: number; hash: string }
  ) {
    if (payload.type === 'workbook' && store.addWorkbookPage()) {
      // タブの追加時はスクロール開始をずらす
      nextTick(() => changePageAndScroll(payload));
      return;
    }

    let h: Optional<string>;
    if ('hash' in payload) {
      h = payload.hash;
    } else if (payload.type === 'memo') {
      h = `memo-${payload.id}`;
      initMemoSearchValue();
    }
    if (h) preventScroll();

    if ('problemIndex' in payload) store.changeProblemIndex(payload.problemIndex);

    const prev = store.page.value;
    const next = payload.type === 'question' ? payload.id : payload.type;
    store.changePageValue(next);
    if (!h) return;

    const area = getAreaPropsBy(options.className);
    const y = (area?.areaPaddingTop ?? 0) + 8 + 8;
    await delayScroll(escapeId(h), {
      delay: prev === next ? 100 : 800,
      times: 5,
      offsetY: y * -1,
      scrollArea: { selector: '.v-window.overflow-y-auto', isUnescapedId: false },
    });
    allowScroll();
  }

  function openQuestionContent(payload: ContentQuestionMovePayload) {
    if (
      props.groupId !== payload.groupId ||
      props.courseId !== payload.courseId ||
      props.contentId !== payload.contentId ||
      store.content.value?.version !== payload.contentVersion
    ) {
      router.push({
        name: 'groupContentVersion',
        params: {
          id: payload.groupId,
          courseId: payload.courseId,
          contentId: payload.contentId,
          contentVersion: payload.contentVersion.toString(),
        },
        query: {
          ...route.query,
          question: payload.from,
          problem: payload.problemIndex?.toString(),
        },
      });
    } else if (payload.problemIndex !== undefined && store.content.value?.type === 'text') {
      changePageAndScroll({
        hash: payload.from,
        problemIndex: payload.problemIndex,
        type: 'workbook',
      });
    } else if (payload.problemIndex !== undefined && store.content.value?.type === 'exam') {
      changePageAndScroll({ hash: payload.from, problemIndex: payload.problemIndex, type: 'exam' });
    } else {
      changePageAndScroll({ hash: payload.from, type: 'text' });
    }
  }

  function refreshQuestion(payload: ContentQuestionSavedPayload) {
    store.fetchQuestionPage(payload.id);
  }

  function closeQuestion(id: WindowControlClearValue) {
    store.removeQuestionPage(id);
  }

  async function changeMemoBody(
    payload:
      | ContentMemosChangeBodyPayload
      | ContentTextChangeMemoPayload
      | ContentExamChangeMemoPayload
      | ContentWorkbookChangeMemoPayload
  ) {
    await store.changeMemoBody({ id: payload.id, body: payload.body });
    payload.done();
  }

  async function changeMemoScope(payload: ContentMemosChangeScopePayload) {
    await store.changeMemoRoles({ id: payload.id, roles: payload.scope.roles });
    payload.done();
  }

  async function removeMemo(
    payload:
      | ContentMemosRemovePayload
      | ContentTextRemoveMemoPayload
      | ContentExamRemoveMemoPayload
      | ContentWorkbookRemoveMemoPayload
  ) {
    await store.removeMemo(payload);
  }

  function mark(
    payload: ContentTextMarkPayload | ContentWorkbookMarkPayload | ContentExamMarkPayload
  ) {
    store.mark(payload);
  }

  function changeProblemIndex(v: ProblemUtilsNavigatorValue) {
    store.changeProblemIndex(v);
    nextTick(() => delayScroll('top'));
  }

  function clearProblemValue(payload: ContentWorkbookClearPayload | ContentExamClearPayload) {
    store.problemController.clear(payload.index);
  }

  function checkProblemValue(payload: ContentWorkbookCheckPayload | ContentExamCheckPayload) {
    const correct = store.problemController.score(payload.index);
    if (correct !== undefined && 'scored' in payload) payload.scored(correct);
  }

  function choiceProblemValue(payload: ContentWorkbookChoicePayload | ContentExamChoicePayload) {
    store.problemController.choice(payload.index, payload.values);
  }

  function complete(payload?: ContentWorkbookCompletePayload) {
    const courseId = store.course.value?.id;
    assertIsDefined(courseId, 'courseId');
    if (payload) {
      store.markContentFinish({ review: payload });
    } else {
      store.markContentFinish();
    }
    const delay = `${config().app.transitionDelay * 1000}`;
    if (options.routeNameCompleted === 'groupCourse') {
      const path = `/groups/${props.groupId}/courses/${courseId}`;
      router.push({ name: 'redirect', query: { p: path, d: delay } });
    } else {
      router.push({ name: 'redirect', query: { d: delay } });
    }
  }

  const tips = computed<ContentTip[]>(() => {
    const items: ContentTip[] = store
      .getEnableMarkers(store.memos.value, store.questions.value, {})
      .map((item) => {
        if ('memo' in item) {
          return {
            ...item,
            avatar: createUserAvatar(item.memo.createdBy, findUser),
            type: 'memo' as const,
          };
        }
        return {
          ...item,
          avatar: createUserAvatar(item.question.createdBy, findUser),
          type: 'question' as const,
        };
      });
    if (store.markedItem.value) {
      const { id, marker } = store.markedItem.value;
      return items.concat({ id, position: createTipPosition(marker), type: 'temp' as const });
    }
    return items;
  });

  const textTips = computed(() => {
    if (!store.content.value || store.content.value.type !== 'text') return [];
    return tips.value.filter((item) => item.position.type.startsWith('text-'));
  });

  const problemTips = computed(() => {
    if (!store.content.value) return [];
    return tips.value.filter((item) => item.position.type.startsWith('problem-'));
  });

  const pages = computed(() =>
    store.pages.value.map((item) => {
      if (['index', 'memo', 'text', 'workbook', 'exam'].includes(item.value)) return item;
      const q = store.questions.value.find((qItem) => qItem.question.id === item.value);
      return { ...item, ...q };
    })
  );

  const toLatest = computed(() => {
    if (store.content.value?.latest) return undefined;
    return {
      name: 'groupContent',
      params: { id: props.groupId, courseId: props.courseId, contentId: props.contentId },
      query: { ...route.query },
    };
  });

  const memos = computed<ContentMemosMemo[]>(() =>
    store.memos.value.map((item) => ({
      memo: item.memo,
      avatar: createUserAvatar(item.memo.createdBy, findUser),
      updatedBy: createUserAvatar(item.memo.updatedBy, findUser),
    }))
  );

  const myId = computed(() => user.value?.id);

  const msgs = useMessages({ prefix: 'training.organisms.contentContainer' });
  return {
    searchQuestionDialog,
    createQuestionDialog,
    createMemoDialog,
    groupRole,
    content: store.content,
    course: store.course,
    courseName: store.courseName,
    page: store.page,
    problemIndex: store.problemIndex,
    problems: store.problemController.problems,
    problemNavigator: store.problemController.navigator,
    enableMarking: store.enableMarking,
    textTips,
    problemTips,
    pages,
    toLatest,
    memoSearchValue,
    memos,
    myId,
    labelClose: msgs.of('close'),
    changeProblemIndex,
    clearProblemValue,
    checkProblemValue,
    choiceProblemValue,
    changePage,
    changePageAndScroll,
    openQuestionContent,
    refreshQuestion,
    closeQuestion,
    changeMemoSearchValue,
    changeMemoBody,
    changeMemoScope,
    removeMemo,
    mark,
    complete,
    ...useAnchorDialog(),
  };
}
