import { onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import {
  ProblemViewChoiceValue,
  ProblemViewClickAnchorPayload,
} from '@/base/app/components/molecules/ProblemViewComposable';
import { getElementRect } from '@/base/app/utils/DomUtils';
import {
  ProblemUtilsNavigator,
  ProblemUtilsNavigatorValue,
  ProblemUtilsProblem,
} from '@/base/app/utils/ProblemUtils';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { Optional } from '@/base/types';

const STYLE_EMPTY = {
  class: undefined as Optional<string>,
  style: undefined as Optional<Record<string, string>>,
};
const STYLES = {
  frame: STYLE_EMPTY,
  windows: STYLE_EMPTY,
  problem: { minHeight: '75vh' as Optional<string> },
};
type Styles = typeof STYLES;

export type UserExamProblemsIndex = number;

export type UserExamProblemsChoicePayload = {
  index: UserExamProblemsIndex;
  values: number[];
};

export type UserExamProblemsToggleFlagPayload = {
  index: UserExamProblemsIndex;
};

export type UserExamProblemsClickAnchorPayload = ProblemViewClickAnchorPayload;

export type PropsUserExamProblems = {
  index: ProblemUtilsNavigatorValue;
  problems: ProblemUtilsProblem[];
  problemNavigator?: ProblemUtilsNavigator;
  showProminent?: boolean;
  isMobile: boolean;
};

export function useUserExamProblems(
  props: PropsUserExamProblems,
  emit: (
    name: string,
    arg?:
      | ProblemUtilsNavigatorValue
      | UserExamProblemsChoicePayload
      | UserExamProblemsToggleFlagPayload
      | UserExamProblemsClickAnchorPayload
  ) => void
) {
  function choiceValue(v: ProblemViewChoiceValue) {
    if (props.index === 'results') return;
    emit('choice-value', { index: props.index, values: v });
  }

  function toggleFlag() {
    if (props.index === 'results') return;
    emit('toggle-flag', { index: props.index });
  }

  function change(payload: ProblemUtilsNavigatorValue) {
    emit('change', payload);
  }

  function prev() {
    if (!props.problemNavigator || props.problemNavigator.prev.value === undefined) return;
    emit('change', props.problemNavigator.prev.value);
  }

  function next() {
    if (!props.problemNavigator || props.problemNavigator.next.value === undefined) return;
    emit('change', props.problemNavigator.next.value);
  }

  function clickAnchor(payload: ProblemViewClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const { mobile } = useVuetify();
  const vWindow = ref<Vue>();
  const styles = ref<Styles>(STYLES);

  function calculateStyle() {
    if (!vWindow.value) return;
    const rectVWindow = vWindow.value.$el.getBoundingClientRect();
    const { top: vwTop } = rectVWindow;
    if (props.isMobile || mobile.value) {
      styles.value = {
        ...STYLES,
        frame: { class: 'pt-16', style: undefined },
        problem: { minHeight: 'calc(100vh - 48px)' },
      };
    } else {
      const rectMain = getElementRect('.user-exam-problems');
      if (!rectMain) return;
      const rectBottom = getElementRect('.user-exam-problems-bottom');
      styles.value = {
        ...STYLES,
        frame: { class: undefined, style: { maxHeight: `calc(100vh - ${rectMain.top + 8}px)` } },
        windows: { class: 'overflow-y-auto', style: undefined },
        problem: { minHeight: `calc(100vh - ${vwTop + (rectBottom?.height ?? 0) + 16}px)` },
      };
    }
  }
  onMounted(() => {
    useTimeoutFn(calculateStyle, 1000);
  });
  watch(
    () => [props.index, props.showProminent, mobile.value],
    () => useTimeoutFn(calculateStyle, 500)
  );

  const msgs = useMessages({ prefix: 'training.molecules.userExamProblems' });
  return {
    vWindow,
    styles,
    labelRaiseFlag: msgs.of('raiseFlag'),
    choiceValue,
    toggleFlag,
    change,
    prev,
    next,
    clickAnchor,
  };
}
