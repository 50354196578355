























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import {
  MarkerControlTileMenusColor,
  PropsMarkerControlTileMenus,
  useMarkerControlTileMenus,
} from './MarkerControlTileMenusComposable';

type Props = PropsMarkerControlTileMenus;

export default defineComponent({
  name: 'TrainingMarkerControlTileMenus',
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: String as PropType<MarkerControlTileMenusColor>, default: undefined },
    height: { type: Number, default: 36 },
  },
  emits: ['change', 'remove'],
  setup(props: Props, { emit }: SetupContext) {
    return useMarkerControlTileMenus(props, emit);
  },
});
