import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { GroupRole } from '@/base/domains';

const MENUS = [
  { value: 'question', icon: 'mdi-forum-outline', roles: ['trainer', 'mentor', 'trainee'] },
  { value: 'memoGroup', icon: 'mdi-note-outline', roles: ['trainer', 'mentor'] },
  { value: 'memoPrivate', icon: 'mdi-note-outline', roles: ['trainer', 'mentor', 'trainee'] },
  { value: 'cancel', icon: 'mdi-close', roles: ['trainer', 'mentor', 'trainee'] },
];

export type ContentCreateMenuCreateMemoPayload = {
  type: 'group' | 'private';
};

export type PropsContentCreateMenus = {
  groupRole?: GroupRole;
  excludes: string[];
};

export function useContentCreateMenus(
  props: PropsContentCreateMenus,
  emit: (name: string, args: void | ContentCreateMenuCreateMemoPayload) => void
) {
  const msgs = useMessages({ prefix: 'training.atoms.contentCreateMenus' });

  const menu = ref(false);
  const items = computed(() =>
    MENUS.filter(
      (item) =>
        props.groupRole &&
        item.roles.includes(props.groupRole) &&
        !props.excludes.includes(item.value)
    ).map((item) => ({
      ...item,
      title: msgs.of(item.value).value,
      description: msgs.of(`${item.value}Description`).value,
    }))
  );

  function action(name: string) {
    switch (name) {
      case 'question':
        emit('create-question');
        break;
      case 'memoGroup':
        emit('create-memo', { type: 'group' });
        break;
      case 'memoPrivate':
        emit('create-memo', { type: 'private' });
        break;
      case 'cancel':
        emit('cancel');
        break;
      default:
    }
  }

  return { menu, items, label: msgs.of('menu'), action };
}
