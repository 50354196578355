



















































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { Memo } from '@/base/domains';

import { PropsMemoMenus, useMemoMenus } from './MemoMenusComposable';

export default defineComponent({
  name: 'TrainingMemoMenus',
  inheritAttrs: false,
  props: {
    memo: { type: Object as PropType<Memo>, required: true },
    disabledScope: { type: Boolean, default: false },
    disabledRemove: { type: Boolean, default: false },
  },
  emits: ['change-scope', 'remove'],
  setup(props: PropsMemoMenus, { emit }: SetupContext) {
    return useMemoMenus(props, emit);
  },
});
