






















import { computed, defineComponent, provide } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import MyApp from '@/base/app/components/MyApp.vue';
import { useGlobalStore } from '@/base/app/store';

import GroupExams from '../components/organisms/GroupExams.vue';
import { GroupExamsStoreKey, useGroupExamsStore } from '../stores';

type Props = {
  id: string;
  courseId: string;
  contentId: string;
  edit: boolean;
};

export default defineComponent({
  name: 'TrainingTrainerGroupExamsPage',
  components: { MyApp, GroupExams },
  props: {
    id: { type: String, required: true },
    courseId: { type: String, required: true },
    contentId: { type: String, required: true },
    edit: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const store = useGroupExamsStore();
    provide(GroupExamsStoreKey, store);

    const msgs = useMessages({ prefix: 'training.pages.trainerGroupExamsPage' });

    const contentName = computed(() => {
      if (!store.content.value) return undefined;
      const { name, open } = store.content.value;
      const params = { contentName: name };
      if (store.lastGroupExam.value?.status === 'in_progress')
        return msgs.of('examInProgress', params).value;
      if (store.scheduledGroupExam.value) return msgs.of('examScheduled', params).value;
      if (open) return msgs.of('opened', params).value;
      return msgs.of('closed', params).value;
    });

    const { groupRole } = useGlobalStore();
    const disabled = computed(() => {
      if (groupRole.value === 'trainer' && props.edit) return false;
      return true;
    });

    return {
      loading: store.loading,
      notFound: store.notFound,
      contentName,
      disabled,
    };
  },
});
