import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn, useWindowSize } from '@vueuse/core';

import {
  BaseMarkdownClickAnchorPayload,
  BaseMarkdownMarkSelection,
} from '@/base/app/components/atoms/BaseMarkdownComposable';
import { getAreaProps, getAreaPropsBy } from '@/base/app/utils/DomUtils';
import { MarkerSelection } from '@/base/app/utils/MarkerUtils';
import { Optional } from '@/base/types';

import { GetContentContent } from '../../../usecases';
import {
  ContentTip,
  ContentTipBlockChangeMemoPayload,
  ContentTipBlockClickAnchorPayload,
  ContentTipBlockOpenTabPayload,
  ContentTipBlockRemoveMemoPayload,
} from './ContentTipBlockComposable';

const STYLES = {
  tip: { nudgeLeft: 0, nudgeTop: 0 },
  selection: { selectionOffsetX: 0, selectionOffsetY: 0 },
};
type Styles = typeof STYLES;

export type ContentTextMarkPayload = Optional<{ selection: MarkerSelection }>;

export type ContentTextClickAnchorPayload =
  | BaseMarkdownClickAnchorPayload
  | ContentTipBlockClickAnchorPayload;

export type ContentTextOpenTabPayload = { type: 'workbook' } | ContentTipBlockOpenTabPayload;

export type ContentTextChangeMemoPayload = ContentTipBlockChangeMemoPayload;

export type ContentTextRemoveMemoPayload = ContentTipBlockRemoveMemoPayload;

export type PropsContentText = {
  groupId: string;
  courseName: string;
  content: GetContentContent;
  tips: ContentTip[];
  hideMarker: boolean;
  showSide: boolean;
  showProminent: boolean;
  isMobile: boolean;
};

export function useContentText(
  props: PropsContentText,
  emit: (
    name: string,
    arg?:
      | ContentTextMarkPayload
      | ContentTextClickAnchorPayload
      | ContentTextOpenTabPayload
      | ContentTextChangeMemoPayload
      | ContentTextRemoveMemoPayload
  ) => void
) {
  function mark(selection: BaseMarkdownMarkSelection) {
    if (selection) {
      emit('mark', { selection });
      return;
    }
    emit('mark');
  }

  function clickAnchor(
    payload: BaseMarkdownClickAnchorPayload | ContentTipBlockClickAnchorPayload
  ) {
    emit('click-anchor', payload);
  }

  function complete() {
    emit('complete');
  }

  function openWorkbook() {
    emit('open-tab', { type: 'workbook' });
  }

  function openTab(payload: ContentTipBlockOpenTabPayload) {
    emit('open-tab', payload);
  }

  function changeMemo(payload: ContentTipBlockChangeMemoPayload) {
    emit('change-memo', payload);
  }

  function removeMemo(payload: ContentTipBlockRemoveMemoPayload) {
    emit('remove-memo', payload);
  }

  const headerMemos = computed(() =>
    props.tips.filter((item) => item.position.type === 'text-no-marker' && item.type === 'memo')
  );

  const contentBody = computed(() => {
    if (props.content.type !== 'text') return undefined;
    return props.content.body;
  });

  const contentBodyMarkers = computed(() =>
    props.tips.filter((item) => item.position.type === 'text-marker')
  );

  const existsWorkbook = computed(() => {
    if (props.content.type !== 'text') return false;
    const { workbook } = props.content;
    return workbook && workbook.problems.length > 0;
  });

  const delayHideMarker = ref(false);
  const divMain = ref<HTMLDivElement>();
  const styles = ref<Styles>(STYLES);

  function calculateStyle() {
    if (!divMain.value || props.hideMarker) {
      styles.value = { ...STYLES };
      return;
    }
    const areaDiv = getAreaProps(divMain.value);
    const areaDivX = areaDiv.areaLeft + window.scrollX + 4;
    const areaDivY = areaDiv.areaTop + window.scrollY - 4;
    const areaBodyWrapper = getAreaPropsBy('.training-content-text-body-wrapper');
    const areaBodyX = (areaBodyWrapper?.areaLeft ?? 0) + window.scrollX;
    const areaBodyY = (areaBodyWrapper?.areaTop ?? 0) + window.scrollY;
    styles.value = {
      ...STYLES,
      tip: { nudgeLeft: areaDivX * -1, nudgeTop: areaDivY * -1 },
      selection: { selectionOffsetX: areaBodyX, selectionOffsetY: areaBodyY },
    };
    delayHideMarker.value = false;
  }
  onMounted(() => {
    delayHideMarker.value = true;
    useTimeoutFn(calculateStyle, 1000);
  });
  watch(
    () => [props.showSide, props.showProminent, props.hideMarker],
    () => {
      delayHideMarker.value = true;
      useTimeoutFn(calculateStyle, 500);
    }
  );
  watch(headerMemos, () => {
    delayHideMarker.value = true;
    useTimeoutFn(calculateStyle, 0);
  });

  const { width } = useWindowSize();
  watch(width, () => {
    delayHideMarker.value = true;
    useTimeoutFn(calculateStyle, 0);
  });

  return {
    headerMemos,
    contentBody,
    contentBodyMarkers,
    existsWorkbook,
    delayHideMarker,
    divMain,
    styles,
    mark,
    clickAnchor,
    complete,
    openWorkbook,
    openTab,
    changeMemo,
    removeMemo,
  };
}
