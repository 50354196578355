import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { useProblemController } from '@/base/app/utils/ProblemUtils';
import { ChoiceReviewProblemProblem } from '@/base/domains';
import { ApplicationError } from '@/base/error';
import { isFailed } from '@/base/usecases';
import {
  GetReviewProblemResponse,
  useGetReviewProblem,
  useRemoveReviewProblemProblems,
} from '@/training/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf } from '@/utils/VueUtils';

const PAGES = ['welcome', 'main', 'setting'] as const;
type Page = typeof PAGES[number];

export function useReviewStore() {
  const msgs = useMessages({ prefix: 'training.stores.reviewStore' });
  const review = ref<GetReviewProblemResponse>();
  const page = ref<Page>();
  const loading = ref(false);

  const reviewName = computed(() => {
    if (!review.value?.course) return undefined;
    return msgs.of('name', { course: review.value.course.name }).value;
  });
  const course = computed(() => review.value?.course);

  function getStorageKey() {
    assertIsDefined(review.value?.reviewProblem, 'review');
    const { id } = review.value.reviewProblem;
    return id;
  }

  const {
    value: problemIndex,
    problems,
    navigator,
    init: initProblem,
    exists: existsProblemData,
    ...problemController
  } = useProblemController({
    content: computed(() => {
      if (!review.value || !review.value.reviewProblem) return undefined;
      return { type: 'review' as const, problems: review.value.reviewProblem.problems };
    }),
    enableResults: true,
  });

  function existsData() {
    const key = getStorageKey();
    return existsProblemData(key);
  }

  function move(v: Page) {
    page.value = v;
  }

  const getReviewProblem = useGetReviewProblem();
  async function fetchReviewProblem(groupId: string, courseId: string) {
    const res = await getReviewProblem.execute({ groupId, courseId });
    if (isFailed(res) || !res.reviewProblem || !res.course) return undefined;
    return res;
  }

  async function fetch(groupId: string, courseId: string) {
    loading.value = true;
    review.value = await fetchReviewProblem(groupId, courseId);
    loading.value = false;
  }

  function start(isContinue = false) {
    const params = { key: getStorageKey(), enableFailureCounts: true, enablePassed: true };
    if (isContinue) {
      initProblem({
        ...params,
        converter: (x, s, _) => {
          const { index, hash } = x;
          // hashを条件に前回の結果を取得
          const result = s?.find((item) => item.hash === hash);
          if (result) return { ...x, ...result, index };
          return x;
        },
      });
    } else {
      initProblem(params);
    }
  }

  const removeProblem = useRemoveReviewProblemProblems();

  async function internalRemove(
    condition: (p: ChoiceReviewProblemProblem) => boolean
  ): Promise<ApplicationError[] | number> {
    assertIsDefined(review.value?.reviewProblem, 'reviewProblem');
    const { id, groupId, courseId, problems: rps } = review.value.reviewProblem;
    const res = await removeProblem.execute({
      id,
      hashListToRemove: rps.filter(condition).map((p) => p.hash),
    });
    if (isFailed(res)) return res.errors;
    const ret = await fetchReviewProblem(groupId, courseId);
    return ret?.reviewProblem?.problems ? ret.reviewProblem.problems.length : 0;
  }

  /**
   * 削除しないで残すインデックスを指定して復習問題を削除する
   *
   * @param remainProblemIndexes 削除しないで残すインデックス
   * @returns エラーが発生した場合はApplicationErrorのリスト、そうでない場合は問題の件数
   */
  async function removeByRemainProblemIndexes(remainProblemIndexes: number[]) {
    return internalRemove((p) => !remainProblemIndexes.includes(p.index));
  }

  async function remove(indexes: number[]) {
    return internalRemove((p) => indexes.includes(p.index));
  }

  const notFound = computed(() => !review.value);

  return {
    page,
    reviewName,
    course,
    loading,
    notFound,
    problemIndex,
    problems,
    problemNavigator: navigator,
    problemController,
    move,
    fetch,
    start,
    remove,
    removeByRemainProblemIndexes,
    existsData,
  };
}

export type ReviewStore = ReturnType<typeof useReviewStore>;

export const ReviewStoreKey = injectionKeyOf<ReviewStore>({
  boundedContext: 'training',
  type: 'store',
  name: 'ReviewStore',
});
