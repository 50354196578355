var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.fullscreen ? 'v-dialog' : 'v-menu',_vm._b({tag:"component",model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},'component',_vm.dialogAttrs,false),[(_vm.mini)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":""},on:{"click":_vm.toggleMinimize}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"value":!!_vm.marker,"avatar":"","overlap":"","color":"","offset-x":"16","offset-y":"18"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-icon',{attrs:{"color":_vm.markerColor}},[_vm._v("mdi-marker")])]},proxy:true}],null,true)},[_c('v-icon',{attrs:{"color":"secondary","size":"32"}},[_vm._v("mdi-note-outline")])],1)],1)]}}],null,false,23158083)},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.labelMaximize))])])]:_vm._e(),_c('v-sheet',_vm._b({class:{
      'base-dialog-full-screen': _vm.fullscreen,
      'd-none': _vm.mini,
      'd-flex flex-column': !_vm.mini,
    },attrs:{"data-cy":"training.contentMemoDialog.dialog"}},'v-sheet',_vm.sheetAttrs,false),[(_vm.fullscreen)?_c('div',{staticClass:"d-flex align-center pt-10 px-10"},[_c('div',{staticClass:"text-h6 text-line-clamp-1"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('update-status-icon',{model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('base-button-icon',{attrs:{"loading":_vm.updating,"button-class":"ma-5"},on:{"click":_vm.close},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-icon',[_vm._v("mdi-close")])]},proxy:true}],null,false,3273271530)},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.labelClose))])])],1):_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"block":"","text":"","tile":"","small":""},on:{"click":_vm.toggleMinimize}},[_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"secondary","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-window-minimize ")])]}}])},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.labelMinimize))])])],1)])],1),_c('div',{staticClass:"d-flex justify-center px-5"},[_c('content-memo-form',_vm._b({ref:"form",on:{"submit":_vm.submit},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}},'content-memo-form',_vm.formAttrs,false),[_c('error-messages',{staticClass:"text-caption px-5 mb-5",attrs:{"errors":_vm.errors}}),(_vm.marker)?_c('content-marker-control',_vm._b({attrs:{"height":_vm.markerControlHeight,"show-hint":_vm.showMarkerHint,"disabled":_vm.fullscreen},on:{"change-color":_vm.changeMarkerColor,"remove":_vm.removeMarkedItem,"move":_vm.moveToMarker}},'content-marker-control',_vm.marker,false)):_vm._e(),_c('div',{staticClass:"d-flex pa-5",class:{ 'justify-center': _vm.fullscreen, 'justify-end': !_vm.fullscreen }},[_c('base-button',{attrs:{"type":"submit","loading":_vm.updating,"data-cy":"training.contentMemoForm.create"}},[_vm._v(" "+_vm._s(_vm.labelCreate)+" ")]),(!_vm.fullscreen)?_c('base-button',{staticClass:"mx-5",attrs:{"text":"","outlined":false,"disabled":_vm.updating,"color":"secondary"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.labelClose)+" ")]):_vm._e()],1)],1)],1)]),_c('base-dialog-confirm',{ref:"confirmDialog",attrs:{"max-width":"350px"}}),_c('base-dialog-ok',{ref:"errorDialog",attrs:{"max-width":"350px"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }