



































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { MyExamResult } from '@/base/domains';

import BaseButtonIcon from '../atoms/BaseButtonIcon.vue';
import BaseMarkdown from '../atoms/BaseMarkdown.vue';
import {
  PropsUserExamResultSummary,
  useUserExamResultSummary,
} from './UserExamResultSummaryComposable';

type Props = PropsUserExamResultSummary;

export default defineComponent({
  name: 'BaseUserExamResultSummary',
  components: { BaseButtonIcon, BaseMarkdown },
  props: {
    result: { type: Object as PropType<MyExamResult>, required: true },
    description: { type: String, default: undefined },
    showHome: { type: Boolean, default: false },
  },
  emits: ['click'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserExamResultSummary(props, emit);
  },
});
