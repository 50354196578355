


















import { computed, defineComponent } from '@vue/composition-api';

import { ScheduleTag } from '@/base/domains';

import { useColor } from '../../utils/ColorUtils';

type Props = { tag: ScheduleTag };

export default defineComponent({
  name: 'BaseScheduleTag',
  inheritAttrs: false,
  props: {
    tag: { type: Object, required: true },
    maxWidth: { type: String, default: 'min(300px, 35vw)' },
    icon: { type: Boolean, default: false },
  },
  setup(props: Props) {
    const { dark, colors } = useColor();
    const chipColor = computed(() => {
      const c = colors.value.find((item) => item.value === props.tag.color);
      if (c) return c.color;
      const [first] = colors.value;
      return first.color;
    });
    return { dark, chipColor };
  },
});
