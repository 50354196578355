

















import { defineComponent } from '@vue/composition-api';

import BaseButtonVertical from '@/base/app/components/atoms/BaseButtonVertical.vue';

export default defineComponent({
  name: 'TrainingContentAppToolBarButton',
  components: { BaseButtonVertical },
  inheritAttrs: false,
  props: {
    label: { type: String, required: true },
    icon: { type: String, required: true },
  },
  emits: ['click'],
});
