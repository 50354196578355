
























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { Schedule } from '@/base/domains';

import { PropsScheduleMenus, useScheduleMenus } from './ScheduleMenusComposable';

type Props = PropsScheduleMenus;

export default defineComponent({
  name: 'TrainingScheduleMenus',
  inheritAttrs: false,
  props: {
    schedule: { type: Object as PropType<Schedule>, required: true },
  },
  emits: ['edit', 'copy', 'remove'],
  setup(props: Props, { emit }: SetupContext) {
    return useScheduleMenus(props, emit);
  },
});
