import { nextTick, onMounted, ref, watch } from '@vue/composition-api';

import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { delayScroll, escapeId } from '@/base/app/utils/DomUtils';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRoute, useRouter } from '@/utils/VueUtils';

import { QuestionStoreKey } from '../../stores';
import {
  ContentQuestionClickAnchorPayload,
  ContentQuestionMovePayload,
} from './ContentQuestionComposable';

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: ContentQuestionClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

export type PropsQuestion = {
  id: string;
  groupId: string;
  disabled: boolean;
  topHeight: number;
};

export function useQuestion(props: PropsQuestion) {
  const router = useRouter();
  const route = useRoute();

  const { question, fetch: fetchQuestion } = requiredInject(QuestionStoreKey);

  function tryScroll(hash?: string) {
    if (!hash) return;
    nextTick(() => delayScroll(escapeId(hash), { delay: 300 }));
    router.replace({
      name: 'groupQuestion',
      params: { id: props.groupId, questionId: props.id },
      hash: undefined,
    });
  }
  watch(
    () => route.hash,
    (newVal) => tryScroll(newVal)
  );

  async function fetch(hash?: string) {
    await fetchQuestion({ id: props.id });
    tryScroll(hash);
  }
  onMounted(() => fetch(route.hash));
  watch(
    () => props.id,
    () => fetch(route.hash)
  );

  function move(payload: ContentQuestionMovePayload) {
    router.push({
      name: 'groupContentVersion',
      params: {
        id: payload.groupId,
        courseId: payload.courseId,
        contentId: payload.contentId,
        contentVersion: payload.contentVersion.toString(),
      },
      query: {
        question: props.id,
        r: route.query.r,
      },
    });
  }

  return { question, move, refresh: fetch, ...useAnchorDialog() };
}
