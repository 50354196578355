import { or } from '@/base/app/utils/FilterUtils';
import { QuestionWithNames } from '@/base/usecases';

const QUESTION_FILTER_TARGET = [
  'title',
  'createdBy',
  'assignee',
  'courseName',
  'contentName',
  'content',
  'own',
  'assigned',
  'unsolved',
  'solved',
  'referToContent',
] as const;
export type QuestionFilterTargetType = typeof QUESTION_FILTER_TARGET[number];

export type QuestionCondition = {
  text?: string;
  userId?: string;
  contentId?: string;
  target: QuestionFilterTargetType[];
};

export type QuestionFilterConvert = (
  t: 'createdBy' | 'assignee' | 'content',
  q: QuestionWithNames
) => string[];

type QuestionFilter = {
  target: QuestionFilterTargetType;
  filter: (c: QuestionCondition, q: QuestionWithNames, convert: QuestionFilterConvert) => boolean;
};

const QUESTION_AND_FILTERS: QuestionFilter[] = [
  {
    target: 'own',
    filter: (c, q) => {
      if (!c.userId) return false;
      return q.createdBy === c.userId;
    },
  },
  {
    target: 'assigned',
    filter: (c, q) => {
      if (!c.userId) return false;
      return q.assignees.includes(c.userId);
    },
  },
  {
    target: 'referToContent',
    filter: (c, q) => {
      if (!c.contentId) return false;
      return q.referTo?.contentId === c.contentId;
    },
  },
];

const QUESTION_OR_FILTERS: QuestionFilter[] = [
  {
    target: 'title',
    filter: (c, q) => or(q.title, c.text),
  },
  {
    target: 'createdBy',
    filter: (c, q, fn) => {
      return fn('createdBy', q).some((item) => or(item, c.text));
    },
  },
  {
    target: 'assignee',
    filter: (c, q, fn) => {
      return fn('assignee', q).some((item) => or(item, c.text));
    },
  },
  {
    target: 'courseName',
    filter: (c, q) => or(q.referTo?.courseName, c.text),
  },
  {
    target: 'contentName',
    filter: (c, q) => or(q.referTo?.contentName, c.text),
  },
  {
    target: 'content',
    filter: (c, q, fn) => {
      const ret = q.comments.some((item) => or(item.body, c.text));
      if (ret) return true;
      return fn('content', q).some((item) => or(item, c.text));
    },
  },
];

export function filterQuestions(
  questions: QuestionWithNames[],
  condition: QuestionCondition,
  convert: QuestionFilterConvert
): QuestionWithNames[] {
  const { target } = condition;
  const ands = QUESTION_AND_FILTERS.filter((f) => target.includes(f.target)).map((f) => f.filter);
  const ors = QUESTION_OR_FILTERS.filter((f) => target.includes(f.target)).map((f) => f.filter);
  return questions.filter(
    (q) =>
      (ands.length === 0 || ands.every((f) => f(condition, q, convert))) &&
      (ors.length === 0 || condition.text === '*' || ors.some((f) => f(condition, q, convert)))
  );
}
