




















import { defineComponent } from '@vue/composition-api';

import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import QuestionBar from '@/base/app/components/molecules/QuestionBar.vue';

import QuestionSearchFields from '../molecules/QuestionSearchFields.vue';
import { PropsQuestions, useQuestions } from './QuestionsComposable';

type Props = PropsQuestions;

export default defineComponent({
  name: 'TrainingQuestions',
  components: { QuestionSearchFields, QuestionBar, BaseMarkdown },
  props: {
    id: { type: String, required: true },
    sort: { type: String, default: undefined },
    flags: { type: String, default: undefined },
    text: { type: String, default: undefined },
  },
  setup(props: Props) {
    return useQuestions(props);
  },
});
