
























































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import TextEditor from '@/base/app/components/molecules/TextEditor.vue';
import { GlobalStoreGroup, GlobalStoreUser } from '@/base/app/store/types';
import { Optional } from '@/base/types';

import { useQuestionCommentAdd } from './QuestionCommentAddComposable';

export default defineComponent({
  name: 'TrainingQuestionCommentAdd',
  components: { User, BaseButton, TextEditor },
  props: {
    user: { type: Object as PropType<UserAvatar>, required: true },
    disabled: { type: Boolean, default: false },
    canChangeAndSolve: { type: Boolean, default: false },
    group: { type: Object as PropType<GlobalStoreGroup>, required: true },
    priorityMentions: { type: Array as PropType<string[]>, default: undefined },
    findUser: {
      type: Function as PropType<(id: string) => Optional<GlobalStoreUser>>,
      required: true,
    },
  },
  emits: ['click-anchor', 'click-user', 'save'],
  setup(_, { emit }: SetupContext) {
    return useQuestionCommentAdd(emit);
  },
});
