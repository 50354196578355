import { computed, ref, watch } from '@vue/composition-api';
import moment from 'moment';

import { useMessages } from '@/base/app';
import { Optional } from '@/base/types';

import { DateTimeRangeFormValue } from '../atoms/DateTimeRangeFormComposable';

export type ScheduleSearchFieldsValue = Optional<DateTimeRangeFormValue>;

export type PropsScheduleSearchFields = {
  value: ScheduleSearchFieldsValue;
};

export function useScheduleSearchFields(
  props: PropsScheduleSearchFields,
  emit: (name: string, arg: ScheduleSearchFieldsValue) => void
) {
  const input = ref<DateTimeRangeFormValue>();

  const display = ref(false);
  watch(display, (newVal) => {
    if (newVal && props.value) {
      input.value = { ...props.value };
    } else if (newVal) {
      input.value = { from: moment().add(-1, 'days').startOf('day'), to: moment().endOf('minute') };
    } else {
      input.value = undefined;
    }
  });

  function close() {
    display.value = false;
  }

  function search() {
    emit('change', input.value);
    close();
  }

  function clear() {
    emit('change', undefined);
  }

  const labelRange = computed(() => {
    if (!props.value) return undefined;
    return [props.value.from.format('lll'), props.value.to.format('lll')].join(' > ');
  });

  const msgs = useMessages({ prefix: 'training.molecules.scheduleSearchFields' });
  return {
    display,
    input,
    labelRange,
    labelSearchByRange: msgs.of('searchByRange'),
    labelFrom: msgs.of('startFrom'),
    labelTo: msgs.of('startTo'),
    labelSearch: msgs.of('search'),
    labelClose: msgs.of('close'),
    labelClearRange: msgs.of('clearRange'),
    close,
    search,
    clear,
  };
}
