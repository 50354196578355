















































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ContentHeader from '@/base/app/components/molecules/ContentHeader.vue';
import ContentIconClosed from '@/base/app/components/molecules/ContentIconClosed.vue';

import { GetContentContent } from '../../../usecases';
import { PropsContentText, useContentText } from './ContentTextComposable';
import ContentTipBlock from './ContentTipBlock.vue';
import { ContentTip } from './ContentTipBlockComposable';
import ContentVersionWarning from './ContentVersionWarning.vue';

type Props = PropsContentText;

export default defineComponent({
  name: 'TrainingContentText',
  components: {
    BaseMarkdown,
    ContentIconClosed,
    ContentHeader,
    ContentVersionWarning,
    ContentTipBlock,
    ContentFooter,
  },
  props: {
    groupId: { type: String, required: true },
    content: { type: Object as PropType<GetContentContent>, required: true },
    courseName: { type: String, required: true },
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    tips: { type: Array as PropType<ContentTip[]>, default: () => [] },
    hideMarker: { type: Boolean, default: false },
    enableMarking: { type: Boolean, default: false },
    hideControl: { type: Boolean, default: false },
    toLatest: { type: Object as PropType<Location>, default: undefined },
    showSide: { type: Boolean, default: false },
    showProminent: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
    minHeight: { type: String, default: 'calc(100vh - 400px)' },
  },
  emits: ['click-anchor', 'complete', 'mark', 'open-tab', 'change-memo', 'remove-memo'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentText(props, emit);
  },
});
