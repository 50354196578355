import { computed, nextTick, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';
import { DataTableHeader } from 'vuetify';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { Course, CourseContent } from '@/base/domains';
import { isFailed, isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';

import { useAddGroupCourse, useGetCourseByTrainer } from '../../../usecases';

const HEADERS: DataTableHeader[] = [
  {
    value: 'open',
    text: '',
    width: 100,
    align: 'center',
    cellClass: 'course-add-dialog-table-open',
  },
  { value: 'name', text: '', class: 'course-add-dialog-table-name' },
  { value: 'typeName', text: '', width: 140 },
  { value: 'requiredTime', text: '', width: 140, align: 'center' },
];

type AddCourseContent = CourseContent & {
  open: boolean;
};

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

export function useCourseAddDialog(emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'training.organisms.courseAddDialog' });
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const dialog = ref(false);
  const all = ref(false);
  const groupId = ref<string>();
  const course = ref<Course>();
  const contents = ref<AddCourseContent[]>([]);
  const errors = ref<ErrorMessage[]>();
  const loading = ref(false);
  const displayName = ref<string>('');
  const observer = ref<InstanceType<typeof ValidationObserver>>();

  function close() {
    dialog.value = false;
    all.value = false;
    groupId.value = undefined;
    course.value = undefined;
    contents.value = [];
    errors.value = undefined;
    displayName.value = '';
  }
  watch(dialog, (newVal) => {
    if (!newVal) close();
  });

  const getCourse = useGetCourseByTrainer();
  async function fetch(cId: string, gId: string) {
    loading.value = true;
    const res = await getCourse.execute({ id: cId, groupId: gId });
    if (isSucceeded(res)) {
      course.value = res.course;
      contents.value = res.course?.contents.map((c) => ({ ...c, open: c.type !== 'exam' })) || [];
      displayName.value = res.course?.name ?? '';
    } else {
      course.value = undefined;
      contents.value = [];
      displayName.value = '';
    }
    loading.value = false;
  }

  async function open(payload: { groupId: string; courseId: string }) {
    dialog.value = true;
    groupId.value = payload.groupId;
    nextTick(() => {
      if (observer.value) observer.value.reset();
    });
    await fetch(payload.courseId, payload.groupId);
    if (!course.value) errors.value = [msgs.of('noData').value];
  }

  function toggle(item: AddCourseContent) {
    const content = contents.value.find((c) => c.id === item.id);
    if (!content) return;
    content.open = !item.open;
  }

  function toggleAll() {
    if (contents.value) contents.value.forEach((c) => Object.assign(c, { open: all.value }));
    all.value = !all.value;
  }

  const addCourse = useAddGroupCourse();
  async function add() {
    assertIsDefined(groupId.value, 'groupId');
    assertIsDefined(course.value, 'course');
    errors.value = undefined;
    loading.value = true;
    const { id: courseId } = course.value;
    const res = await addCourse.execute({
      groupId: groupId.value,
      courseId,
      contents: contents.value?.map((c) => ({ id: c.id, open: c.open })),
      courseDisplayName: displayName.value,
    });
    if (isFailed(res)) {
      loading.value = false;
      errors.value = res.errors;
      return;
    }
    waitTransition(() => {
      loading.value = false;
      emit('done');
      close();
    });
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    if (contents.value?.some((item) => item.type === 'exam' && item.open)) {
      confirm(msgs.of('confirmOpenExam').value, add);
      return;
    }
    add();
  }

  const title = computed(() => course.value?.name);
  const headers = computed(() => HEADERS.map((h) => ({ ...h, text: msgs.of(h.value).value })));
  const items = computed(() =>
    contents.value.map((c) => ({ ...c, typeName: msgs.of(c.type).value }))
  );
  const notFound = computed(() => !course.value);
  return {
    observer,
    displayName,
    confirmDialog,
    dialog,
    all,
    course,
    errors,
    loading,
    title,
    headers,
    items,
    notFound,
    labelAllOpen: msgs.of('allOpen'),
    labelAllClose: msgs.of('allClose'),
    labelAdd: msgs.of('add'),
    labelClose: msgs.of('close'),
    labelDisplayName: msgs.of('displayName'),
    close,
    open,
    toggle,
    toggleAll,
    submit,
  };
}

export type CourseAddDialog = ReturnType<typeof useCourseAddDialog>;
