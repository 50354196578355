import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';

export type PropsMarkerControlHint = {
  hint?: string;
};

export function useMarkerControlHint(props: PropsMarkerControlHint) {
  const msgs = useMessages({ prefix: 'training.atoms.markerControlHint' });
  const labelHint = computed(() => props.hint || msgs.of('hint').value);
  return { labelHint };
}
