import { useMessages } from '@/base/app';

import { ContentCreateMenuCreateMemoPayload } from '../atoms/ContentCreateMenusComposable';

export type ContentAppToolBarCreateMenusCreateMemoPayload = ContentCreateMenuCreateMemoPayload;

export function useContentAppToolBarCreateMenus(
  emit: (name: string, args?: ContentAppToolBarCreateMenusCreateMemoPayload) => void
) {
  function createQuestion() {
    emit('create-question');
  }

  function createMemo(payload: ContentCreateMenuCreateMemoPayload) {
    emit('create-memo', payload);
  }

  function cancel() {
    emit('cancel');
  }

  function action(name: string) {
    switch (name) {
      case 'question':
        emit('create-question');
        break;
      case 'memoGroup':
        emit('create-memo', { type: 'group' });
        break;
      case 'memoPrivate':
        emit('create-memo', { type: 'private' });
        break;
      case 'cancel':
        emit('cancel');
        break;
      default:
    }
  }

  const msgs = useMessages({ prefix: 'training.molecules.contentAppToolBarCreateMenus' });
  return { label: msgs.of('menu'), createQuestion, createMemo, cancel, action };
}
