import { computed, nextTick, onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn, useWindowSize } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { getAreaProps, parseStyleSize } from '@/base/app/utils/DomUtils';
import { getMarkerRects, MarkerRect, TipPosition } from '@/base/app/utils/MarkerUtils';
import { Memo, Question } from '@/base/domains';
import { Optional } from '@/base/types';

import { MemoMenusRemovePayload } from '../atoms/MemoMenusComposable';
import {
  ContentMemoCardClickAnchorPayload,
  ContentMemoCardSubmitPayload,
} from './ContentMemoCardComposable';
import { ContentQuestionCardOpenPayload } from './ContentQuestionCardComposable';

type Marker = {
  id: string;
  markerRects: Optional<MarkerRect[]>;
  markerColor: string;
};

export type ContentTipBlockClickAnchorPayload = ContentMemoCardClickAnchorPayload;

export type ContentTipBlockChangeMemoPayload = ContentMemoCardSubmitPayload;

export type ContentTipBlockRemoveMemoPayload = MemoMenusRemovePayload;

export type ContentTipBlockOpenTabPayload =
  | {
      type: 'memo';
      id: string;
    }
  | ({
      type: 'question';
    } & ContentQuestionCardOpenPayload);

export type ContentTip =
  | {
      type: 'memo';
      memo: Memo;
      position: TipPosition;
      avatar: UserAvatar;
      disabled: boolean;
    }
  | {
      type: 'question';
      question: Question;
      position: TipPosition;
      avatar: UserAvatar;
    }
  | {
      type: 'temp';
      id: string;
      position: TipPosition;
    };

export type PropsContentTipBlock = {
  tips: ContentTip[];
  selectionRootName: string;
  selectionOffsetX: number;
  selectionOffsetY: number;
  nudgeLeft: number;
  nudgeTop: number;
  hideSelection: boolean;
  disabled: boolean;
};

export function useContentTipBlock(
  props: PropsContentTipBlock,
  emit: (
    name: string,
    arg:
      | ContentTipBlockClickAnchorPayload
      | ContentTipBlockChangeMemoPayload
      | ContentTipBlockRemoveMemoPayload
      | ContentTipBlockOpenTabPayload
  ) => void
) {
  const markers = ref<Marker[]>([]);

  function getId(item: ContentTip) {
    if (item.type === 'memo') return item.memo.id;
    if (item.type === 'question') return item.question.id;
    return item.id;
  }

  function calculate() {
    if (props.hideSelection) {
      markers.value = [];
      return;
    }
    markers.value = props.tips
      .map((item) => {
        const { position, ...slotProps } = item;
        const { type } = position;
        if (type === 'problem-marker' || type === 'text-marker') {
          const { selection } = position;
          let name = props.selectionRootName;
          if (selection.position !== undefined) name += `-${selection.position}`;
          const [root] = document.getElementsByClassName(name);
          if (!root) return [];
          const { paddingLeft, paddingTop } = window.getComputedStyle(root);
          const offsetX = parseStyleSize(paddingLeft) + props.selectionOffsetX;
          const offsetY = parseStyleSize(paddingTop) + props.selectionOffsetY;
          const area = getAreaProps(root.closest('.overflow-y-auto'));
          return [
            {
              id: getId(item),
              slotProps,
              markerRects: getMarkerRects(
                root,
                selection,
                area.scrollLeft - area.areaLeft - offsetX,
                area.scrollTop - area.areaTop - offsetY
              ),
              markerColor: selection.color,
              disabled: props.disabled || item.type === 'temp',
            },
          ];
        }
        return [];
      })
      .flat();
  }
  onMounted(() => useTimeoutFn(calculate, 500));
  watch(
    () => props.hideSelection,
    (newVal) => {
      if (newVal) {
        markers.value = [];
        return;
      }
      useTimeoutFn(calculate, 300);
    }
  );
  watch(
    () => props.tips,
    () => nextTick(calculate)
  );
  watch(
    () => [props.selectionOffsetX, props.selectionOffsetY],
    () => {
      markers.value = [];
      useTimeoutFn(calculate, 300);
    }
  );

  const { width, height } = useWindowSize();
  watch(
    () => [width.value, height.value],
    () => {
      markers.value = [];
      useTimeoutFn(calculate, 300);
    }
  );

  const links = computed(() =>
    props.tips
      .map((item) => {
        if (item.type !== 'memo') return [];
        if (item.position.type === 'text-no-marker' || item.position.type === 'problem-no-marker') {
          const { memo, avatar, disabled } = item;
          return [{ memo, slotProps: { avatar, disabled } }];
        }
        return [];
      })
      .flat()
  );

  function clickAnchor(payload: ContentMemoCardClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function changeMemo(payload: ContentMemoCardSubmitPayload) {
    emit('change-memo', payload);
  }

  function removeMemo(payload: MemoMenusRemovePayload) {
    emit('remove-memo', payload);
  }

  function openMemoTab(memo: Memo) {
    emit('open-tab', { id: memo.id, type: 'memo' });
  }

  function openQuestion(payload: ContentQuestionCardOpenPayload) {
    emit('open-tab', { ...payload, type: 'question' });
  }

  const msgs = useMessages({ prefix: 'training.molecules.contentTipBlock' });
  return {
    markers,
    links,
    labelMemoTab: msgs.of('toList'),
    clickAnchor,
    changeMemo,
    removeMemo,
    openMemoTab,
    openQuestion,
  };
}
