
































import { defineComponent, SetupContext } from '@vue/composition-api';

import RefreshButton from '@/base/app/components/atoms/RefreshButton.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';

import ScheduleTagDialog from '../molecules/ScheduleTagDialog.vue';
import ScheduleTagTable from '../molecules/ScheduleTagTable.vue';
import { useScheduleTagsDialog } from './ScheduleTagsDialogComposable';

export default defineComponent({
  name: 'TrainingScheduleTagsDialog',
  components: {
    BaseDialogFullScreen,
    ScheduleTagDialog,
    ScheduleTagTable,
    BaseDialogConfirm,
    RefreshButton,
  },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useScheduleTagsDialog(emit);
  },
});
