import { computed, ref } from '@vue/composition-api';

import { BaseMarkdownMarkSelection } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { Optional } from '@/base/types';

import { MarkerControlTileMenusColor } from '../atoms/MarkerControlTileMenusComposable';

export type ContentMarkerText = {
  selection: BaseMarkdownMarkSelection;
};

export type ContentMarkerProblem = {
  selection: BaseMarkdownMarkSelection;
  problemIndex: number;
};

export type ContentMarkerControlId = string;
export type ContentMarkerControlColor = MarkerControlTileMenusColor;

export type ContentMarkerControl = {
  id: ContentMarkerControlId;
  type: 'memo' | 'question' | 'unknown';
  marker?: ContentMarkerText | ContentMarkerProblem;
};

export type PropsContentMarkerControl = ContentMarkerControl;

export function useContentMarkerControl(
  props: PropsContentMarkerControl,
  emit: (name: string, args: ContentMarkerControlColor | ContentMarkerControlId) => void
) {
  const win = ref('caption');
  function toggle() {
    if (win.value === 'caption') win.value = 'menus';
    else win.value = 'caption';
  }

  function changeColor(v: MarkerControlTileMenusColor) {
    emit('change-color', v);
  }

  function remove() {
    emit('remove', props.id);
    win.value = 'caption';
  }

  function move() {
    emit('move', props.id);
  }

  const color = computed<Optional<MarkerControlTileMenusColor>>(
    () => props.marker?.selection?.color
  );
  const caption = computed(() => props.marker?.selection?.caption);

  return { win, color, caption, toggle, changeColor, remove, move };
}
