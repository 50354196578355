











































































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ContentHeader from '@/base/app/components/molecules/ContentHeader.vue';

import { PropsCourseReviewWelcome, useCourseReviewWelcome } from './CourseReviewWelcomeComposable';

type Props = PropsCourseReviewWelcome;

export default defineComponent({
  name: 'TrainingCourseReviewWelcome',
  components: { BaseButton, BaseMarkdown, ContentHeader, ContentFooter },
  props: {
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
    courseName: { type: String, required: true },
    courseColor: { type: String, default: undefined },
    courseImage: { type: String, default: undefined },
    courseFontColorOnImage: { type: String, default: undefined },
    contentName: { type: String, required: true },
    problemCount: { type: Number, default: 0 },
    existsData: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
  },
  emits: ['start', 'setting'],
  setup(props: Props, { emit }: SetupContext) {
    return useCourseReviewWelcome(props, emit);
  },
});
