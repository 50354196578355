import { onMounted, ref, watch } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { assertIsDefined } from '@/utils/Asserts';

export type QuestionTitleChangePayload = {
  title: string;
  done: () => void;
};

export type PropsQuestionTitle = {
  title: string;
};

export function useQuestionTitle(
  props: PropsQuestionTitle,
  emit: (name: string, arg: QuestionTitleChangePayload) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.questionTitle' });

  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const loading = ref(false);
  const editing = ref(false);
  const input = ref<string>('');

  function init() {
    input.value = props.title;
  }
  onMounted(init);
  watch(() => props.title, init);

  function toggleEditing() {
    input.value = props.title;
    editing.value = !editing.value;
  }

  async function change() {
    assertIsDefined(observer.value);
    const valid = await observer.value.validate();
    if (!valid) return;
    if (props.title === input.value) {
      editing.value = false;
      return;
    }
    loading.value = true;
    assertIsDefined(input.value);
    emit('change', {
      title: input.value,
      done: () => {
        editing.value = false;
        loading.value = false;
      },
    });
  }

  return {
    observer,
    loading,
    editing,
    input,
    toggleEditing,
    change,
    labelEdit: msgs.of('edit'),
    labelCancel: msgs.of('cancel'),
    labelChange: msgs.of('change'),
    labelTitle: msgs.of('title'),
  };
}
