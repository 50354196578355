









































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import MarkerRects from '../atoms/MarkerRects.vue';
import MemoMenus from '../atoms/MemoMenus.vue';
import MemoTitleLink from '../atoms/MemoTitleLink.vue';
import ContentMemoCard from './ContentMemoCard.vue';
import ContentQuestionCard from './ContentQuestionCard.vue';
import { ContentTip, PropsContentTipBlock, useContentTipBlock } from './ContentTipBlockComposable';

type Props = PropsContentTipBlock;

export default defineComponent({
  name: 'TrainingContentTipBlock',
  components: { ContentMemoCard, ContentQuestionCard, MemoMenus, MarkerRects, MemoTitleLink },
  props: {
    tips: { type: Array as PropType<ContentTip[]>, default: () => [] },
    selectionRootName: { type: String, default: 'tip-block' },
    selectionOffsetX: { type: Number, default: 0 },
    selectionOffsetY: { type: Number, default: 0 },
    markerRelativeClassName: { type: String, default: undefined },
    nudgeLeft: { type: Number, default: 0 },
    nudgeTop: { type: Number, default: 0 },
    hideSelection: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  emits: ['change-memo', 'remove-memo', 'open-tab', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentTipBlock(props, emit);
  },
});
