import { nextTick } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { delayScroll } from '@/base/app/utils/DomUtils';
import { ProblemUtilsNavigatorValue } from '@/base/app/utils/ProblemUtils';
import { requiredInject } from '@/utils/VueUtils';

import { ReviewStoreKey } from '../../stores';

export function useCourseReviewAppHeaderExtension() {
  const { page, problemIndex, problemNavigator, problemController, move } =
    requiredInject(ReviewStoreKey);

  function back() {
    move('welcome');
  }

  function setting() {
    move('setting');
  }

  function changeIndex(v: ProblemUtilsNavigatorValue) {
    problemController.move(v);
    nextTick(() => delayScroll('top'));
  }

  function prev() {
    if (!problemNavigator.value || problemNavigator.value.prev.value === undefined) return;
    changeIndex(problemNavigator.value.prev.value);
  }

  function next() {
    if (!problemNavigator.value || problemNavigator.value.next.value === undefined) return;
    changeIndex(problemNavigator.value.next.value);
  }

  const msgs = useMessages({ prefix: 'training.organisms.courseReviewAppHeaderExtension' });
  return {
    page,
    problemIndex,
    problemNavigator,
    labelSetting: msgs.of('setting'),
    labelBack: msgs.of('back'),
    back,
    setting,
    changeIndex,
    prev,
    next,
  };
}
