






























































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ProblemNavigator from '@/base/app/components/atoms/ProblemNavigator.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ProblemView from '@/base/app/components/molecules/ProblemView.vue';
import { ProblemUtilsNavigator, ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';
import { GetContentContent } from '@/training/usecases';

import { PropsContentExam, useContentExam } from './ContentExamComposable';
import ContentTipBlock from './ContentTipBlock.vue';
import { ContentTip } from './ContentTipBlockComposable';
import ContentVersionWarning from './ContentVersionWarning.vue';

type Props = PropsContentExam;

export default defineComponent({
  name: 'TrainingContentExam',
  components: {
    BaseButton,
    ProblemNavigator,
    ProblemView,
    ContentVersionWarning,
    ContentTipBlock,
    ContentFooter,
  },
  props: {
    index: { type: [Number, String], default: 0 },
    groupId: { type: String, required: true },
    content: { type: Object as PropType<GetContentContent>, required: true },
    courseName: { type: String, required: true },
    problems: { type: Array as PropType<ProblemUtilsProblem[]>, default: () => [] },
    problemNavigator: { type: Object as PropType<ProblemUtilsNavigator>, default: undefined },
    tips: { type: Array as PropType<ContentTip[]>, default: () => [] },
    hideMarker: { type: Boolean, default: false },
    enableMarking: { type: Boolean, default: false },
    toLatest: { type: Object as PropType<Location>, default: undefined },
    showProminent: { type: Boolean, default: false },
    shrinkLimit: { type: Number, default: 16 },
    isMobile: { type: Boolean, default: false },
  },
  emits: [
    'change',
    'choice-value',
    'check-value',
    'clear-value',
    'complete',
    'click-anchor',
    'mark',
    'open-tab',
    'change-memo',
    'remove-memo',
  ],
  setup(props: Props, { emit }: SetupContext) {
    return useContentExam(props, emit);
  },
});
