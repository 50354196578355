





























import { defineComponent, SetupContext } from '@vue/composition-api';
import draggable from 'vuedraggable';

import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import Loading from '@/base/app/components/organisms/Loading.vue';

import CourseItem from '../molecules/CourseItem.vue';
import { useCourseListDialog } from './CourseListDialogComposable';

export default defineComponent({
  name: 'TrainingCourseListDialog',
  components: {
    BaseDialogFullScreen,
    CourseItem,
    Loading,
    draggable,
  },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useCourseListDialog(emit);
  },
});
