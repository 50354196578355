








































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import TextEditor from '@/base/app/components/molecules/TextEditor.vue';
import { Memo } from '@/base/domains';

import { PropsContentMemoCard, useContentMemoCard } from './ContentMemoCardComposable';

type Props = PropsContentMemoCard;

export default defineComponent({
  name: 'TrainingContentMemoCard',
  components: { BaseButton, BaseMarkdown, TextEditor, User },
  inheritAttrs: false,
  props: {
    memo: { type: Object as PropType<Memo>, required: true },
    avatar: { type: Object as PropType<UserAvatar>, required: true },
    maxWidth: { type: [Number, String], default: undefined },
    maxHeight: { type: Number, default: undefined },
    disabled: { type: Boolean, default: false },
  },
  emits: ['submit', 'click-anchor'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentMemoCard(props, emit);
  },
});
