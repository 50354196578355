



































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import DateTimeNow from '@/base/app/components/molecules/DateTimeNow.vue';
import TextEditor from '@/base/app/components/molecules/TextEditor.vue';
import MentionMarkdown from '@/base/app/components/organisms/MentionMarkdown.vue';
import { GlobalStoreGroup, GlobalStoreUser } from '@/base/app/store/types';
import { LocalDateTime, Optional } from '@/base/types';

import {
  PropsQuestionCommentChange,
  useQuestionCommentChange,
} from './QuestionCommentChangeComposable';

type Props = PropsQuestionCommentChange;

export default defineComponent({
  name: 'TrainingQuestionCommentChange',
  components: { User, BaseButton, DateTimeNow, TextEditor, MentionMarkdown },
  props: {
    id: { type: String, required: true },
    body: { type: String, required: true },
    createdAt: { type: Object as PropType<LocalDateTime>, default: undefined },
    createdBy: { type: Object as PropType<UserAvatar>, required: true },
    editedAt: { type: Object as PropType<LocalDateTime>, default: undefined },
    editedBy: { type: Object as PropType<UserAvatar>, default: undefined },
    disabled: { type: Boolean, default: false },
    user: { type: Object as PropType<UserAvatar>, required: true },
    priorityMentions: { type: Array as PropType<string[]>, default: undefined },
    group: { type: Object as PropType<GlobalStoreGroup>, required: true },
    findUser: {
      type: Function as PropType<(id: string) => Optional<GlobalStoreUser>>,
      required: true,
    },
  },
  emits: ['click-anchor', 'click-user', 'submit'],
  setup(props: Props, { emit }: SetupContext) {
    return useQuestionCommentChange(props, emit);
  },
});
