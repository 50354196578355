import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn, useWindowSize } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { ProblemResultsViewClickAnchorPayload } from '@/base/app/components/molecules/ProblemResultsViewComposable';
import {
  ProblemViewChoiceValue,
  ProblemViewClickAnchorPayload,
  ProblemViewMarkSelection,
} from '@/base/app/components/molecules/ProblemViewComposable';
import { getElementRect } from '@/base/app/utils/DomUtils';
import { MarkerSelection } from '@/base/app/utils/MarkerUtils';
import {
  ProblemUtilsNavigator,
  ProblemUtilsNavigatorValue,
  ProblemUtilsProblem,
} from '@/base/app/utils/ProblemUtils';
import { useVuetify } from '@/base/app/utils/VuetifyUtils';
import { Optional } from '@/base/types';
import { GetContentContent } from '@/training/usecases';

import {
  ContentTip,
  ContentTipBlockChangeMemoPayload,
  ContentTipBlockClickAnchorPayload,
  ContentTipBlockOpenTabPayload,
  ContentTipBlockRemoveMemoPayload,
} from './ContentTipBlockComposable';

const STYLE_EMPTY = {
  class: undefined as Optional<string>,
  style: undefined as Optional<Record<string, string>>,
};
const STYLES = {
  frame: STYLE_EMPTY,
  windows: STYLE_EMPTY,
  problem: { minHeight: '75vh' as Optional<string> },
  tip: { nudgeLeft: 0, nudgeTop: 0 },
  selection: { selectionOffsetX: 0, selectionOffsetY: 0 },
};
type Styles = typeof STYLES;

export type ContentExamIndex = number;

export type ContentExamCheckPayload = {
  index: ContentExamIndex;
};

export type ContentExamChoicePayload = {
  index: ContentExamIndex;
  values: number[];
};

export type ContentExamClearPayload = {
  index: ContentExamIndex;
};

export type ContentExamClickAnchorPayload =
  | ProblemViewClickAnchorPayload
  | ProblemResultsViewClickAnchorPayload
  | ContentTipBlockClickAnchorPayload;

export type ContentExamMarkPayload = Optional<{
  selection: MarkerSelection;
  problemIndex: number;
}>;

export type ContentExamOpenTabPayload = ContentTipBlockOpenTabPayload;

export type ContentExamChangeMemoPayload = ContentTipBlockChangeMemoPayload;

export type ContentExamRemoveMemoPayload = ContentTipBlockRemoveMemoPayload;

export type PropsContentExam = {
  index: ProblemUtilsNavigatorValue;
  content: GetContentContent;
  courseName: string;
  problems: ProblemUtilsProblem[];
  problemNavigator?: ProblemUtilsNavigator;
  tips: ContentTip[];
  hideMarker: boolean;
  showProminent?: boolean;
  shrinkLimit?: number;
  isMobile: boolean;
};

export function useContentExam(
  props: PropsContentExam,
  emit: (
    name: string,
    arg?:
      | ProblemUtilsNavigatorValue
      | ContentExamCheckPayload
      | ContentExamChoicePayload
      | ContentExamClearPayload
      | ContentExamClickAnchorPayload
      | ContentExamMarkPayload
      | ContentExamOpenTabPayload
      | ContentExamChangeMemoPayload
      | ContentExamRemoveMemoPayload
  ) => void
) {
  function choiceValue(v: ProblemViewChoiceValue) {
    if (props.index === 'results') return;
    emit('choice-value', { index: props.index, values: v });
  }

  function checkValue() {
    if (props.index === 'results') return;
    emit('check-value', { index: props.index });
  }

  function clearValue() {
    if (props.index === 'results') return;
    emit('clear-value', { index: props.index });
  }

  function change(payload: ProblemUtilsNavigatorValue) {
    emit('change', payload);
  }

  function prev() {
    if (!props.problemNavigator || props.problemNavigator.prev.value === undefined) return;
    emit('change', props.problemNavigator.prev.value);
  }

  function next() {
    if (!props.problemNavigator || props.problemNavigator.next.value === undefined) return;
    emit('change', props.problemNavigator.next.value);
  }

  function complete() {
    emit('complete');
  }

  function clickAnchor(payload: ProblemViewClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function mark(selection: ProblemViewMarkSelection) {
    if (props.index === 'results') return;
    if (selection) emit('mark', { problemIndex: props.index, selection });
    else emit('mark');
  }

  function openTab(payload: ContentTipBlockOpenTabPayload) {
    emit('open-tab', payload);
  }

  function changeMemo(payload: ContentTipBlockChangeMemoPayload) {
    emit('change-memo', payload);
  }

  function removeMemo(payload: ContentTipBlockRemoveMemoPayload) {
    emit('remove-memo', payload);
  }

  const items = computed(() =>
    props.problems.map((problem) => {
      const memos = props.tips.filter(
        (item) =>
          item.position.type === 'problem-no-marker' &&
          item.position.problemIndex === problem.index &&
          item.type === 'memo'
      );
      const markers = props.tips.filter(
        (item) =>
          item.position.type === 'problem-marker' && item.position.problemIndex === problem.index
      );
      return { problem, memos, markers };
    })
  );

  const progressValue = computed(() => {
    if (!props.problemNavigator) return undefined;
    const total = props.problemNavigator.items.filter((item) => item.value !== 'results').length;
    const count = props.problemNavigator.items.filter((item) => item.passed).length;
    return (count / total) * 100;
  });

  const { mobile } = useVuetify();
  const vWindow = ref<Vue>();
  const delayHideMarker = ref(false);
  const styles = ref<Styles>(STYLES);
  const baseMarkerClassName = 'base-content-problem';

  function calculateStyle() {
    if (!vWindow.value || props.hideMarker) return;
    const rectVWindow = vWindow.value.$el.getBoundingClientRect();
    const { left: vwLeft, top: vwTop } = rectVWindow;
    if (props.isMobile || props.index === 'results' || mobile.value) {
      styles.value = {
        ...STYLES,
        problem: { minHeight: 'calc(100vh - 48px)' },

        tip: {
          nudgeLeft: vwLeft * -1,
          nudgeTop: (vwTop + window.scrollY) * -1,
        },
        selection: {
          selectionOffsetX: vwLeft,
          selectionOffsetY: vwTop + (props.showProminent ? 0 : window.scrollY),
        },
      };
    } else {
      const rectMain = getElementRect('.content-exam');
      if (!rectMain) return;
      const rectBottom = getElementRect('.content-exam-bottom');
      styles.value = {
        ...STYLES,
        frame: { class: undefined, style: { maxHeight: `calc(100vh - ${rectMain.top + 8}px)` } },
        windows: { class: 'overflow-y-auto', style: undefined },
        problem: { minHeight: `calc(100vh - ${vwTop + (rectBottom?.height ?? 0) + 16}px)` },
      };
    }
    delayHideMarker.value = false;
  }
  onMounted(() => {
    delayHideMarker.value = true;
    useTimeoutFn(calculateStyle, 1000);
  });
  watch(
    () => [props.index, props.showProminent, props.hideMarker, mobile.value],
    () => {
      delayHideMarker.value = true;
      useTimeoutFn(calculateStyle, 500);
    }
  );
  watch(items, () => {
    delayHideMarker.value = true;
    useTimeoutFn(calculateStyle, 0);
  });

  const { width } = useWindowSize();
  watch(width, () => {
    delayHideMarker.value = true;
    useTimeoutFn(calculateStyle, 0);
  });

  const msgs = useMessages({ prefix: 'training.molecules.contentExam' });
  const iconComplete = computed(() =>
    props.content.learningStatus === 'completed'
      ? 'mdi-checkbox-marked-circle'
      : 'mdi-checkbox-marked-circle-outline'
  );

  return {
    items,
    progressValue,
    vWindow,
    delayHideMarker,
    styles,
    baseMarkerClassName,
    iconComplete,
    labelComplete: msgs.of('complete'),
    choiceValue,
    checkValue,
    clearValue,
    change,
    prev,
    next,
    complete,
    clickAnchor,
    mark,
    openTab,
    changeMemo,
    removeMemo,
  };
}
