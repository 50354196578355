

















































import { defineComponent, PropType } from '@vue/composition-api';

import BaseLabel from '@/base/app/components/atoms/BaseLabel.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ContentHeader from '@/base/app/components/molecules/ContentHeader.vue';

import { UserExamStoreExam } from '../../stores';
import { PropsUserExamFinished, useUserExamFinished } from './UserExamFinishedComposable';

type Props = PropsUserExamFinished;

export default defineComponent({
  name: 'TrainingUserExamFinished',
  components: { BaseLabel, BaseMarkdown, ContentHeader, ContentFooter },
  props: {
    exam: { type: Object as PropType<UserExamStoreExam>, required: true },
    groupId: { type: String, required: true },
    isMobile: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useUserExamFinished(props);
  },
});
