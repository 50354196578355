

















































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';

import DateTimeRangeForm from '../atoms/DateTimeRangeForm.vue';
import {
  PropsScheduleSearchFields,
  ScheduleSearchFieldsValue,
  useScheduleSearchFields,
} from './ScheduleSearchFieldsComposable';

export default defineComponent({
  name: 'TrainingScheduleSearchFields',
  components: { DateTimeRangeForm, BaseButton, BaseButtonIcon },
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<ScheduleSearchFieldsValue>, default: undefined },
    loading: { type: Boolean, default: false },
    hideSearchHistory: { type: Boolean, default: false },
  },
  emits: ['change'],
  setup(props: PropsScheduleSearchFields, { emit }: SetupContext) {
    return useScheduleSearchFields(props, emit);
  },
});
