












import { defineComponent, PropType } from '@vue/composition-api';

type CourseItemCourse = {
  courseId: string;
  courseName: string;
  index: number;
};

export default defineComponent({
  name: 'TrainingCourseItem',
  inheritAttrs: false,
  props: {
    course: { type: Object as PropType<CourseItemCourse>, required: true },
    width: { type: String, default: 'min(400px, 80vw)' },
  },
  setup() {
    return {};
  },
});
