import { computed, nextTick, onUnmounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { getMarkerBackground } from '@/base/app/utils/ColorUtils';
import { getAreaProps, getElement, toStyleSize } from '@/base/app/utils/DomUtils';
import { MarkerRect, toStyle } from '@/base/app/utils/MarkerUtils';

export type RectIndex = number;

export type MarkerRectsShowPayload = {
  id: string;
  index: RectIndex;
};

export type MarkerRectsHidePayload = {
  id: string;
};

export type PropsMarkerRects = {
  id: string;
  markerRects: MarkerRect[];
  markerColor?: string;
  relativeClassName: string;
  nudgeLeft: number;
  nudgeTop: number;
  disabled: boolean;
};

export function useMarkerRects(
  props: PropsMarkerRects,
  emit: (name: string, arg: MarkerRectsShowPayload | MarkerRectsHidePayload) => void
) {
  const attrs = computed(() => {
    const className = `training-marker-rect--${props.disabled ? 'disabled' : 'active'}`;
    const background = getMarkerBackground(props.markerColor);
    return props.markerRects.map((rect) => ({
      ...toStyle(rect, { background }),
      class: className,
    }));
  });

  const slotDisplayIndex = ref<RectIndex>();
  const slotAttrs = ref<Record<string, unknown>>();

  function tryClose(e?: Event) {
    if (e) {
      const slotEl = (e.target as Element).closest('.training-marker-slot');
      if (slotEl) return;
    }
    slotDisplayIndex.value = undefined;
    slotAttrs.value = undefined;
    document.removeEventListener('click', tryClose);
  }

  function closeSlot() {
    tryClose();
  }

  function calculateSlot() {
    if (props.markerRects.length === 0 || slotDisplayIndex.value === undefined) {
      closeSlot();
      return;
    }
    const markerRect = props.markerRects[slotDisplayIndex.value];
    if (!markerRect) {
      closeSlot();
      return;
    }
    const slotEl = getElement(`${props.id}-slot`, true);
    if (!slotEl) {
      closeSlot();
      return;
    }
    const slotRect = getAreaProps(slotEl);
    if (!slotRect) {
      closeSlot();
      return;
    }

    const relativeRect = getAreaProps(slotEl.closest(props.relativeClassName));
    const scrollRect = getAreaProps(slotEl.closest('.overflow-y-auto'));

    let top = markerRect.top + markerRect.height + props.nudgeTop;
    const bottom = top + slotRect.areaHeight;
    if (scrollRect.type === 'window') {
      if (bottom > scrollRect.areaHeight - relativeRect.areaTop || bottom > relativeRect.areaHeight)
        top = markerRect.top - slotRect.areaHeight - props.nudgeTop;
    } else if (scrollRect.type === 'element') {
      if (bottom > scrollRect.areaHeight + scrollRect.scrollTop)
        top = markerRect.top - slotRect.areaHeight - props.nudgeTop;
    }
    if (top <= 0) top = Math.abs(props.nudgeTop);

    let left = markerRect.left + props.nudgeLeft;
    const diff = left + slotRect.areaWidth - relativeRect.areaWidth;
    if (diff > 0) left -= diff - props.nudgeLeft;
    if (left <= 0) left = Math.abs(props.nudgeLeft);

    slotAttrs.value = { style: { top: toStyleSize(top), left: toStyleSize(left) } };
  }
  watch(slotDisplayIndex, (newVal) => {
    if (newVal === undefined) return;
    document.addEventListener('click', tryClose);
    nextTick(calculateSlot);
  });

  const slotDisplay = computed(() => slotDisplayIndex.value !== undefined);
  watch(slotDisplay, (newVal) => {
    if (newVal) emit('show', { id: props.id, index: slotDisplayIndex.value });
    else emit('hide', { id: props.id });
  });

  function click(e: Event, i: RectIndex) {
    if (slotDisplay.value && slotDisplayIndex.value === i) {
      closeSlot();
      return;
    }
    useTimeoutFn(() => {
      slotDisplayIndex.value = i;
    }, 10);
  }

  onUnmounted(() => {
    document.removeEventListener('click', tryClose);
  });

  return { attrs, slotDisplay, slotAttrs, click, close: closeSlot };
}
