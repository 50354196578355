





















import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ErrorMessages from '@/base/app/components/molecules/ErrorMessages.vue';

import { useScheduleTagDialog } from './ScheduleTagDialogComposable';
import ScheduleTagForm from './ScheduleTagForm.vue';

export default defineComponent({
  name: 'TrainingScheduleTagDialog',
  components: { BaseButton, ScheduleTagForm, ErrorMessages },
  inheritAttrs: false,
  emits: ['submit'],
  setup(_, { emit }: SetupContext) {
    return useScheduleTagDialog(emit);
  },
});
