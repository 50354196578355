

















import { defineComponent } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';

import { useCourseReviewAppHeaderProminent } from './CourseReviewAppHeaderProminentComposable';

export default defineComponent({
  name: 'TrainingCourseReviewAppHeaderProminent',
  components: { BaseLink },
  props: {
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
  },
  setup() {
    return useCourseReviewAppHeaderProminent();
  },
});
