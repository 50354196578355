













































































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseRadioGroup from '@/base/app/components/atoms/BaseRadioGroup.vue';
import BaseText from '@/base/app/components/atoms/BaseText.vue';
import BaseTextDateTime from '@/base/app/components/atoms/BaseTextDateTime.vue';
import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';

import {
  GroupExamOpenFormValue,
  PropsGroupExamOpenForm,
  useGroupExamOpenForm,
} from './GroupExamOpenFormComposable';

type Props = PropsGroupExamOpenForm;

export default defineComponent({
  name: 'TrainingGroupExamOpenForm',
  components: { BaseRadioGroup, BaseText, BaseTextDateTime, User, HintIcon },
  model: { prop: 'value', event: 'submit' },
  props: {
    value: { type: Object as PropType<GroupExamOpenFormValue>, required: true },
    problemCount: { type: Number, required: true },
    userIds: { type: Array as PropType<string[]>, default: () => [] },
    trainees: { type: Array as PropType<UserAvatar[]>, default: () => [] },
  },
  emits: ['submit', 'change-all-trainees'],
  setup(props: Props, { emit }: SetupContext) {
    return useGroupExamOpenForm(props, emit);
  },
});
