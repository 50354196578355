











import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import { LocalDateTime } from '@/base/types';

import BaseText from './BaseText.vue';
import { PropsBaseTextDateTime, useBaseTextDateTime } from './BaseTextDateTimeComposable';

type Props = PropsBaseTextDateTime;

export default defineComponent({
  name: 'BaseBaseTextDateTime',
  components: { BaseText },
  inheritAttrs: false,
  model: { prop: 'value', event: 'change' },
  props: {
    value: { type: Object as PropType<LocalDateTime>, default: undefined },
    start: { type: Object as PropType<LocalDateTime>, default: undefined },
    startLabel: { type: String, default: undefined },
    required: { type: Boolean, default: false },
    laterThanNow: { type: Boolean, default: false },
  },
  emits: ['change'],
  setup(props: Props, { emit }: SetupContext) {
    return useBaseTextDateTime(props, emit);
  },
});
