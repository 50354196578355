import { computed, onMounted, Ref, ref, watch } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { DialogAnchorConfirm } from '@/base/app/components/organisms/DialogAnchorConfirmComposable';
import { DialogName, useDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { delayScroll, escapeId } from '@/base/app/utils/DomUtils';
import { Schedule, ScheduleTag } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { isFailed, useGetScheduleTags } from '@/base/usecases';
import { useGetSchedules, useRemoveSchedule, useUpdateSchedule } from '@/training/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { ScheduleMenusActionPayload } from '../atoms/ScheduleMenusComposable';
import { ScheduleTagSelectorChangePayload } from '../atoms/ScheduleTagSelectorComposable';
import { ScheduleCardActionPayload } from '../molecules/ScheduleCardComposable';
import { ScheduleSearchFieldsValue } from '../molecules/ScheduleSearchFieldsComposable';
import { ScheduleDialog, ScheduleDialogClickAnchorPayload } from './ScheduleDialogComposable';
import { ScheduleTagsDialog } from './ScheduleTagsDialogComposable';

type ScheduleDialogQuery = {
  id: string;
  mode: 'edit' | 'copy' | 'refer';
};

type ScheduleToken = {
  nextToken: string;
  tagId?: string;
} & ({ recently: boolean } | { from: LocalDateTime; to: LocalDateTime });

export type PropsSchedules = {
  id: string;
  disabled: boolean;
  disabledTags: boolean;
  topHeight: number;
};

function useAnchorDialog() {
  const anchorDialog = ref<DialogAnchorConfirm>();
  function clickAnchor(payload: ScheduleDialogClickAnchorPayload) {
    assertIsDefined(anchorDialog.value);
    anchorDialog.value.confirm(payload.event);
  }
  return { anchorDialog, clickAnchor };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function confirm(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, confirm };
}

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

function useScheduleDialog(props: PropsSchedules, schedules: Ref<Schedule[]>) {
  const scheduleDialog = ref<ScheduleDialog>();
  const { getQuery, moveTo } = useDialogQuery(DialogName.TRAINING_SCHEDULE);
  function moveToSchedule(params: ScheduleDialogQuery) {
    moveTo(params);
  }
  function watchScheduleDialogQuery() {
    if (!scheduleDialog.value) return false;
    const q = getQuery();
    if (q && q.params && 'id' in q.params && 'mode' in q.params) {
      const { id, mode } = q.params;
      const schedule = schedules.value.find((s) => s.id === id);
      if (schedule) {
        scheduleDialog.value.open(
          { groupId: props.id, schedule },
          mode === 'copy' && !props.disabled,
          (mode !== 'edit' && mode !== 'copy') || props.disabled
        );
        return true;
      }
    } else if (q && !props.disabled) {
      scheduleDialog.value.open({ groupId: props.id });
      return true;
    } else if (!q && scheduleDialog.value.opened()) {
      scheduleDialog.value.close();
    }
    return false;
  }
  return { scheduleDialog, moveToSchedule, watchScheduleDialogQuery };
}

function useTagsDialog(props: PropsSchedules) {
  const tagsDialog = ref<ScheduleTagsDialog>();
  const { getQuery, moveTo } = useDialogQuery(DialogName.TRAINING_SCHEDULE_TAGS);
  function moveToTags() {
    moveTo();
  }
  function watchTagsDialogQuery() {
    if (!tagsDialog.value) return false;
    const q = getQuery();
    if (q) {
      tagsDialog.value.open({ groupId: props.id }, props.disabledTags);
      return true;
    }
    if (!q && tagsDialog.value.opened()) {
      tagsDialog.value.close();
    }
    return false;
  }
  return { tagsDialog, moveToTags, watchTagsDialogQuery };
}

export function useSchedules(props: PropsSchedules) {
  const msgs = useMessages({ prefix: 'training.organisms.schedules' });
  const route = useRoute();
  const router = useRouter();

  const schedules = ref<Schedule[]>([]);
  const tags = ref<ScheduleTag[]>([]);
  const tagId = ref<string>();
  const condition = ref<ScheduleSearchFieldsValue>();
  const token = ref<ScheduleToken>();
  const loading = ref(false);

  const { tagsDialog, moveToTags, watchTagsDialogQuery } = useTagsDialog(props);
  const { scheduleDialog, moveToSchedule, watchScheduleDialogQuery } = useScheduleDialog(
    props,
    schedules
  );
  const { confirmDialog, confirm } = useConfirmDialog();
  const { errorDialog, error } = useErrorDialog();

  const getTags = useGetScheduleTags();
  async function fetchTags() {
    const res = await getTags.execute({ groupId: props.id });
    if (isFailed(res)) {
      tags.value = [];
      tagId.value = undefined;
      return;
    }
    tags.value = res.scheduleTags;
    if (tagId.value && tags.value.every((t) => t.id !== tagId.value)) {
      tagId.value = undefined;
    }
  }

  const getSchedules = useGetSchedules();
  async function fetchRecently(tId?: string, nextToken?: string) {
    const res = await getSchedules.execute({
      groupId: props.id,
      recently: true,
      tagId: tId,
      nextToken,
    });
    if (isFailed(res)) {
      schedules.value = [];
      token.value = undefined;
      return;
    }
    if (nextToken) {
      schedules.value = [...schedules.value, ...res.schedules];
    } else {
      schedules.value = res.schedules;
    }
    if (res.nextToken) {
      token.value = { nextToken: res.nextToken, tagId: tId, recently: true };
    } else {
      token.value = undefined;
    }
  }

  async function fetchByStart(
    from: LocalDateTime,
    to: LocalDateTime,
    tId?: string,
    nextToken?: string
  ) {
    const res = await getSchedules.execute({
      groupId: props.id,
      startFrom: from,
      startTo: to,
      tagId: tId,
      nextToken,
    });
    if (isFailed(res)) {
      schedules.value = [];
      token.value = undefined;
      return;
    }
    if (nextToken) {
      schedules.value = [...schedules.value, ...res.schedules];
    } else {
      schedules.value = res.schedules;
    }
    if (res.nextToken) {
      token.value = { nextToken: res.nextToken, tagId: tId, from, to };
    } else {
      token.value = undefined;
    }
  }

  async function search() {
    loading.value = true;
    if (condition.value) {
      await fetchByStart(condition.value.from, condition.value.to, tagId.value);
    } else {
      await fetchRecently(tagId.value);
    }
    loading.value = false;
  }

  async function searchMore() {
    if (!token.value) return;
    loading.value = true;
    if ('recently' in token.value) {
      await fetchRecently(token.value.tagId, token.value.nextToken);
    } else {
      await fetchByStart(
        token.value.from,
        token.value.to,
        token.value.tagId,
        token.value.nextToken
      );
    }
    loading.value = false;
  }

  async function fetch() {
    loading.value = true;
    await fetchTags();
    await search();
    loading.value = false;
  }

  function tryScroll() {
    if (!route.hash) return;
    delayScroll(escapeId(route.hash));
    router.replace({ hash: undefined });
  }

  function initDialog() {
    if (!watchScheduleDialogQuery() && !watchTagsDialogQuery()) {
      tryScroll();
    }
  }

  async function init() {
    await fetch();
    initDialog();
    tryScroll();
  }
  onMounted(init);
  watch(() => props.id, init);
  watch(() => route.query, initDialog);

  function show(payload: ScheduleCardActionPayload) {
    moveToSchedule({ ...payload, mode: 'refer' });
  }

  function edit(payload: ScheduleMenusActionPayload) {
    moveToSchedule({ ...payload, mode: 'edit' });
  }

  function copy(payload: ScheduleMenusActionPayload) {
    moveToSchedule({ ...payload, mode: 'copy' });
  }

  const updateSchedule = useUpdateSchedule();
  async function changeTags(payload: ScheduleTagSelectorChangePayload) {
    const { id, tagIds } = payload;
    const original = schedules.value.find((item) => item.id === id);
    if (!original) return;
    const res = await updateSchedule.execute({
      id,
      start: original.start,
      end: original.end ?? original.start.clone().add(15, 'minutes'),
      title: original.title,
      body: original.body,
      tagIds,
    });
    if (isFailed(res)) {
      error(res.errors);
      payload.done(true);
      return;
    }
    payload.done(false);
    const items = schedules.value.filter((item) => item.id !== id);
    items.push(res.schedule);
    items.sort((a, b) => (a.start.isBefore(b.start) ? -1 : 1));
    schedules.value = items;
  }

  const removeSchedule = useRemoveSchedule();
  async function remove(id: string) {
    loading.value = true;
    await removeSchedule.execute({ id });
    fetch();
  }

  function confirmRemove(payload: ScheduleMenusActionPayload) {
    confirm(msgs.of('confirmRemove').value, () => remove(payload.id));
  }

  const displaySearchMore = computed(() => !!token.value);

  return {
    tagsDialog,
    scheduleDialog,
    confirmDialog,
    errorDialog,
    loading,
    schedules,
    tags,
    tagId,
    condition,
    displaySearchMore,
    labelCreate: msgs.of('create'),
    labelTag: msgs.of('tag'),
    labelTagSetting: msgs.of('tagSetting'),
    labelRefresh: msgs.of('refresh'),
    labelMore: msgs.of('more'),
    labelNoData: msgs.of('noData'),
    fetch,
    search,
    searchMore,
    show,
    edit,
    copy,
    openTags: moveToTags,
    create: moveToSchedule,
    changeTags,
    remove: confirmRemove,
    ...useAnchorDialog(),
  };
}
