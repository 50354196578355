import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { Schedule } from '@/base/domains';

export type ScheduleCardActionPayload = { id: string };

export type PropsScheduleCard = {
  schedule: Schedule;
};

export function useScheduleCard(
  props: PropsScheduleCard,
  emit: (name: string, args: ScheduleCardActionPayload) => void
) {
  const month = computed(() => props.schedule.start.format('MMM'));
  const day = computed(() => props.schedule.start.format('D'));
  const w = computed(() => props.schedule.start.format('[(]ddd[)]'));
  const startDate = computed(() => props.schedule.start.format('ll'));
  const startTime = computed(() => props.schedule.start.format('HH:mm'));
  const endTime = computed(() => props.schedule.end?.format('HH:mm'));

  function show() {
    emit('show', { id: props.schedule.id });
  }

  const msgs = useMessages({ prefix: 'training.molecules.scheduleCard' });
  return { month, day, w, startDate, startTime, endTime, labelDetail: msgs.of('detail'), show };
}
