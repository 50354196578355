




























































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseLabel from '@/base/app/components/atoms/BaseLabel.vue';
import User from '@/base/app/components/atoms/User.vue';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { LocalDateTime } from '@/base/types';
import { QuestionWithNames } from '@/base/usecases';

import BaseLink from '../atoms/BaseLink.vue';
import DateTimeNow from './DateTimeNow.vue';
import { PropsQuestionBar, useQuestionBar } from './QuestionBarComposable';

type Props = PropsQuestionBar;

export default defineComponent({
  name: 'TrainingQuestionBar',
  components: { User, BaseLabel, DateTimeNow, BaseLink },
  props: {
    question: { type: Object as PropType<QuestionWithNames>, required: true },
    createdBy: { type: Object as PropType<UserAvatar>, required: true },
    resolvedBy: { type: Object as PropType<UserAvatar>, default: undefined },
    updatedAt: { type: Object as PropType<LocalDateTime>, required: true },
    assignees: { type: Array as PropType<UserAvatar[]>, default: () => [] },
    disabledUser: { type: Boolean, default: false },
    hover: { type: Boolean, default: false },
    omit: { type: Boolean, default: false },
  },
  emits: ['click-user', 'select'],
  setup(props: Props, { emit }: SetupContext) {
    return useQuestionBar(props, emit);
  },
});
