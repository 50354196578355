



















import { defineComponent, PropType } from '@vue/composition-api';

import BaseButtonIcon from '@/base/app/components/atoms/BaseButtonIcon.vue';
import { GroupRole } from '@/base/domains';

import ContentCreateMenus from '../atoms/ContentCreateMenus.vue';
import { PropsContentAppHeaderEnd, useContentAppHeaderEnd } from './ContentAppHeaderEndComposable';

type Props = PropsContentAppHeaderEnd;

export default defineComponent({
  name: 'TrainingContentAppHeaderEnd',
  components: { BaseButtonIcon, ContentCreateMenus },
  props: {
    groupId: { type: String, required: true },
    groupRole: { type: String as PropType<GroupRole>, required: true },
    questionAvailable: { type: Boolean, default: false },
  },
  setup(props: Props) {
    return useContentAppHeaderEnd(props);
  },
});
