import { computed, onMounted, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app/Messages';
import { ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

import {
  ProblemListItemChangePayload,
  ProblemListItemClickAnchorPayload,
} from './ProblemListItemComposable';

const MENUS = ['selectAll', 'releaseAll', 'resetSelection'] as const;
type Menu = typeof MENUS[number];

export type ProblemResultsViewClickAnchorPayload = ProblemListItemClickAnchorPayload;

export type ProblemResultsViewValue = number[];

export type PropsProblemResultsView = {
  value: ProblemResultsViewValue;
  contentName: string;
  courseName: string;
  problems: ProblemUtilsProblem[];
  enableResetSelection: boolean;
};

export function useProblemResultsView(
  props: PropsProblemResultsView,
  emit: (name: string, arg?: ProblemResultsViewValue | ProblemResultsViewClickAnchorPayload) => void
) {
  let initialValue: number[] = [];
  function init() {
    initialValue = [...props.value];
  }
  onMounted(init);

  function change(payload: ProblemListItemChangePayload) {
    const v = props.value.filter((index) => index !== payload.index);
    if (payload.value) v.push(payload.index);
    emit('change', v);
  }

  const menu = ref(false);
  const boxIcon = computed(() => {
    const count = props.value.length;
    if (count === 0) return 'mdi-checkbox-blank-outline';
    if (props.problems.length === count) return 'mdi-checkbox-marked';
    return 'mdi-minus-box';
  });

  function choiceMenu(v: Menu) {
    switch (v) {
      case 'selectAll':
        emit(
          'change',
          props.problems.map((item) => item.index)
        );
        break;
      case 'releaseAll':
        emit('change', []);
        break;
      case 'resetSelection':
        emit('change', initialValue);
        break;
      default:
    }
  }

  function complete() {
    emit('complete');
  }

  function clickAnchor(payload: ProblemListItemClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const items = computed(() =>
    props.problems.map((item) => ({ problem: item, value: props.value.includes(item.index) }))
  );

  const msgs = useMessages({ prefix: 'base.molecules.problemResultsView' });
  const menus = computed(() =>
    MENUS.filter((v) => props.enableResetSelection || v !== 'resetSelection').map((v) => ({
      value: v,
      text: msgs.of(v).value,
    }))
  );

  return {
    items,
    boxIcon,
    menu,
    menus,
    labelReviewLater: msgs.of('reviewLater'),
    hintReviewLater: msgs.of('hintOfReviewLater'),
    labelNoData: msgs.of('noData'),
    change,
    choiceMenu,
    complete,
    clickAnchor,
  };
}
