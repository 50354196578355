



































































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseLabel from '@/base/app/components/atoms/BaseLabel.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ContentHeader from '@/base/app/components/molecules/ContentHeader.vue';

import { UserExamStoreExam } from '../../stores';
import { PropsUserExamNotStarted, useUserExamNotStarted } from './UserExamNotStartedComposable';

type Props = PropsUserExamNotStarted;

export default defineComponent({
  name: 'TrainingUserExamNotStarted',
  components: { BaseButton, BaseLabel, BaseMarkdown, ContentHeader, ContentFooter },
  props: {
    exam: { type: Object as PropType<UserExamStoreExam>, required: true },
    groupId: { type: String, required: true },
    existsOtherActive: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    scheduled: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
  },
  emits: ['start'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserExamNotStarted(props, emit);
  },
});
