import { onMounted, ref, watch } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { ProblemResultsViewClickAnchorPayload } from '@/base/app/components/molecules/ProblemResultsViewComposable';
import { getElementRect } from '@/base/app/utils/DomUtils';
import { Optional } from '@/base/types';

const STYLE_EMPTY = {
  class: undefined as Optional<string>,
  style: undefined as Optional<Record<string, string>>,
};
const STYLES = {
  frame: STYLE_EMPTY,
  problem: { minHeight: '75vh' as Optional<string> },
};
type Styles = typeof STYLES;

type CourseReviewSettingIndex = number;

export type CourseReviewSettingUpdatePayload = {
  indexes: CourseReviewSettingIndex[];
  done: () => void;
};

export type CourseReviewSettingClickAnchorPayload = ProblemResultsViewClickAnchorPayload;

export type PropsCourseReviewSetting = {
  showProminent: boolean;
  isMobile: boolean;
};

export function useCourseReviewSetting(
  props: PropsCourseReviewSetting,
  emit: (
    name: string,
    arg?: CourseReviewSettingUpdatePayload | CourseReviewSettingClickAnchorPayload
  ) => void
) {
  const removeIndexes = ref<CourseReviewSettingIndex[]>([]);
  const loading = ref(false);
  function init() {
    removeIndexes.value = [];
    loading.value = false;
  }

  function done() {
    loading.value = false;
  }

  function update() {
    loading.value = true;
    emit('update', { indexes: removeIndexes.value, done });
  }

  function back() {
    emit('back');
  }

  function clickAnchor(payload: ProblemResultsViewClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const styles = ref<Styles>(STYLES);

  function calculateStyle() {
    const rect = getElementRect('.course-review-setting-result');
    if (!rect) return;
    if (props.isMobile) {
      styles.value = {
        ...STYLES,
        frame: { class: 'pt-16', style: undefined },
        problem: { minHeight: 'calc(100vh - 48px)' },
      };
    } else {
      const { top: vwTop } = rect;
      const rectBottom = getElementRect('.content-review-setting-bottom');
      const minHeight = `calc(100vh - ${vwTop + (rectBottom?.height ?? 0) + 16}px)`;
      styles.value = { ...STYLES, problem: { minHeight } };
    }
  }
  onMounted(() => {
    init();
    useTimeoutFn(calculateStyle, 1000);
  });
  watch(
    () => [props.showProminent],
    () => useTimeoutFn(calculateStyle, 500)
  );

  const msgs = useMessages({ prefix: 'training.molecules.courseReviewSetting' });
  return {
    removeIndexes,
    loading,
    styles,
    labelUpdate: msgs.of('update'),
    update,
    back,
    clickAnchor,
  };
}
