import { computed } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { requiredInject } from '@/utils/VueUtils';

import { ReviewStoreKey } from '../../stores';

export function useCourseReviewAppHeaderProminent() {
  const msgs = useMessages({ prefix: 'training.organisms.courseReviewAppHeaderProminent' });
  const { course, reviewName, problems } = requiredInject(ReviewStoreKey);

  const count = computed(() => {
    if (!problems.value) return undefined;
    return msgs.of('problems', { size: problems.value?.length ?? 0 }).value;
  });

  return { course, count, name: reviewName };
}
