





















import { defineComponent } from '@vue/composition-api';

import BaseLink from '@/base/app/components/atoms/BaseLink.vue';

import { useContentAppHeaderProminent } from './ContentAppHeaderProminentComposable';

export default defineComponent({
  name: 'TrainingContentAppHeaderProminent',
  components: { BaseLink },
  props: {
    groupId: { type: String, required: true },
  },
  setup() {
    return useContentAppHeaderProminent();
  },
});
