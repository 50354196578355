import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { BaseMarkdownClickAnchorPayload } from '@/base/app/components/atoms/BaseMarkdownComposable';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import { TextEditorClickAnchorPayload } from '@/base/app/components/molecules/TextEditorComposable';
import { toStyleSize } from '@/base/app/utils/DomUtils';
import { Memo } from '@/base/domains';

export type ContentMemoCardSubmitPayload = {
  id: string;
  body: string;
  done: () => void;
};

export type ContentMemoCardClickAnchorPayload =
  | BaseMarkdownClickAnchorPayload
  | TextEditorClickAnchorPayload;

export type PropsContentMemoCard = {
  memo: Memo;
  avatar: UserAvatar;
  maxWidth?: number | string;
  maxHeight?: number;
};

export function useContentMemoCard(
  props: PropsContentMemoCard,
  emit: (
    name: string,
    args: ContentMemoCardSubmitPayload | ContentMemoCardClickAnchorPayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.contentMemoCard' });

  const chips = computed(() => {
    const { type } = props.memo.scope;
    const ret: { label: string; props?: Record<string, string | boolean> }[] = [
      { label: msgs.of(type).value },
    ];
    if (type === 'group') {
      const { roles } = props.memo.scope;
      if (roles.length > 0) {
        ret.push({
          label: msgs.of('roles', { roles: roles.map((r) => msgs.of(r).value).join() }).value,
          props: { color: 'primary' },
        });
      } else {
        ret.push({ label: msgs.of('noRoles').value, props: { color: 'primary', outlined: true } });
      }
    }
    return ret;
  });

  const edit = ref(false);
  const updating = ref(false);
  const input = ref<string>();

  function toggle() {
    edit.value = !edit.value;
    if (edit.value) input.value = props.memo.body;
  }

  function done() {
    updating.value = false;
    edit.value = false;
  }

  function submit() {
    updating.value = true;
    emit('submit', { id: props.memo.id, body: input.value ?? '', done });
  }

  function clickAnchor(payload: BaseMarkdownClickAnchorPayload | TextEditorClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const sheetStyle = computed(() => {
    if (!props.maxWidth) return undefined;
    const w = toStyleSize(props.maxWidth);
    return { width: w, 'max-width': w };
  });
  const bodyStyle = computed(() => {
    if (!props.maxHeight) return undefined;
    const padding = edit.value ? 8 : 0;
    const h = toStyleSize(props.maxHeight - padding);
    return { 'max-height': h };
  });
  const buttonSize = computed(() => {
    if (!props.maxHeight) return { update: {}, other: { small: true } };
    return { update: { small: true }, other: { 'x-small': true } };
  });

  return {
    chips,
    edit,
    updating,
    input,
    sheetStyle,
    bodyStyle,
    buttonSize,
    labelEdit: msgs.of('edit'),
    labelUpdate: msgs.of('update'),
    labelCancel: msgs.of('cancel'),
    toggle,
    submit,
    clickAnchor,
  };
}
