












import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseTable from '@/base/app/components/molecules/BaseTable.vue';

import { PropsUserExamTable, UserExamTableItem, useUserExamTable } from './UserExamTableComposable';

type Props = PropsUserExamTable;

export default defineComponent({
  name: 'TrainingUserExamTable',
  components: { BaseTable },
  inheritAttrs: false,
  props: {
    userExams: { type: Array as PropType<UserExamTableItem[]>, default: () => [] },
    headerKeys: {
      type: Array as PropType<string[]>,
      default: () => ['name', 'statusName', 'passFail', 'scorePer', 'startedAt', 'finishedAt'],
    },
  },
  emits: ['action'],
  setup(props: Props, { emit }: SetupContext) {
    return useUserExamTable(props, emit);
  },
});
