import { computed, ref } from '@vue/composition-api';
import { useTimeoutFn } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { useGlobalStore } from '@/base/app/store';
import { FuncSaveScrollPositionPageChanged } from '@/base/app/utils/DomUtils';
import {
  MarkerProblem,
  MarkerText,
  TipPosition,
  toTipPosition,
} from '@/base/app/utils/MarkerUtils';
import { useProblemController } from '@/base/app/utils/ProblemUtils';
import { GroupId, Memo, MemoContentReference, Problem } from '@/base/domains';
import { Optional } from '@/base/types';
import { isSucceeded, QuestionContentReferenceWithNames, QuestionWithNames } from '@/base/usecases';
import { config } from '@/config';
import {
  ChangeScopeRolesOfMemoRequest,
  CreateMemoRequest,
  GetContentContent,
  GetContentContentWorkbook,
  GetContentCourse,
  GetContentRequest,
  GetContentResponse,
  RemoveMemoRequest,
  UpdateMemoBodyRequest,
  useChangeScopeRolesOfMemo,
  useCreateMemo,
  useExitContent,
  useFinishContent,
  useGetContent,
  useGetQuestion,
  useOpenContent,
  useRemoveMemo,
  useUpdateMemoBody,
} from '@/training/usecases';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

import { QuestionCondition } from '../utils/FilterUtils';

type Page = {
  value: string;
  label: string;
  icon: string;
  clearable?: boolean;
  loading?: boolean;
};

type ContentQuestion = {
  question: QuestionWithNames;
  position: TipPosition;
  disabledVersion: boolean;
};

type ContentMemo = {
  memo: Memo;
  position: TipPosition;
  disabled: boolean;
  disabledVersion: boolean;
};

type ContentMarkerTempType = 'memo' | 'question' | 'unknown';

type Marker = MarkerText | MarkerProblem;

type ContentMarkerTemp = {
  type: ContentMarkerTempType;
  marker?: Marker;
};

type QuestionListAttrs = {
  groupId: string;
  pending: boolean;
  loading: boolean;
  x?: number;
  w?: number;
};

type DialogAttrs =
  | { name: 'contentQuestion' }
  | { name: 'contentMemo'; groupId: string; scopeType: 'private' | 'group' };

type MarkedContentFinish =
  | boolean
  | {
      review: { indexes: number[] };
    };

export function useContentStore() {
  const msgs = useMessages({ prefix: 'training.stores.contentStore' });
  const { user, groupRole, checkLimitation } = useGlobalStore();
  const adminMode = ref(false);

  const loading = ref(false);
  const groupId = ref<GroupId>();
  const content = ref<GetContentContent>();
  const course = ref<GetContentCourse>();
  const notFound = computed(() => !content.value || !course.value);
  const courseName = computed(() => course.value?.name);

  function getEnableMarkers(
    memos: ContentMemo[],
    questions: ContentQuestion[],
    option: { isProblemMarker?: boolean }
  ) {
    const m = memos
      .map((item) => {
        const { disabledVersion, ...x } = item;
        if (disabledVersion) return [];
        if (option.isProblemMarker === true && x.position.type !== 'problem-marker') return [];
        return [x];
      })
      .flat();
    const q = questions
      .map((item) => {
        const { disabledVersion, ...x } = item;
        if (disabledVersion) return [];
        if (option.isProblemMarker === true && x.position.type !== 'problem-marker') return [];
        return [x];
      })
      .flat();
    return [...m, ...q];
  }

  function isDisabledVersion(referTo?: MemoContentReference | QuestionContentReferenceWithNames) {
    if (!content.value) return true;
    const { id: contentId, version: contentVersion } = content.value;
    return !(
      referTo &&
      referTo.contentId === contentId &&
      referTo.contentVersion === contentVersion
    );
  }

  function isDisabledMemo(memo: Memo) {
    if (adminMode.value) return true;
    if (memo.scope.type === 'private') return memo.createdBy !== user.value?.id;
    if (memo.scope.type === 'group')
      return !!groupRole.value && !['trainer', 'mentor'].includes(groupRole.value);
    return false;
  }

  const memos = ref<ContentMemo[]>([]);

  const createMemo = useCreateMemo();
  async function addMemo(req: CreateMemoRequest) {
    const res = await createMemo.execute(req);
    if (isSucceeded(res)) {
      memos.value = [
        ...memos.value,
        {
          memo: res.memo,
          position: toTipPosition(res.memo.referTo),
          disabled: isDisabledMemo(res.memo),
          disabledVersion: isDisabledVersion(res.memo.referTo),
        },
      ];
      return true;
    }
    return res.errors;
  }

  const updateMemoBody = useUpdateMemoBody();
  async function changeMemoBody(req: UpdateMemoBodyRequest): Promise<void> {
    const res = await updateMemoBody.execute(req);
    if (isSucceeded(res)) {
      const { memo } = res;
      const items = memos.value.filter((item) => item.memo.id !== memo.id);
      const original = memos.value.find((item) => item.memo.id === memo.id);
      if (!original) return;
      items.push({ ...original, memo });
      memos.value = items;
    }
  }

  const updateMemoRoles = useChangeScopeRolesOfMemo();
  async function changeMemoRoles(req: ChangeScopeRolesOfMemoRequest): Promise<void> {
    const res = await updateMemoRoles.execute(req);
    if (isSucceeded(res)) {
      const { memo } = res;
      const items = memos.value.filter((item) => item.memo.id !== memo.id);
      const original = memos.value.find((item) => item.memo.id === memo.id);
      if (!original) return;
      items.push({ ...original, memo });
      memos.value = items;
    }
  }

  const deleteMemo = useRemoveMemo();
  async function removeMemo(req: RemoveMemoRequest): Promise<void> {
    const res = await deleteMemo.execute(req);
    if (isSucceeded(res)) {
      memos.value = memos.value.filter((item) => item.memo.id !== req.id);
    }
  }

  const questionAvailable = computed(
    () => checkLimitation('question', adminMode.value) === 'enabled'
  );

  const pageValue = ref<string>();
  const pages = ref<Page[]>([]);

  let funcChangedPageValue: Optional<FuncSaveScrollPositionPageChanged>;
  function onChangePageValue(func: FuncSaveScrollPositionPageChanged) {
    funcChangedPageValue = func;
  }

  function changePageValue(v: Optional<string>) {
    const o = pageValue.value;
    pageValue.value = v;
    if (o !== v && funcChangedPageValue) funcChangedPageValue(v, o);
  }

  function addPage(page: Page) {
    if (pages.value.some((item) => item.value === page.value)) return;
    pages.value.push(page);
  }

  function removePage(value: string) {
    pages.value = pages.value.filter((item) => item.value !== value);
  }

  function addWorkbookPage() {
    if (pages.value.some((item) => item.value === 'workbook')) return false;
    const wb = {
      value: 'workbook',
      label: msgs.of('workbook').value,
      icon: 'mdi-checkbox-multiple-marked-outline',
    };
    const textIndex = pages.value.findIndex((item) => item.value === 'text');
    if (textIndex === -1) {
      pages.value.push(wb);
      return true;
    }
    pages.value.splice(textIndex + 1, 0, wb);
    return true;
  }

  const questions = ref<ContentQuestion[]>([]);

  function addQuestionPage(question: QuestionWithNames) {
    if (questions.value.some((item) => item.question.id === question.id)) return;
    questions.value.push({
      question,
      position: toTipPosition(question.referTo),
      disabledVersion: isDisabledVersion(question.referTo),
    });
    addPage({
      value: question.id,
      label: question.title,
      icon: 'mdi-note-outline',
      clearable: true,
    });
  }

  const getQuestion = useGetQuestion();
  async function fetchQuestion(id: string) {
    const res = await getQuestion.execute({ id });
    if (isSucceeded(res) && res.question) return res.question;
    return undefined;
  }

  async function fetchQuestionRetry(id: string) {
    const question = await fetchQuestion(id);
    if (question) return question;
    if (config().app.fetchRetry.interval < 0 || config().app.fetchRetry.max <= 0) return undefined;
    return new Promise<Optional<QuestionWithNames>>((resolve) => {
      let count = 0;
      const { start } = useTimeoutFn(async () => {
        const ret = await fetchQuestion(id);
        if (ret) {
          resolve(ret);
        } else if (count < config().app.fetchRetry.max) {
          count += 1;
          start();
        } else {
          resolve(undefined);
        }
      }, config().app.fetchRetry.interval * 1000);
    });
  }

  async function fetchQuestionPage(id: string) {
    if (questions.value.some((item) => item.question.id === id)) {
      const res = await fetchQuestionRetry(id);
      if (res) {
        const q = questions.value.find((item) => item.question.id === id);
        if (q) q.question = res;
      }
    } else {
      const page: Page = {
        value: id,
        label: '...',
        icon: 'mdi-note-outline',
        clearable: true,
        loading: true,
      };
      addPage(page);
      const res = await fetchQuestionRetry(id);
      if (res) {
        page.label = res.title;
        questions.value.push({
          question: res,
          position: toTipPosition(res.referTo),
          disabledVersion: isDisabledVersion(res.referTo),
        });
      }
      page.loading = false;
    }
    return questions.value.find((q) => q.question.id === id);
  }

  function removeQuestionPage(id: string) {
    removePage(id);
    questions.value = questions.value.filter((item) => item.question.id !== id);
  }

  const questionCondition = ref<QuestionCondition>();
  const questionListAttrs = ref<QuestionListAttrs>();
  function changeQuestionCondition(v: QuestionCondition) {
    questionCondition.value = v;
  }

  function clearQuestionCondition() {
    questionCondition.value = {
      text: '',
      target: ['title', 'createdBy', 'assignee', 'content', 'unsolved', 'solved', 'referToContent'],
      userId: user.value?.id,
      contentId: content.value?.id,
    };
  }

  function changeQuestionListAttrs(v?: QuestionListAttrs) {
    questionListAttrs.value = v;
  }

  const {
    value: problemIndex,
    init: initProblems,
    move: changeProblemIndex,
    ...problemController
  } = useProblemController({
    content: computed(() => {
      if (!content.value) return undefined;
      const { type, id, version } = content.value;
      const key = [id, version, groupId.value].join('_');
      if (
        type === 'text' &&
        'workbook' in content.value &&
        content.value.workbook &&
        content.value.workbook.problems.length > 0
      ) {
        return { key, type: 'workbook' as const, workbook: content.value.workbook };
      }
      if (type === 'exam') {
        return { key, type: 'exam' as const, body: content.value.body };
      }
      return undefined;
    }),
    markers: computed(() =>
      getEnableMarkers(memos.value, questions.value, { isProblemMarker: true }).map(
        (item) => item.position as MarkerProblem
      )
    ),
    enableResults: (problems, base) => {
      if (base?.type !== 'workbook') return false;
      return problems.every((item) => item.passed);
    },
  });

  const headingId = ref<string>();
  function changeHeadingId(id: Optional<string>) {
    headingId.value = id;
  }
  let getHeadingId: () => void;
  function onFetchHeadingId(func: () => void) {
    getHeadingId = func;
  }
  function fetchHeadingId() {
    if (!getHeadingId) return;
    getHeadingId();
  }

  const dialog = ref<DialogAttrs>();
  function openDialog(d: DialogAttrs) {
    dialog.value = d;
  }

  function clearDialog() {
    dialog.value = undefined;
  }

  const temp = ref<ContentMarkerTemp>();
  const enableMarking = computed(() => !!temp.value);
  const enableMarkingPage = computed(
    () =>
      (pageValue.value && ['text', 'exam'].includes(pageValue.value)) ||
      (pageValue.value === 'workbook' &&
        problemIndex.value !== undefined &&
        problemIndex.value !== 'results')
  );
  const markedItem = computed<
    Optional<{ id: string; type: ContentMarkerTempType; marker: Marker }>
  >(() => {
    if (!temp.value || !temp.value.marker) return undefined;
    return { id: 'marker_temp', type: temp.value.type, marker: temp.value.marker };
  });

  function prepareMarker(type: ContentMarkerTempType = 'unknown') {
    temp.value = { type };
  }

  function finishMarker() {
    temp.value = undefined;
  }

  function changeMarkerColor(color: string) {
    if (!temp.value || !temp.value.marker) return;
    const selection = { ...temp.value.marker.selection, color };
    temp.value = { ...temp.value, marker: { ...temp.value.marker, selection } };
  }

  function mark(marker?: MarkerText | MarkerProblem) {
    if (!temp.value) return;
    temp.value = { ...temp.value, marker };
  }

  const openContent = useOpenContent();
  function fetched(res: GetContentResponse, options: string[] = []) {
    content.value = res.content;
    course.value = res.course;
    if (!groupId.value || !content.value || !course.value) return;

    const { id: contentId, version: contentVersion, type: contentType } = content.value;
    clearQuestionCondition();

    memos.value = res.memos.map((memo) => ({
      memo,
      position: toTipPosition(memo.referTo),
      disabled: isDisabledMemo(memo),
      disabledVersion: isDisabledVersion(memo.referTo),
    }));

    if (contentType === 'text') {
      initProblems({ enableFailureCounts: true, enablePassed: true });
      const hasWorkbook = content.value.workbook && content.value.workbook.problems.length > 0;
      const isWorkbookOnly = !content.value.body.body && hasWorkbook;
      if (options.includes('index') && !isWorkbookOnly)
        addPage({
          value: 'index',
          label: msgs.of('index').value,
          icon: 'mdi-format-list-bulleted',
        });
      if (options.includes('memo'))
        addPage({ value: 'memo', label: msgs.of('memo').value, icon: 'mdi-note-multiple-outline' });
      if (!isWorkbookOnly)
        addPage({ value: 'text', label: msgs.of('text').value, icon: 'mdi-text' });
      if (isWorkbookOnly || (options.includes('workbook') && hasWorkbook)) addWorkbookPage();
    } else if (contentType === 'exam') {
      initProblems({ enablePassed: true });
      if (options.includes('memo'))
        addPage({ value: 'memo', label: msgs.of('memo').value, icon: 'mdi-note-multiple-outline' });
      addPage({
        value: 'exam',
        label: msgs.of('exam').value,
        icon: 'mdi-checkbox-multiple-marked-outline',
      });
    }
    const { id: courseId } = course.value;
    openContent.execute({ groupId: groupId.value, contentId, courseId, contentVersion });
  }

  let markedContentFinish: Optional<MarkedContentFinish>;
  function markContentFinish(payload?: MarkedContentFinish) {
    markedContentFinish = payload ?? true;
  }

  function getUpdateReviewProblems(
    workbook: Optional<GetContentContentWorkbook>,
    marked: MarkedContentFinish
  ) {
    if (!workbook || typeof marked === 'boolean') return { reviewProblem: undefined };
    const parseBody = (problem: Problem) => {
      if (!problem.headerId) return problem.body;
      const header = workbook.problemHeaders.find((item) => item.id === problem.headerId);
      if (!header) return problem.body;
      if (!problem.body) return header.body;
      return [header.body, problem.body].join('\n\n');
    };
    const { indexes } = marked.review;
    const problems = workbook.problems
      .filter((item) => indexes.includes(item.index) && !item.isReviewProblemRegistered)
      .map((p) => {
        const { index, ...x } = p;
        return { ...x, body: parseBody(p), originalIndex: index };
      });
    const problemsToRemove = workbook.problems
      .filter((item) => !indexes.includes(item.index) && item.isReviewProblemRegistered)
      .map((p) => ({ body: parseBody(p), answer: p.answer, options: p.options }));
    return { reviewProblem: { problems, problemsToRemove } };
  }

  const exitContent = useExitContent();
  const finishContent = useFinishContent();
  function dispose() {
    if (groupId.value && content.value && course.value) {
      problemController.remove();
      const { id: contentId, version: contentVersion, type } = content.value;
      const { id: courseId } = course.value;
      if (markedContentFinish) {
        const review =
          type === 'text'
            ? getUpdateReviewProblems(content.value.workbook, markedContentFinish)
            : undefined;
        finishContent.execute({
          groupId: groupId.value,
          courseId,
          contentId,
          contentVersion,
          alreadyFinished: content.value?.learningStatus === 'completed',
          ...review,
        });
      } else {
        exitContent.execute({ groupId: groupId.value, courseId, contentId, contentVersion });
      }
    }
    adminMode.value = false;
    groupId.value = undefined;
    course.value = undefined;
    content.value = undefined;
    memos.value = [];
    markedContentFinish = undefined;
    pages.value = [];
    pageValue.value = undefined;
    questions.value = [];
    questionCondition.value = undefined;
    questionListAttrs.value = undefined;
    dialog.value = undefined;
  }

  const getContent = useGetContent();
  async function fetch(req: GetContentRequest, optionPages: string[], admin: boolean) {
    dispose();
    adminMode.value = admin;
    groupId.value = req.groupId;
    loading.value = true;
    const res = await getContent.execute(req);
    if (isSucceeded(res)) fetched(res, optionPages);
    loading.value = false;
  }

  return {
    content: readonly(content),
    course: readonly(course),
    memos: readonly(memos),
    loading: readonly(loading),
    notFound,
    courseName,
    questionAvailable,
    page: readonly(pageValue),
    pages: readonly(pages),
    questions: readonly(questions),
    questionListAttrs: readonly(questionListAttrs),
    questionCondition: readonly(questionCondition),
    headingId: readonly(headingId),
    dialog: readonly(dialog),
    problemIndex,
    problemController,
    markedItem,
    enableMarking,
    enableMarkingPage,
    getEnableMarkers,
    addMemo,
    changeMemoBody,
    changeMemoRoles,
    removeMemo,
    onChangePageValue,
    changePageValue,
    addWorkbookPage,
    addQuestionPage,
    fetchQuestionPage,
    removeQuestionPage,
    changeQuestionCondition,
    clearQuestionCondition,
    changeQuestionListAttrs,
    changeProblemIndex,
    changeHeadingId,
    onFetchHeadingId,
    fetchHeadingId,
    openDialog,
    clearDialog,
    prepareMarker,
    finishMarker,
    changeMarkerColor,
    mark,
    markContentFinish,
    fetch,
    dispose,
  };
}

export type ContentStore = ReturnType<typeof useContentStore>;

export const ContentStoreKey = injectionKeyOf<ContentStore>({
  boundedContext: 'training',
  type: 'store',
  name: 'ContentStore',
});
