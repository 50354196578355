






































import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import ProblemResultsView from '@/base/app/components/molecules/ProblemResultsView.vue';
import { ProblemUtilsProblem } from '@/base/app/utils/ProblemUtils';

import { PropsCourseReviewSetting, useCourseReviewSetting } from './CourseReviewSettingComposable';

type Props = PropsCourseReviewSetting;

export default defineComponent({
  name: 'TrainingCourseReviewSetting',
  components: { BaseButton, ProblemResultsView, ContentFooter },
  props: {
    groupId: { type: String, required: true },
    courseId: { type: String, required: true },
    courseName: { type: String, required: true },
    contentName: { type: String, required: true },
    problems: { type: Array as PropType<ProblemUtilsProblem[]>, default: () => [] },
    showProminent: { type: Boolean, default: false },
    isMobile: { type: Boolean, default: false },
  },
  emits: ['update', 'back'],
  setup(props: Props, { emit }: SetupContext) {
    return useCourseReviewSetting(props, emit);
  },
});
