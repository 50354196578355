var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('my-app',{attrs:{"group-id":_vm.id,"auth-rules":"supervisor|admin|trainer|mentor|trainee","loading":_vm.loading,"not-found":_vm.notFound,"show-prominent-extension":"","show-switch-mobile":"","app-bar-image":_vm.courseImage,"app-bar-class":_vm.courseColorClass},scopedSlots:_vm._u([{key:"header-start",fn:function(ref){
var mode = ref.mode;
return [(mode === 'mobile')?_c('span'):(!_vm.loading && !_vm.notFound)?_c('content-app-header-start',{attrs:{"group-id":_vm.id,"heading-id":_vm.headingId},on:{"opened":_vm.fetchHeadingId}}):_vm._e()]}},{key:"header-label",fn:function(ref){
var mode = ref.mode;
var topHeight = ref.topHeight;
return [(!_vm.loading && !_vm.notFound && mode === 'desktop')?_c('content-app-header-label',{staticClass:"flex-grow-1 flex-shrink-1",attrs:{"top-height":topHeight}}):_c('div',{staticClass:"flex-grow-1 flex-shrink-1"})]}},{key:"header-end",fn:function(ref){
var mode = ref.mode;
var admin = ref.admin;
return [(mode === 'mobile')?_c('span'):(!_vm.loading && !_vm.notFound && !(_vm.disabled || admin))?_c('content-app-header-end',{attrs:{"group-id":_vm.id,"group-role":_vm.groupRole,"question-available":_vm.questionAvailable}}):_vm._e()]}},{key:"header-prominent",fn:function(){return [(!_vm.loading && !_vm.notFound)?_c('content-app-header-prominent',{attrs:{"group-id":_vm.id}}):_vm._e()]},proxy:true},{key:"header-extension",fn:function(){return [(!_vm.loading && !_vm.notFound)?_c('content-app-header-extension-tabs'):_vm._e()]},proxy:true},{key:"header-img",fn:function(ref){
var props = ref.props;
return [(_vm.courseImage)?_c('v-img',_vm._b({},'v-img',props,false)):_vm._e()]}},{key:"tool-bar",fn:function(ref){
var admin = ref.admin;
var topHeight = ref.topHeight;
var mode = ref.mode;
return [(!_vm.loading && !_vm.notFound && mode === 'mobile' && !(_vm.disabled || admin))?_c('content-app-tool-bar',{attrs:{"group-role":_vm.groupRole,"question-available":_vm.questionAvailable,"top-height":topHeight}}):_vm._e()]}},{key:"default",fn:function(ref){
var mode = ref.mode;
var admin = ref.admin;
var topHeight = ref.topHeight;
var sideWidth = ref.sideWidth;
var side = ref.side;
var showProminent = ref.showProminent;
var shrinkLimit = ref.shrinkLimit;
return [_c('v-container',{attrs:{"fluid":""}},[(mode === 'desktop')?_c('content-container',{attrs:{"group-id":_vm.id,"course-id":_vm.courseId,"content-id":_vm.contentId,"content-version":_vm.contentVersion,"question":_vm.question,"memo":_vm.memo,"problem":_vm.problem,"hash":_vm.hash,"disabled":_vm.disabled || admin,"admin-mode":admin,"top-height":topHeight,"side-width":sideWidth,"side":side},on:{"change-heading-id":_vm.changeHeadingId}}):(mode === 'mobile')?_c('content-container-mobile',{attrs:{"group-id":_vm.id,"course-id":_vm.courseId,"content-id":_vm.contentId,"content-version":_vm.contentVersion,"question":_vm.question,"problem":_vm.problem,"hash":_vm.hash,"disabled":_vm.disabled,"admin-mode":admin,"top-height":topHeight,"show-prominent":showProminent,"shrink-limit":shrinkLimit},on:{"change-heading-id":_vm.changeHeadingId}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }