
























































































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import User from '@/base/app/components/atoms/User.vue';
import BaseDialogConfirm from '@/base/app/components/molecules/BaseDialogConfirm.vue';
import BaseDialogFullScreen from '@/base/app/components/molecules/BaseDialogFullScreen.vue';
import BaseTable from '@/base/app/components/molecules/BaseTable.vue';
import HintIcon from '@/base/app/components/molecules/HintIcon.vue';

import GroupExamOpenForm from '../molecules/GroupExamOpenForm.vue';
import { useGroupExamOpenDialog } from './GroupExamOpenDialogComposable';

export default defineComponent({
  name: 'TrainingGroupExamOpenDialog',
  components: {
    BaseDialogFullScreen,
    BaseDialogConfirm,
    BaseButton,
    BaseTable,
    GroupExamOpenForm,
    User,
    HintIcon,
  },
  emits: ['done'],
  setup(_, { emit }: SetupContext) {
    return useGroupExamOpenDialog(emit);
  },
});
