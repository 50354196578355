import { computed, onUnmounted, ref } from '@vue/composition-api';
import { useIntervalFn } from '@vueuse/core';
import moment from 'moment';

import { LocalDateTime } from '@/base/types';

import { useColor } from '../../utils/ColorUtils';

function formatHMS(value: number) {
  const h = Math.floor(value / 3600);
  const m = Math.floor((value - h * 3600) / 60);
  const s = value - h * 3600 - m * 60;
  let hh: string;
  if (h < 1) hh = '';
  else if (h < 10) hh = `0${h}:`;
  else hh = `${h}:`;
  return `${hh}${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`;
}

type Target = {
  startedAt: LocalDateTime;
  timeLimit?: number;
  base?: {
    server: LocalDateTime;
    local: LocalDateTime;
  };
};

export function useTimer(emit: (name: string) => void) {
  const seconds = ref(0);
  const target = ref<Target>();
  const goal = computed(() => (target.value?.timeLimit || 0) * 60);

  const { start: timerStart, stop: timerStop } = useIntervalFn(
    () => {
      if (goal.value > 0 && goal.value <= seconds.value) {
        timerStop();
        emit('time-over');
        return;
      }
      seconds.value += 1;
    },
    1000,
    false
  );

  function stop() {
    timerStop();
    seconds.value = 0;
    target.value = undefined;
  }
  onUnmounted(stop);

  function start(payload: Target) {
    target.value = payload;
    let diff = 0;
    if (payload.base) diff = payload.base.server.diff(payload.base.local, 's');
    const now = moment().add(diff, 's');
    seconds.value = now.diff(payload.startedAt, 's');
    timerStart();
  }

  const percent = computed(() => {
    if (goal.value <= 0) return undefined;
    return Math.round(((goal.value - seconds.value) / goal.value) * 1000) / 10;
  });
  const hms = computed(() => {
    if (goal.value <= 0) return formatHMS(seconds.value);
    if (goal.value < seconds.value) return '-';
    return formatHMS(Math.abs(goal.value - seconds.value));
  });
  const lastMinute = computed(() => goal.value > 0 && goal.value - seconds.value < 60);

  const { dark } = useColor();
  const progressColor = computed(() => (lastMinute.value ? 'error' : 'primary'));
  const progressStyle = computed(() => {
    if (lastMinute.value) return { opacity: 0.5 };
    if (dark.value) return { opacity: 0.8 };
    return { opacity: 0.2 };
  });
  const textClass = computed(() => {
    if (lastMinute.value) return 'error--text';
    return undefined;
  });

  return { percent, hms, progressColor, progressStyle, textClass, stop, start };
}

export type Timer = ReturnType<typeof useTimer>;
