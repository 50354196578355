var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-table',_vm._b({scopedSlots:_vm._u([{key:"end",fn:function(){return [_vm._t("end")]},proxy:true},{key:"default",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"id","fixed-header":""},scopedSlots:_vm._u([{key:"item.open",fn:function(ref){
var item = ref.item;
return [_vm._t("item-open",null,null,{ item: item })]}},{key:"item.version",fn:function(ref){
var item = ref.item;
return [_vm._t("item-version",null,null,{ item: item })]}}],null,true)},'v-data-table',attrs,false),on))]}}],null,true)},'base-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }