
















































import { computed, defineComponent, provide } from '@vue/composition-api';

import MyApp from '@/base/app/components/MyApp.vue';
import NotFound from '@/base/app/components/organisms/NotFound.vue';
import { useCourseColor } from '@/base/app/utils/ColorUtils';

import CourseReviewAppHeaderExtension from '../components/organisms/CourseReviewAppHeaderExtension.vue';
import CourseReviewAppHeaderProminent from '../components/organisms/CourseReviewAppHeaderProminent.vue';
import CourseReviewContainer from '../components/organisms/CourseReviewContainer.vue';
import { ReviewStoreKey, useReviewStore } from '../stores';

export default defineComponent({
  name: 'TrainingGroupCourseReviewPage',
  components: {
    MyApp,
    CourseReviewAppHeaderProminent,
    CourseReviewAppHeaderExtension,
    CourseReviewContainer,
    NotFound,
  },
  props: {
    id: { type: String, required: true },
    courseId: { type: String, required: true },
  },
  setup() {
    const store = useReviewStore();
    provide(ReviewStoreKey, store);

    const courseColorClass = computed(() => {
      if (!store.course.value) return undefined;
      const {
        color: courseColor,
        image: courseImage,
        fontColorOnImage: courseFontColorOnImage,
      } = store.course.value;
      const { courseColorClass: ret } = useCourseColor({
        courseColor,
        courseImage,
        courseFontColorOnImage,
      });
      return ret.value;
    });
    const courseImage = computed(() => store.course.value?.image);

    return {
      loading: store.loading,
      notFound: store.notFound,
      title: store.reviewName,
      courseColorClass,
      courseImage,
    };
  },
});
