import { useMessages } from '@/base/app';
import { requiredInject } from '@/utils/VueUtils';

import { ContentStoreKey } from '../../stores';

export function useContentAppHeaderProminent() {
  const store = requiredInject(ContentStoreKey);
  const msgs = useMessages({ prefix: 'training.organisms.contentAppHeaderProminent' });
  return { content: store.content, course: store.course, labelClosed: msgs.of('closed') };
}
