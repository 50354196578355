import { computed, ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';

import { QuestionCondition, QuestionFilterTargetType } from '../../utils/FilterUtils';

const DEFAULT_OPTIONS: { key: QuestionFilterTargetType; group?: string }[] = [
  { key: 'title' },
  { key: 'createdBy' },
  { key: 'assignee' },
  { key: 'content' },
  { key: 'unsolved', group: 'status' },
  { key: 'solved', group: 'status' },
  { key: 'own', group: 'other' },
  { key: 'referToContent', group: 'other' },
];

export type ContentQuestionCondition = QuestionCondition;

export type PropsContentQuestionSearchFields = {
  value: ContentQuestionCondition;
};

export function useContentQuestionSearchFields(
  props: PropsContentQuestionSearchFields,
  emit: (name: string, args: void | ContentQuestionCondition) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.contentQuestionSearchFields' });

  const menu = ref(false);

  const options = computed(() =>
    DEFAULT_OPTIONS.filter((item) => !item.group).map((item) => ({
      key: item.key,
      value: props.value.target.includes(item.key),
      label: msgs.of(item.key).value,
    }))
  );

  const statusOptions = computed(() =>
    DEFAULT_OPTIONS.filter((item) => item.group === 'status').map((item) => ({
      key: item.key,
      value: props.value.target.includes(item.key),
      label: msgs.of(item.key).value,
    }))
  );

  const otherOptions = computed(() =>
    DEFAULT_OPTIONS.filter((item) => item.group === 'other').map((item) => ({
      key: item.key,
      value: props.value.target.includes(item.key),
      label: msgs.of(item.key).value,
    }))
  );

  function toggleMenu() {
    menu.value = !menu.value;
  }

  function changeText(value: string) {
    emit('change', { ...props.value, text: value });
  }

  function changeValue(name: QuestionFilterTargetType, value: boolean) {
    const target = props.value.target.filter((key) => key !== name);
    if (value) target.push(name);
    emit('change', { ...props.value, target });
  }

  function openList() {
    if (!props.value?.text) return;
    emit('open-list');
  }

  function closeList() {
    emit('close-list');
  }

  function close() {
    emit('close');
  }

  return {
    menu,
    options,
    statusOptions,
    otherOptions,
    labelClose: msgs.of('close'),
    labelContents: msgs.of('contents'),
    labelCreatedBy: msgs.of('createdBy'),
    labelCreatedQuestion: msgs.of('createdQuestion'),
    labelAssignee: msgs.of('assignee'),
    labelLink: msgs.of('link'),
    placeholderSearch: msgs.of('search'),
    labelSearchSetting: msgs.of('searchSetting'),
    labelSolved: msgs.of('solved'),
    labelStatus: msgs.of('status'),
    labelTarget: msgs.of('target'),
    labelTitle: msgs.of('title'),
    labelUnsolved: msgs.of('unsolved'),
    toggleMenu,
    changeText,
    changeValue,
    openList,
    closeList,
    close,
  };
}
