
























import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import {
  ContentHeadingListContent,
  PropsContentHeadingList,
  useContentHeadingList,
} from './ContentHeadingListComposable';

type Props = PropsContentHeadingList;

export default defineComponent({
  name: 'TrainingContentHeadingList',
  inheritAttrs: false,
  props: {
    headingId: { type: String, default: undefined },
    content: { type: Object as PropType<ContentHeadingListContent>, required: true },
    limit: { type: Number, default: 3 },
    link: { type: Boolean, default: false },
  },
  emits: ['change', 'click'],
  setup(props: Props, { emit }: SetupContext) {
    return useContentHeadingList(props, emit);
  },
});
