import { computed, onMounted, ref, watch } from '@vue/composition-api';
import { Location } from 'vue-router';

import { useMessages } from '@/base/app';
import { UserAvatar } from '@/base/app/components/atoms/UserComposable';
import {
  UserSelectItem,
  UserSelectValue,
} from '@/base/app/components/molecules/UserSelectComposable';
import { getMarkerBackground } from '@/base/app/utils/ColorUtils';
import { toMarker } from '@/base/app/utils/MarkerUtils';
import { Comment, UserId } from '@/base/domains';
import { LocalDateTime, Optional } from '@/base/types';
import { QuestionContentReferenceWithNames } from '@/base/usecases';

import { QuestionTitleChangePayload } from './QuestionTitleComposable';

type CommentAndUser = Comment & { createdByUser?: UserAvatar };

export type QuestionHeaderMovePayload = Omit<
  QuestionContentReferenceWithNames,
  'selection' | 'problemIndex'
> & {
  groupId: string;
  problemIndex?: number;
};

export type QuestionHeaderChangeTitlePayload = QuestionTitleChangePayload;

export type QuestionHeaderChangeAssigneesPayload = {
  assignees: string[];
  done: () => void;
};

export type PropsQuestionHeader = {
  id: string;
  title: string;
  resolved: boolean;
  createdBy?: UserAvatar;
  createdAt?: LocalDateTime;
  resolvedBy?: UserAvatar;
  resolvedAt?: LocalDateTime;
  assignees: UserId[];
  referTo?: QuestionContentReferenceWithNames;
  lastComment?: CommentAndUser;
  groupId: string;
  users: UserSelectItem[];
  showRefresh: boolean;
  disabledReferTo: boolean;
};

export function useQuestionHeader(
  props: PropsQuestionHeader,
  emit: (
    name: string,
    arg:
      | QuestionHeaderMovePayload
      | QuestionHeaderChangeAssigneesPayload
      | QuestionHeaderChangeTitlePayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'training.molecules.questionHeader' });

  const avatarName = (a?: UserAvatar) =>
    a && a.id ? a.name || msgs.of('unknown', { id: a.id }).value : undefined;
  const creator = computed(() => ({ name: avatarName(props.createdBy), date: props.createdAt }));
  const commenter = computed(() => {
    if (!props.lastComment) return '';
    return { name: avatarName(props.lastComment.createdByUser), date: props.lastComment.createdAt };
  });
  const resolvedUserName = computed(() => avatarName(props.resolvedBy));

  const assigneeIds = ref<string[]>([]);
  const assigneeNames = computed(() => {
    const find = (id: string) => props.users.find((u) => u.id === id);
    return assigneeIds.value
      .map((id) => find(id)?.name || msgs.of('unknown', { id }).value)
      .sort()
      .join(',');
  });

  const link = computed<
    Optional<{
      to: Location;
      courseName: string;
      contentName: string;
      problemNo?: string;
      marker?: { caption: string; color: string; background: string };
      icon: string;
    }>
  >(() => {
    if (!props.referTo) return undefined;
    let index: number | undefined;
    let no: number | undefined;
    if ('problemIndex' in props.referTo) {
      index = props.referTo.problemIndex;
      no = index + 1;
    }

    const m = toMarker(props.referTo);

    return {
      to: {
        name: 'groupContentVersion',
        params: {
          id: props.groupId,
          courseId: props.referTo.courseId,
          contentId: props.referTo.contentId,
          contentVersion: props.referTo.contentVersion.toString(),
        },
        query: { question: props.id, problem: index?.toString() },
      },
      courseName: props.referTo.courseName,
      contentName: props.referTo.contentName,
      problemNo: no === undefined ? undefined : msgs.of('problem', { no }).value,
      marker: m
        ? { ...m.selection, background: getMarkerBackground(m.selection.color) }
        : undefined,
      icon: m ? 'mdi-marker' : 'mdi-book-outline',
    };
  });

  async function changeTitle(payload: QuestionTitleChangePayload) {
    emit('change-title', payload);
  }

  function move() {
    if (!props.referTo || props.disabledReferTo) return;
    if ('selection' in props.referTo) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { selection, ...payload } = props.referTo;
      emit('move', { ...payload, groupId: props.groupId });
      return;
    }
    emit('move', { ...props.referTo, groupId: props.groupId });
  }

  function changeAssignees(v: UserSelectValue) {
    if (!Array.isArray(v)) return;
    assigneeIds.value = v;
  }
  onMounted(() => {
    changeAssignees(props.assignees);
  });
  watch(() => props.assignees, changeAssignees);

  const loading = ref(false);
  function done() {
    loading.value = false;
  }

  function submitAssignees() {
    const idA = JSON.stringify([...props.assignees].sort());
    const idB = JSON.stringify([...assigneeIds.value].sort());
    if (idA === idB) return;
    loading.value = true;
    emit('change-assignees', { assignees: assigneeIds.value, done });
  }

  return {
    loading,
    creator,
    commenter,
    resolvedUserName,
    assigneeIds,
    assigneeNames,
    link,
    move,
    changeTitle,
    changeAssignees,
    submitAssignees,
    labelEdit: msgs.of('edit'),
  };
}
