import { computed, ref } from '@vue/composition-api';
import moment from 'moment';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { LocalDateTime } from '@/base/types';
import { assertIsDefined } from '@/utils/Asserts';

type GroupExamChangeScheduleDialogValue = {
  scheduledStart: LocalDateTime;
  scheduledFinish?: LocalDateTime;
};

export type GroupExamChangeScheduleDialogSubmitPayload = GroupExamChangeScheduleDialogValue & {
  done: () => void;
};

export function useGroupExamChangeScheduleDialog(
  emit: (name: string, args: GroupExamChangeScheduleDialogSubmitPayload) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();

  const dialog = ref(false);
  const loading = ref(false);
  const input = ref<GroupExamChangeScheduleDialogValue>({
    scheduledStart: moment(),
    scheduledFinish: undefined,
  });
  const originalValue = ref<GroupExamChangeScheduleDialogValue>();

  function close() {
    dialog.value = false;
  }

  function open(v: GroupExamChangeScheduleDialogValue) {
    originalValue.value = v;
    input.value = { ...v };
    dialog.value = true;
  }

  function done() {
    loading.value = false;
    close();
  }

  async function submit() {
    const valid = await observer.value?.validate();
    if (!valid) return;
    assertIsDefined(input.value, 'input');
    assertIsDefined(originalValue.value, 'original');

    const { scheduledStart: inS, scheduledFinish: inF } = input.value;
    const { scheduledStart: orgS, scheduledFinish: orgF } = originalValue.value;
    if (inS.isSame(orgS) && ((inF && orgF && inF.isSame(orgF)) || !(inF || orgF))) {
      close();
      return;
    }

    loading.value = true;
    emit('submit', { ...input.value, done });
  }

  const isStarted = computed(() => originalValue.value?.scheduledStart.isBefore());

  const msgs = useMessages({ prefix: 'training.molecules.groupExamChangeScheduleDialog' });
  return {
    observer,
    dialog,
    input,
    loading,
    isStarted,
    labelTitle: msgs.of('changeHoldingPeriod'),
    labelStartDate: msgs.of('scheduledStart'),
    labelEndDate: msgs.of('scheduledEnd'),
    labelChange: msgs.of('change'),
    labelClose: msgs.of('close'),
    close,
    open,
    submit,
  };
}

export type GroupExamChangeScheduleDialog = ReturnType<typeof useGroupExamChangeScheduleDialog>;
