import { computed, ref } from '@vue/composition-api';
import moment from 'moment';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import {
  ErrorMessage,
  ReplaceError,
} from '@/base/app/components/molecules/ErrorMessagesComposable';
import {
  TextEditorClickAnchorPayload,
  TextEditorClickUserPayload,
  TextEditorValue,
} from '@/base/app/components/molecules/TextEditorComposable';
import {
  MentionMarkdownClickAnchorPayload,
  MentionMarkdownClickMentionPayload,
} from '@/base/app/components/organisms/MentionMarkdownComposable';
import { useGlobalStore } from '@/base/app/store';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { Schedule, ScheduleTag } from '@/base/domains';
import { ApplicationError } from '@/base/error';
import { LocalDate, LocalDateTime } from '@/base/types';
import { isFailed } from '@/base/usecases';
import { SCHEDULE_TAGS_DO_NOT_EXIST } from '@/training/ErrorCodes';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { useCreateSchedule, useRemoveSchedule, useUpdateSchedule } from '../../../usecases';

type ScheduleForm = {
  title: string;
  body: TextEditorValue;
  date: LocalDate;
  start: LocalDateTime;
  end?: LocalDateTime;
  tagIds: string[];
};

type ScheduleDialogTarget = {
  id?: string;
  updatedBy?: string;
  groupId: string;
  disabled: boolean;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[], replace?: ReplaceError[], ok?: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors, replace, ok);
  }
  return { dialogFullScreen, dialog, opened, error };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function confirm(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, confirm };
}

export type ScheduleDialogClickAnchorPayload =
  | TextEditorClickAnchorPayload
  | MentionMarkdownClickAnchorPayload;

export type ScheduleDialogClickUserPayload =
  | TextEditorClickUserPayload
  | MentionMarkdownClickMentionPayload;

export type PropsScheduleDialog = {
  tags: ScheduleTag[];
};

export function useScheduleDialog(
  props: PropsScheduleDialog,
  emit: (
    name: string,
    arg?: ScheduleDialogClickAnchorPayload | ScheduleDialogClickUserPayload
  ) => void
) {
  const msgs = useMessages({ prefix: 'training.organisms.scheduleDialog' });
  const route = useRoute();
  const router = useRouter();
  const { dialogFullScreen, dialog, opened, error } = useDialogFullScreen();
  const { findUser } = useGlobalStore();
  const { confirmDialog, confirm } = useConfirmDialog();

  const observer = ref<InstanceType<typeof ValidationObserver>>();

  const target = ref<ScheduleDialogTarget>();
  const input = ref<ScheduleForm>();
  const loading = ref(false);

  const isUpdate = computed(() => !!target.value?.id);
  const lastUpdatedBy = computed(() => {
    const id = target.value?.updatedBy;
    if (!id) return undefined;
    const name = findUser(id)?.name || msgs.of('unknown', { id }).value;
    return msgs.of('updatedBy', { name }).value;
  });
  const height = computed(() => `calc(100vh - ${target.value?.disabled ? 176 : 274}px)`);
  const dialogProps = computed(() => {
    if (!target.value) return undefined;
    let key: string;
    if (target.value.disabled) key = 'scheduleReference';
    else if (isUpdate.value) key = 'scheduleUpdate';
    else key = 'scheduleCreate';
    return { title: msgs.of(key).value, disabled: target.value.disabled };
  });

  function close() {
    const hash = target.value?.id ? `#${target.value.id}` : undefined;
    dialog.value = { display: false };
    target.value = undefined;
    input.value = undefined;
    const to = clearDialogQuery(route, hash);
    if (to) router.replace(to);
  }

  function open(payload: { groupId: string; schedule?: Schedule }, copy = false, disabled = false) {
    if (dialog.value.display && input.value) {
      // すでに表示されている場合はタグのみ最新化する
      const tagIds =
        props.tags.filter((t) => input.value?.tagIds?.some((id) => t.id === id)).map((t) => t.id) ??
        [];
      input.value = { ...input.value, tagIds };
      return;
    }
    if (copy || !payload.schedule) {
      // コピー、新規
      target.value = { groupId: payload.groupId, id: undefined, disabled };
    } else {
      // 編集
      target.value = {
        groupId: payload.groupId,
        id: payload.schedule.id,
        updatedBy: payload.schedule.updatedBy ?? payload.schedule.createdBy,
        disabled,
      };
    }
    if (payload.schedule) {
      // コピー、編集
      input.value = {
        date: payload.schedule.start.clone().startOf('day'),
        start: payload.schedule.start,
        end: payload.schedule.end,
        title: payload.schedule.title,
        body: payload.schedule.body ?? '',
        tagIds: payload.schedule.tags.map((t) => t.id),
      };
    } else {
      // 新規
      input.value = {
        date: moment().startOf('day'),
        start: moment().startOf('hour').add(1, 'hour'),
        end: moment().startOf('hour').add(1, 'hour').add(30, 'minutes'),
        title: '',
        body: '',
        tagIds: [],
      };
    }
    dialog.value = { display: true };
  }

  async function change() {
    dialog.value.status = 'changed';
  }

  function changeTags(tagIds: string[]) {
    assertIsDefined(input.value);
    input.value = { ...input.value, tagIds };
    change();
  }

  async function validate() {
    assertIsDefined(observer.value);
    const valid = await observer.value.validate();
    return valid;
  }

  function concatDateTime(date: LocalDate, time?: LocalDateTime) {
    if (!time) return undefined;
    const arr = [date.format('YYYY/MM/DD'), time.format('HH:mm')];
    return moment(arr.join(' '), 'YYYY/MM/DD HH:mm');
  }

  function toMessageParams(aes: ApplicationError[]) {
    const tags = aes
      .map((e) => {
        if (!e.payload) return [];
        const p = e.payload as Parameters<typeof SCHEDULE_TAGS_DO_NOT_EXIST.toApplicationError>[0];
        return p?.tagIds.map((id) => props.tags.find((t) => t.id === id)?.text ?? id) ?? [];
      })
      .flat();
    return { tags: tags.join() };
  }

  const createSchedule = useCreateSchedule();
  const updateSchedule = useUpdateSchedule();
  async function save() {
    const valid = await validate();
    if (!valid) return;

    const replaceTR0007: ReplaceError = {
      from: 'TR0007',
      to: {
        prefix: 'training.organisms.scheduleDialog',
        key: 'failedChangeScheduleTagsNotExists',
        values: toMessageParams,
      },
    };

    assertIsDefined(target.value, 'target');
    assertIsDefined(input.value, 'input');
    const start = concatDateTime(input.value.date, input.value.start);
    assertIsDefined(start, 'start');
    const end = concatDateTime(input.value.date, input.value.end);
    assertIsDefined(end, 'end');
    dialog.value.status = 'updating';
    loading.value = true;
    if (target.value.id) {
      const ret = await updateSchedule.execute({
        start,
        end,
        tagIds: input.value.tagIds,
        title: input.value.title,
        body: input.value.body ?? '',
        id: target.value.id,
      });
      if (isFailed(ret)) {
        loading.value = false;
        dialog.value.status = 'changed';
        error(ret.errors, [replaceTR0007], () => {
          if (ret.errors.some((e) => e.code === 'TR0007')) emit('done');
        });
        return;
      }
    } else {
      const ret = await createSchedule.execute({
        start,
        end,
        tagIds: input.value.tagIds,
        title: input.value.title,
        body: input.value.body ?? '',
        groupId: target.value.groupId,
      });
      if (isFailed(ret)) {
        loading.value = false;
        dialog.value.status = 'changed';
        error(ret.errors, [replaceTR0007], () => {
          if (ret.errors.some((e) => e.code === 'TR0007')) emit('done');
        });
        return;
      }
      target.value = { ...target.value, id: ret.schedule.id };
    }
    loading.value = false;
    dialog.value.status = 'updated';
    emit('done');
    close();
  }

  const removeSchedule = useRemoveSchedule();
  async function remove() {
    assertIsDefined(target.value?.id, 'target');
    dialog.value.status = 'updating';
    loading.value = true;
    const ret = await removeSchedule.execute({ id: target.value.id });
    if (isFailed(ret)) {
      loading.value = false;
      dialog.value.status = 'changed';
      error(ret.errors);
      return;
    }
    loading.value = false;
    emit('done');
    close();
  }

  function confirmRemove() {
    confirm(msgs.of('confirmRemove').value, remove);
  }

  function clickAnchor(payload: TextEditorClickAnchorPayload | MentionMarkdownClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function clickUser(payload: TextEditorClickUserPayload | MentionMarkdownClickMentionPayload) {
    emit('click-user', payload);
  }

  return {
    dialogFullScreen,
    dialog,
    loading,
    observer,
    input,
    height,
    dialogProps,
    lastUpdatedBy,
    isUpdate,
    labelDate: msgs.of('date'),
    labelStart: msgs.of('start'),
    labelEnd: msgs.of('end'),
    labelTitle: msgs.of('title'),
    labelBody: msgs.of('body'),
    labelTag: msgs.of('tag'),
    labelTagManagement: msgs.of('tagManagement'),
    labelUpdate: msgs.of('update'),
    labelCreate: msgs.of('create'),
    labelRemove: msgs.of('remove'),
    confirmDialog,
    close,
    open,
    opened,
    changeTags,
    change,
    save,
    confirmRemove,
    clickAnchor,
    clickUser,
  };
}

export type ScheduleDialog = ReturnType<typeof useScheduleDialog>;
