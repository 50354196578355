import { computed, ref } from '@vue/composition-api';

import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { useMessages } from '@/base/app/Messages';
import { useGlobalStore } from '@/base/app/store';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import {
  GetGroupExamByTrainerGroupExam,
  isFailed,
  isSucceeded,
  useFinishGroupExam,
  useGetGroupExamByTrainer,
} from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { isDefined } from '@/utils/TsUtils';
import { useRoute, useRouter } from '@/utils/VueUtils';

type Target = {
  groupExamId: string;
  disabled: boolean;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(msg: string, ok?: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(msg, ok);
  }
  return { dialog, dialogFullScreen, opened, error, info };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function open(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, open };
}

export function useGroupExamDialog(emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'training.organisms.groupExamDialog' });
  const router = useRouter();
  const route = useRoute();
  const { groupRole, findUser } = useGlobalStore();

  const { dialog, dialogFullScreen, opened, error, info } = useDialogFullScreen();
  const { confirmDialog, open: confirm } = useConfirmDialog();
  const loading = ref(false);
  const target = ref<Target>();
  const groupExam = ref<GetGroupExamByTrainerGroupExam>();

  function calculate(v?: number) {
    const count = groupExam.value?.content.problems.length;
    if (!isDefined(v) || !isDefined(count)) return undefined;
    if (!v || !count) return 0;
    return Math.round((v / count) * 1000) / 10;
  }

  const passingStandardPer = computed(() => {
    if (!groupExam.value) return undefined;
    if ('passingStandard' in groupExam.value && isDefined(groupExam.value.passingStandard)) {
      return calculate(groupExam.value.passingStandard);
    }
    return undefined;
  });

  const getEndDate = (ge: GetGroupExamByTrainerGroupExam) => {
    if (!ge.finishedAt || !ge.scheduledFinish) return ge.scheduledFinish ?? ge.finishedAt;
    return ge.finishedAt.isBefore(ge.scheduledFinish) ? ge.finishedAt : ge.scheduledFinish;
  };

  const dialogProps = computed(() => {
    if (!groupExam.value) return undefined;
    const { content, times, scheduledStart } = groupExam.value;
    const subtitles = [
      msgs.of('examTerm', {
        start: scheduledStart.format('YYYY/MM/DD HH:mm'),
        end: getEndDate(groupExam.value)?.format('YYYY/MM/DD HH:mm') ?? '',
      }).value,
    ];
    if (passingStandardPer.value)
      subtitles.push(msgs.of('passingStandard', { value: passingStandardPer.value }).value);
    return {
      title: msgs.of('title', { contentName: content.name, times }).value,
      subtitle: subtitles.join(),
      disabled: target.value?.disabled,
    };
  });

  function close() {
    dialog.value = { display: false };
    target.value = undefined;
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  const getGroupExam = useGetGroupExamByTrainer();
  async function fetch() {
    assertIsDefined(target.value, 'target');
    loading.value = true;
    const res = await getGroupExam.execute({ id: target.value.groupExamId });
    if (isSucceeded(res)) groupExam.value = res.groupExam;
    else groupExam.value = undefined;
    loading.value = false;
  }

  async function open(payload: { groupExamId: string }, disabled: boolean) {
    target.value = { ...payload, disabled: disabled || groupRole.value !== 'trainer' };
    dialog.value = { display: true };
    await fetch();
    if (!groupExam.value) info(msgs.of('noData').value, close);
  }

  function done() {
    emit('done');
    fetch();
  }

  const finishExam = useFinishGroupExam();
  function finish() {
    assertIsDefined(target.value, 'target');
    const { groupExamId: id } = target.value;
    confirm(msgs.of('confirmFinish').value, async () => {
      loading.value = true;
      const res = await finishExam.execute({ id });
      if (isFailed(res)) {
        loading.value = false;
        error(res.errors);
        return;
      }
      waitTransition(done);
    });
  }

  const headerKeys = computed(() => {
    if (!groupExam.value) return [];
    const ret = ['name', 'statusName', 'scorePer', 'startedAt', 'finishedAt'];
    if (isDefined(passingStandardPer.value)) ret.splice(2, 0, 'passFail');
    return ret;
  });
  const items = computed(() => {
    if (!groupExam.value) return [];
    const ps = passingStandardPer.value;
    return groupExam.value?.userExams
      .map((u) => {
        const user = findUser(u.userId);
        return {
          ...u,
          scorePer: calculate(u.score),
          passingStandardPer: ps,
          name: user?.name || msgs.of('unknownUser', { id: u.userId }).value,
        };
      })
      .sort((a, b) => {
        if (a.name === b.name) return a.userId < b.userId ? -1 : 1;
        return a.name < b.name ? -1 : 1;
      });
  });
  const enableFinish = computed(() => groupExam.value?.status === 'in_progress');

  return {
    dialogFullScreen,
    confirmDialog,
    dialog,
    dialogProps,
    loading,
    groupExam,
    headerKeys,
    items,
    enableFinish,
    labelFinish: msgs.of('finishExam'),
    close,
    open,
    opened,
    finish,
    done,
    refresh: fetch,
  };
}

export type GroupExamDialog = ReturnType<typeof useGroupExamDialog>;
