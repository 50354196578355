import { computed, ref, watch } from '@vue/composition-api';
import { useWindowSize } from '@vueuse/core';

import { useMessages } from '@/base/app';
import { BaseDialogConfirm } from '@/base/app/components/molecules/BaseDialogConfirmComposable';
import { BaseDialogOk } from '@/base/app/components/molecules/BaseDialogOkComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { UpdateStatusIcon } from '@/base/app/components/molecules/UpdateStatusIconComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { delayScroll, escapeId, getAreaPropsBy, toStyleSize } from '@/base/app/utils/DomUtils';
import { waitTransition } from '@/base/app/utils/TransitionUtils';
import { MemoContentReference, MemoScope } from '@/base/domains';
import { assertIsDefined } from '@/utils/Asserts';
import { requiredInject, useRoute, useRouter } from '@/utils/VueUtils';

import { ContentStoreKey } from '../../stores';
import { ContentMarkerControlColor } from '../molecules/ContentMarkerControlComposable';
import { ContentMemoForm, ContentMemoFormValue } from '../molecules/ContentMemoFormComposable';

function useErrorDialog() {
  const errorDialog = ref<BaseDialogOk>();
  function error(errors: ErrorMessage[]) {
    assertIsDefined(errorDialog.value);
    errorDialog.value.error(errors);
  }
  return { errorDialog, error };
}

function useConfirmDialog() {
  const confirmDialog = ref<BaseDialogConfirm>();
  function confirm(msg: string, ok: () => void) {
    assertIsDefined(confirmDialog.value);
    confirmDialog.value.open(msg, ok);
  }
  return { confirmDialog, confirm };
}

type ContentMemoDialogTarget = {
  groupId: string;
  scopeType: 'private' | 'group';
};

export type PropsContentMemoDialog = {
  fullscreen: boolean;
  dialogClassName: string;
  containerClassName: string;
  width: number;
  height: number | string;
};

export function useContentMemoDialog(props: PropsContentMemoDialog) {
  const msgs = useMessages({ prefix: 'training.organisms.contentMemoDialog' });

  const store = requiredInject(ContentStoreKey);
  const { errorDialog, error } = useErrorDialog();
  const { confirmDialog, confirm } = useConfirmDialog();
  const router = useRouter();
  const route = useRoute();

  const dialog = ref(false);
  const mini = ref(false);
  const target = ref<ContentMemoDialogTarget>();
  const form = ref<ContentMemoForm>();
  const input = ref<ContentMemoFormValue>({ body: '', roles: [] });
  const errors = ref<ErrorMessage[]>();

  const marker = computed(() => {
    if (!dialog.value) return undefined;
    if (store.markedItem.value) return store.markedItem.value;
    if (store.enableMarking.value && !props.fullscreen) return { id: 'no-mark', type: 'question' };
    return undefined;
  });
  const markerColor = computed(() => {
    if (!marker.value) return undefined;
    if (!('marker' in marker.value)) return 'secondary';
    return marker.value.marker.selection.color ?? 'secondary';
  });
  const showMarkerHint = computed(() => store.enableMarkingPage.value && !props.fullscreen);

  function close() {
    dialog.value = false;
    mini.value = false;
    errors.value = undefined;
    if (store.dialog.value) {
      store.clearDialog();
      store.finishMarker();
    }
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }
  watch(dialog, (newVal) => {
    if (newVal) return;
    close();
  });

  function open(payload: ContentMemoDialogTarget) {
    if (!dialog.value && form.value) form.value.reset();
    dialog.value = true;
    target.value = payload;
    input.value = { body: '', roles: ['trainee', 'mentor', 'trainer'] };
  }
  watch(store.dialog, (newVal) => {
    if (dialog.value) {
      if (!newVal || newVal.name !== 'contentMemo') close();
      return;
    }
    if (!newVal || newVal.name !== 'contentMemo') return;
    open(newVal);
  });

  function opened() {
    return dialog.value;
  }

  function toggleMinimize() {
    if (props.fullscreen) return;
    mini.value = !mini.value;
  }

  function changeMarkerColor(color: ContentMarkerControlColor) {
    store.changeMarkerColor(color);
  }

  function removeMarkedItem() {
    store.mark();
  }

  function moveToMarker() {
    if (!marker.value || !('marker' in marker.value)) return;
    const content = store.content.value;
    assertIsDefined(content, 'content');
    const { type } = content;
    let page: 'text' | 'exam' | 'workbook' = type;
    if ('problemIndex' in marker.value.marker) {
      store.changeProblemIndex(marker.value.marker.problemIndex);
      if (type === 'text') page = 'workbook';
    }
    const changed = store.page.value !== page;
    store.changePageValue(page);
    const area = getAreaPropsBy(props.containerClassName);
    const y = (area?.areaPaddingTop ?? 0) + 8 + 8;
    delayScroll(escapeId(marker.value.id), { delay: changed ? 300 : 50, offsetY: y * -1 });
  }

  const status = ref<UpdateStatusIcon>();
  const updating = computed(() => status.value === 'updating');

  async function submit() {
    const content = store.content.value;
    assertIsDefined(content, 'content');
    assertIsDefined(target.value, 'target');

    const referTo: MemoContentReference = {
      contentId: content.id,
      contentVersion: content.version,
    };
    if (store.markedItem.value) {
      Object.assign(referTo, { selection: store.markedItem.value.marker.selection });
      if ('problemIndex' in store.markedItem.value.marker)
        Object.assign(referTo, { problemIndex: store.markedItem.value.marker.problemIndex });
    } else if (
      content.type === 'text' &&
      store.page.value === 'workbook' &&
      store.problemIndex.value !== 'results'
    ) {
      Object.assign(referTo, { problemIndex: store.problemIndex.value });
    } else if (content.type === 'exam') {
      Object.assign(referTo, { problemIndex: store.problemIndex.value });
    }

    const { groupId, scopeType } = target.value;
    const { body, roles } = input.value;
    const scope: MemoScope = scopeType === 'group' ? { type: 'group', roles } : { type: 'private' };

    status.value = 'updating';
    const ret = await store.addMemo({ groupId, body, scope, referTo });
    if (ret !== true) {
      status.value = 'changed';
      if (props.fullscreen) {
        error(ret);
      } else {
        errors.value = ret;
      }
      return;
    }
    store.finishMarker();
    waitTransition(() => {
      status.value = 'updated';
      close();
    });
  }

  function confirmSubmit() {
    const content = store.content.value;
    assertIsDefined(content, 'content');
    assertIsDefined(target.value, 'target');
    if (
      !content.open &&
      target.value.scopeType === 'group' &&
      input.value.roles.includes('trainee')
    ) {
      confirm(msgs.of('confirmClosedContent').value, submit);
      return;
    }
    submit();
  }

  const dialogAttrs = computed(() => {
    if (props.fullscreen) {
      return { fullscreen: true, persistent: updating.value };
    }
    const right = 24;
    let x = window.innerWidth - props.width;
    const contentClass = [props.dialogClassName];
    if (mini.value) {
      x = window.innerWidth - 88;
      contentClass.push(`${props.dialogClassName}--min`);
    }
    return {
      'position-x': x - right,
      'content-class': contentClass.join(' '),
      'close-on-click': false,
      'close-on-content-click': false,
      transition: 'scroll-y-transition',
      'max-height': toStyleSize(props.height),
    };
  });

  const windowSize = useWindowSize();
  const sheetAttrs = computed(() => {
    if (props.fullscreen) return { height: `${windowSize.height.value}px` };
    return { rounded: true };
  });

  const markerControlHeight = computed(() => {
    if (marker.value) return 42;
    return 0;
  });

  const formAttrs = computed(() => {
    if (props.fullscreen) {
      const header = 120;
      return {
        scopeType: target.value?.scopeType ?? 'private',
        height: `calc(${windowSize.height.value}px - ${toStyleSize(header)})`,
        width: 'min(600px, calc(100vw - 24px))',
        bodyHeightOffset: markerControlHeight.value,
      };
    }
    const header = 42;
    const padding = 20;
    return {
      scopeType: target.value?.scopeType ?? 'private',
      height: `calc(${toStyleSize(props.height)} - ${toStyleSize(header)})`,
      width: toStyleSize(props.width - padding),
      bodyHeightOffset: markerControlHeight.value,
    };
  });

  return {
    confirmDialog,
    errorDialog,
    dialog,
    mini,
    form,
    input,
    marker,
    markerColor,
    showMarkerHint,
    errors,
    status,
    updating,
    dialogAttrs,
    sheetAttrs,
    formAttrs,
    markerControlHeight,
    labelCreate: msgs.of('create'),
    labelClose: msgs.of('close'),
    labelMinimize: msgs.of('minimize'),
    labelMaximize: msgs.of('maximize'),
    title: msgs.of('createMemo'),
    close,
    open,
    opened,
    toggleMinimize,
    changeMarkerColor,
    removeMarkedItem,
    moveToMarker,
    submit: confirmSubmit,
  };
}

export type ContentMemoDialog = ReturnType<typeof useContentMemoDialog>;
