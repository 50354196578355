import { onMounted, ref, watch } from '@vue/composition-api';
import moment from 'moment';

import { useMessages } from '@/base/app';
import { findMentions } from '@/base/app/components/atoms/BaseMarkdownMention';
import { toQuery } from '@/base/app/components/atoms/ReturnButtonComposable';
import { createUserAvatar } from '@/base/app/components/atoms/UserComposable';
import {
  PropsQuestionBar,
  QuestionBarSelectPayload,
} from '@/base/app/components/molecules/QuestionBarComposable';
import { useGlobalStore } from '@/base/app/store';
import { Comment } from '@/base/domains';
import { LocalDateTime } from '@/base/types';
import { isFailed } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { useGetQuestions } from '../../../usecases';
import {
  filterQuestions,
  QuestionFilterConvert,
  QuestionFilterTargetType,
} from '../../utils/FilterUtils';
import { QuestionSearchFields } from '../molecules/QuestionSearchFieldsComposable';

const CONDITION_STATUS: QuestionFilterTargetType[] = ['solved', 'unsolved'];
const CONDITION_TARGET: QuestionFilterTargetType[] = [
  'title',
  'createdBy',
  'assignee',
  'courseName',
  'contentName',
  'content',
];
const SORT_KEYS = [
  'created-at-desc',
  'created-at-asc',
  'updated-at-desc',
  'updated-at-asc',
  'created-by-asc',
  'content-name-asc',
];

type Question = PropsQuestionBar;

type QuestionsReturnQuery = {
  s: string;
  f: string;
  t: string;
};

const DEFAULT_CONDITION: QuestionSearchFields = {
  text: '',
  target: ['title', 'createdBy', 'assignee', 'content', 'courseName', 'contentName', 'unsolved'],
  sort: SORT_KEYS[0],
};

export type PropsQuestions = {
  id: string;
  sort?: string;
  flags?: string;
  text?: string;
};

export function useQuestions(props: PropsQuestions) {
  const msgs = useMessages({ prefix: 'training.organisms.questions' });
  const route = useRoute();
  const router = useRouter();
  const { user, findUser } = useGlobalStore();

  const condition = ref<QuestionSearchFields>(DEFAULT_CONDITION);
  const questions = ref<Question[]>([]);
  const warn = ref<string>();
  const loading = ref(false);

  function compareTo(key: string, a?: object, b?: object) {
    const strA = a ? a[key] : '';
    const strB = b ? b[key] : '';
    return strA.localeCompare(strB);
  }

  function maxUpdatedAt(comments: Comment[]) {
    return moment.max(
      comments.flatMap((c) => [c.createdAt, c.editedAt]).filter((m) => m) as LocalDateTime[]
    );
  }

  const getQuestions = useGetQuestions();
  async function fetchQuestions() {
    warn.value = undefined;
    questions.value = [];
    const { text, target } = condition.value;
    if (CONDITION_STATUS.every((key) => !target.includes(key))) return;
    if (!!text && CONDITION_TARGET.every((key) => !target.includes(key))) return;

    assertIsDefined(user.value, 'user');
    const userId = user.value.id;

    let resolved: boolean | undefined;
    if (target.includes('solved') && !target.includes('unsolved')) {
      resolved = true;
    } else if (!target.includes('solved') && target.includes('unsolved')) {
      resolved = false;
    }

    const createdBy = target.includes('own') ? userId : undefined;
    const convert: QuestionFilterConvert = (t, q) => {
      switch (t) {
        case 'createdBy': {
          const u = findUser(q.createdBy);
          if (u) return [u.name];
          return [];
        }
        case 'assignee':
          return q.assignees.map((id) => findUser(id)?.name).filter((u) => !!u) as string[];
        case 'content':
          return q.comments
            .map((item) => findMentions(item.body, findUser).map((m) => `@${m.user.name}`))
            .flat();
        default:
      }
      return [];
    };

    loading.value = true;
    const res = await getQuestions.execute({ groupId: props.id, resolved, createdBy });
    loading.value = false;
    if (isFailed(res)) return;

    questions.value = filterQuestions(res.questions, { text, target, userId }, convert)
      .map((q) => ({
        question: q,
        createdBy: createUserAvatar(q.createdBy, findUser),
        resolvedBy: q.resolvedBy ? createUserAvatar(q.resolvedBy, findUser) : undefined,
        assignees: q.assignees.map((a) => createUserAvatar(a, findUser)),
        updatedAt: maxUpdatedAt(q.comments),
      }))
      .sort((a, b) => {
        switch (condition.value.sort) {
          case 'created-at-asc': {
            return a.question.createdAt.diff(b.question.createdAt);
          }
          case 'created-by-asc': {
            return compareTo('name', a.createdBy, b.createdBy);
          }
          case 'updated-at-desc': {
            return b.updatedAt.diff(a.updatedAt);
          }
          case 'updated-at-asc': {
            return a.updatedAt.diff(b.updatedAt);
          }
          case 'content-name-asc': {
            const i = compareTo('courseName', a.question.referTo, b.question.referTo);
            if (i === 0) return compareTo('contentName', a.question.referTo, b.question.referTo);
            return i;
          }
          default:
            return b.question.createdAt.diff(a.question.createdAt);
        }
      });
    if (res.limitExceeded) {
      const [q] = questions.value.slice(-1);
      warn.value = msgs.of('limitExceeded', {
        date: q?.question.createdAt.format('ll HH:mm') ?? '-',
      }).value;
    }
  }

  async function fetch() {
    await fetchQuestions();
  }

  function createReturnQuery(c: QuestionSearchFields): QuestionsReturnQuery {
    return {
      s: c.sort,
      f: c.target.map((k) => k.toLocaleLowerCase()).join(','),
      t: c.text?.slice(0, 100) || '',
    };
  }

  function init() {
    const value = { ...DEFAULT_CONDITION };
    if (props.sort && SORT_KEYS.some((key) => props.sort === key)) {
      value.sort = props.sort;
    }
    if (props.flags) {
      const keys = [...CONDITION_STATUS, ...CONDITION_TARGET].map((key) => ({
        key,
        low: key.toLowerCase(),
      }));
      const target = props.flags
        .split(',')
        .map((f) => {
          const k = keys.find((item) => item.low === f.toLocaleLowerCase());
          if (k) return k.key;
          return undefined;
        })
        .filter((key) => !!key) as QuestionFilterTargetType[];
      value.target = target;
    }
    if (props.text) {
      value.text = props.text;
    }
    condition.value = value;
    fetch();
  }
  onMounted(init);
  watch(() => props.id, init);

  function move(payload: QuestionBarSelectPayload) {
    const query = toQuery(route, createReturnQuery(condition.value));
    router.push({
      name: 'groupQuestion',
      params: { id: payload.groupId, questionId: payload.questionId },
      query,
    });
  }

  return {
    condition,
    questions,
    warn,
    loading,
    labelNoData: msgs.of('noData'),
    search: fetchQuestions,
    move,
  };
}
