import { ref } from '@vue/composition-api';
import { ValidationObserver } from 'vee-validate';

import { useMessages } from '@/base/app';
import {
  TextEditorClickAnchorPayload,
  TextEditorClickUserPayload,
  TextEditorValue,
} from '@/base/app/components/molecules/TextEditorComposable';
import { assertIsDefined } from '@/utils/Asserts';

type QuestionCommentAddForm = {
  body: TextEditorValue;
};

export type QuestionCommentAddSubmitPayload = QuestionCommentAddForm & {
  resolve: boolean;
  done: () => void;
};

export type QuestionCommentAddClickAnchorPayload = TextEditorClickAnchorPayload;

export type QuestionCommentAddClickUserPayload = TextEditorClickUserPayload;

export function useQuestionCommentAdd(
  emit: (
    name: string,
    arg:
      | QuestionCommentAddSubmitPayload
      | QuestionCommentAddClickAnchorPayload
      | QuestionCommentAddClickUserPayload
  ) => void
) {
  const observer = ref<InstanceType<typeof ValidationObserver>>();
  const input = ref<QuestionCommentAddForm>({ body: '' });
  const loading = ref(false);
  const loadingSolve = ref(false);
  function init() {
    loading.value = false;
    loadingSolve.value = false;
    input.value = { body: '' };
    if (observer.value) observer.value.reset();
  }

  async function submit() {
    assertIsDefined(observer.value);
    const valid = await observer.value.validate();
    if (!valid) return;
    loading.value = true;
    emit('submit', { ...input.value, resolve: false, done: init });
  }

  function solve() {
    loadingSolve.value = true;
    const body = input.value.body || '';
    emit('submit', { body, resolve: true, done: init });
  }

  function clickAnchor(payload: TextEditorClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  function clickUser(payload: TextEditorClickUserPayload) {
    emit('click-user', payload);
  }

  const msgs = useMessages({ prefix: 'training.molecules.questionCommentAdd' });
  return {
    observer,
    input,
    loading,
    loadingSolve,
    labelComment: msgs.of('comment'),
    placeholderNewComment: msgs.of('newComment'),
    labelSolve: msgs.of('solve'),
    submit,
    solve,
    clickAnchor,
    clickUser,
  };
}
