import { computed, ref } from '@vue/composition-api';
import moment from 'moment';

import { useProblemController } from '@/base/app/utils/ProblemUtils';
import { ActiveExam } from '@/base/domains';
import { LocalDateTime, Optional } from '@/base/types';
import { isFailed, useGetActiveExam } from '@/base/usecases';
import {
  FinishExamResponse,
  GetMyExamExam,
  useFinishExam,
  useGetMyExam,
  useStartExam,
} from '@/training/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { injectionKeyOf, readonly } from '@/utils/VueUtils';

const PAGES = ['not_started', 'in_progress', 'scored', 'finished', 'warning'] as const;
type Page = typeof PAGES[number];

export type UserExamStoreExam = {
  courseId: string;
  courseName: string;
  courseColor?: string;
  courseImage?: string;
  courseFontColorOnImage?: string;
  contentName: string;
  contentVersion: number;
  problemCount: number;
  times?: number;
  timeLimit?: number;
  startedAt?: LocalDateTime;
  finishedAt?: LocalDateTime;
  scheduledStart: LocalDateTime;
  scheduledFinish?: LocalDateTime;
};

export type UserExamStoreNow = {
  server: LocalDateTime;
  local: LocalDateTime;
};

export function useUserExamStore() {
  const page = ref<Page>('not_started');
  const activeExam = ref<Pick<ActiveExam, 'examId' | 'startedAt'>>();
  const myExam = ref<GetMyExamExam>();
  const myResult = ref<FinishExamResponse>();
  const loading = ref(false);
  const now = ref<UserExamStoreNow>();
  const existsOtherActive = ref(false);

  const status = computed(() => {
    if (!myExam.value) return undefined;
    if (myResult.value) return 'scored';
    const { status: v, groupExam } = myExam.value;
    if (groupExam.status === 'not_scheduled') return undefined;
    if (groupExam.status === 'announced') return 'scheduled';
    if (v === 'in_progress') return 'in_progress';
    if (groupExam.status === 'finished') return 'finished';
    if (v === 'skipped') return 'finished';
    return v;
  });

  function getStorageKey() {
    assertIsDefined(myExam.value, 'myExam');
    const { id } = myExam.value;
    return id;
  }

  const {
    value: problemIndex,
    problems,
    navigator,
    init: initProblem,
    exists: existsProblemData,
    ...problemController
  } = useProblemController({
    content: computed(() => {
      if (!myExam.value) return undefined;
      const { content } = myExam.value.groupExam;
      return { type: 'activeExam' as const, body: content };
    }),
  });

  function dispose() {
    activeExam.value = undefined;
    myExam.value = undefined;
    myResult.value = undefined;
    now.value = undefined;
    existsOtherActive.value = false;
  }

  function allocate() {
    const v = status.value;
    if (!v) return;
    if (v === 'in_progress' && !existsProblemData(getStorageKey())) {
      page.value = 'warning';
      return;
    }
    if (v === 'in_progress') {
      initProblem({
        key: getStorageKey(),
        converter: (x, s, _) => {
          const { index } = x;
          const r = s?.find((item) => item.index === index);
          return { ...x, ...r, index };
        },
      });
      problemController.first();
    }
    if (v === 'scheduled') {
      page.value = 'not_started';
      return;
    }
    page.value = v;
  }

  const getActiveExam = useGetActiveExam();
  async function fetchActiveExam(examId: string) {
    const res = await getActiveExam.execute({});
    if (isFailed(res)) return false;
    if (res.exam && res.exam.examId !== examId) {
      existsOtherActive.value = true;
      return false;
    }
    activeExam.value = res.exam;
    return true;
  }

  const getExam = useGetMyExam();
  async function fetchExam(examId: string) {
    const res = await getExam.execute({ id: examId });
    if (isFailed(res) || !res.exam) return false;
    myExam.value = res.exam;
    now.value = { server: res.now, local: moment() };
    return true;
  }

  async function fetch(examId: string) {
    loading.value = true;
    const [retMyExam] = await Promise.all([fetchExam(examId), fetchActiveExam(examId)]);
    loading.value = false;
    if (!retMyExam) {
      dispose();
      return false;
    }
    return true;
  }

  function reset() {
    if (status.value !== 'in_progress') return;
    initProblem({ key: getStorageKey() });
  }

  const startExam = useStartExam();
  async function start() {
    assertIsDefined(myExam.value, 'myExam');
    const { id: examId } = myExam.value;
    loading.value = true;
    const res = await startExam.execute({ examId });
    loading.value = false;
    if (isFailed(res)) return res.errors;
    initProblem({ key: getStorageKey() });
    const n = moment();
    activeExam.value = { examId: res.exam.id, startedAt: res.exam.startedAt ?? n };
    myExam.value = { ...myExam.value, status: 'in_progress' };
    now.value = { server: n, local: n };
    return true;
  }

  const scoring = ref(false);
  const finishExam = useFinishExam();
  async function score() {
    assertIsDefined(activeExam.value, 'activeExam');
    scoring.value = true;
    const { examId } = activeExam.value;
    const answers =
      problems.value?.map((item) => ({
        index: item.index,
        values: item.choiceIndexes,
      })) ?? [];
    const res = await finishExam.execute({ examId, answers });
    scoring.value = false;
    if (isFailed(res)) return res.errors;
    problemController.remove();
    activeExam.value = undefined;
    myResult.value = res;
    return true;
  }

  const notFound = computed(() => !myExam.value);
  const exam = computed<Optional<UserExamStoreExam>>(() => {
    if (!myExam.value) return undefined;
    const { course: cs, content: ct, times, timeLimit } = myExam.value.groupExam;
    return {
      courseId: cs.id,
      courseName: cs.name,
      courseColor: cs.color,
      courseImage: cs.image,
      courseFontColorOnImage: cs.fontColorOnImage,
      contentName: ct.name,
      contentVersion: ct.version,
      problemCount: ct.problems.length,
      times,
      timeLimit,
      startedAt: activeExam.value?.startedAt,
      finishedAt: myExam.value.finishedAt,
      scheduledStart: myExam.value.groupExam.scheduledStart,
      scheduledFinish: myExam.value.groupExam.scheduledFinish,
    };
  });

  return {
    page,
    status,
    loading,
    scoring,
    notFound,
    exam,
    problemIndex,
    problems,
    problemNavigator: navigator,
    problemController,
    results: readonly(myResult),
    now: readonly(now),
    existsOtherActive: readonly(existsOtherActive),
    allocate,
    fetch,
    reset,
    start,
    score,
  };
}

export type UserExamStore = ReturnType<typeof useUserExamStore>;

export const UserExamStoreKey = injectionKeyOf<UserExamStore>({
  boundedContext: 'training',
  type: 'store',
  name: 'UserExamStore',
});
