




















import { defineComponent, PropType, SetupContext } from '@vue/composition-api';

import ContentFooter from '@/base/app/components/molecules/ContentFooter.vue';
import { GetContentContent } from '@/training/usecases';

import ContentHeadingList from './ContentHeadingList.vue';
import { useContentTextIndexMobile } from './ContentTextIndexMobileComposable';
import ContentVersionWarning from './ContentVersionWarning.vue';

export default defineComponent({
  name: 'TrainingContentTextIndexMobile',
  components: { ContentVersionWarning, ContentHeadingList, ContentFooter },
  inheritAttrs: false,
  props: {
    groupId: { type: String, required: true },
    courseName: { type: String, required: true },
    content: { type: Object as PropType<GetContentContent>, required: true },
    toLatest: { type: Object as PropType<Location>, default: undefined },
    minHeight: { type: String, default: undefined },
  },
  emits: ['open'],
  setup(_, { emit }: SetupContext) {
    return useContentTextIndexMobile(emit);
  },
});
