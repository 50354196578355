








































import { defineComponent, SetupContext } from '@vue/composition-api';

import BaseButton from '@/base/app/components/atoms/BaseButton.vue';
import BaseMarkdown from '@/base/app/components/atoms/BaseMarkdown.vue';
import BaseTextDateTime from '@/base/app/components/atoms/BaseTextDateTime.vue';

import { useGroupExamChangeScheduleDialog } from './GroupExamChangeScheduleDialogComposable';

export default defineComponent({
  name: 'TrainingGroupExamChangeScheduleDialog',
  components: { BaseButton, BaseTextDateTime, BaseMarkdown },
  inheritAttrs: false,
  setup(_, { emit }: SetupContext) {
    return useGroupExamChangeScheduleDialog(emit);
  },
});
