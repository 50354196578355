import { computed, onMounted, ref, watch } from '@vue/composition-api';

import { getMarkerBackground, KNOWTE_MARKER_COLORS } from '@/base/app/utils/ColorUtils';
import { Optional } from '@/base/types';

export type MarkerControlTileMenusColor = string;

export type PropsMarkerControlTileMenus = {
  value: Optional<MarkerControlTileMenusColor>;
};

export function useMarkerControlTileMenus(
  props: PropsMarkerControlTileMenus,
  emit: (name: string, args: void | MarkerControlTileMenusColor) => void
) {
  const input = ref<MarkerControlTileMenusColor>();
  function init() {
    input.value = props.value;
  }
  onMounted(init);
  watch(() => props.value, init);

  function change(v?: string) {
    if (!v) return;
    emit('change', v);
  }

  function remove() {
    emit('remove');
  }

  const colors = computed(() =>
    KNOWTE_MARKER_COLORS.map((color) => {
      const background = getMarkerBackground(color);
      return { color, background };
    })
  );

  return { input, colors, change, remove };
}
