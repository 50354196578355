import { computed, onMounted } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import { ProblemResultsViewClickAnchorPayload } from '@/base/app/components/molecules/ProblemResultsViewComposable';
import { useProblemController } from '@/base/app/utils/ProblemUtils';
import { ExamResultVisibilityLevel, MyExamResult, Problem, ProblemHeader } from '@/base/domains';

import { UserExamStoreExam } from '../../stores';

export type UserExamResultsClickAnchorPayload = ProblemResultsViewClickAnchorPayload;

export type PropsUserExamResults = {
  exam: UserExamStoreExam;
  groupId: string;
  examResult: MyExamResult;
  problems: Problem[];
  problemHeaders: ProblemHeader[];
  visibilityLevel: ExamResultVisibilityLevel;
};

export function useUserExamResults(
  props: PropsUserExamResults,
  emit: (name: string, arg?: UserExamResultsClickAnchorPayload) => void
) {
  const problemController = useProblemController({
    content: computed(() => {
      if (props.visibilityLevel !== 'details') return undefined;
      return {
        type: 'exam' as const,
        body: { problems: props.problems, problemHeaders: props.problemHeaders },
      };
    }),
  });

  function init() {
    problemController.init({
      converter: (x, _, f) => {
        if (props.examResult.visibilityLevel !== 'details') return x;
        const answer = props.examResult.answers.find((item) => item.index === x.index);
        // scoredChoiceNosを選択結果に置き換え
        const scoredChoiceNos = answer ? f(answer.values as number[]) : [];
        // passedを正否(correct)に置き換え
        const passed = answer?.correct ?? false;
        return { ...x, scoredChoiceNos, passed };
      },
    });
  }
  onMounted(init);

  function complete() {
    emit('complete');
  }

  function clickAnchor(payload: ProblemResultsViewClickAnchorPayload) {
    emit('click-anchor', payload);
  }

  const msgs = useMessages({ prefix: 'training.molecules.userExamResults' });
  return {
    details: problemController.problems,
    description: msgs.of('description'),
    complete,
    clickAnchor,
  };
}
