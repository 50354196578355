import { ref } from '@vue/composition-api';

import { useMessages } from '@/base/app';
import {
  BaseDialogFullScreen,
  BaseDialogFullScreenValue,
} from '@/base/app/components/molecules/BaseDialogFullScreenComposable';
import { ErrorMessage } from '@/base/app/components/molecules/ErrorMessagesComposable';
import { clearDialogQuery } from '@/base/app/utils/DialogQueryUtils';
import { isSucceeded } from '@/base/usecases';
import { assertIsDefined } from '@/utils/Asserts';
import { useRoute, useRouter } from '@/utils/VueUtils';

import { useChangeGroupTrainingCoursesSort, useGetGroupTraining } from '../../../usecases';

type CourseItemCourse = {
  courseId: string;
  courseName: string;
  index: number;
};

function useDialogFullScreen() {
  const dialogFullScreen = ref<BaseDialogFullScreen>();
  const dialog = ref<BaseDialogFullScreenValue>({ display: false });
  function opened() {
    return dialog.value.display;
  }
  function error(errors: ErrorMessage[]) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showErrorDialog(errors);
  }
  function info(message: string, ok: () => void) {
    assertIsDefined(dialogFullScreen.value);
    dialogFullScreen.value.showDialog(message, ok);
  }
  return { dialogFullScreen, dialog, opened, error, info };
}

export function useCourseListDialog(emit: (name: string) => void) {
  const msgs = useMessages({ prefix: 'training.organisms.courseListDialog' });

  const { dialog, dialogFullScreen, opened, error, info } = useDialogFullScreen();

  const route = useRoute();
  const router = useRouter();
  const loading = ref(false);
  const courses = ref<CourseItemCourse[]>([]);
  const groupId = ref<string>();

  function close() {
    dialog.value = { display: false };
    courses.value = [];
    groupId.value = undefined;
    const to = clearDialogQuery(route);
    if (to) router.replace(to);
  }

  const getGroupTraining = useGetGroupTraining();
  async function fetchCourse() {
    loading.value = true;
    assertIsDefined(groupId.value, 'groupId');
    const res = await getGroupTraining.execute({ groupId: groupId.value });
    if (isSucceeded(res)) {
      courses.value =
        res.groupTrainingCourses.map((item, i) => ({
          ...item,
          index: i,
        })) ?? [];
    } else {
      info(msgs.of('noData').value, close);
    }
    loading.value = false;
  }

  function open(payload: { groupId: string }) {
    groupId.value = payload.groupId;
    dialog.value = { display: true };
    fetchCourse();
  }

  const changeGroupTrainingCoursesSort = useChangeGroupTrainingCoursesSort();
  async function changeOrderOfCourses() {
    dialog.value = { ...dialog.value, status: 'updating' };
    const courseIds = courses.value.map((c) => c.courseId);
    assertIsDefined(groupId.value, 'groupId');
    const res = await changeGroupTrainingCoursesSort.execute({
      id: groupId.value,
      courseIds,
    });
    if (isSucceeded(res)) {
      dialog.value = { ...dialog.value, status: 'updated' };
      courses.value = res.courses.map((item, index) => ({
        ...item,
        index,
      }));
      emit('done');
      return;
    }
    dialog.value = { ...dialog.value, status: 'changed' };
    error(res.errors);
  }

  return {
    open,
    opened,
    close,
    changeOrderOfCourses,
    dialog,
    dialogFullScreen,
    loading,
    courses,
    labelNoData: msgs.of('noData'),
    title: msgs.of('sortCourses'),
  };
}

export type CourseListDialog = ReturnType<typeof useCourseListDialog>;
